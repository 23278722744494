@charset "UTF-8";
/**======================================================================
=========================================================================
Template Name: Able Pro - Bootstrap Admin Template
Author: Phoenixcoded
Support: https://phoenixcoded.authordesk.app
File: style.css

1.  Generic-class css
2.  Custom css
3.  Sidebar css
4.  header css
5.  footer css
6.  Common layout css
7.  Compact layout css
8.  Horizontal layout css
9.  Tab layout css
10. Widget css 
11. Avtar css
12. Badge css
13. Button css
14. Dropdown css
15. Card css
16. Progress css
17. Table css
18. Tabs css
19. Form css
20. Authentication css
21. Maintaince css
22. Pages css
23. Icon layouts css
24. User card css
25. Application css
26. Sweetalert css
27. Lightbox css
28. Notification css
29. Modal css
30. Rangeslider css
31. Daterange css
32. Tagsinput css
33. Slider css
34. Switches css
35. Data Tables css
36. Customizer css

=========================================================================
=================================================================================== */
:root {
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --pc-heading-color: #1d2630;
  --pc-active-background: #f3f5f7;
  --pc-sidebar-background: transparent;
  --pc-sidebar-color: #5b6b79;
  --pc-sidebar-color-rgb: 91, 107, 121;
  --pc-sidebar-active-color: #0d6efd;
  --pc-sidebar-shadow: none;
  --pc-sidebar-caption-color: #3e4853;
  --pc-sidebar-border: 1px dashed #bec8d0;
  --pc-sidebar-user-background: #f3f5f7;
  --pc-header-background: rgba(var(--bs-body-bg-rgb), 0.7);
  --pc-header-color: #5b6b79;
  --pc-header-shadow: none;
  --pc-card-box-shadow: none;
  --pc-header-submenu-background: #ffffff;
  --pc-header-submenu-color: #5b6b79;
}

[data-pc-theme_contrast=true] {
  --bs-body-bg: #ffffff;
  --pc-sidebar-background: transparent;
  --pc-sidebar-active-color: #0d6efd;
  --pc-sidebar-shadow: 1px 0 3px 0px #dbe0e5;
  --pc-sidebar-border: none;
  --pc-card-box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
}

body {
  font-feature-settings: "salt";
}

h1, .h1,
h2,
.h2 {
  font-weight: 700;
}

/* $btn-border-radius: 12px;
$btn-border-radius-sm: 8px;
$btn-border-radius-lg: 14px; */
:root,
[data-bs-theme=light] {
  --bs-blue: #1A237E;
  --bs-indigo: #6610f2;
  --bs-purple: #673ab7;
  --bs-pink: #e83e8c;
  --bs-red: #dc2626;
  --bs-orange: #fd7e14;
  --bs-yellow: #e58a00;
  --bs-green: #2ca87f;
  --bs-teal: #008080;
  --bs-cyan: #3ec9d6;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-gray: #5b6b79;
  --bs-gray-dark: #1d2630;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #f3f5f7;
  --bs-gray-300: #dbe0e5;
  --bs-gray-400: #bec8d0;
  --bs-gray-500: #8996a4;
  --bs-gray-600: #5b6b79;
  --bs-gray-700: #3e4853;
  --bs-gray-800: #1d2630;
  --bs-gray-900: #131920;
  --bs-theme: #ff3366;
  --bs-primary: #1A237E;
  --bs-secondary: #5b6b79;
  --bs-success: #2ca87f;
  --bs-info: #3ec9d6;
  --bs-warning: #e58a00;
  --bs-danger: #dc2626;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-theme-rgb: 255, 51, 102;
  --bs-primary-rgb: 26, 35, 126;
  --bs-secondary-rgb: 91, 107, 121;
  --bs-success-rgb: 44, 168, 127;
  --bs-info-rgb: 62, 201, 214;
  --bs-warning-rgb: 229, 138, 0;
  --bs-danger-rgb: 220, 38, 38;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #757bb1;
  --bs-secondary-bg-subtle: #f8f9fa;
  --bs-success-bg-subtle: #c0e5d9;
  --bs-info-bg-subtle: #c5eff3;
  --bs-warning-bg-subtle: #f7dcb3;
  --bs-danger-bg-subtle: #f5bebe;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #bec8d0;
  --bs-primary-border-subtle: #5e65a4;
  --bs-secondary-border-subtle: #f3f5f7;
  --bs-success-border-subtle: #96d4bf;
  --bs-info-border-subtle: #9fe4eb;
  --bs-warning-border-subtle: #f2c580;
  --bs-danger-border-subtle: #ee9393;
  --bs-light-border-subtle: #f3f5f7;
  --bs-dark-border-subtle: #8996a4;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Inter var", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #131920;
  --bs-body-color-rgb: 19, 25, 32;
  --bs-body-bg: #f8f9fa;
  --bs-body-bg-rgb: 248, 249, 250;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: #262626;
  --bs-link-color: #1A237E;
  --bs-link-color-rgb: 26, 35, 126;
  --bs-link-decoration: none;
  --bs-link-hover-color: #151c65;
  --bs-link-hover-color-rgb: 21, 28, 101;
  --bs-link-hover-decoration: underline;
  --bs-code-color: #e83e8c;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fcf8e3;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e7eaee;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 8px;
  --bs-border-radius-sm: 6px;
  --bs-border-radius-lg: 10px;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.3625rem + 1.35vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.375rem;
  }
}

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.875rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 80%;
}

mark, .mark {
  padding: 0.2em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 80%;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 80%;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 80%;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 6px;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  color: #5b6b79;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 80%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 80%;
  color: #5b6b79;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8f9fa;
  border: 1px solid #e7eaee;
  border-radius: 8px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #5b6b79;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: #131920;
  --bs-table-bg: transparent;
  --bs-table-border-color: #e7eaee;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #131920;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #131920;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #131920;
  --bs-table-hover-bg: rgba(26, 35, 126, 0.02);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.9rem 0.75rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(1px * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.3rem 0.3rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #212529;
  --bs-table-bg: #d1d3e5;
  --bs-table-border-color: #bfc2d2;
  --bs-table-striped-bg: #c8cadc;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #bfc2d2;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #cdd0e1;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #212529;
  --bs-table-bg: #dee1e4;
  --bs-table-border-color: #cbced1;
  --bs-table-striped-bg: #d5d8db;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #cbced1;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #dadde0;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #212529;
  --bs-table-bg: #d5eee5;
  --bs-table-border-color: #c3dad2;
  --bs-table-striped-bg: #cce4dc;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #c3dad2;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #d1eae1;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #212529;
  --bs-table-bg: #d8f4f7;
  --bs-table-border-color: #c6dfe2;
  --bs-table-striped-bg: #cfeaed;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #c6dfe2;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #d4f0f3;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #212529;
  --bs-table-bg: #fae8cc;
  --bs-table-border-color: #e4d5bc;
  --bs-table-striped-bg: #efdec4;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #e4d5bc;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #f6e4c9;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #212529;
  --bs-table-bg: #f8d4d4;
  --bs-table-border-color: #e3c3c3;
  --bs-table-striped-bg: #edcbcb;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #e3c3c3;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #f4d1d1;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #212529;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #e3e4e5;
  --bs-table-striped-bg: #edeef0;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #e3e4e5;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #f4f5f6;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #25292d;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  color: var(--pc-heading-color);
}

.col-form-label {
  padding-top: calc(0.8rem + 1px);
  padding-bottom: calc(0.8rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  color: var(--pc-heading-color);
}

.col-form-label-lg {
  padding-top: calc(0.775rem + 1px);
  padding-bottom: calc(0.775rem + 1px);
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  font-size: 0.765625rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #5b6b79;
}

.form-control, .datatable-input, .custom-select,
.dataTable-selector,
.dataTable-input {
  display: block;
  width: 100%;
  padding: 0.8rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #131920;
  appearance: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #bec8d0;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control, .datatable-input, .custom-select,
  .dataTable-selector,
  .dataTable-input {
    transition: none;
  }
}
.form-control[type=file], [type=file].datatable-input, [type=file].custom-select,
[type=file].dataTable-selector,
[type=file].dataTable-input {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]), [type=file].datatable-input:not(:disabled):not([readonly]), [type=file].custom-select:not(:disabled):not([readonly]),
[type=file].dataTable-selector:not(:disabled):not([readonly]),
[type=file].dataTable-input:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus, .datatable-input:focus, .custom-select:focus,
.dataTable-selector:focus,
.dataTable-input:focus {
  color: #131920;
  background-color: #f8f9fa;
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: 0 0 0 1px rgba(var(--bs-primary-rgb), 0.1);
}
.form-control::-webkit-date-and-time-value, .datatable-input::-webkit-date-and-time-value, .custom-select::-webkit-date-and-time-value,
.dataTable-selector::-webkit-date-and-time-value,
.dataTable-input::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit, .datatable-input::-webkit-datetime-edit, .custom-select::-webkit-datetime-edit,
.dataTable-selector::-webkit-datetime-edit,
.dataTable-input::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder, .datatable-input::placeholder, .custom-select::placeholder,
.dataTable-selector::placeholder,
.dataTable-input::placeholder {
  color: #bec8d0;
  opacity: 1;
}
.form-control:disabled, .datatable-input:disabled, .custom-select:disabled,
.dataTable-selector:disabled,
.dataTable-input:disabled {
  background-color: #f3f5f7;
  opacity: 1;
}
.form-control::file-selector-button, .datatable-input::file-selector-button, .custom-select::file-selector-button,
.dataTable-selector::file-selector-button,
.dataTable-input::file-selector-button {
  padding: 0.8rem 0.75rem;
  margin: -0.8rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #131920;
  background-color: #f8f9fa;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button, .datatable-input::file-selector-button, .custom-select::file-selector-button,
  .dataTable-selector::file-selector-button,
  .dataTable-input::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button, .datatable-input:hover:not(:disabled):not([readonly])::file-selector-button, .custom-select:hover:not(:disabled):not([readonly])::file-selector-button,
.dataTable-selector:hover:not(:disabled):not([readonly])::file-selector-button,
.dataTable-input:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #ecedee;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.8rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #262626;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.7rem;
  font-size: 0.765625rem;
  border-radius: 6px;
}
.form-control-sm::file-selector-button {
  padding: 0.375rem 0.7rem;
  margin: -0.375rem -0.7rem;
  margin-inline-end: 0.7rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.55rem + 2px);
  padding: 0.775rem 0.85rem;
  font-size: 1.09375rem;
  border-radius: 10px;
}
.form-control-lg::file-selector-button {
  padding: 0.775rem 0.85rem;
  margin: -0.775rem -0.85rem;
  margin-inline-end: 0.85rem;
}

textarea.form-control, textarea.datatable-input, textarea.custom-select,
textarea.dataTable-selector,
textarea.dataTable-input {
  min-height: calc(1.5em + 1.6rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.55rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.6rem + 2px);
  padding: 0.8rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 8px;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 8px;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.75rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1.55rem + 2px);
}

.form-select, .datatable-selector {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231d2630' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.8rem 2rem 0.8rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #131920;
  appearance: none;
  background-color: #ffffff;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #bec8d0;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select, .datatable-selector {
    transition: none;
  }
}
.form-select:focus, .datatable-selector:focus {
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: 0 0 0 1px var(--bs-primary);
}
.form-select[multiple], [multiple].datatable-selector, .form-select[size]:not([size="1"]), [size].datatable-selector:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled, .datatable-selector:disabled {
  color: #5b6b79;
  background-color: #f3f5f7;
}
.form-select:-moz-focusring, .datatable-selector:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #131920;
}

.form-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.7rem;
  font-size: 0.765625rem;
  border-radius: 6px;
}

.form-select-lg {
  padding-top: 0.775rem;
  padding-bottom: 0.775rem;
  padding-left: 0.85rem;
  font-size: 1.09375rem;
  border-radius: 10px;
}

[data-bs-theme=dark] .form-select, [data-bs-theme=dark] .datatable-selector {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.75em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.75em;
}

.form-check-reverse {
  padding-right: 1.75em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.75em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: #ffffff;
  flex-shrink: 0;
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.125em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  print-color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}
.form-check-input[type=checkbox] {
  border-radius: 6px;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(var(--bs-primary), 0.25);
}
.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='var%28--bs-primary%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .introjs-tooltip .btn-check[disabled] + .introjs-button, .btn-check:disabled + .btn, .introjs-tooltip .btn-check:disabled + .introjs-button {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 1px rgba(var(--bs-primary-rgb), 0.1);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 1px rgba(var(--bs-primary-rgb), 0.1);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: var(--bs-primary);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: rgba(var(--bs-primary-rgb), 0.8);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #f8f9fa;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: var(--bs-primary);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: rgba(var(--bs-primary-rgb), 0.8);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #f8f9fa;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #8996a4;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #8996a4;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control, .form-floating > .datatable-input, .form-floating > .custom-select,
.form-floating > .dataTable-selector,
.form-floating > .dataTable-input,
.form-floating > .form-control-plaintext,
.form-floating > .form-select,
.form-floating > .datatable-selector {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control, .form-floating > .datatable-input, .form-floating > .custom-select,
.form-floating > .dataTable-selector,
.form-floating > .dataTable-input,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder, .form-floating > .datatable-input::placeholder, .form-floating > .custom-select::placeholder,
.form-floating > .dataTable-selector::placeholder,
.form-floating > .dataTable-input::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .datatable-input:focus, .form-floating > .custom-select:focus,
.form-floating > .dataTable-selector:focus,
.form-floating > .dataTable-input:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .datatable-input:not(:placeholder-shown), .form-floating > .custom-select:not(:placeholder-shown),
.form-floating > .dataTable-selector:not(:placeholder-shown),
.form-floating > .dataTable-input:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill, .form-floating > .datatable-input:-webkit-autofill, .form-floating > .custom-select:-webkit-autofill,
.form-floating > .dataTable-selector:-webkit-autofill,
.form-floating > .dataTable-input:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select, .form-floating > .datatable-selector {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label, .form-floating > .datatable-input:focus ~ label, .form-floating > .custom-select:focus ~ label,
.form-floating > .dataTable-selector:focus ~ label,
.form-floating > .dataTable-input:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .datatable-input:not(:placeholder-shown) ~ label,
.form-floating > .custom-select:not(:placeholder-shown) ~ label,
.form-floating > .dataTable-selector:not(:placeholder-shown) ~ label,
.form-floating > .dataTable-input:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label,
.form-floating > .datatable-selector ~ label {
  color: rgba(var(--bs-body-color-rgb), 1);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after, .form-floating > .datatable-input:focus ~ label::after, .form-floating > .custom-select:focus ~ label::after,
.form-floating > .dataTable-selector:focus ~ label::after,
.form-floating > .dataTable-input:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .datatable-input:not(:placeholder-shown) ~ label::after,
.form-floating > .custom-select:not(:placeholder-shown) ~ label::after,
.form-floating > .dataTable-selector:not(:placeholder-shown) ~ label::after,
.form-floating > .dataTable-input:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after,
.form-floating > .datatable-selector ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #ffffff;
  border-radius: 8px;
}
.form-floating > .form-control:-webkit-autofill ~ label, .form-floating > .datatable-input:-webkit-autofill ~ label, .form-floating > .custom-select:-webkit-autofill ~ label,
.form-floating > .dataTable-selector:-webkit-autofill ~ label,
.form-floating > .dataTable-input:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 1);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: #f3f5f7;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control, .input-group > .datatable-input, .input-group > .custom-select,
.input-group > .dataTable-selector,
.input-group > .dataTable-input,
.input-group > .form-select,
.input-group > .datatable-selector,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus, .input-group > .datatable-input:focus, .input-group > .custom-select:focus,
.input-group > .dataTable-selector:focus,
.input-group > .dataTable-input:focus,
.input-group > .form-select:focus,
.input-group > .datatable-selector:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn, .input-group .introjs-tooltip .introjs-button, .introjs-tooltip .input-group .introjs-button {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus, .input-group .introjs-tooltip .introjs-button:focus, .introjs-tooltip .input-group .introjs-button:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.8rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #131920;
  text-align: center;
  white-space: nowrap;
  background-color: #f8f9fa;
  border: 1px solid #bec8d0;
  border-radius: 8px;
}

.input-group-lg > .form-control, .input-group-lg > .datatable-input, .input-group-lg > .custom-select,
.input-group-lg > .dataTable-selector,
.input-group-lg > .dataTable-input,
.input-group-lg > .form-select,
.input-group-lg > .datatable-selector,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.introjs-tooltip .input-group-lg > .introjs-button {
  padding: 0.775rem 0.85rem;
  font-size: 1.09375rem;
  border-radius: 10px;
}

.input-group-sm > .form-control, .input-group-sm > .datatable-input, .input-group-sm > .custom-select,
.input-group-sm > .dataTable-selector,
.input-group-sm > .dataTable-input,
.input-group-sm > .form-select,
.input-group-sm > .datatable-selector,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.introjs-tooltip .input-group-sm > .introjs-button {
  padding: 0.375rem 0.7rem;
  font-size: 0.765625rem;
  border-radius: 6px;
}

.input-group-lg > .form-select, .input-group-lg > .datatable-selector,
.input-group-sm > .form-select,
.input-group-sm > .datatable-selector {
  padding-right: 2.75rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .datatable-input,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .custom-select,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .dataTable-selector,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .dataTable-input,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .datatable-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .datatable-input,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .custom-select,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .dataTable-selector,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .dataTable-input,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .datatable-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(1px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .datatable-input, .input-group > .form-floating:not(:first-child) > .custom-select,
.input-group > .form-floating:not(:first-child) > .dataTable-selector,
.input-group > .form-floating:not(:first-child) > .dataTable-input,
.input-group > .form-floating:not(:first-child) > .form-select,
.input-group > .form-floating:not(:first-child) > .datatable-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #2ca87f;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(44, 168, 127, 0.9);
  border-radius: 8px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .was-validated .datatable-input:valid, .was-validated .custom-select:valid,
.was-validated .dataTable-selector:valid,
.was-validated .dataTable-input:valid, .form-control.is-valid, .is-valid.datatable-input, .is-valid.custom-select,
.is-valid.dataTable-selector,
.is-valid.dataTable-input {
  border-color: #2ca87f;
  padding-right: calc(1.5em + 1.6rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232ca87f' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.4rem) center;
  background-size: calc(0.75em + 0.8rem) calc(0.75em + 0.8rem);
}
.was-validated .form-control:valid:focus, .was-validated .datatable-input:valid:focus, .was-validated .custom-select:valid:focus,
.was-validated .dataTable-selector:valid:focus,
.was-validated .dataTable-input:valid:focus, .form-control.is-valid:focus, .is-valid.datatable-input:focus, .is-valid.custom-select:focus,
.is-valid.dataTable-selector:focus,
.is-valid.dataTable-input:focus {
  border-color: #2ca87f;
  box-shadow: 0 0 0 0.2rem rgba(44, 168, 127, 0.25);
}

.was-validated textarea.form-control:valid, .was-validated textarea.datatable-input:valid, .was-validated textarea.custom-select:valid,
.was-validated textarea.dataTable-selector:valid,
.was-validated textarea.dataTable-input:valid, textarea.form-control.is-valid, textarea.is-valid.datatable-input, textarea.is-valid.custom-select,
textarea.is-valid.dataTable-selector,
textarea.is-valid.dataTable-input {
  padding-right: calc(1.5em + 1.6rem);
  background-position: top calc(0.375em + 0.4rem) right calc(0.375em + 0.4rem);
}

.was-validated .form-select:valid, .was-validated .datatable-selector:valid, .form-select.is-valid, .is-valid.datatable-selector {
  border-color: #2ca87f;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .datatable-selector:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .was-validated .datatable-selector:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .is-valid.datatable-selector:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], .is-valid.datatable-selector:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232ca87f' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: calc(0.75em + 3.95rem);
  background-position: right 0.75rem center, center right 2.75rem;
  background-size: 16px 12px, calc(0.75em + 0.8rem) calc(0.75em + 0.8rem);
}
.was-validated .form-select:valid:focus, .was-validated .datatable-selector:valid:focus, .form-select.is-valid:focus, .is-valid.datatable-selector:focus {
  border-color: #2ca87f;
  box-shadow: 0 0 0 0.2rem rgba(44, 168, 127, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.6rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #2ca87f;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #2ca87f;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 168, 127, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #2ca87f;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .was-validated .input-group > .datatable-input:not(:focus):valid, .was-validated .input-group > .custom-select:not(:focus):valid,
.was-validated .input-group > .dataTable-selector:not(:focus):valid,
.was-validated .input-group > .dataTable-input:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .input-group > .datatable-input:not(:focus).is-valid, .input-group > .custom-select:not(:focus).is-valid,
.input-group > .dataTable-selector:not(:focus).is-valid,
.input-group > .dataTable-input:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.was-validated .input-group > .datatable-selector:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.input-group > .datatable-selector:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc2626;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(220, 38, 38, 0.9);
  border-radius: 8px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .was-validated .datatable-input:invalid, .was-validated .custom-select:invalid,
.was-validated .dataTable-selector:invalid,
.was-validated .dataTable-input:invalid, .form-control.is-invalid, .is-invalid.datatable-input, .is-invalid.custom-select,
.is-invalid.dataTable-selector,
.is-invalid.dataTable-input {
  border-color: #dc2626;
  padding-right: calc(1.5em + 1.6rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc2626'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc2626' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.4rem) center;
  background-size: calc(0.75em + 0.8rem) calc(0.75em + 0.8rem);
}
.was-validated .form-control:invalid:focus, .was-validated .datatable-input:invalid:focus, .was-validated .custom-select:invalid:focus,
.was-validated .dataTable-selector:invalid:focus,
.was-validated .dataTable-input:invalid:focus, .form-control.is-invalid:focus, .is-invalid.datatable-input:focus, .is-invalid.custom-select:focus,
.is-invalid.dataTable-selector:focus,
.is-invalid.dataTable-input:focus {
  border-color: #dc2626;
  box-shadow: 0 0 0 0.2rem rgba(220, 38, 38, 0.25);
}

.was-validated textarea.form-control:invalid, .was-validated textarea.datatable-input:invalid, .was-validated textarea.custom-select:invalid,
.was-validated textarea.dataTable-selector:invalid,
.was-validated textarea.dataTable-input:invalid, textarea.form-control.is-invalid, textarea.is-invalid.datatable-input, textarea.is-invalid.custom-select,
textarea.is-invalid.dataTable-selector,
textarea.is-invalid.dataTable-input {
  padding-right: calc(1.5em + 1.6rem);
  background-position: top calc(0.375em + 0.4rem) right calc(0.375em + 0.4rem);
}

.was-validated .form-select:invalid, .was-validated .datatable-selector:invalid, .form-select.is-invalid, .is-invalid.datatable-selector {
  border-color: #dc2626;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .datatable-selector:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .was-validated .datatable-selector:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .is-invalid.datatable-selector:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], .is-invalid.datatable-selector:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc2626'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc2626' stroke='none'/%3e%3c/svg%3e");
  padding-right: calc(0.75em + 3.95rem);
  background-position: right 0.75rem center, center right 2.75rem;
  background-size: 16px 12px, calc(0.75em + 0.8rem) calc(0.75em + 0.8rem);
}
.was-validated .form-select:invalid:focus, .was-validated .datatable-selector:invalid:focus, .form-select.is-invalid:focus, .is-invalid.datatable-selector:focus {
  border-color: #dc2626;
  box-shadow: 0 0 0 0.2rem rgba(220, 38, 38, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.6rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc2626;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc2626;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 38, 38, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc2626;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .was-validated .input-group > .datatable-input:not(:focus):invalid, .was-validated .input-group > .custom-select:not(:focus):invalid,
.was-validated .input-group > .dataTable-selector:not(:focus):invalid,
.was-validated .input-group > .dataTable-input:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .input-group > .datatable-input:not(:focus).is-invalid, .input-group > .custom-select:not(:focus).is-invalid,
.input-group > .dataTable-selector:not(:focus).is-invalid,
.input-group > .dataTable-input:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.was-validated .input-group > .datatable-selector:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.input-group > .datatable-selector:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn, .introjs-tooltip .introjs-button {
  --bs-btn-padding-x: 1rem;
  --bs-btn-padding-y: 0.562rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 20px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .introjs-tooltip .introjs-button {
    transition: none;
  }
}
.btn:hover, .introjs-tooltip .introjs-button:hover {
  color: var(--bs-btn-hover-color);
  text-decoration: none;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover, .introjs-tooltip .btn-check + .introjs-button:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible, .introjs-tooltip .introjs-button:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn, .introjs-tooltip .btn-check:focus-visible + .introjs-button {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .introjs-tooltip .btn-check:checked + .introjs-button, :not(.btn-check) + .btn:active, .introjs-tooltip :not(.btn-check) + .introjs-button:active, .btn:first-child:active, .introjs-tooltip .introjs-button:first-child:active, .btn.active, .introjs-tooltip .active.introjs-button, .btn.show, .introjs-tooltip .show.introjs-button {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, .introjs-tooltip .btn-check:checked + .introjs-button:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .introjs-tooltip :not(.btn-check) + .introjs-button:active:focus-visible, .btn:first-child:active:focus-visible, .introjs-tooltip .introjs-button:first-child:active:focus-visible, .btn.active:focus-visible, .introjs-tooltip .active.introjs-button:focus-visible, .btn.show:focus-visible, .introjs-tooltip .show.introjs-button:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn, .introjs-tooltip .btn-check:checked:focus-visible + .introjs-button {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .introjs-tooltip .introjs-button:disabled, .btn.disabled, .introjs-tooltip .disabled.introjs-button, fieldset:disabled .btn, fieldset:disabled .introjs-tooltip .introjs-button, .introjs-tooltip fieldset:disabled .introjs-button {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-theme {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #ff3366;
  --bs-btn-border-color: #ff3366;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d92b57;
  --bs-btn-hover-border-color: #cc2952;
  --bs-btn-focus-shadow-rgb: 255, 82, 125;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #cc2952;
  --bs-btn-active-border-color: #bf264d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #ff3366;
  --bs-btn-disabled-border-color: #ff3366;
}

.btn-primary, .introjs-tooltip .introjs-button.introjs-nextbutton {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1A237E;
  --bs-btn-border-color: #1A237E;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #161e6b;
  --bs-btn-hover-border-color: #151c65;
  --bs-btn-focus-shadow-rgb: 60, 68, 145;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #151c65;
  --bs-btn-active-border-color: #141a5f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1A237E;
  --bs-btn-disabled-border-color: #1A237E;
}

.btn-secondary, .introjs-tooltip .introjs-button.introjs-prevbutton {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #5b6b79;
  --bs-btn-border-color: #5b6b79;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #4d5b67;
  --bs-btn-hover-border-color: #495661;
  --bs-btn-focus-shadow-rgb: 116, 129, 141;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #495661;
  --bs-btn-active-border-color: #44505b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #5b6b79;
  --bs-btn-disabled-border-color: #5b6b79;
}

.btn-success {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2ca87f;
  --bs-btn-border-color: #2ca87f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #258f6c;
  --bs-btn-hover-border-color: #238666;
  --bs-btn-focus-shadow-rgb: 76, 181, 146;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #238666;
  --bs-btn-active-border-color: #217e5f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2ca87f;
  --bs-btn-disabled-border-color: #2ca87f;
}

.btn-info {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3ec9d6;
  --bs-btn-border-color: #3ec9d6;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #35abb6;
  --bs-btn-hover-border-color: #32a1ab;
  --bs-btn-focus-shadow-rgb: 91, 209, 220;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #32a1ab;
  --bs-btn-active-border-color: #2f97a1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3ec9d6;
  --bs-btn-disabled-border-color: #3ec9d6;
}

.btn-warning {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #e58a00;
  --bs-btn-border-color: #e58a00;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c37500;
  --bs-btn-hover-border-color: #b76e00;
  --bs-btn-focus-shadow-rgb: 233, 156, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #b76e00;
  --bs-btn-active-border-color: #ac6800;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #e58a00;
  --bs-btn-disabled-border-color: #e58a00;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #dc2626;
  --bs-btn-border-color: #dc2626;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #bb2020;
  --bs-btn-hover-border-color: #b01e1e;
  --bs-btn-focus-shadow-rgb: 225, 71, 71;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #b01e1e;
  --bs-btn-active-border-color: #a51d1d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #dc2626;
  --bs-btn-disabled-border-color: #dc2626;
}

.btn-light {
  --bs-btn-color: #212529;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #212529;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 216, 217, 219;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-theme {
  --bs-btn-color: #ff3366;
  --bs-btn-border-color: #ff3366;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ff3366;
  --bs-btn-hover-border-color: #ff3366;
  --bs-btn-focus-shadow-rgb: 255, 51, 102;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ff3366;
  --bs-btn-active-border-color: #ff3366;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff3366;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff3366;
  --bs-gradient: none;
}

.btn-outline-primary {
  --bs-btn-color: #1A237E;
  --bs-btn-border-color: #1A237E;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1A237E;
  --bs-btn-hover-border-color: #1A237E;
  --bs-btn-focus-shadow-rgb: 26, 35, 126;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1A237E;
  --bs-btn-active-border-color: #1A237E;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1A237E;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1A237E;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #5b6b79;
  --bs-btn-border-color: #5b6b79;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5b6b79;
  --bs-btn-hover-border-color: #5b6b79;
  --bs-btn-focus-shadow-rgb: 91, 107, 121;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #5b6b79;
  --bs-btn-active-border-color: #5b6b79;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5b6b79;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5b6b79;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #2ca87f;
  --bs-btn-border-color: #2ca87f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2ca87f;
  --bs-btn-hover-border-color: #2ca87f;
  --bs-btn-focus-shadow-rgb: 44, 168, 127;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2ca87f;
  --bs-btn-active-border-color: #2ca87f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2ca87f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2ca87f;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #3ec9d6;
  --bs-btn-border-color: #3ec9d6;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3ec9d6;
  --bs-btn-hover-border-color: #3ec9d6;
  --bs-btn-focus-shadow-rgb: 62, 201, 214;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3ec9d6;
  --bs-btn-active-border-color: #3ec9d6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3ec9d6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3ec9d6;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #e58a00;
  --bs-btn-border-color: #e58a00;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #e58a00;
  --bs-btn-hover-border-color: #e58a00;
  --bs-btn-focus-shadow-rgb: 229, 138, 0;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #e58a00;
  --bs-btn-active-border-color: #e58a00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e58a00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e58a00;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc2626;
  --bs-btn-border-color: #dc2626;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #dc2626;
  --bs-btn-hover-border-color: #dc2626;
  --bs-btn-focus-shadow-rgb: 220, 38, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #dc2626;
  --bs-btn-active-border-color: #dc2626;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc2626;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc2626;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #212529;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #212529;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #1A237E;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #151c65;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #151c65;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #5b6b79;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn, .introjs-tooltip .btn-group-lg > .introjs-button {
  --bs-btn-padding-y: 1rem;
  --bs-btn-padding-x: 1.3rem;
  --bs-btn-font-size: 1.09375rem;
  --bs-btn-border-radius: 26px;
}

.btn-sm, .btn-group-sm > .btn, .introjs-tooltip .btn-group-sm > .introjs-button {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.765625rem;
  --bs-btn-border-radius: 15px;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1026;
  --bs-dropdown-min-width: 12rem;
  --bs-dropdown-padding-x: 0.5rem;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: #131920;
  --bs-dropdown-bg: #ffffff;
  --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
  --bs-dropdown-border-radius: 8px;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: 8px;
  --bs-dropdown-divider-bg: #f3f5f7;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  --bs-dropdown-link-color: #131920;
  --bs-dropdown-link-hover-color: var(--bs-dropdown-link-color);
  --bs-dropdown-link-hover-bg: #f3f5f7;
  --bs-dropdown-link-active-color: var(--bs-dropdown-link-color);
  --bs-dropdown-link-active-bg: var(--pc-active-background);
  --bs-dropdown-link-disabled-color: #5b6b79;
  --bs-dropdown-item-padding-x: 0.95rem;
  --bs-dropdown-item-padding-y: 0.65rem;
  --bs-dropdown-header-color: #5b6b79;
  --bs-dropdown-header-padding-x: 0.95rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dbe0e5;
  --bs-dropdown-bg: #1d2630;
  --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dbe0e5;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: #f3f5f7;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: var(--bs-dropdown-link-color);
  --bs-dropdown-link-active-bg: var(--pc-active-background);
  --bs-dropdown-link-disabled-color: #8996a4;
  --bs-dropdown-header-color: #8996a4;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .introjs-tooltip .btn-group > .introjs-button,
.btn-group-vertical > .btn,
.introjs-tooltip .btn-group-vertical > .introjs-button {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn, .introjs-tooltip .btn-group > .btn-check:checked + .introjs-button,
.btn-group > .btn-check:focus + .btn,
.introjs-tooltip .btn-group > .btn-check:focus + .introjs-button,
.btn-group > .btn:hover,
.introjs-tooltip .btn-group > .introjs-button:hover,
.btn-group > .btn:focus,
.introjs-tooltip .btn-group > .introjs-button:focus,
.btn-group > .btn:active,
.introjs-tooltip .btn-group > .introjs-button:active,
.btn-group > .btn.active,
.introjs-tooltip .btn-group > .active.introjs-button,
.btn-group-vertical > .btn-check:checked + .btn,
.introjs-tooltip .btn-group-vertical > .btn-check:checked + .introjs-button,
.btn-group-vertical > .btn-check:focus + .btn,
.introjs-tooltip .btn-group-vertical > .btn-check:focus + .introjs-button,
.btn-group-vertical > .btn:hover,
.introjs-tooltip .btn-group-vertical > .introjs-button:hover,
.btn-group-vertical > .btn:focus,
.introjs-tooltip .btn-group-vertical > .introjs-button:focus,
.btn-group-vertical > .btn:active,
.introjs-tooltip .btn-group-vertical > .introjs-button:active,
.btn-group-vertical > .btn.active,
.introjs-tooltip .btn-group-vertical > .active.introjs-button {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 20px;
}
.btn-group > :not(.btn-check:first-child) + .btn, .introjs-tooltip .btn-group > :not(.btn-check:first-child) + .introjs-button,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(1px * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .introjs-tooltip .btn-group > .introjs-button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.introjs-tooltip .btn-group > .dropdown-toggle-split.introjs-button:first-child,
.btn-group > .btn-group:not(:last-child) > .btn,
.introjs-tooltip .btn-group > .btn-group:not(:last-child) > .introjs-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3), .introjs-tooltip .btn-group > .introjs-button:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.introjs-tooltip .btn-group > :not(.btn-check) + .introjs-button,
.btn-group > .btn-group:not(:first-child) > .btn,
.introjs-tooltip .btn-group > .btn-group:not(:first-child) > .introjs-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .introjs-tooltip .btn-group-sm > .introjs-button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .introjs-tooltip .btn-group-lg > .introjs-button + .dropdown-toggle-split {
  padding-right: 0.975rem;
  padding-left: 0.975rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .introjs-tooltip .btn-group-vertical > .introjs-button,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .introjs-tooltip .btn-group-vertical > .introjs-button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(1px * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .introjs-tooltip .btn-group-vertical > .introjs-button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.introjs-tooltip .btn-group-vertical > .btn-group:not(:last-child) > .introjs-button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn, .introjs-tooltip .btn-group-vertical > .introjs-button ~ .btn, .introjs-tooltip .btn-group-vertical > .btn ~ .introjs-button, .introjs-tooltip .btn-group-vertical > .introjs-button ~ .introjs-button,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.introjs-tooltip .btn-group-vertical > .btn-group:not(:first-child) > .introjs-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #5b6b79;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
  text-decoration: none;
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: 8px;
  --bs-nav-tabs-link-hover-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-body-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 8px;
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: var(--bs-primary);
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: calc(1rem / 2);
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3359375rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.09375rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.09375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 20px;
  --bs-navbar-toggler-focus-width: 0.2rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 25px;
  --bs-card-spacer-x: 25px;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 1px;
  --bs-card-border-color: #e7eaee;
  --bs-card-border-radius: 12px;
  --bs-card-box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
  --bs-card-inner-border-radius: calc(12px - 1px);
  --bs-card-cap-padding-y: 25px;
  --bs-card-cap-padding-x: 25px;
  --bs-card-cap-bg: transparent;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #ffffff;
  --bs-card-img-overlay-padding: 1.25rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #131920;
  --bs-accordion-bg: #ffffff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 8px;
  --bs-accordion-inner-border-radius: 7px;
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #131920;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23131920'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23172071'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-primary), 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #172071;
  --bs-accordion-active-bg: #e8e9f2;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 2;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #5b6b79;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #5b6b79;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14' stroke='%235b6b79' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E")) /* rtl: var(--bs-breadcrumb-divider, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14' stroke='%235b6b79' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E")) */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #ffffff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dbe0e5;
  --bs-pagination-border-radius: 8px;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #f3f5f7;
  --bs-pagination-hover-border-color: #dbe0e5;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #f3f5f7;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-primary), 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: var(--bs-primary);
  --bs-pagination-active-border-color: var(--bs-primary);
  --bs-pagination-disabled-color: #5b6b79;
  --bs-pagination-disabled-bg: #ffffff;
  --bs-pagination-disabled-border-color: #dbe0e5;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  text-decoration: none;
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item .page-link {
  border-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.09375rem;
  --bs-pagination-border-radius: 10px;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.765625rem;
  --bs-pagination-border-radius: 6px;
}

.badge {
  --bs-badge-padding-x: 0.8em;
  --bs-badge-padding-y: 0.45em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: 6px;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge, .introjs-tooltip .introjs-button .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.25rem;
  --bs-alert-padding-y: 0.75rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 8px;
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.9375rem 1.25rem;
}

.alert-theme {
  --bs-alert-color: var(--bs-theme-text-emphasis);
  --bs-alert-bg: var(--bs-theme-bg-subtle);
  --bs-alert-border-color: var(--bs-theme-border-subtle);
  --bs-alert-link-color: var(--bs-theme-text-emphasis);
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: #f8f9fa;
  --bs-progress-border-radius: 8px;
  --bs-progress-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #1A237E;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: ;
  --bs-list-group-bg: #ffffff;
  --bs-list-group-border-color: #e7eaee;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 8px;
  --bs-list-group-item-padding-x: 25px;
  --bs-list-group-item-padding-y: 16.6666666667px;
  --bs-list-group-action-color: #3e4853;
  --bs-list-group-action-hover-color: #3e4853;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #131920;
  --bs-list-group-action-active-bg: #f3f5f7;
  --bs-list-group-disabled-color: #dbe0e5;
  --bs-list-group-disabled-bg: #ffffff;
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: var(--bs-primary);
  --bs-list-group-active-border-color: var(--bs-primary);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-theme {
  --bs-list-group-color: var(--bs-theme-text-emphasis);
  --bs-list-group-bg: var(--bs-theme-bg-subtle);
  --bs-list-group-border-color: var(--bs-theme-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-theme-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-theme-border-subtle);
  --bs-list-group-active-color: var(--bs-theme-bg-subtle);
  --bs-list-group-active-bg: var(--bs-theme-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-theme-text-emphasis);
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.2rem rgba(var(--bs-primary), 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 8px;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.25rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: rgba(0, 0, 0, 0.1);
  --bs-toast-border-radius: 0.25rem;
  --bs-toast-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  --bs-toast-header-color: #5b6b79;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1.25rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #ffffff;
  --bs-modal-border-color: rgba(0, 0, 0, 0.2);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 10px;
  --bs-modal-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  --bs-modal-inner-border-radius: 9px;
  --bs-modal-header-padding-x: 1.5625rem;
  --bs-modal-header-padding-y: 1.25rem;
  --bs-modal-header-padding: 1.25rem 1.5625rem;
  --bs-modal-header-border-color: #e7eaee;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: #e7eaee;
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content, .modal-dialog-scrollable .introjs-tooltip {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content, .introjs-tooltip {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content, .modal-fullscreen .introjs-tooltip {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content, .modal-fullscreen-sm-down .introjs-tooltip {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content, .modal-fullscreen-md-down .introjs-tooltip {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content, .modal-fullscreen-lg-down .introjs-tooltip {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content, .modal-fullscreen-xl-down .introjs-tooltip {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content, .modal-fullscreen-xxl-down .introjs-tooltip {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size: 0.765625rem;
  --bs-tooltip-color: #ffffff;
  --bs-tooltip-bg: #000000;
  --bs-tooltip-border-radius: 8px;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.765625rem;
  --bs-popover-bg: #ffffff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: rgba(0, 0, 0, 0.2);
  --bs-popover-border-radius: 10px;
  --bs-popover-inner-border-radius: 9px;
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 0.75rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #262626;
  --bs-popover-header-bg: #f7f7f7;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #131920;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 360px;
  --bs-offcanvas-height: 33vh;
  --bs-offcanvas-padding-x: 1.25rem;
  --bs-offcanvas-padding-y: 1.25rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #ffffff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: rgba(0, 0, 0, 0.2);
  --bs-offcanvas-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before, .introjs-tooltip .placeholder.introjs-button::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-theme {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-theme-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #212529 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-theme {
  color: RGBA(var(--bs-theme-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-theme-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-theme:hover, .link-theme:focus {
  color: RGBA(204, 41, 82, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(204, 41, 82, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(21, 28, 101, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(21, 28, 101, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(73, 86, 97, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(73, 86, 97, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(35, 134, 102, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(35, 134, 102, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(50, 161, 171, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(50, 161, 171, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(183, 110, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(183, 110, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 30, 30, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 30, 30, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

[data-pc-direction=ltr] {
  /* rtl:begin:remove */
  /* rtl:end:remove */
}
[data-pc-direction=ltr] .align-baseline {
  vertical-align: baseline !important;
}
[data-pc-direction=ltr] .align-top {
  vertical-align: top !important;
}
[data-pc-direction=ltr] .align-middle {
  vertical-align: middle !important;
}
[data-pc-direction=ltr] .align-bottom {
  vertical-align: bottom !important;
}
[data-pc-direction=ltr] .align-text-bottom {
  vertical-align: text-bottom !important;
}
[data-pc-direction=ltr] .align-text-top {
  vertical-align: text-top !important;
}
[data-pc-direction=ltr] .float-start {
  float: left !important;
}
[data-pc-direction=ltr] .float-end {
  float: right !important;
}
[data-pc-direction=ltr] .float-none {
  float: none !important;
}
[data-pc-direction=ltr] .object-fit-contain {
  object-fit: contain !important;
}
[data-pc-direction=ltr] .object-fit-cover {
  object-fit: cover !important;
}
[data-pc-direction=ltr] .object-fit-fill {
  object-fit: fill !important;
}
[data-pc-direction=ltr] .object-fit-scale {
  object-fit: scale-down !important;
}
[data-pc-direction=ltr] .object-fit-none {
  object-fit: none !important;
}
[data-pc-direction=ltr] .opacity-0 {
  opacity: 0 !important;
}
[data-pc-direction=ltr] .opacity-25 {
  opacity: 0.25 !important;
}
[data-pc-direction=ltr] .opacity-50 {
  opacity: 0.5 !important;
}
[data-pc-direction=ltr] .opacity-75 {
  opacity: 0.75 !important;
}
[data-pc-direction=ltr] .opacity-100 {
  opacity: 1 !important;
}
[data-pc-direction=ltr] .overflow-auto {
  overflow: auto !important;
}
[data-pc-direction=ltr] .overflow-hidden {
  overflow: hidden !important;
}
[data-pc-direction=ltr] .overflow-visible {
  overflow: visible !important;
}
[data-pc-direction=ltr] .overflow-scroll {
  overflow: scroll !important;
}
[data-pc-direction=ltr] .overflow-x-auto {
  overflow-x: auto !important;
}
[data-pc-direction=ltr] .overflow-x-hidden {
  overflow-x: hidden !important;
}
[data-pc-direction=ltr] .overflow-x-visible {
  overflow-x: visible !important;
}
[data-pc-direction=ltr] .overflow-x-scroll {
  overflow-x: scroll !important;
}
[data-pc-direction=ltr] .overflow-y-auto {
  overflow-y: auto !important;
}
[data-pc-direction=ltr] .overflow-y-hidden {
  overflow-y: hidden !important;
}
[data-pc-direction=ltr] .overflow-y-visible {
  overflow-y: visible !important;
}
[data-pc-direction=ltr] .overflow-y-scroll {
  overflow-y: scroll !important;
}
[data-pc-direction=ltr] .d-inline {
  display: inline !important;
}
[data-pc-direction=ltr] .d-inline-block {
  display: inline-block !important;
}
[data-pc-direction=ltr] .d-block {
  display: block !important;
}
[data-pc-direction=ltr] .d-grid {
  display: grid !important;
}
[data-pc-direction=ltr] .d-inline-grid {
  display: inline-grid !important;
}
[data-pc-direction=ltr] .d-table {
  display: table !important;
}
[data-pc-direction=ltr] .d-table-row {
  display: table-row !important;
}
[data-pc-direction=ltr] .d-table-cell {
  display: table-cell !important;
}
[data-pc-direction=ltr] .d-flex {
  display: flex !important;
}
[data-pc-direction=ltr] .d-inline-flex {
  display: inline-flex !important;
}
[data-pc-direction=ltr] .d-none {
  display: none !important;
}
[data-pc-direction=ltr] .shadow {
  box-shadow: var(--bs-box-shadow) !important;
}
[data-pc-direction=ltr] .shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}
[data-pc-direction=ltr] .shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}
[data-pc-direction=ltr] .shadow-none {
  box-shadow: none !important;
}
[data-pc-direction=ltr] .focus-ring-theme {
  --bs-focus-ring-color: rgba(var(--bs-theme-rgb), var(--bs-focus-ring-opacity));
}
[data-pc-direction=ltr] .focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}
[data-pc-direction=ltr] .focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}
[data-pc-direction=ltr] .focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}
[data-pc-direction=ltr] .focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}
[data-pc-direction=ltr] .focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}
[data-pc-direction=ltr] .focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}
[data-pc-direction=ltr] .focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}
[data-pc-direction=ltr] .focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}
[data-pc-direction=ltr] .position-static {
  position: static !important;
}
[data-pc-direction=ltr] .position-relative {
  position: relative !important;
}
[data-pc-direction=ltr] .position-absolute {
  position: absolute !important;
}
[data-pc-direction=ltr] .position-fixed {
  position: fixed !important;
}
[data-pc-direction=ltr] .position-sticky {
  position: sticky !important;
}
[data-pc-direction=ltr] .top-0 {
  top: 0 !important;
}
[data-pc-direction=ltr] .top-50 {
  top: 50% !important;
}
[data-pc-direction=ltr] .top-100 {
  top: 100% !important;
}
[data-pc-direction=ltr] .bottom-0 {
  bottom: 0 !important;
}
[data-pc-direction=ltr] .bottom-50 {
  bottom: 50% !important;
}
[data-pc-direction=ltr] .bottom-100 {
  bottom: 100% !important;
}
[data-pc-direction=ltr] .start-0 {
  left: 0 !important;
}
[data-pc-direction=ltr] .start-50 {
  left: 50% !important;
}
[data-pc-direction=ltr] .start-100 {
  left: 100% !important;
}
[data-pc-direction=ltr] .end-0 {
  right: 0 !important;
}
[data-pc-direction=ltr] .end-50 {
  right: 50% !important;
}
[data-pc-direction=ltr] .end-100 {
  right: 100% !important;
}
[data-pc-direction=ltr] .translate-middle {
  transform: translate(-50%, -50%) !important;
}
[data-pc-direction=ltr] .translate-middle-x {
  transform: translateX(-50%) !important;
}
[data-pc-direction=ltr] .translate-middle-y {
  transform: translateY(-50%) !important;
}
[data-pc-direction=ltr] .border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
[data-pc-direction=ltr] .border-0 {
  border: 0 !important;
}
[data-pc-direction=ltr] .border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
[data-pc-direction=ltr] .border-top-0 {
  border-top: 0 !important;
}
[data-pc-direction=ltr] .border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
[data-pc-direction=ltr] .border-end-0 {
  border-right: 0 !important;
}
[data-pc-direction=ltr] .border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
[data-pc-direction=ltr] .border-bottom-0 {
  border-bottom: 0 !important;
}
[data-pc-direction=ltr] .border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
[data-pc-direction=ltr] .border-start-0 {
  border-left: 0 !important;
}
[data-pc-direction=ltr] .border-theme {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-theme-rgb), var(--bs-border-opacity)) !important;
}
[data-pc-direction=ltr] .border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}
[data-pc-direction=ltr] .border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}
[data-pc-direction=ltr] .border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}
[data-pc-direction=ltr] .border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}
[data-pc-direction=ltr] .border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}
[data-pc-direction=ltr] .border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}
[data-pc-direction=ltr] .border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}
[data-pc-direction=ltr] .border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}
[data-pc-direction=ltr] .border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}
[data-pc-direction=ltr] .border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}
[data-pc-direction=ltr] .border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}
[data-pc-direction=ltr] .border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}
[data-pc-direction=ltr] .border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}
[data-pc-direction=ltr] .border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}
[data-pc-direction=ltr] .border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}
[data-pc-direction=ltr] .border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}
[data-pc-direction=ltr] .border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}
[data-pc-direction=ltr] .border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}
[data-pc-direction=ltr] .border-0 {
  border-width: 0 !important;
}
[data-pc-direction=ltr] .border-1 {
  border-width: 1px !important;
}
[data-pc-direction=ltr] .border-2 {
  border-width: 2px !important;
}
[data-pc-direction=ltr] .border-3 {
  border-width: 3px !important;
}
[data-pc-direction=ltr] .border-4 {
  border-width: 4px !important;
}
[data-pc-direction=ltr] .border-5 {
  border-width: 5px !important;
}
[data-pc-direction=ltr] .border-opacity-10 {
  --bs-border-opacity: 0.1;
}
[data-pc-direction=ltr] .border-opacity-25 {
  --bs-border-opacity: 0.25;
}
[data-pc-direction=ltr] .border-opacity-50 {
  --bs-border-opacity: 0.5;
}
[data-pc-direction=ltr] .border-opacity-75 {
  --bs-border-opacity: 0.75;
}
[data-pc-direction=ltr] .border-opacity-100 {
  --bs-border-opacity: 1;
}
[data-pc-direction=ltr] .w-25 {
  width: 25% !important;
}
[data-pc-direction=ltr] .w-50 {
  width: 50% !important;
}
[data-pc-direction=ltr] .w-75 {
  width: 75% !important;
}
[data-pc-direction=ltr] .w-100 {
  width: 100% !important;
}
[data-pc-direction=ltr] .w-auto {
  width: auto !important;
}
[data-pc-direction=ltr] .mw-100 {
  max-width: 100% !important;
}
[data-pc-direction=ltr] .vw-100 {
  width: 100vw !important;
}
[data-pc-direction=ltr] .min-vw-100 {
  min-width: 100vw !important;
}
[data-pc-direction=ltr] .h-25 {
  height: 25% !important;
}
[data-pc-direction=ltr] .h-50 {
  height: 50% !important;
}
[data-pc-direction=ltr] .h-75 {
  height: 75% !important;
}
[data-pc-direction=ltr] .h-100 {
  height: 100% !important;
}
[data-pc-direction=ltr] .h-auto {
  height: auto !important;
}
[data-pc-direction=ltr] .mh-100 {
  max-height: 100% !important;
}
[data-pc-direction=ltr] .vh-100 {
  height: 100vh !important;
}
[data-pc-direction=ltr] .min-vh-100 {
  min-height: 100vh !important;
}
[data-pc-direction=ltr] .flex-fill {
  flex: 1 1 auto !important;
}
[data-pc-direction=ltr] .flex-row {
  flex-direction: row !important;
}
[data-pc-direction=ltr] .flex-column {
  flex-direction: column !important;
}
[data-pc-direction=ltr] .flex-row-reverse {
  flex-direction: row-reverse !important;
}
[data-pc-direction=ltr] .flex-column-reverse {
  flex-direction: column-reverse !important;
}
[data-pc-direction=ltr] .flex-grow-0 {
  flex-grow: 0 !important;
}
[data-pc-direction=ltr] .flex-grow-1 {
  flex-grow: 1 !important;
}
[data-pc-direction=ltr] .flex-shrink-0 {
  flex-shrink: 0 !important;
}
[data-pc-direction=ltr] .flex-shrink-1 {
  flex-shrink: 1 !important;
}
[data-pc-direction=ltr] .flex-wrap {
  flex-wrap: wrap !important;
}
[data-pc-direction=ltr] .flex-nowrap {
  flex-wrap: nowrap !important;
}
[data-pc-direction=ltr] .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
[data-pc-direction=ltr] .justify-content-start {
  justify-content: flex-start !important;
}
[data-pc-direction=ltr] .justify-content-end {
  justify-content: flex-end !important;
}
[data-pc-direction=ltr] .justify-content-center {
  justify-content: center !important;
}
[data-pc-direction=ltr] .justify-content-between {
  justify-content: space-between !important;
}
[data-pc-direction=ltr] .justify-content-around {
  justify-content: space-around !important;
}
[data-pc-direction=ltr] .justify-content-evenly {
  justify-content: space-evenly !important;
}
[data-pc-direction=ltr] .align-items-start {
  align-items: flex-start !important;
}
[data-pc-direction=ltr] .align-items-end {
  align-items: flex-end !important;
}
[data-pc-direction=ltr] .align-items-center {
  align-items: center !important;
}
[data-pc-direction=ltr] .align-items-baseline {
  align-items: baseline !important;
}
[data-pc-direction=ltr] .align-items-stretch {
  align-items: stretch !important;
}
[data-pc-direction=ltr] .align-content-start {
  align-content: flex-start !important;
}
[data-pc-direction=ltr] .align-content-end {
  align-content: flex-end !important;
}
[data-pc-direction=ltr] .align-content-center {
  align-content: center !important;
}
[data-pc-direction=ltr] .align-content-between {
  align-content: space-between !important;
}
[data-pc-direction=ltr] .align-content-around {
  align-content: space-around !important;
}
[data-pc-direction=ltr] .align-content-stretch {
  align-content: stretch !important;
}
[data-pc-direction=ltr] .align-self-auto {
  align-self: auto !important;
}
[data-pc-direction=ltr] .align-self-start {
  align-self: flex-start !important;
}
[data-pc-direction=ltr] .align-self-end {
  align-self: flex-end !important;
}
[data-pc-direction=ltr] .align-self-center {
  align-self: center !important;
}
[data-pc-direction=ltr] .align-self-baseline {
  align-self: baseline !important;
}
[data-pc-direction=ltr] .align-self-stretch {
  align-self: stretch !important;
}
[data-pc-direction=ltr] .order-first {
  order: -1 !important;
}
[data-pc-direction=ltr] .order-0 {
  order: 0 !important;
}
[data-pc-direction=ltr] .order-1 {
  order: 1 !important;
}
[data-pc-direction=ltr] .order-2 {
  order: 2 !important;
}
[data-pc-direction=ltr] .order-3 {
  order: 3 !important;
}
[data-pc-direction=ltr] .order-4 {
  order: 4 !important;
}
[data-pc-direction=ltr] .order-5 {
  order: 5 !important;
}
[data-pc-direction=ltr] .order-last {
  order: 6 !important;
}
[data-pc-direction=ltr] .m-0 {
  margin: 0 !important;
}
[data-pc-direction=ltr] .m-1 {
  margin: 0.25rem !important;
}
[data-pc-direction=ltr] .m-2 {
  margin: 0.5rem !important;
}
[data-pc-direction=ltr] .m-3 {
  margin: 1rem !important;
}
[data-pc-direction=ltr] .m-4 {
  margin: 1.5rem !important;
}
[data-pc-direction=ltr] .m-5 {
  margin: 3rem !important;
}
[data-pc-direction=ltr] .m-auto {
  margin: auto !important;
}
[data-pc-direction=ltr] .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
[data-pc-direction=ltr] .mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
[data-pc-direction=ltr] .mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
[data-pc-direction=ltr] .mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
[data-pc-direction=ltr] .mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
[data-pc-direction=ltr] .mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
[data-pc-direction=ltr] .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
[data-pc-direction=ltr] .my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
[data-pc-direction=ltr] .my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
[data-pc-direction=ltr] .my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
[data-pc-direction=ltr] .my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
[data-pc-direction=ltr] .my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
[data-pc-direction=ltr] .my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
[data-pc-direction=ltr] .my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
[data-pc-direction=ltr] .mt-0 {
  margin-top: 0 !important;
}
[data-pc-direction=ltr] .mt-1 {
  margin-top: 0.25rem !important;
}
[data-pc-direction=ltr] .mt-2 {
  margin-top: 0.5rem !important;
}
[data-pc-direction=ltr] .mt-3 {
  margin-top: 1rem !important;
}
[data-pc-direction=ltr] .mt-4 {
  margin-top: 1.5rem !important;
}
[data-pc-direction=ltr] .mt-5 {
  margin-top: 3rem !important;
}
[data-pc-direction=ltr] .mt-auto {
  margin-top: auto !important;
}
[data-pc-direction=ltr] .me-0 {
  margin-right: 0 !important;
}
[data-pc-direction=ltr] .me-1 {
  margin-right: 0.25rem !important;
}
[data-pc-direction=ltr] .me-2 {
  margin-right: 0.5rem !important;
}
[data-pc-direction=ltr] .me-3 {
  margin-right: 1rem !important;
}
[data-pc-direction=ltr] .me-4 {
  margin-right: 1.5rem !important;
}
[data-pc-direction=ltr] .me-5 {
  margin-right: 3rem !important;
}
[data-pc-direction=ltr] .me-auto {
  margin-right: auto !important;
}
[data-pc-direction=ltr] .mb-0 {
  margin-bottom: 0 !important;
}
[data-pc-direction=ltr] .mb-1 {
  margin-bottom: 0.25rem !important;
}
[data-pc-direction=ltr] .mb-2 {
  margin-bottom: 0.5rem !important;
}
[data-pc-direction=ltr] .mb-3 {
  margin-bottom: 1rem !important;
}
[data-pc-direction=ltr] .mb-4 {
  margin-bottom: 1.5rem !important;
}
[data-pc-direction=ltr] .mb-5 {
  margin-bottom: 3rem !important;
}
[data-pc-direction=ltr] .mb-auto {
  margin-bottom: auto !important;
}
[data-pc-direction=ltr] .ms-0 {
  margin-left: 0 !important;
}
[data-pc-direction=ltr] .ms-1 {
  margin-left: 0.25rem !important;
}
[data-pc-direction=ltr] .ms-2 {
  margin-left: 0.5rem !important;
}
[data-pc-direction=ltr] .ms-3 {
  margin-left: 1rem !important;
}
[data-pc-direction=ltr] .ms-4 {
  margin-left: 1.5rem !important;
}
[data-pc-direction=ltr] .ms-5 {
  margin-left: 3rem !important;
}
[data-pc-direction=ltr] .ms-auto {
  margin-left: auto !important;
}
[data-pc-direction=ltr] .m-n1 {
  margin: -0.25rem !important;
}
[data-pc-direction=ltr] .m-n2 {
  margin: -0.5rem !important;
}
[data-pc-direction=ltr] .m-n3 {
  margin: -1rem !important;
}
[data-pc-direction=ltr] .m-n4 {
  margin: -1.5rem !important;
}
[data-pc-direction=ltr] .m-n5 {
  margin: -3rem !important;
}
[data-pc-direction=ltr] .mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}
[data-pc-direction=ltr] .mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}
[data-pc-direction=ltr] .mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
[data-pc-direction=ltr] .mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}
[data-pc-direction=ltr] .mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}
[data-pc-direction=ltr] .my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}
[data-pc-direction=ltr] .my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}
[data-pc-direction=ltr] .my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}
[data-pc-direction=ltr] .my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}
[data-pc-direction=ltr] .my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}
[data-pc-direction=ltr] .mt-n1 {
  margin-top: -0.25rem !important;
}
[data-pc-direction=ltr] .mt-n2 {
  margin-top: -0.5rem !important;
}
[data-pc-direction=ltr] .mt-n3 {
  margin-top: -1rem !important;
}
[data-pc-direction=ltr] .mt-n4 {
  margin-top: -1.5rem !important;
}
[data-pc-direction=ltr] .mt-n5 {
  margin-top: -3rem !important;
}
[data-pc-direction=ltr] .me-n1 {
  margin-right: -0.25rem !important;
}
[data-pc-direction=ltr] .me-n2 {
  margin-right: -0.5rem !important;
}
[data-pc-direction=ltr] .me-n3 {
  margin-right: -1rem !important;
}
[data-pc-direction=ltr] .me-n4 {
  margin-right: -1.5rem !important;
}
[data-pc-direction=ltr] .me-n5 {
  margin-right: -3rem !important;
}
[data-pc-direction=ltr] .mb-n1 {
  margin-bottom: -0.25rem !important;
}
[data-pc-direction=ltr] .mb-n2 {
  margin-bottom: -0.5rem !important;
}
[data-pc-direction=ltr] .mb-n3 {
  margin-bottom: -1rem !important;
}
[data-pc-direction=ltr] .mb-n4 {
  margin-bottom: -1.5rem !important;
}
[data-pc-direction=ltr] .mb-n5 {
  margin-bottom: -3rem !important;
}
[data-pc-direction=ltr] .ms-n1 {
  margin-left: -0.25rem !important;
}
[data-pc-direction=ltr] .ms-n2 {
  margin-left: -0.5rem !important;
}
[data-pc-direction=ltr] .ms-n3 {
  margin-left: -1rem !important;
}
[data-pc-direction=ltr] .ms-n4 {
  margin-left: -1.5rem !important;
}
[data-pc-direction=ltr] .ms-n5 {
  margin-left: -3rem !important;
}
[data-pc-direction=ltr] .p-0 {
  padding: 0 !important;
}
[data-pc-direction=ltr] .p-1 {
  padding: 0.25rem !important;
}
[data-pc-direction=ltr] .p-2 {
  padding: 0.5rem !important;
}
[data-pc-direction=ltr] .p-3 {
  padding: 1rem !important;
}
[data-pc-direction=ltr] .p-4 {
  padding: 1.5rem !important;
}
[data-pc-direction=ltr] .p-5 {
  padding: 3rem !important;
}
[data-pc-direction=ltr] .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
[data-pc-direction=ltr] .px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
[data-pc-direction=ltr] .px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
[data-pc-direction=ltr] .px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
[data-pc-direction=ltr] .px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
[data-pc-direction=ltr] .px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
[data-pc-direction=ltr] .py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
[data-pc-direction=ltr] .py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
[data-pc-direction=ltr] .py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
[data-pc-direction=ltr] .py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
[data-pc-direction=ltr] .py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
[data-pc-direction=ltr] .py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
[data-pc-direction=ltr] .pt-0 {
  padding-top: 0 !important;
}
[data-pc-direction=ltr] .pt-1 {
  padding-top: 0.25rem !important;
}
[data-pc-direction=ltr] .pt-2 {
  padding-top: 0.5rem !important;
}
[data-pc-direction=ltr] .pt-3 {
  padding-top: 1rem !important;
}
[data-pc-direction=ltr] .pt-4 {
  padding-top: 1.5rem !important;
}
[data-pc-direction=ltr] .pt-5 {
  padding-top: 3rem !important;
}
[data-pc-direction=ltr] .pe-0 {
  padding-right: 0 !important;
}
[data-pc-direction=ltr] .pe-1 {
  padding-right: 0.25rem !important;
}
[data-pc-direction=ltr] .pe-2 {
  padding-right: 0.5rem !important;
}
[data-pc-direction=ltr] .pe-3 {
  padding-right: 1rem !important;
}
[data-pc-direction=ltr] .pe-4 {
  padding-right: 1.5rem !important;
}
[data-pc-direction=ltr] .pe-5 {
  padding-right: 3rem !important;
}
[data-pc-direction=ltr] .pb-0 {
  padding-bottom: 0 !important;
}
[data-pc-direction=ltr] .pb-1 {
  padding-bottom: 0.25rem !important;
}
[data-pc-direction=ltr] .pb-2 {
  padding-bottom: 0.5rem !important;
}
[data-pc-direction=ltr] .pb-3 {
  padding-bottom: 1rem !important;
}
[data-pc-direction=ltr] .pb-4 {
  padding-bottom: 1.5rem !important;
}
[data-pc-direction=ltr] .pb-5 {
  padding-bottom: 3rem !important;
}
[data-pc-direction=ltr] .ps-0 {
  padding-left: 0 !important;
}
[data-pc-direction=ltr] .ps-1 {
  padding-left: 0.25rem !important;
}
[data-pc-direction=ltr] .ps-2 {
  padding-left: 0.5rem !important;
}
[data-pc-direction=ltr] .ps-3 {
  padding-left: 1rem !important;
}
[data-pc-direction=ltr] .ps-4 {
  padding-left: 1.5rem !important;
}
[data-pc-direction=ltr] .ps-5 {
  padding-left: 3rem !important;
}
[data-pc-direction=ltr] .gap-0 {
  gap: 0 !important;
}
[data-pc-direction=ltr] .gap-1 {
  gap: 0.25rem !important;
}
[data-pc-direction=ltr] .gap-2 {
  gap: 0.5rem !important;
}
[data-pc-direction=ltr] .gap-3 {
  gap: 1rem !important;
}
[data-pc-direction=ltr] .gap-4 {
  gap: 1.5rem !important;
}
[data-pc-direction=ltr] .gap-5 {
  gap: 3rem !important;
}
[data-pc-direction=ltr] .row-gap-0 {
  row-gap: 0 !important;
}
[data-pc-direction=ltr] .row-gap-1 {
  row-gap: 0.25rem !important;
}
[data-pc-direction=ltr] .row-gap-2 {
  row-gap: 0.5rem !important;
}
[data-pc-direction=ltr] .row-gap-3 {
  row-gap: 1rem !important;
}
[data-pc-direction=ltr] .row-gap-4 {
  row-gap: 1.5rem !important;
}
[data-pc-direction=ltr] .row-gap-5 {
  row-gap: 3rem !important;
}
[data-pc-direction=ltr] .column-gap-0 {
  column-gap: 0 !important;
}
[data-pc-direction=ltr] .column-gap-1 {
  column-gap: 0.25rem !important;
}
[data-pc-direction=ltr] .column-gap-2 {
  column-gap: 0.5rem !important;
}
[data-pc-direction=ltr] .column-gap-3 {
  column-gap: 1rem !important;
}
[data-pc-direction=ltr] .column-gap-4 {
  column-gap: 1.5rem !important;
}
[data-pc-direction=ltr] .column-gap-5 {
  column-gap: 3rem !important;
}
[data-pc-direction=ltr] .font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
[data-pc-direction=ltr] .fs-1 {
  font-size: calc(1.3625rem + 1.35vw) !important;
}
[data-pc-direction=ltr] .fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}
[data-pc-direction=ltr] .fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
[data-pc-direction=ltr] .fs-4 {
  font-size: 1.25rem !important;
}
[data-pc-direction=ltr] .fs-5 {
  font-size: 1rem !important;
}
[data-pc-direction=ltr] .fs-6 {
  font-size: 0.875rem !important;
}
[data-pc-direction=ltr] .fst-italic {
  font-style: italic !important;
}
[data-pc-direction=ltr] .fst-normal {
  font-style: normal !important;
}
[data-pc-direction=ltr] .fw-lighter {
  font-weight: lighter !important;
}
[data-pc-direction=ltr] .fw-light {
  font-weight: 300 !important;
}
[data-pc-direction=ltr] .fw-normal {
  font-weight: 400 !important;
}
[data-pc-direction=ltr] .fw-medium {
  font-weight: 500 !important;
}
[data-pc-direction=ltr] .fw-semibold {
  font-weight: 600 !important;
}
[data-pc-direction=ltr] .fw-bold {
  font-weight: 700 !important;
}
[data-pc-direction=ltr] .fw-bolder {
  font-weight: bolder !important;
}
[data-pc-direction=ltr] .lh-1 {
  line-height: 1 !important;
}
[data-pc-direction=ltr] .lh-sm {
  line-height: 1.25 !important;
}
[data-pc-direction=ltr] .lh-base {
  line-height: 1.5 !important;
}
[data-pc-direction=ltr] .lh-lg {
  line-height: 2 !important;
}
[data-pc-direction=ltr] .text-start {
  text-align: left !important;
}
[data-pc-direction=ltr] .text-end {
  text-align: right !important;
}
[data-pc-direction=ltr] .text-center {
  text-align: center !important;
}
[data-pc-direction=ltr] .text-decoration-none {
  text-decoration: none !important;
}
[data-pc-direction=ltr] .text-decoration-underline {
  text-decoration: underline !important;
}
[data-pc-direction=ltr] .text-decoration-line-through {
  text-decoration: line-through !important;
}
[data-pc-direction=ltr] .text-lowercase {
  text-transform: lowercase !important;
}
[data-pc-direction=ltr] .text-uppercase {
  text-transform: uppercase !important;
}
[data-pc-direction=ltr] .text-capitalize {
  text-transform: capitalize !important;
}
[data-pc-direction=ltr] .text-wrap {
  white-space: normal !important;
}
[data-pc-direction=ltr] .text-nowrap {
  white-space: nowrap !important;
}
[data-pc-direction=ltr] .text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
[data-pc-direction=ltr] .text-theme {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-theme-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=ltr] .text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=ltr] .text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=ltr] .text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=ltr] .text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=ltr] .text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=ltr] .text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=ltr] .text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=ltr] .text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=ltr] .text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=ltr] .text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=ltr] .text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=ltr] .text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}
[data-pc-direction=ltr] .text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
[data-pc-direction=ltr] .text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
[data-pc-direction=ltr] .text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}
[data-pc-direction=ltr] .text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}
[data-pc-direction=ltr] .text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}
[data-pc-direction=ltr] .text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
[data-pc-direction=ltr] .text-opacity-25 {
  --bs-text-opacity: 0.25;
}
[data-pc-direction=ltr] .text-opacity-50 {
  --bs-text-opacity: 0.5;
}
[data-pc-direction=ltr] .text-opacity-75 {
  --bs-text-opacity: 0.75;
}
[data-pc-direction=ltr] .text-opacity-100 {
  --bs-text-opacity: 1;
}
[data-pc-direction=ltr] .text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}
[data-pc-direction=ltr] .text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}
[data-pc-direction=ltr] .text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}
[data-pc-direction=ltr] .text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}
[data-pc-direction=ltr] .text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}
[data-pc-direction=ltr] .text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}
[data-pc-direction=ltr] .text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}
[data-pc-direction=ltr] .text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}
[data-pc-direction=ltr] .link-opacity-10 {
  --bs-link-opacity: 0.1;
}
[data-pc-direction=ltr] .link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}
[data-pc-direction=ltr] .link-opacity-25 {
  --bs-link-opacity: 0.25;
}
[data-pc-direction=ltr] .link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}
[data-pc-direction=ltr] .link-opacity-50 {
  --bs-link-opacity: 0.5;
}
[data-pc-direction=ltr] .link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}
[data-pc-direction=ltr] .link-opacity-75 {
  --bs-link-opacity: 0.75;
}
[data-pc-direction=ltr] .link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}
[data-pc-direction=ltr] .link-opacity-100 {
  --bs-link-opacity: 1;
}
[data-pc-direction=ltr] .link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}
[data-pc-direction=ltr] .link-offset-1 {
  text-underline-offset: 0.125em !important;
}
[data-pc-direction=ltr] .link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}
[data-pc-direction=ltr] .link-offset-2 {
  text-underline-offset: 0.25em !important;
}
[data-pc-direction=ltr] .link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}
[data-pc-direction=ltr] .link-offset-3 {
  text-underline-offset: 0.375em !important;
}
[data-pc-direction=ltr] .link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}
[data-pc-direction=ltr] .link-underline-theme {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-theme-rgb), var(--bs-link-underline-opacity)) !important;
}
[data-pc-direction=ltr] .link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}
[data-pc-direction=ltr] .link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}
[data-pc-direction=ltr] .link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}
[data-pc-direction=ltr] .link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}
[data-pc-direction=ltr] .link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}
[data-pc-direction=ltr] .link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}
[data-pc-direction=ltr] .link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}
[data-pc-direction=ltr] .link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}
[data-pc-direction=ltr] .link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
[data-pc-direction=ltr] .link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}
[data-pc-direction=ltr] .link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}
[data-pc-direction=ltr] .link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}
[data-pc-direction=ltr] .link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}
[data-pc-direction=ltr] .link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}
[data-pc-direction=ltr] .link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}
[data-pc-direction=ltr] .link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}
[data-pc-direction=ltr] .link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}
[data-pc-direction=ltr] .link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}
[data-pc-direction=ltr] .link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}
[data-pc-direction=ltr] .link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}
[data-pc-direction=ltr] .link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}
[data-pc-direction=ltr] .bg-theme {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-theme-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
[data-pc-direction=ltr] .bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=ltr] .bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
[data-pc-direction=ltr] .bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
[data-pc-direction=ltr] .bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
[data-pc-direction=ltr] .bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
[data-pc-direction=ltr] .bg-opacity-100 {
  --bs-bg-opacity: 1;
}
[data-pc-direction=ltr] .bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}
[data-pc-direction=ltr] .bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}
[data-pc-direction=ltr] .bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}
[data-pc-direction=ltr] .bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}
[data-pc-direction=ltr] .bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}
[data-pc-direction=ltr] .bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}
[data-pc-direction=ltr] .bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}
[data-pc-direction=ltr] .bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}
[data-pc-direction=ltr] .bg-gradient {
  background-image: var(--bs-gradient) !important;
}
[data-pc-direction=ltr] .user-select-all {
  user-select: all !important;
}
[data-pc-direction=ltr] .user-select-auto {
  user-select: auto !important;
}
[data-pc-direction=ltr] .user-select-none {
  user-select: none !important;
}
[data-pc-direction=ltr] .pe-none {
  pointer-events: none !important;
}
[data-pc-direction=ltr] .pe-auto {
  pointer-events: auto !important;
}
[data-pc-direction=ltr] .rounded {
  border-radius: var(--bs-border-radius) !important;
}
[data-pc-direction=ltr] .rounded-0 {
  border-radius: 0 !important;
}
[data-pc-direction=ltr] .rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}
[data-pc-direction=ltr] .rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}
[data-pc-direction=ltr] .rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}
[data-pc-direction=ltr] .rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}
[data-pc-direction=ltr] .rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}
[data-pc-direction=ltr] .rounded-circle {
  border-radius: 50% !important;
}
[data-pc-direction=ltr] .rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}
[data-pc-direction=ltr] .rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
[data-pc-direction=ltr] .rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
[data-pc-direction=ltr] .rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}
[data-pc-direction=ltr] .rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
[data-pc-direction=ltr] .rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}
[data-pc-direction=ltr] .rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}
[data-pc-direction=ltr] .rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}
[data-pc-direction=ltr] .rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}
[data-pc-direction=ltr] .rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}
[data-pc-direction=ltr] .rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
[data-pc-direction=ltr] .rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
[data-pc-direction=ltr] .rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}
[data-pc-direction=ltr] .rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
[data-pc-direction=ltr] .rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}
[data-pc-direction=ltr] .rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}
[data-pc-direction=ltr] .rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}
[data-pc-direction=ltr] .rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
[data-pc-direction=ltr] .rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}
[data-pc-direction=ltr] .rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
[data-pc-direction=ltr] .rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
[data-pc-direction=ltr] .rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}
[data-pc-direction=ltr] .rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
[data-pc-direction=ltr] .rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}
[data-pc-direction=ltr] .rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}
[data-pc-direction=ltr] .rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}
[data-pc-direction=ltr] .rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
[data-pc-direction=ltr] .rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}
[data-pc-direction=ltr] .rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
[data-pc-direction=ltr] .rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
[data-pc-direction=ltr] .rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}
[data-pc-direction=ltr] .rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
[data-pc-direction=ltr] .rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}
[data-pc-direction=ltr] .rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}
[data-pc-direction=ltr] .rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}
[data-pc-direction=ltr] .rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}
[data-pc-direction=ltr] .rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}
[data-pc-direction=ltr] .visible {
  visibility: visible !important;
}
[data-pc-direction=ltr] .invisible {
  visibility: hidden !important;
}
[data-pc-direction=ltr] .z-n1 {
  z-index: -1 !important;
}
[data-pc-direction=ltr] .z-0 {
  z-index: 0 !important;
}
[data-pc-direction=ltr] .z-1 {
  z-index: 1 !important;
}
[data-pc-direction=ltr] .z-2 {
  z-index: 2 !important;
}
[data-pc-direction=ltr] .z-3 {
  z-index: 3 !important;
}
@media (min-width: 576px) {
  [data-pc-direction=ltr] .float-sm-start {
    float: left !important;
  }
  [data-pc-direction=ltr] .float-sm-end {
    float: right !important;
  }
  [data-pc-direction=ltr] .float-sm-none {
    float: none !important;
  }
  [data-pc-direction=ltr] .object-fit-sm-contain {
    object-fit: contain !important;
  }
  [data-pc-direction=ltr] .object-fit-sm-cover {
    object-fit: cover !important;
  }
  [data-pc-direction=ltr] .object-fit-sm-fill {
    object-fit: fill !important;
  }
  [data-pc-direction=ltr] .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  [data-pc-direction=ltr] .object-fit-sm-none {
    object-fit: none !important;
  }
  [data-pc-direction=ltr] .d-sm-inline {
    display: inline !important;
  }
  [data-pc-direction=ltr] .d-sm-inline-block {
    display: inline-block !important;
  }
  [data-pc-direction=ltr] .d-sm-block {
    display: block !important;
  }
  [data-pc-direction=ltr] .d-sm-grid {
    display: grid !important;
  }
  [data-pc-direction=ltr] .d-sm-inline-grid {
    display: inline-grid !important;
  }
  [data-pc-direction=ltr] .d-sm-table {
    display: table !important;
  }
  [data-pc-direction=ltr] .d-sm-table-row {
    display: table-row !important;
  }
  [data-pc-direction=ltr] .d-sm-table-cell {
    display: table-cell !important;
  }
  [data-pc-direction=ltr] .d-sm-flex {
    display: flex !important;
  }
  [data-pc-direction=ltr] .d-sm-inline-flex {
    display: inline-flex !important;
  }
  [data-pc-direction=ltr] .d-sm-none {
    display: none !important;
  }
  [data-pc-direction=ltr] .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  [data-pc-direction=ltr] .flex-sm-row {
    flex-direction: row !important;
  }
  [data-pc-direction=ltr] .flex-sm-column {
    flex-direction: column !important;
  }
  [data-pc-direction=ltr] .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  [data-pc-direction=ltr] .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  [data-pc-direction=ltr] .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  [data-pc-direction=ltr] .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  [data-pc-direction=ltr] .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  [data-pc-direction=ltr] .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  [data-pc-direction=ltr] .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  [data-pc-direction=ltr] .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  [data-pc-direction=ltr] .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  [data-pc-direction=ltr] .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  [data-pc-direction=ltr] .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  [data-pc-direction=ltr] .justify-content-sm-center {
    justify-content: center !important;
  }
  [data-pc-direction=ltr] .justify-content-sm-between {
    justify-content: space-between !important;
  }
  [data-pc-direction=ltr] .justify-content-sm-around {
    justify-content: space-around !important;
  }
  [data-pc-direction=ltr] .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  [data-pc-direction=ltr] .align-items-sm-start {
    align-items: flex-start !important;
  }
  [data-pc-direction=ltr] .align-items-sm-end {
    align-items: flex-end !important;
  }
  [data-pc-direction=ltr] .align-items-sm-center {
    align-items: center !important;
  }
  [data-pc-direction=ltr] .align-items-sm-baseline {
    align-items: baseline !important;
  }
  [data-pc-direction=ltr] .align-items-sm-stretch {
    align-items: stretch !important;
  }
  [data-pc-direction=ltr] .align-content-sm-start {
    align-content: flex-start !important;
  }
  [data-pc-direction=ltr] .align-content-sm-end {
    align-content: flex-end !important;
  }
  [data-pc-direction=ltr] .align-content-sm-center {
    align-content: center !important;
  }
  [data-pc-direction=ltr] .align-content-sm-between {
    align-content: space-between !important;
  }
  [data-pc-direction=ltr] .align-content-sm-around {
    align-content: space-around !important;
  }
  [data-pc-direction=ltr] .align-content-sm-stretch {
    align-content: stretch !important;
  }
  [data-pc-direction=ltr] .align-self-sm-auto {
    align-self: auto !important;
  }
  [data-pc-direction=ltr] .align-self-sm-start {
    align-self: flex-start !important;
  }
  [data-pc-direction=ltr] .align-self-sm-end {
    align-self: flex-end !important;
  }
  [data-pc-direction=ltr] .align-self-sm-center {
    align-self: center !important;
  }
  [data-pc-direction=ltr] .align-self-sm-baseline {
    align-self: baseline !important;
  }
  [data-pc-direction=ltr] .align-self-sm-stretch {
    align-self: stretch !important;
  }
  [data-pc-direction=ltr] .order-sm-first {
    order: -1 !important;
  }
  [data-pc-direction=ltr] .order-sm-0 {
    order: 0 !important;
  }
  [data-pc-direction=ltr] .order-sm-1 {
    order: 1 !important;
  }
  [data-pc-direction=ltr] .order-sm-2 {
    order: 2 !important;
  }
  [data-pc-direction=ltr] .order-sm-3 {
    order: 3 !important;
  }
  [data-pc-direction=ltr] .order-sm-4 {
    order: 4 !important;
  }
  [data-pc-direction=ltr] .order-sm-5 {
    order: 5 !important;
  }
  [data-pc-direction=ltr] .order-sm-last {
    order: 6 !important;
  }
  [data-pc-direction=ltr] .m-sm-0 {
    margin: 0 !important;
  }
  [data-pc-direction=ltr] .m-sm-1 {
    margin: 0.25rem !important;
  }
  [data-pc-direction=ltr] .m-sm-2 {
    margin: 0.5rem !important;
  }
  [data-pc-direction=ltr] .m-sm-3 {
    margin: 1rem !important;
  }
  [data-pc-direction=ltr] .m-sm-4 {
    margin: 1.5rem !important;
  }
  [data-pc-direction=ltr] .m-sm-5 {
    margin: 3rem !important;
  }
  [data-pc-direction=ltr] .m-sm-auto {
    margin: auto !important;
  }
  [data-pc-direction=ltr] .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [data-pc-direction=ltr] .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  [data-pc-direction=ltr] .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  [data-pc-direction=ltr] .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  [data-pc-direction=ltr] .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  [data-pc-direction=ltr] .mt-sm-0 {
    margin-top: 0 !important;
  }
  [data-pc-direction=ltr] .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mt-sm-3 {
    margin-top: 1rem !important;
  }
  [data-pc-direction=ltr] .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mt-sm-5 {
    margin-top: 3rem !important;
  }
  [data-pc-direction=ltr] .mt-sm-auto {
    margin-top: auto !important;
  }
  [data-pc-direction=ltr] .me-sm-0 {
    margin-right: 0 !important;
  }
  [data-pc-direction=ltr] .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  [data-pc-direction=ltr] .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  [data-pc-direction=ltr] .me-sm-3 {
    margin-right: 1rem !important;
  }
  [data-pc-direction=ltr] .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  [data-pc-direction=ltr] .me-sm-5 {
    margin-right: 3rem !important;
  }
  [data-pc-direction=ltr] .me-sm-auto {
    margin-right: auto !important;
  }
  [data-pc-direction=ltr] .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .mb-sm-auto {
    margin-bottom: auto !important;
  }
  [data-pc-direction=ltr] .ms-sm-0 {
    margin-left: 0 !important;
  }
  [data-pc-direction=ltr] .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .ms-sm-3 {
    margin-left: 1rem !important;
  }
  [data-pc-direction=ltr] .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .ms-sm-5 {
    margin-left: 3rem !important;
  }
  [data-pc-direction=ltr] .ms-sm-auto {
    margin-left: auto !important;
  }
  [data-pc-direction=ltr] .m-sm-n1 {
    margin: -0.25rem !important;
  }
  [data-pc-direction=ltr] .m-sm-n2 {
    margin: -0.5rem !important;
  }
  [data-pc-direction=ltr] .m-sm-n3 {
    margin: -1rem !important;
  }
  [data-pc-direction=ltr] .m-sm-n4 {
    margin: -1.5rem !important;
  }
  [data-pc-direction=ltr] .m-sm-n5 {
    margin: -3rem !important;
  }
  [data-pc-direction=ltr] .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  [data-pc-direction=ltr] .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  [data-pc-direction=ltr] .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=ltr] .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=ltr] .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=ltr] .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=ltr] .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=ltr] .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  [data-pc-direction=ltr] .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  [data-pc-direction=ltr] .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  [data-pc-direction=ltr] .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  [data-pc-direction=ltr] .me-sm-n3 {
    margin-right: -1rem !important;
  }
  [data-pc-direction=ltr] .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  [data-pc-direction=ltr] .me-sm-n5 {
    margin-right: -3rem !important;
  }
  [data-pc-direction=ltr] .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=ltr] .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=ltr] .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=ltr] .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=ltr] .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  [data-pc-direction=ltr] .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=ltr] .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  [data-pc-direction=ltr] .p-sm-0 {
    padding: 0 !important;
  }
  [data-pc-direction=ltr] .p-sm-1 {
    padding: 0.25rem !important;
  }
  [data-pc-direction=ltr] .p-sm-2 {
    padding: 0.5rem !important;
  }
  [data-pc-direction=ltr] .p-sm-3 {
    padding: 1rem !important;
  }
  [data-pc-direction=ltr] .p-sm-4 {
    padding: 1.5rem !important;
  }
  [data-pc-direction=ltr] .p-sm-5 {
    padding: 3rem !important;
  }
  [data-pc-direction=ltr] .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [data-pc-direction=ltr] .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  [data-pc-direction=ltr] .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  [data-pc-direction=ltr] .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .pt-sm-0 {
    padding-top: 0 !important;
  }
  [data-pc-direction=ltr] .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pt-sm-3 {
    padding-top: 1rem !important;
  }
  [data-pc-direction=ltr] .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pt-sm-5 {
    padding-top: 3rem !important;
  }
  [data-pc-direction=ltr] .pe-sm-0 {
    padding-right: 0 !important;
  }
  [data-pc-direction=ltr] .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pe-sm-3 {
    padding-right: 1rem !important;
  }
  [data-pc-direction=ltr] .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pe-sm-5 {
    padding-right: 3rem !important;
  }
  [data-pc-direction=ltr] .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .ps-sm-0 {
    padding-left: 0 !important;
  }
  [data-pc-direction=ltr] .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .ps-sm-3 {
    padding-left: 1rem !important;
  }
  [data-pc-direction=ltr] .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .ps-sm-5 {
    padding-left: 3rem !important;
  }
  [data-pc-direction=ltr] .gap-sm-0 {
    gap: 0 !important;
  }
  [data-pc-direction=ltr] .gap-sm-1 {
    gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .gap-sm-2 {
    gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .gap-sm-3 {
    gap: 1rem !important;
  }
  [data-pc-direction=ltr] .gap-sm-4 {
    gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .gap-sm-5 {
    gap: 3rem !important;
  }
  [data-pc-direction=ltr] .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  [data-pc-direction=ltr] .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  [data-pc-direction=ltr] .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  [data-pc-direction=ltr] .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  [data-pc-direction=ltr] .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  [data-pc-direction=ltr] .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  [data-pc-direction=ltr] .text-sm-start {
    text-align: left !important;
  }
  [data-pc-direction=ltr] .text-sm-end {
    text-align: right !important;
  }
  [data-pc-direction=ltr] .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  [data-pc-direction=ltr] .float-md-start {
    float: left !important;
  }
  [data-pc-direction=ltr] .float-md-end {
    float: right !important;
  }
  [data-pc-direction=ltr] .float-md-none {
    float: none !important;
  }
  [data-pc-direction=ltr] .object-fit-md-contain {
    object-fit: contain !important;
  }
  [data-pc-direction=ltr] .object-fit-md-cover {
    object-fit: cover !important;
  }
  [data-pc-direction=ltr] .object-fit-md-fill {
    object-fit: fill !important;
  }
  [data-pc-direction=ltr] .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  [data-pc-direction=ltr] .object-fit-md-none {
    object-fit: none !important;
  }
  [data-pc-direction=ltr] .d-md-inline {
    display: inline !important;
  }
  [data-pc-direction=ltr] .d-md-inline-block {
    display: inline-block !important;
  }
  [data-pc-direction=ltr] .d-md-block {
    display: block !important;
  }
  [data-pc-direction=ltr] .d-md-grid {
    display: grid !important;
  }
  [data-pc-direction=ltr] .d-md-inline-grid {
    display: inline-grid !important;
  }
  [data-pc-direction=ltr] .d-md-table {
    display: table !important;
  }
  [data-pc-direction=ltr] .d-md-table-row {
    display: table-row !important;
  }
  [data-pc-direction=ltr] .d-md-table-cell {
    display: table-cell !important;
  }
  [data-pc-direction=ltr] .d-md-flex {
    display: flex !important;
  }
  [data-pc-direction=ltr] .d-md-inline-flex {
    display: inline-flex !important;
  }
  [data-pc-direction=ltr] .d-md-none {
    display: none !important;
  }
  [data-pc-direction=ltr] .flex-md-fill {
    flex: 1 1 auto !important;
  }
  [data-pc-direction=ltr] .flex-md-row {
    flex-direction: row !important;
  }
  [data-pc-direction=ltr] .flex-md-column {
    flex-direction: column !important;
  }
  [data-pc-direction=ltr] .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  [data-pc-direction=ltr] .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  [data-pc-direction=ltr] .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  [data-pc-direction=ltr] .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  [data-pc-direction=ltr] .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  [data-pc-direction=ltr] .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  [data-pc-direction=ltr] .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  [data-pc-direction=ltr] .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  [data-pc-direction=ltr] .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  [data-pc-direction=ltr] .justify-content-md-start {
    justify-content: flex-start !important;
  }
  [data-pc-direction=ltr] .justify-content-md-end {
    justify-content: flex-end !important;
  }
  [data-pc-direction=ltr] .justify-content-md-center {
    justify-content: center !important;
  }
  [data-pc-direction=ltr] .justify-content-md-between {
    justify-content: space-between !important;
  }
  [data-pc-direction=ltr] .justify-content-md-around {
    justify-content: space-around !important;
  }
  [data-pc-direction=ltr] .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  [data-pc-direction=ltr] .align-items-md-start {
    align-items: flex-start !important;
  }
  [data-pc-direction=ltr] .align-items-md-end {
    align-items: flex-end !important;
  }
  [data-pc-direction=ltr] .align-items-md-center {
    align-items: center !important;
  }
  [data-pc-direction=ltr] .align-items-md-baseline {
    align-items: baseline !important;
  }
  [data-pc-direction=ltr] .align-items-md-stretch {
    align-items: stretch !important;
  }
  [data-pc-direction=ltr] .align-content-md-start {
    align-content: flex-start !important;
  }
  [data-pc-direction=ltr] .align-content-md-end {
    align-content: flex-end !important;
  }
  [data-pc-direction=ltr] .align-content-md-center {
    align-content: center !important;
  }
  [data-pc-direction=ltr] .align-content-md-between {
    align-content: space-between !important;
  }
  [data-pc-direction=ltr] .align-content-md-around {
    align-content: space-around !important;
  }
  [data-pc-direction=ltr] .align-content-md-stretch {
    align-content: stretch !important;
  }
  [data-pc-direction=ltr] .align-self-md-auto {
    align-self: auto !important;
  }
  [data-pc-direction=ltr] .align-self-md-start {
    align-self: flex-start !important;
  }
  [data-pc-direction=ltr] .align-self-md-end {
    align-self: flex-end !important;
  }
  [data-pc-direction=ltr] .align-self-md-center {
    align-self: center !important;
  }
  [data-pc-direction=ltr] .align-self-md-baseline {
    align-self: baseline !important;
  }
  [data-pc-direction=ltr] .align-self-md-stretch {
    align-self: stretch !important;
  }
  [data-pc-direction=ltr] .order-md-first {
    order: -1 !important;
  }
  [data-pc-direction=ltr] .order-md-0 {
    order: 0 !important;
  }
  [data-pc-direction=ltr] .order-md-1 {
    order: 1 !important;
  }
  [data-pc-direction=ltr] .order-md-2 {
    order: 2 !important;
  }
  [data-pc-direction=ltr] .order-md-3 {
    order: 3 !important;
  }
  [data-pc-direction=ltr] .order-md-4 {
    order: 4 !important;
  }
  [data-pc-direction=ltr] .order-md-5 {
    order: 5 !important;
  }
  [data-pc-direction=ltr] .order-md-last {
    order: 6 !important;
  }
  [data-pc-direction=ltr] .m-md-0 {
    margin: 0 !important;
  }
  [data-pc-direction=ltr] .m-md-1 {
    margin: 0.25rem !important;
  }
  [data-pc-direction=ltr] .m-md-2 {
    margin: 0.5rem !important;
  }
  [data-pc-direction=ltr] .m-md-3 {
    margin: 1rem !important;
  }
  [data-pc-direction=ltr] .m-md-4 {
    margin: 1.5rem !important;
  }
  [data-pc-direction=ltr] .m-md-5 {
    margin: 3rem !important;
  }
  [data-pc-direction=ltr] .m-md-auto {
    margin: auto !important;
  }
  [data-pc-direction=ltr] .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [data-pc-direction=ltr] .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  [data-pc-direction=ltr] .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  [data-pc-direction=ltr] .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  [data-pc-direction=ltr] .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  [data-pc-direction=ltr] .mt-md-0 {
    margin-top: 0 !important;
  }
  [data-pc-direction=ltr] .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mt-md-3 {
    margin-top: 1rem !important;
  }
  [data-pc-direction=ltr] .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mt-md-5 {
    margin-top: 3rem !important;
  }
  [data-pc-direction=ltr] .mt-md-auto {
    margin-top: auto !important;
  }
  [data-pc-direction=ltr] .me-md-0 {
    margin-right: 0 !important;
  }
  [data-pc-direction=ltr] .me-md-1 {
    margin-right: 0.25rem !important;
  }
  [data-pc-direction=ltr] .me-md-2 {
    margin-right: 0.5rem !important;
  }
  [data-pc-direction=ltr] .me-md-3 {
    margin-right: 1rem !important;
  }
  [data-pc-direction=ltr] .me-md-4 {
    margin-right: 1.5rem !important;
  }
  [data-pc-direction=ltr] .me-md-5 {
    margin-right: 3rem !important;
  }
  [data-pc-direction=ltr] .me-md-auto {
    margin-right: auto !important;
  }
  [data-pc-direction=ltr] .mb-md-0 {
    margin-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .mb-md-auto {
    margin-bottom: auto !important;
  }
  [data-pc-direction=ltr] .ms-md-0 {
    margin-left: 0 !important;
  }
  [data-pc-direction=ltr] .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .ms-md-3 {
    margin-left: 1rem !important;
  }
  [data-pc-direction=ltr] .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .ms-md-5 {
    margin-left: 3rem !important;
  }
  [data-pc-direction=ltr] .ms-md-auto {
    margin-left: auto !important;
  }
  [data-pc-direction=ltr] .m-md-n1 {
    margin: -0.25rem !important;
  }
  [data-pc-direction=ltr] .m-md-n2 {
    margin: -0.5rem !important;
  }
  [data-pc-direction=ltr] .m-md-n3 {
    margin: -1rem !important;
  }
  [data-pc-direction=ltr] .m-md-n4 {
    margin: -1.5rem !important;
  }
  [data-pc-direction=ltr] .m-md-n5 {
    margin: -3rem !important;
  }
  [data-pc-direction=ltr] .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  [data-pc-direction=ltr] .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  [data-pc-direction=ltr] .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=ltr] .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=ltr] .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=ltr] .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=ltr] .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=ltr] .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mt-md-n3 {
    margin-top: -1rem !important;
  }
  [data-pc-direction=ltr] .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mt-md-n5 {
    margin-top: -3rem !important;
  }
  [data-pc-direction=ltr] .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  [data-pc-direction=ltr] .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  [data-pc-direction=ltr] .me-md-n3 {
    margin-right: -1rem !important;
  }
  [data-pc-direction=ltr] .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  [data-pc-direction=ltr] .me-md-n5 {
    margin-right: -3rem !important;
  }
  [data-pc-direction=ltr] .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=ltr] .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=ltr] .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=ltr] .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=ltr] .ms-md-n3 {
    margin-left: -1rem !important;
  }
  [data-pc-direction=ltr] .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=ltr] .ms-md-n5 {
    margin-left: -3rem !important;
  }
  [data-pc-direction=ltr] .p-md-0 {
    padding: 0 !important;
  }
  [data-pc-direction=ltr] .p-md-1 {
    padding: 0.25rem !important;
  }
  [data-pc-direction=ltr] .p-md-2 {
    padding: 0.5rem !important;
  }
  [data-pc-direction=ltr] .p-md-3 {
    padding: 1rem !important;
  }
  [data-pc-direction=ltr] .p-md-4 {
    padding: 1.5rem !important;
  }
  [data-pc-direction=ltr] .p-md-5 {
    padding: 3rem !important;
  }
  [data-pc-direction=ltr] .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [data-pc-direction=ltr] .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  [data-pc-direction=ltr] .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  [data-pc-direction=ltr] .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .pt-md-0 {
    padding-top: 0 !important;
  }
  [data-pc-direction=ltr] .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pt-md-3 {
    padding-top: 1rem !important;
  }
  [data-pc-direction=ltr] .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pt-md-5 {
    padding-top: 3rem !important;
  }
  [data-pc-direction=ltr] .pe-md-0 {
    padding-right: 0 !important;
  }
  [data-pc-direction=ltr] .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pe-md-3 {
    padding-right: 1rem !important;
  }
  [data-pc-direction=ltr] .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pe-md-5 {
    padding-right: 3rem !important;
  }
  [data-pc-direction=ltr] .pb-md-0 {
    padding-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .ps-md-0 {
    padding-left: 0 !important;
  }
  [data-pc-direction=ltr] .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .ps-md-3 {
    padding-left: 1rem !important;
  }
  [data-pc-direction=ltr] .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .ps-md-5 {
    padding-left: 3rem !important;
  }
  [data-pc-direction=ltr] .gap-md-0 {
    gap: 0 !important;
  }
  [data-pc-direction=ltr] .gap-md-1 {
    gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .gap-md-2 {
    gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .gap-md-3 {
    gap: 1rem !important;
  }
  [data-pc-direction=ltr] .gap-md-4 {
    gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .gap-md-5 {
    gap: 3rem !important;
  }
  [data-pc-direction=ltr] .row-gap-md-0 {
    row-gap: 0 !important;
  }
  [data-pc-direction=ltr] .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  [data-pc-direction=ltr] .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  [data-pc-direction=ltr] .column-gap-md-0 {
    column-gap: 0 !important;
  }
  [data-pc-direction=ltr] .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  [data-pc-direction=ltr] .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  [data-pc-direction=ltr] .text-md-start {
    text-align: left !important;
  }
  [data-pc-direction=ltr] .text-md-end {
    text-align: right !important;
  }
  [data-pc-direction=ltr] .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  [data-pc-direction=ltr] .float-lg-start {
    float: left !important;
  }
  [data-pc-direction=ltr] .float-lg-end {
    float: right !important;
  }
  [data-pc-direction=ltr] .float-lg-none {
    float: none !important;
  }
  [data-pc-direction=ltr] .object-fit-lg-contain {
    object-fit: contain !important;
  }
  [data-pc-direction=ltr] .object-fit-lg-cover {
    object-fit: cover !important;
  }
  [data-pc-direction=ltr] .object-fit-lg-fill {
    object-fit: fill !important;
  }
  [data-pc-direction=ltr] .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  [data-pc-direction=ltr] .object-fit-lg-none {
    object-fit: none !important;
  }
  [data-pc-direction=ltr] .d-lg-inline {
    display: inline !important;
  }
  [data-pc-direction=ltr] .d-lg-inline-block {
    display: inline-block !important;
  }
  [data-pc-direction=ltr] .d-lg-block {
    display: block !important;
  }
  [data-pc-direction=ltr] .d-lg-grid {
    display: grid !important;
  }
  [data-pc-direction=ltr] .d-lg-inline-grid {
    display: inline-grid !important;
  }
  [data-pc-direction=ltr] .d-lg-table {
    display: table !important;
  }
  [data-pc-direction=ltr] .d-lg-table-row {
    display: table-row !important;
  }
  [data-pc-direction=ltr] .d-lg-table-cell {
    display: table-cell !important;
  }
  [data-pc-direction=ltr] .d-lg-flex {
    display: flex !important;
  }
  [data-pc-direction=ltr] .d-lg-inline-flex {
    display: inline-flex !important;
  }
  [data-pc-direction=ltr] .d-lg-none {
    display: none !important;
  }
  [data-pc-direction=ltr] .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  [data-pc-direction=ltr] .flex-lg-row {
    flex-direction: row !important;
  }
  [data-pc-direction=ltr] .flex-lg-column {
    flex-direction: column !important;
  }
  [data-pc-direction=ltr] .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  [data-pc-direction=ltr] .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  [data-pc-direction=ltr] .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  [data-pc-direction=ltr] .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  [data-pc-direction=ltr] .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  [data-pc-direction=ltr] .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  [data-pc-direction=ltr] .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  [data-pc-direction=ltr] .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  [data-pc-direction=ltr] .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  [data-pc-direction=ltr] .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  [data-pc-direction=ltr] .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  [data-pc-direction=ltr] .justify-content-lg-center {
    justify-content: center !important;
  }
  [data-pc-direction=ltr] .justify-content-lg-between {
    justify-content: space-between !important;
  }
  [data-pc-direction=ltr] .justify-content-lg-around {
    justify-content: space-around !important;
  }
  [data-pc-direction=ltr] .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  [data-pc-direction=ltr] .align-items-lg-start {
    align-items: flex-start !important;
  }
  [data-pc-direction=ltr] .align-items-lg-end {
    align-items: flex-end !important;
  }
  [data-pc-direction=ltr] .align-items-lg-center {
    align-items: center !important;
  }
  [data-pc-direction=ltr] .align-items-lg-baseline {
    align-items: baseline !important;
  }
  [data-pc-direction=ltr] .align-items-lg-stretch {
    align-items: stretch !important;
  }
  [data-pc-direction=ltr] .align-content-lg-start {
    align-content: flex-start !important;
  }
  [data-pc-direction=ltr] .align-content-lg-end {
    align-content: flex-end !important;
  }
  [data-pc-direction=ltr] .align-content-lg-center {
    align-content: center !important;
  }
  [data-pc-direction=ltr] .align-content-lg-between {
    align-content: space-between !important;
  }
  [data-pc-direction=ltr] .align-content-lg-around {
    align-content: space-around !important;
  }
  [data-pc-direction=ltr] .align-content-lg-stretch {
    align-content: stretch !important;
  }
  [data-pc-direction=ltr] .align-self-lg-auto {
    align-self: auto !important;
  }
  [data-pc-direction=ltr] .align-self-lg-start {
    align-self: flex-start !important;
  }
  [data-pc-direction=ltr] .align-self-lg-end {
    align-self: flex-end !important;
  }
  [data-pc-direction=ltr] .align-self-lg-center {
    align-self: center !important;
  }
  [data-pc-direction=ltr] .align-self-lg-baseline {
    align-self: baseline !important;
  }
  [data-pc-direction=ltr] .align-self-lg-stretch {
    align-self: stretch !important;
  }
  [data-pc-direction=ltr] .order-lg-first {
    order: -1 !important;
  }
  [data-pc-direction=ltr] .order-lg-0 {
    order: 0 !important;
  }
  [data-pc-direction=ltr] .order-lg-1 {
    order: 1 !important;
  }
  [data-pc-direction=ltr] .order-lg-2 {
    order: 2 !important;
  }
  [data-pc-direction=ltr] .order-lg-3 {
    order: 3 !important;
  }
  [data-pc-direction=ltr] .order-lg-4 {
    order: 4 !important;
  }
  [data-pc-direction=ltr] .order-lg-5 {
    order: 5 !important;
  }
  [data-pc-direction=ltr] .order-lg-last {
    order: 6 !important;
  }
  [data-pc-direction=ltr] .m-lg-0 {
    margin: 0 !important;
  }
  [data-pc-direction=ltr] .m-lg-1 {
    margin: 0.25rem !important;
  }
  [data-pc-direction=ltr] .m-lg-2 {
    margin: 0.5rem !important;
  }
  [data-pc-direction=ltr] .m-lg-3 {
    margin: 1rem !important;
  }
  [data-pc-direction=ltr] .m-lg-4 {
    margin: 1.5rem !important;
  }
  [data-pc-direction=ltr] .m-lg-5 {
    margin: 3rem !important;
  }
  [data-pc-direction=ltr] .m-lg-auto {
    margin: auto !important;
  }
  [data-pc-direction=ltr] .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [data-pc-direction=ltr] .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  [data-pc-direction=ltr] .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  [data-pc-direction=ltr] .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  [data-pc-direction=ltr] .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  [data-pc-direction=ltr] .mt-lg-0 {
    margin-top: 0 !important;
  }
  [data-pc-direction=ltr] .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mt-lg-3 {
    margin-top: 1rem !important;
  }
  [data-pc-direction=ltr] .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mt-lg-5 {
    margin-top: 3rem !important;
  }
  [data-pc-direction=ltr] .mt-lg-auto {
    margin-top: auto !important;
  }
  [data-pc-direction=ltr] .me-lg-0 {
    margin-right: 0 !important;
  }
  [data-pc-direction=ltr] .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  [data-pc-direction=ltr] .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  [data-pc-direction=ltr] .me-lg-3 {
    margin-right: 1rem !important;
  }
  [data-pc-direction=ltr] .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  [data-pc-direction=ltr] .me-lg-5 {
    margin-right: 3rem !important;
  }
  [data-pc-direction=ltr] .me-lg-auto {
    margin-right: auto !important;
  }
  [data-pc-direction=ltr] .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .mb-lg-auto {
    margin-bottom: auto !important;
  }
  [data-pc-direction=ltr] .ms-lg-0 {
    margin-left: 0 !important;
  }
  [data-pc-direction=ltr] .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .ms-lg-3 {
    margin-left: 1rem !important;
  }
  [data-pc-direction=ltr] .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .ms-lg-5 {
    margin-left: 3rem !important;
  }
  [data-pc-direction=ltr] .ms-lg-auto {
    margin-left: auto !important;
  }
  [data-pc-direction=ltr] .m-lg-n1 {
    margin: -0.25rem !important;
  }
  [data-pc-direction=ltr] .m-lg-n2 {
    margin: -0.5rem !important;
  }
  [data-pc-direction=ltr] .m-lg-n3 {
    margin: -1rem !important;
  }
  [data-pc-direction=ltr] .m-lg-n4 {
    margin: -1.5rem !important;
  }
  [data-pc-direction=ltr] .m-lg-n5 {
    margin: -3rem !important;
  }
  [data-pc-direction=ltr] .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  [data-pc-direction=ltr] .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  [data-pc-direction=ltr] .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=ltr] .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=ltr] .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=ltr] .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=ltr] .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=ltr] .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  [data-pc-direction=ltr] .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  [data-pc-direction=ltr] .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  [data-pc-direction=ltr] .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  [data-pc-direction=ltr] .me-lg-n3 {
    margin-right: -1rem !important;
  }
  [data-pc-direction=ltr] .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  [data-pc-direction=ltr] .me-lg-n5 {
    margin-right: -3rem !important;
  }
  [data-pc-direction=ltr] .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=ltr] .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=ltr] .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=ltr] .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=ltr] .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  [data-pc-direction=ltr] .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=ltr] .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  [data-pc-direction=ltr] .p-lg-0 {
    padding: 0 !important;
  }
  [data-pc-direction=ltr] .p-lg-1 {
    padding: 0.25rem !important;
  }
  [data-pc-direction=ltr] .p-lg-2 {
    padding: 0.5rem !important;
  }
  [data-pc-direction=ltr] .p-lg-3 {
    padding: 1rem !important;
  }
  [data-pc-direction=ltr] .p-lg-4 {
    padding: 1.5rem !important;
  }
  [data-pc-direction=ltr] .p-lg-5 {
    padding: 3rem !important;
  }
  [data-pc-direction=ltr] .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [data-pc-direction=ltr] .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  [data-pc-direction=ltr] .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  [data-pc-direction=ltr] .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .pt-lg-0 {
    padding-top: 0 !important;
  }
  [data-pc-direction=ltr] .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pt-lg-3 {
    padding-top: 1rem !important;
  }
  [data-pc-direction=ltr] .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pt-lg-5 {
    padding-top: 3rem !important;
  }
  [data-pc-direction=ltr] .pe-lg-0 {
    padding-right: 0 !important;
  }
  [data-pc-direction=ltr] .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pe-lg-3 {
    padding-right: 1rem !important;
  }
  [data-pc-direction=ltr] .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pe-lg-5 {
    padding-right: 3rem !important;
  }
  [data-pc-direction=ltr] .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .ps-lg-0 {
    padding-left: 0 !important;
  }
  [data-pc-direction=ltr] .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .ps-lg-3 {
    padding-left: 1rem !important;
  }
  [data-pc-direction=ltr] .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .ps-lg-5 {
    padding-left: 3rem !important;
  }
  [data-pc-direction=ltr] .gap-lg-0 {
    gap: 0 !important;
  }
  [data-pc-direction=ltr] .gap-lg-1 {
    gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .gap-lg-2 {
    gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .gap-lg-3 {
    gap: 1rem !important;
  }
  [data-pc-direction=ltr] .gap-lg-4 {
    gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .gap-lg-5 {
    gap: 3rem !important;
  }
  [data-pc-direction=ltr] .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  [data-pc-direction=ltr] .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  [data-pc-direction=ltr] .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  [data-pc-direction=ltr] .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  [data-pc-direction=ltr] .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  [data-pc-direction=ltr] .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  [data-pc-direction=ltr] .text-lg-start {
    text-align: left !important;
  }
  [data-pc-direction=ltr] .text-lg-end {
    text-align: right !important;
  }
  [data-pc-direction=ltr] .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  [data-pc-direction=ltr] .float-xl-start {
    float: left !important;
  }
  [data-pc-direction=ltr] .float-xl-end {
    float: right !important;
  }
  [data-pc-direction=ltr] .float-xl-none {
    float: none !important;
  }
  [data-pc-direction=ltr] .object-fit-xl-contain {
    object-fit: contain !important;
  }
  [data-pc-direction=ltr] .object-fit-xl-cover {
    object-fit: cover !important;
  }
  [data-pc-direction=ltr] .object-fit-xl-fill {
    object-fit: fill !important;
  }
  [data-pc-direction=ltr] .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  [data-pc-direction=ltr] .object-fit-xl-none {
    object-fit: none !important;
  }
  [data-pc-direction=ltr] .d-xl-inline {
    display: inline !important;
  }
  [data-pc-direction=ltr] .d-xl-inline-block {
    display: inline-block !important;
  }
  [data-pc-direction=ltr] .d-xl-block {
    display: block !important;
  }
  [data-pc-direction=ltr] .d-xl-grid {
    display: grid !important;
  }
  [data-pc-direction=ltr] .d-xl-inline-grid {
    display: inline-grid !important;
  }
  [data-pc-direction=ltr] .d-xl-table {
    display: table !important;
  }
  [data-pc-direction=ltr] .d-xl-table-row {
    display: table-row !important;
  }
  [data-pc-direction=ltr] .d-xl-table-cell {
    display: table-cell !important;
  }
  [data-pc-direction=ltr] .d-xl-flex {
    display: flex !important;
  }
  [data-pc-direction=ltr] .d-xl-inline-flex {
    display: inline-flex !important;
  }
  [data-pc-direction=ltr] .d-xl-none {
    display: none !important;
  }
  [data-pc-direction=ltr] .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  [data-pc-direction=ltr] .flex-xl-row {
    flex-direction: row !important;
  }
  [data-pc-direction=ltr] .flex-xl-column {
    flex-direction: column !important;
  }
  [data-pc-direction=ltr] .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  [data-pc-direction=ltr] .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  [data-pc-direction=ltr] .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  [data-pc-direction=ltr] .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  [data-pc-direction=ltr] .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  [data-pc-direction=ltr] .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  [data-pc-direction=ltr] .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  [data-pc-direction=ltr] .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  [data-pc-direction=ltr] .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  [data-pc-direction=ltr] .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  [data-pc-direction=ltr] .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  [data-pc-direction=ltr] .justify-content-xl-center {
    justify-content: center !important;
  }
  [data-pc-direction=ltr] .justify-content-xl-between {
    justify-content: space-between !important;
  }
  [data-pc-direction=ltr] .justify-content-xl-around {
    justify-content: space-around !important;
  }
  [data-pc-direction=ltr] .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  [data-pc-direction=ltr] .align-items-xl-start {
    align-items: flex-start !important;
  }
  [data-pc-direction=ltr] .align-items-xl-end {
    align-items: flex-end !important;
  }
  [data-pc-direction=ltr] .align-items-xl-center {
    align-items: center !important;
  }
  [data-pc-direction=ltr] .align-items-xl-baseline {
    align-items: baseline !important;
  }
  [data-pc-direction=ltr] .align-items-xl-stretch {
    align-items: stretch !important;
  }
  [data-pc-direction=ltr] .align-content-xl-start {
    align-content: flex-start !important;
  }
  [data-pc-direction=ltr] .align-content-xl-end {
    align-content: flex-end !important;
  }
  [data-pc-direction=ltr] .align-content-xl-center {
    align-content: center !important;
  }
  [data-pc-direction=ltr] .align-content-xl-between {
    align-content: space-between !important;
  }
  [data-pc-direction=ltr] .align-content-xl-around {
    align-content: space-around !important;
  }
  [data-pc-direction=ltr] .align-content-xl-stretch {
    align-content: stretch !important;
  }
  [data-pc-direction=ltr] .align-self-xl-auto {
    align-self: auto !important;
  }
  [data-pc-direction=ltr] .align-self-xl-start {
    align-self: flex-start !important;
  }
  [data-pc-direction=ltr] .align-self-xl-end {
    align-self: flex-end !important;
  }
  [data-pc-direction=ltr] .align-self-xl-center {
    align-self: center !important;
  }
  [data-pc-direction=ltr] .align-self-xl-baseline {
    align-self: baseline !important;
  }
  [data-pc-direction=ltr] .align-self-xl-stretch {
    align-self: stretch !important;
  }
  [data-pc-direction=ltr] .order-xl-first {
    order: -1 !important;
  }
  [data-pc-direction=ltr] .order-xl-0 {
    order: 0 !important;
  }
  [data-pc-direction=ltr] .order-xl-1 {
    order: 1 !important;
  }
  [data-pc-direction=ltr] .order-xl-2 {
    order: 2 !important;
  }
  [data-pc-direction=ltr] .order-xl-3 {
    order: 3 !important;
  }
  [data-pc-direction=ltr] .order-xl-4 {
    order: 4 !important;
  }
  [data-pc-direction=ltr] .order-xl-5 {
    order: 5 !important;
  }
  [data-pc-direction=ltr] .order-xl-last {
    order: 6 !important;
  }
  [data-pc-direction=ltr] .m-xl-0 {
    margin: 0 !important;
  }
  [data-pc-direction=ltr] .m-xl-1 {
    margin: 0.25rem !important;
  }
  [data-pc-direction=ltr] .m-xl-2 {
    margin: 0.5rem !important;
  }
  [data-pc-direction=ltr] .m-xl-3 {
    margin: 1rem !important;
  }
  [data-pc-direction=ltr] .m-xl-4 {
    margin: 1.5rem !important;
  }
  [data-pc-direction=ltr] .m-xl-5 {
    margin: 3rem !important;
  }
  [data-pc-direction=ltr] .m-xl-auto {
    margin: auto !important;
  }
  [data-pc-direction=ltr] .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [data-pc-direction=ltr] .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  [data-pc-direction=ltr] .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  [data-pc-direction=ltr] .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  [data-pc-direction=ltr] .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  [data-pc-direction=ltr] .mt-xl-0 {
    margin-top: 0 !important;
  }
  [data-pc-direction=ltr] .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mt-xl-3 {
    margin-top: 1rem !important;
  }
  [data-pc-direction=ltr] .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mt-xl-5 {
    margin-top: 3rem !important;
  }
  [data-pc-direction=ltr] .mt-xl-auto {
    margin-top: auto !important;
  }
  [data-pc-direction=ltr] .me-xl-0 {
    margin-right: 0 !important;
  }
  [data-pc-direction=ltr] .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  [data-pc-direction=ltr] .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  [data-pc-direction=ltr] .me-xl-3 {
    margin-right: 1rem !important;
  }
  [data-pc-direction=ltr] .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  [data-pc-direction=ltr] .me-xl-5 {
    margin-right: 3rem !important;
  }
  [data-pc-direction=ltr] .me-xl-auto {
    margin-right: auto !important;
  }
  [data-pc-direction=ltr] .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .mb-xl-auto {
    margin-bottom: auto !important;
  }
  [data-pc-direction=ltr] .ms-xl-0 {
    margin-left: 0 !important;
  }
  [data-pc-direction=ltr] .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .ms-xl-3 {
    margin-left: 1rem !important;
  }
  [data-pc-direction=ltr] .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .ms-xl-5 {
    margin-left: 3rem !important;
  }
  [data-pc-direction=ltr] .ms-xl-auto {
    margin-left: auto !important;
  }
  [data-pc-direction=ltr] .m-xl-n1 {
    margin: -0.25rem !important;
  }
  [data-pc-direction=ltr] .m-xl-n2 {
    margin: -0.5rem !important;
  }
  [data-pc-direction=ltr] .m-xl-n3 {
    margin: -1rem !important;
  }
  [data-pc-direction=ltr] .m-xl-n4 {
    margin: -1.5rem !important;
  }
  [data-pc-direction=ltr] .m-xl-n5 {
    margin: -3rem !important;
  }
  [data-pc-direction=ltr] .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  [data-pc-direction=ltr] .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  [data-pc-direction=ltr] .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=ltr] .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=ltr] .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=ltr] .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=ltr] .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=ltr] .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  [data-pc-direction=ltr] .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  [data-pc-direction=ltr] .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  [data-pc-direction=ltr] .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  [data-pc-direction=ltr] .me-xl-n3 {
    margin-right: -1rem !important;
  }
  [data-pc-direction=ltr] .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  [data-pc-direction=ltr] .me-xl-n5 {
    margin-right: -3rem !important;
  }
  [data-pc-direction=ltr] .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=ltr] .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=ltr] .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=ltr] .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=ltr] .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  [data-pc-direction=ltr] .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=ltr] .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  [data-pc-direction=ltr] .p-xl-0 {
    padding: 0 !important;
  }
  [data-pc-direction=ltr] .p-xl-1 {
    padding: 0.25rem !important;
  }
  [data-pc-direction=ltr] .p-xl-2 {
    padding: 0.5rem !important;
  }
  [data-pc-direction=ltr] .p-xl-3 {
    padding: 1rem !important;
  }
  [data-pc-direction=ltr] .p-xl-4 {
    padding: 1.5rem !important;
  }
  [data-pc-direction=ltr] .p-xl-5 {
    padding: 3rem !important;
  }
  [data-pc-direction=ltr] .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [data-pc-direction=ltr] .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  [data-pc-direction=ltr] .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  [data-pc-direction=ltr] .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .pt-xl-0 {
    padding-top: 0 !important;
  }
  [data-pc-direction=ltr] .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pt-xl-3 {
    padding-top: 1rem !important;
  }
  [data-pc-direction=ltr] .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pt-xl-5 {
    padding-top: 3rem !important;
  }
  [data-pc-direction=ltr] .pe-xl-0 {
    padding-right: 0 !important;
  }
  [data-pc-direction=ltr] .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pe-xl-3 {
    padding-right: 1rem !important;
  }
  [data-pc-direction=ltr] .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pe-xl-5 {
    padding-right: 3rem !important;
  }
  [data-pc-direction=ltr] .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .ps-xl-0 {
    padding-left: 0 !important;
  }
  [data-pc-direction=ltr] .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .ps-xl-3 {
    padding-left: 1rem !important;
  }
  [data-pc-direction=ltr] .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .ps-xl-5 {
    padding-left: 3rem !important;
  }
  [data-pc-direction=ltr] .gap-xl-0 {
    gap: 0 !important;
  }
  [data-pc-direction=ltr] .gap-xl-1 {
    gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .gap-xl-2 {
    gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .gap-xl-3 {
    gap: 1rem !important;
  }
  [data-pc-direction=ltr] .gap-xl-4 {
    gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .gap-xl-5 {
    gap: 3rem !important;
  }
  [data-pc-direction=ltr] .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  [data-pc-direction=ltr] .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  [data-pc-direction=ltr] .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  [data-pc-direction=ltr] .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  [data-pc-direction=ltr] .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  [data-pc-direction=ltr] .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  [data-pc-direction=ltr] .text-xl-start {
    text-align: left !important;
  }
  [data-pc-direction=ltr] .text-xl-end {
    text-align: right !important;
  }
  [data-pc-direction=ltr] .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  [data-pc-direction=ltr] .float-xxl-start {
    float: left !important;
  }
  [data-pc-direction=ltr] .float-xxl-end {
    float: right !important;
  }
  [data-pc-direction=ltr] .float-xxl-none {
    float: none !important;
  }
  [data-pc-direction=ltr] .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  [data-pc-direction=ltr] .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  [data-pc-direction=ltr] .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  [data-pc-direction=ltr] .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  [data-pc-direction=ltr] .object-fit-xxl-none {
    object-fit: none !important;
  }
  [data-pc-direction=ltr] .d-xxl-inline {
    display: inline !important;
  }
  [data-pc-direction=ltr] .d-xxl-inline-block {
    display: inline-block !important;
  }
  [data-pc-direction=ltr] .d-xxl-block {
    display: block !important;
  }
  [data-pc-direction=ltr] .d-xxl-grid {
    display: grid !important;
  }
  [data-pc-direction=ltr] .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  [data-pc-direction=ltr] .d-xxl-table {
    display: table !important;
  }
  [data-pc-direction=ltr] .d-xxl-table-row {
    display: table-row !important;
  }
  [data-pc-direction=ltr] .d-xxl-table-cell {
    display: table-cell !important;
  }
  [data-pc-direction=ltr] .d-xxl-flex {
    display: flex !important;
  }
  [data-pc-direction=ltr] .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  [data-pc-direction=ltr] .d-xxl-none {
    display: none !important;
  }
  [data-pc-direction=ltr] .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  [data-pc-direction=ltr] .flex-xxl-row {
    flex-direction: row !important;
  }
  [data-pc-direction=ltr] .flex-xxl-column {
    flex-direction: column !important;
  }
  [data-pc-direction=ltr] .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  [data-pc-direction=ltr] .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  [data-pc-direction=ltr] .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  [data-pc-direction=ltr] .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  [data-pc-direction=ltr] .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  [data-pc-direction=ltr] .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  [data-pc-direction=ltr] .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  [data-pc-direction=ltr] .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  [data-pc-direction=ltr] .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  [data-pc-direction=ltr] .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  [data-pc-direction=ltr] .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  [data-pc-direction=ltr] .justify-content-xxl-center {
    justify-content: center !important;
  }
  [data-pc-direction=ltr] .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  [data-pc-direction=ltr] .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  [data-pc-direction=ltr] .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  [data-pc-direction=ltr] .align-items-xxl-start {
    align-items: flex-start !important;
  }
  [data-pc-direction=ltr] .align-items-xxl-end {
    align-items: flex-end !important;
  }
  [data-pc-direction=ltr] .align-items-xxl-center {
    align-items: center !important;
  }
  [data-pc-direction=ltr] .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  [data-pc-direction=ltr] .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  [data-pc-direction=ltr] .align-content-xxl-start {
    align-content: flex-start !important;
  }
  [data-pc-direction=ltr] .align-content-xxl-end {
    align-content: flex-end !important;
  }
  [data-pc-direction=ltr] .align-content-xxl-center {
    align-content: center !important;
  }
  [data-pc-direction=ltr] .align-content-xxl-between {
    align-content: space-between !important;
  }
  [data-pc-direction=ltr] .align-content-xxl-around {
    align-content: space-around !important;
  }
  [data-pc-direction=ltr] .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  [data-pc-direction=ltr] .align-self-xxl-auto {
    align-self: auto !important;
  }
  [data-pc-direction=ltr] .align-self-xxl-start {
    align-self: flex-start !important;
  }
  [data-pc-direction=ltr] .align-self-xxl-end {
    align-self: flex-end !important;
  }
  [data-pc-direction=ltr] .align-self-xxl-center {
    align-self: center !important;
  }
  [data-pc-direction=ltr] .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  [data-pc-direction=ltr] .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  [data-pc-direction=ltr] .order-xxl-first {
    order: -1 !important;
  }
  [data-pc-direction=ltr] .order-xxl-0 {
    order: 0 !important;
  }
  [data-pc-direction=ltr] .order-xxl-1 {
    order: 1 !important;
  }
  [data-pc-direction=ltr] .order-xxl-2 {
    order: 2 !important;
  }
  [data-pc-direction=ltr] .order-xxl-3 {
    order: 3 !important;
  }
  [data-pc-direction=ltr] .order-xxl-4 {
    order: 4 !important;
  }
  [data-pc-direction=ltr] .order-xxl-5 {
    order: 5 !important;
  }
  [data-pc-direction=ltr] .order-xxl-last {
    order: 6 !important;
  }
  [data-pc-direction=ltr] .m-xxl-0 {
    margin: 0 !important;
  }
  [data-pc-direction=ltr] .m-xxl-1 {
    margin: 0.25rem !important;
  }
  [data-pc-direction=ltr] .m-xxl-2 {
    margin: 0.5rem !important;
  }
  [data-pc-direction=ltr] .m-xxl-3 {
    margin: 1rem !important;
  }
  [data-pc-direction=ltr] .m-xxl-4 {
    margin: 1.5rem !important;
  }
  [data-pc-direction=ltr] .m-xxl-5 {
    margin: 3rem !important;
  }
  [data-pc-direction=ltr] .m-xxl-auto {
    margin: auto !important;
  }
  [data-pc-direction=ltr] .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [data-pc-direction=ltr] .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  [data-pc-direction=ltr] .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  [data-pc-direction=ltr] .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  [data-pc-direction=ltr] .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  [data-pc-direction=ltr] .mt-xxl-0 {
    margin-top: 0 !important;
  }
  [data-pc-direction=ltr] .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  [data-pc-direction=ltr] .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  [data-pc-direction=ltr] .mt-xxl-auto {
    margin-top: auto !important;
  }
  [data-pc-direction=ltr] .me-xxl-0 {
    margin-right: 0 !important;
  }
  [data-pc-direction=ltr] .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  [data-pc-direction=ltr] .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  [data-pc-direction=ltr] .me-xxl-3 {
    margin-right: 1rem !important;
  }
  [data-pc-direction=ltr] .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  [data-pc-direction=ltr] .me-xxl-5 {
    margin-right: 3rem !important;
  }
  [data-pc-direction=ltr] .me-xxl-auto {
    margin-right: auto !important;
  }
  [data-pc-direction=ltr] .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  [data-pc-direction=ltr] .ms-xxl-0 {
    margin-left: 0 !important;
  }
  [data-pc-direction=ltr] .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  [data-pc-direction=ltr] .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  [data-pc-direction=ltr] .ms-xxl-auto {
    margin-left: auto !important;
  }
  [data-pc-direction=ltr] .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  [data-pc-direction=ltr] .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  [data-pc-direction=ltr] .m-xxl-n3 {
    margin: -1rem !important;
  }
  [data-pc-direction=ltr] .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  [data-pc-direction=ltr] .m-xxl-n5 {
    margin: -3rem !important;
  }
  [data-pc-direction=ltr] .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  [data-pc-direction=ltr] .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  [data-pc-direction=ltr] .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=ltr] .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=ltr] .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=ltr] .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=ltr] .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=ltr] .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  [data-pc-direction=ltr] .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  [data-pc-direction=ltr] .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  [data-pc-direction=ltr] .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  [data-pc-direction=ltr] .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  [data-pc-direction=ltr] .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  [data-pc-direction=ltr] .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  [data-pc-direction=ltr] .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=ltr] .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=ltr] .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=ltr] .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=ltr] .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=ltr] .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=ltr] .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=ltr] .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  [data-pc-direction=ltr] .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=ltr] .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  [data-pc-direction=ltr] .p-xxl-0 {
    padding: 0 !important;
  }
  [data-pc-direction=ltr] .p-xxl-1 {
    padding: 0.25rem !important;
  }
  [data-pc-direction=ltr] .p-xxl-2 {
    padding: 0.5rem !important;
  }
  [data-pc-direction=ltr] .p-xxl-3 {
    padding: 1rem !important;
  }
  [data-pc-direction=ltr] .p-xxl-4 {
    padding: 1.5rem !important;
  }
  [data-pc-direction=ltr] .p-xxl-5 {
    padding: 3rem !important;
  }
  [data-pc-direction=ltr] .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [data-pc-direction=ltr] .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  [data-pc-direction=ltr] .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  [data-pc-direction=ltr] .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .pt-xxl-0 {
    padding-top: 0 !important;
  }
  [data-pc-direction=ltr] .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  [data-pc-direction=ltr] .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  [data-pc-direction=ltr] .pe-xxl-0 {
    padding-right: 0 !important;
  }
  [data-pc-direction=ltr] .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  [data-pc-direction=ltr] .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  [data-pc-direction=ltr] .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  [data-pc-direction=ltr] .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=ltr] .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=ltr] .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=ltr] .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=ltr] .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=ltr] .ps-xxl-0 {
    padding-left: 0 !important;
  }
  [data-pc-direction=ltr] .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=ltr] .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=ltr] .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  [data-pc-direction=ltr] .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=ltr] .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  [data-pc-direction=ltr] .gap-xxl-0 {
    gap: 0 !important;
  }
  [data-pc-direction=ltr] .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .gap-xxl-3 {
    gap: 1rem !important;
  }
  [data-pc-direction=ltr] .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .gap-xxl-5 {
    gap: 3rem !important;
  }
  [data-pc-direction=ltr] .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  [data-pc-direction=ltr] .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  [data-pc-direction=ltr] .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  [data-pc-direction=ltr] .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  [data-pc-direction=ltr] .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  [data-pc-direction=ltr] .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  [data-pc-direction=ltr] .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  [data-pc-direction=ltr] .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  [data-pc-direction=ltr] .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  [data-pc-direction=ltr] .text-xxl-start {
    text-align: left !important;
  }
  [data-pc-direction=ltr] .text-xxl-end {
    text-align: right !important;
  }
  [data-pc-direction=ltr] .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  [data-pc-direction=ltr] .fs-1 {
    font-size: 2.375rem !important;
  }
  [data-pc-direction=ltr] .fs-2 {
    font-size: 1.875rem !important;
  }
  [data-pc-direction=ltr] .fs-3 {
    font-size: 1.5rem !important;
  }
}
@media print {
  [data-pc-direction=ltr] .d-print-inline {
    display: inline !important;
  }
  [data-pc-direction=ltr] .d-print-inline-block {
    display: inline-block !important;
  }
  [data-pc-direction=ltr] .d-print-block {
    display: block !important;
  }
  [data-pc-direction=ltr] .d-print-grid {
    display: grid !important;
  }
  [data-pc-direction=ltr] .d-print-inline-grid {
    display: inline-grid !important;
  }
  [data-pc-direction=ltr] .d-print-table {
    display: table !important;
  }
  [data-pc-direction=ltr] .d-print-table-row {
    display: table-row !important;
  }
  [data-pc-direction=ltr] .d-print-table-cell {
    display: table-cell !important;
  }
  [data-pc-direction=ltr] .d-print-flex {
    display: flex !important;
  }
  [data-pc-direction=ltr] .d-print-inline-flex {
    display: inline-flex !important;
  }
  [data-pc-direction=ltr] .d-print-none {
    display: none !important;
  }
}

[data-pc-direction=rtl] {
  /* rtl:begin:remove */
  /* rtl:end:remove */
}
[data-pc-direction=rtl] .align-baseline {
  vertical-align: baseline !important;
}
[data-pc-direction=rtl] .align-top {
  vertical-align: top !important;
}
[data-pc-direction=rtl] .align-middle {
  vertical-align: middle !important;
}
[data-pc-direction=rtl] .align-bottom {
  vertical-align: bottom !important;
}
[data-pc-direction=rtl] .align-text-bottom {
  vertical-align: text-bottom !important;
}
[data-pc-direction=rtl] .align-text-top {
  vertical-align: text-top !important;
}
[data-pc-direction=rtl] .float-start {
  float: right !important;
}
[data-pc-direction=rtl] .float-end {
  float: left !important;
}
[data-pc-direction=rtl] .float-none {
  float: none !important;
}
[data-pc-direction=rtl] .opacity-0 {
  opacity: 0 !important;
}
[data-pc-direction=rtl] .opacity-25 {
  opacity: 0.25 !important;
}
[data-pc-direction=rtl] .opacity-50 {
  opacity: 0.5 !important;
}
[data-pc-direction=rtl] .opacity-75 {
  opacity: 0.75 !important;
}
[data-pc-direction=rtl] .opacity-100 {
  opacity: 1 !important;
}
[data-pc-direction=rtl] .overflow-auto {
  overflow: auto !important;
}
[data-pc-direction=rtl] .overflow-hidden {
  overflow: hidden !important;
}
[data-pc-direction=rtl] .overflow-visible {
  overflow: visible !important;
}
[data-pc-direction=rtl] .overflow-scroll {
  overflow: scroll !important;
}
[data-pc-direction=rtl] .d-inline {
  display: inline !important;
}
[data-pc-direction=rtl] .d-inline-block {
  display: inline-block !important;
}
[data-pc-direction=rtl] .d-block {
  display: block !important;
}
[data-pc-direction=rtl] .d-grid {
  display: grid !important;
}
[data-pc-direction=rtl] .d-table {
  display: table !important;
}
[data-pc-direction=rtl] .d-table-row {
  display: table-row !important;
}
[data-pc-direction=rtl] .d-table-cell {
  display: table-cell !important;
}
[data-pc-direction=rtl] .d-flex {
  display: flex !important;
}
[data-pc-direction=rtl] .d-inline-flex {
  display: inline-flex !important;
}
[data-pc-direction=rtl] .d-none {
  display: none !important;
}
[data-pc-direction=rtl] .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
[data-pc-direction=rtl] .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
[data-pc-direction=rtl] .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
[data-pc-direction=rtl] .shadow-none {
  box-shadow: none !important;
}
[data-pc-direction=rtl] .position-static {
  position: static !important;
}
[data-pc-direction=rtl] .position-relative {
  position: relative !important;
}
[data-pc-direction=rtl] .position-absolute {
  position: absolute !important;
}
[data-pc-direction=rtl] .position-fixed {
  position: fixed !important;
}
[data-pc-direction=rtl] .position-sticky {
  position: sticky !important;
}
[data-pc-direction=rtl] .top-0 {
  top: 0 !important;
}
[data-pc-direction=rtl] .top-50 {
  top: 50% !important;
}
[data-pc-direction=rtl] .top-100 {
  top: 100% !important;
}
[data-pc-direction=rtl] .bottom-0 {
  bottom: 0 !important;
}
[data-pc-direction=rtl] .bottom-50 {
  bottom: 50% !important;
}
[data-pc-direction=rtl] .bottom-100 {
  bottom: 100% !important;
}
[data-pc-direction=rtl] .start-0 {
  right: 0 !important;
}
[data-pc-direction=rtl] .start-50 {
  right: 50% !important;
}
[data-pc-direction=rtl] .start-100 {
  right: 100% !important;
}
[data-pc-direction=rtl] .end-0 {
  left: 0 !important;
}
[data-pc-direction=rtl] .end-50 {
  left: 50% !important;
}
[data-pc-direction=rtl] .end-100 {
  left: 100% !important;
}
[data-pc-direction=rtl] .translate-middle {
  transform: translate(50%, -50%) !important;
}
[data-pc-direction=rtl] .translate-middle-x {
  transform: translateX(-50%) !important;
}
[data-pc-direction=rtl] .translate-middle-y {
  transform: translateY(-50%) !important;
}
[data-pc-direction=rtl] .border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
[data-pc-direction=rtl] .border-0 {
  border: 0 !important;
}
[data-pc-direction=rtl] .border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
[data-pc-direction=rtl] .border-top-0 {
  border-top: 0 !important;
}
[data-pc-direction=rtl] .border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
[data-pc-direction=rtl] .border-end-0 {
  border-right: 0 !important;
}
[data-pc-direction=rtl] .border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
[data-pc-direction=rtl] .border-bottom-0 {
  border-bottom: 0 !important;
}
[data-pc-direction=rtl] .border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
[data-pc-direction=rtl] .border-start-0 {
  border-left: 0 !important;
}
[data-pc-direction=rtl] .border-theme {
  border-color: #ff3366 !important;
}
[data-pc-direction=rtl] .border-primary {
  border-color: #1A237E !important;
}
[data-pc-direction=rtl] .border-secondary {
  border-color: #5b6b79 !important;
}
[data-pc-direction=rtl] .border-success {
  border-color: #2ca87f !important;
}
[data-pc-direction=rtl] .border-info {
  border-color: #3ec9d6 !important;
}
[data-pc-direction=rtl] .border-warning {
  border-color: #e58a00 !important;
}
[data-pc-direction=rtl] .border-danger {
  border-color: #dc2626 !important;
}
[data-pc-direction=rtl] .border-light {
  border-color: #f8f9fa !important;
}
[data-pc-direction=rtl] .border-dark {
  border-color: #212529 !important;
}
[data-pc-direction=rtl] .border-white {
  border-color: #ffffff !important;
}
[data-pc-direction=rtl] .border-0 {
  border-width: 0 !important;
}
[data-pc-direction=rtl] .border-1 {
  border-width: 1px !important;
}
[data-pc-direction=rtl] .border-2 {
  border-width: 2px !important;
}
[data-pc-direction=rtl] .border-3 {
  border-width: 3px !important;
}
[data-pc-direction=rtl] .border-4 {
  border-width: 4px !important;
}
[data-pc-direction=rtl] .border-5 {
  border-width: 5px !important;
}
[data-pc-direction=rtl] .w-25 {
  width: 25% !important;
}
[data-pc-direction=rtl] .w-50 {
  width: 50% !important;
}
[data-pc-direction=rtl] .w-75 {
  width: 75% !important;
}
[data-pc-direction=rtl] .w-100 {
  width: 100% !important;
}
[data-pc-direction=rtl] .w-auto {
  width: auto !important;
}
[data-pc-direction=rtl] .mw-100 {
  max-width: 100% !important;
}
[data-pc-direction=rtl] .vw-100 {
  width: 100vw !important;
}
[data-pc-direction=rtl] .min-vw-100 {
  min-width: 100vw !important;
}
[data-pc-direction=rtl] .h-25 {
  height: 25% !important;
}
[data-pc-direction=rtl] .h-50 {
  height: 50% !important;
}
[data-pc-direction=rtl] .h-75 {
  height: 75% !important;
}
[data-pc-direction=rtl] .h-100 {
  height: 100% !important;
}
[data-pc-direction=rtl] .h-auto {
  height: auto !important;
}
[data-pc-direction=rtl] .mh-100 {
  max-height: 100% !important;
}
[data-pc-direction=rtl] .vh-100 {
  height: 100vh !important;
}
[data-pc-direction=rtl] .min-vh-100 {
  min-height: 100vh !important;
}
[data-pc-direction=rtl] .flex-fill {
  flex: 1 1 auto !important;
}
[data-pc-direction=rtl] .flex-row {
  flex-direction: row !important;
}
[data-pc-direction=rtl] .flex-column {
  flex-direction: column !important;
}
[data-pc-direction=rtl] .flex-row-reverse {
  flex-direction: row-reverse !important;
}
[data-pc-direction=rtl] .flex-column-reverse {
  flex-direction: column-reverse !important;
}
[data-pc-direction=rtl] .flex-grow-0 {
  flex-grow: 0 !important;
}
[data-pc-direction=rtl] .flex-grow-1 {
  flex-grow: 1 !important;
}
[data-pc-direction=rtl] .flex-shrink-0 {
  flex-shrink: 0 !important;
}
[data-pc-direction=rtl] .flex-shrink-1 {
  flex-shrink: 1 !important;
}
[data-pc-direction=rtl] .flex-wrap {
  flex-wrap: wrap !important;
}
[data-pc-direction=rtl] .flex-nowrap {
  flex-wrap: nowrap !important;
}
[data-pc-direction=rtl] .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
[data-pc-direction=rtl] .gap-0 {
  gap: 0 !important;
}
[data-pc-direction=rtl] .gap-1 {
  gap: 0.25rem !important;
}
[data-pc-direction=rtl] .gap-2 {
  gap: 0.5rem !important;
}
[data-pc-direction=rtl] .gap-3 {
  gap: 1rem !important;
}
[data-pc-direction=rtl] .gap-4 {
  gap: 1.5rem !important;
}
[data-pc-direction=rtl] .gap-5 {
  gap: 3rem !important;
}
[data-pc-direction=rtl] .justify-content-start {
  justify-content: flex-end !important;
}
[data-pc-direction=rtl] .justify-content-end {
  justify-content: flex-start !important;
}
[data-pc-direction=rtl] .justify-content-center {
  justify-content: center !important;
}
[data-pc-direction=rtl] .justify-content-between {
  justify-content: space-between !important;
}
[data-pc-direction=rtl] .justify-content-around {
  justify-content: space-around !important;
}
[data-pc-direction=rtl] .justify-content-evenly {
  justify-content: space-evenly !important;
}
[data-pc-direction=rtl] .align-items-start {
  align-items: flex-start !important;
}
[data-pc-direction=rtl] .align-items-end {
  align-items: flex-end !important;
}
[data-pc-direction=rtl] .align-items-center {
  align-items: center !important;
}
[data-pc-direction=rtl] .align-items-baseline {
  align-items: baseline !important;
}
[data-pc-direction=rtl] .align-items-stretch {
  align-items: stretch !important;
}
[data-pc-direction=rtl] .align-content-start {
  align-content: flex-end !important;
}
[data-pc-direction=rtl] .align-content-end {
  align-content: flex-start !important;
}
[data-pc-direction=rtl] .align-content-center {
  align-content: center !important;
}
[data-pc-direction=rtl] .align-content-between {
  align-content: space-between !important;
}
[data-pc-direction=rtl] .align-content-around {
  align-content: space-around !important;
}
[data-pc-direction=rtl] .align-content-stretch {
  align-content: stretch !important;
}
[data-pc-direction=rtl] .align-self-auto {
  align-self: auto !important;
}
[data-pc-direction=rtl] .align-self-start {
  align-self: flex-end !important;
}
[data-pc-direction=rtl] .align-self-end {
  align-self: flex-start !important;
}
[data-pc-direction=rtl] .align-self-center {
  align-self: center !important;
}
[data-pc-direction=rtl] .align-self-baseline {
  align-self: baseline !important;
}
[data-pc-direction=rtl] .align-self-stretch {
  align-self: stretch !important;
}
[data-pc-direction=rtl] .order-first {
  order: -1 !important;
}
[data-pc-direction=rtl] .order-0 {
  order: 0 !important;
}
[data-pc-direction=rtl] .order-1 {
  order: 1 !important;
}
[data-pc-direction=rtl] .order-2 {
  order: 2 !important;
}
[data-pc-direction=rtl] .order-3 {
  order: 3 !important;
}
[data-pc-direction=rtl] .order-4 {
  order: 4 !important;
}
[data-pc-direction=rtl] .order-5 {
  order: 5 !important;
}
[data-pc-direction=rtl] .order-last {
  order: 6 !important;
}
[data-pc-direction=rtl] .m-0 {
  margin: 0 !important;
}
[data-pc-direction=rtl] .m-1 {
  margin: 0.25rem !important;
}
[data-pc-direction=rtl] .m-2 {
  margin: 0.5rem !important;
}
[data-pc-direction=rtl] .m-3 {
  margin: 1rem !important;
}
[data-pc-direction=rtl] .m-4 {
  margin: 1.5rem !important;
}
[data-pc-direction=rtl] .m-5 {
  margin: 3rem !important;
}
[data-pc-direction=rtl] .m-auto {
  margin: auto !important;
}
[data-pc-direction=rtl] .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
[data-pc-direction=rtl] .mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
[data-pc-direction=rtl] .mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
[data-pc-direction=rtl] .mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
[data-pc-direction=rtl] .mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
[data-pc-direction=rtl] .mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
[data-pc-direction=rtl] .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
[data-pc-direction=rtl] .my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
[data-pc-direction=rtl] .my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
[data-pc-direction=rtl] .my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
[data-pc-direction=rtl] .my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
[data-pc-direction=rtl] .my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
[data-pc-direction=rtl] .my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
[data-pc-direction=rtl] .my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
[data-pc-direction=rtl] .mt-0 {
  margin-top: 0 !important;
}
[data-pc-direction=rtl] .mt-1 {
  margin-top: 0.25rem !important;
}
[data-pc-direction=rtl] .mt-2 {
  margin-top: 0.5rem !important;
}
[data-pc-direction=rtl] .mt-3 {
  margin-top: 1rem !important;
}
[data-pc-direction=rtl] .mt-4 {
  margin-top: 1.5rem !important;
}
[data-pc-direction=rtl] .mt-5 {
  margin-top: 3rem !important;
}
[data-pc-direction=rtl] .mt-auto {
  margin-top: auto !important;
}
[data-pc-direction=rtl] .me-0 {
  margin-left: 0 !important;
}
[data-pc-direction=rtl] .me-1 {
  margin-left: 0.25rem !important;
}
[data-pc-direction=rtl] .me-2 {
  margin-left: 0.5rem !important;
}
[data-pc-direction=rtl] .me-3 {
  margin-left: 1rem !important;
}
[data-pc-direction=rtl] .me-4 {
  margin-left: 1.5rem !important;
}
[data-pc-direction=rtl] .me-5 {
  margin-left: 3rem !important;
}
[data-pc-direction=rtl] .me-auto {
  margin-left: auto !important;
}
[data-pc-direction=rtl] .mb-0 {
  margin-bottom: 0 !important;
}
[data-pc-direction=rtl] .mb-1 {
  margin-bottom: 0.25rem !important;
}
[data-pc-direction=rtl] .mb-2 {
  margin-bottom: 0.5rem !important;
}
[data-pc-direction=rtl] .mb-3 {
  margin-bottom: 1rem !important;
}
[data-pc-direction=rtl] .mb-4 {
  margin-bottom: 1.5rem !important;
}
[data-pc-direction=rtl] .mb-5 {
  margin-bottom: 3rem !important;
}
[data-pc-direction=rtl] .mb-auto {
  margin-bottom: auto !important;
}
[data-pc-direction=rtl] .ms-0 {
  margin-right: 0 !important;
}
[data-pc-direction=rtl] .ms-1 {
  margin-right: 0.25rem !important;
}
[data-pc-direction=rtl] .ms-2 {
  margin-right: 0.5rem !important;
}
[data-pc-direction=rtl] .ms-3 {
  margin-right: 1rem !important;
}
[data-pc-direction=rtl] .ms-4 {
  margin-right: 1.5rem !important;
}
[data-pc-direction=rtl] .ms-5 {
  margin-right: 3rem !important;
}
[data-pc-direction=rtl] .ms-auto {
  margin-right: auto !important;
}
[data-pc-direction=rtl] .m-n1 {
  margin: -0.25rem !important;
}
[data-pc-direction=rtl] .m-n2 {
  margin: -0.5rem !important;
}
[data-pc-direction=rtl] .m-n3 {
  margin: -1rem !important;
}
[data-pc-direction=rtl] .m-n4 {
  margin: -1.5rem !important;
}
[data-pc-direction=rtl] .m-n5 {
  margin: -3rem !important;
}
[data-pc-direction=rtl] .mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}
[data-pc-direction=rtl] .mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}
[data-pc-direction=rtl] .mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
[data-pc-direction=rtl] .mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}
[data-pc-direction=rtl] .mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}
[data-pc-direction=rtl] .my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}
[data-pc-direction=rtl] .my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}
[data-pc-direction=rtl] .my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}
[data-pc-direction=rtl] .my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}
[data-pc-direction=rtl] .my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}
[data-pc-direction=rtl] .mt-n1 {
  margin-top: -0.25rem !important;
}
[data-pc-direction=rtl] .mt-n2 {
  margin-top: -0.5rem !important;
}
[data-pc-direction=rtl] .mt-n3 {
  margin-top: -1rem !important;
}
[data-pc-direction=rtl] .mt-n4 {
  margin-top: -1.5rem !important;
}
[data-pc-direction=rtl] .mt-n5 {
  margin-top: -3rem !important;
}
[data-pc-direction=rtl] .me-n1 {
  margin-left: -0.25rem !important;
}
[data-pc-direction=rtl] .me-n2 {
  margin-left: -0.5rem !important;
}
[data-pc-direction=rtl] .me-n3 {
  margin-left: -1rem !important;
}
[data-pc-direction=rtl] .me-n4 {
  margin-left: -1.5rem !important;
}
[data-pc-direction=rtl] .me-n5 {
  margin-left: -3rem !important;
}
[data-pc-direction=rtl] .mb-n1 {
  margin-bottom: -0.25rem !important;
}
[data-pc-direction=rtl] .mb-n2 {
  margin-bottom: -0.5rem !important;
}
[data-pc-direction=rtl] .mb-n3 {
  margin-bottom: -1rem !important;
}
[data-pc-direction=rtl] .mb-n4 {
  margin-bottom: -1.5rem !important;
}
[data-pc-direction=rtl] .mb-n5 {
  margin-bottom: -3rem !important;
}
[data-pc-direction=rtl] .ms-n1 {
  margin-right: -0.25rem !important;
}
[data-pc-direction=rtl] .ms-n2 {
  margin-right: -0.5rem !important;
}
[data-pc-direction=rtl] .ms-n3 {
  margin-right: -1rem !important;
}
[data-pc-direction=rtl] .ms-n4 {
  margin-right: -1.5rem !important;
}
[data-pc-direction=rtl] .ms-n5 {
  margin-right: -3rem !important;
}
[data-pc-direction=rtl] .p-0 {
  padding: 0 !important;
}
[data-pc-direction=rtl] .p-1 {
  padding: 0.25rem !important;
}
[data-pc-direction=rtl] .p-2 {
  padding: 0.5rem !important;
}
[data-pc-direction=rtl] .p-3 {
  padding: 1rem !important;
}
[data-pc-direction=rtl] .p-4 {
  padding: 1.5rem !important;
}
[data-pc-direction=rtl] .p-5 {
  padding: 3rem !important;
}
[data-pc-direction=rtl] .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
[data-pc-direction=rtl] .px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
[data-pc-direction=rtl] .px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
[data-pc-direction=rtl] .px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
[data-pc-direction=rtl] .px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
[data-pc-direction=rtl] .px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
[data-pc-direction=rtl] .py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
[data-pc-direction=rtl] .py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
[data-pc-direction=rtl] .py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
[data-pc-direction=rtl] .py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
[data-pc-direction=rtl] .py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
[data-pc-direction=rtl] .py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
[data-pc-direction=rtl] .pt-0 {
  padding-top: 0 !important;
}
[data-pc-direction=rtl] .pt-1 {
  padding-top: 0.25rem !important;
}
[data-pc-direction=rtl] .pt-2 {
  padding-top: 0.5rem !important;
}
[data-pc-direction=rtl] .pt-3 {
  padding-top: 1rem !important;
}
[data-pc-direction=rtl] .pt-4 {
  padding-top: 1.5rem !important;
}
[data-pc-direction=rtl] .pt-5 {
  padding-top: 3rem !important;
}
[data-pc-direction=rtl] .pe-0 {
  padding-left: 0 !important;
}
[data-pc-direction=rtl] .pe-1 {
  padding-left: 0.25rem !important;
}
[data-pc-direction=rtl] .pe-2 {
  padding-left: 0.5rem !important;
}
[data-pc-direction=rtl] .pe-3 {
  padding-left: 1rem !important;
}
[data-pc-direction=rtl] .pe-4 {
  padding-left: 1.5rem !important;
}
[data-pc-direction=rtl] .pe-5 {
  padding-left: 3rem !important;
}
[data-pc-direction=rtl] .pb-0 {
  padding-bottom: 0 !important;
}
[data-pc-direction=rtl] .pb-1 {
  padding-bottom: 0.25rem !important;
}
[data-pc-direction=rtl] .pb-2 {
  padding-bottom: 0.5rem !important;
}
[data-pc-direction=rtl] .pb-3 {
  padding-bottom: 1rem !important;
}
[data-pc-direction=rtl] .pb-4 {
  padding-bottom: 1.5rem !important;
}
[data-pc-direction=rtl] .pb-5 {
  padding-bottom: 3rem !important;
}
[data-pc-direction=rtl] .ps-0 {
  padding-right: 0 !important;
}
[data-pc-direction=rtl] .ps-1 {
  padding-right: 0.25rem !important;
}
[data-pc-direction=rtl] .ps-2 {
  padding-right: 0.5rem !important;
}
[data-pc-direction=rtl] .ps-3 {
  padding-right: 1rem !important;
}
[data-pc-direction=rtl] .ps-4 {
  padding-right: 1.5rem !important;
}
[data-pc-direction=rtl] .ps-5 {
  padding-right: 3rem !important;
}
[data-pc-direction=rtl] .font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
[data-pc-direction=rtl] .fs-1 {
  font-size: calc(1.3625rem + 1.35vw) !important;
}
[data-pc-direction=rtl] .fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}
[data-pc-direction=rtl] .fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
[data-pc-direction=rtl] .fs-4 {
  font-size: 1.25rem !important;
}
[data-pc-direction=rtl] .fs-5 {
  font-size: 1rem !important;
}
[data-pc-direction=rtl] .fs-6 {
  font-size: 0.875rem !important;
}
[data-pc-direction=rtl] .fst-italic {
  font-style: italic !important;
}
[data-pc-direction=rtl] .fst-normal {
  font-style: normal !important;
}
[data-pc-direction=rtl] .fw-light {
  font-weight: 300 !important;
}
[data-pc-direction=rtl] .fw-lighter {
  font-weight: lighter !important;
}
[data-pc-direction=rtl] .fw-normal {
  font-weight: 400 !important;
}
[data-pc-direction=rtl] .fw-bold {
  font-weight: 700 !important;
}
[data-pc-direction=rtl] .fw-bolder {
  font-weight: bolder !important;
}
[data-pc-direction=rtl] .lh-1 {
  line-height: 1 !important;
}
[data-pc-direction=rtl] .lh-sm {
  line-height: 1.25 !important;
}
[data-pc-direction=rtl] .lh-base {
  line-height: 1.5 !important;
}
[data-pc-direction=rtl] .lh-lg {
  line-height: 2 !important;
}
[data-pc-direction=rtl] .text-start {
  text-align: right !important;
}
[data-pc-direction=rtl] .text-end {
  text-align: left !important;
}
[data-pc-direction=rtl] .text-center {
  text-align: center !important;
}
[data-pc-direction=rtl] .text-decoration-none {
  text-decoration: none !important;
}
[data-pc-direction=rtl] .text-decoration-underline {
  text-decoration: underline !important;
}
[data-pc-direction=rtl] .text-decoration-line-through {
  text-decoration: line-through !important;
}
[data-pc-direction=rtl] .text-lowercase {
  text-transform: lowercase !important;
}
[data-pc-direction=rtl] .text-uppercase {
  text-transform: uppercase !important;
}
[data-pc-direction=rtl] .text-capitalize {
  text-transform: capitalize !important;
}
[data-pc-direction=rtl] .text-wrap {
  white-space: normal !important;
}
[data-pc-direction=rtl] .text-nowrap {
  white-space: nowrap !important;
}
[data-pc-direction=rtl] .text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
[data-pc-direction=rtl] .text-theme {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-theme-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=rtl] .text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=rtl] .text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=rtl] .text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=rtl] .text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=rtl] .text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=rtl] .text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=rtl] .text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=rtl] .text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=rtl] .text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=rtl] .text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=rtl] .text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
[data-pc-direction=rtl] .text-muted {
  --bs-text-opacity: 1;
  color: #5b6b79 !important;
}
[data-pc-direction=rtl] .text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
[data-pc-direction=rtl] .text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
[data-pc-direction=rtl] .text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
[data-pc-direction=rtl] .text-opacity-25 {
  --bs-text-opacity: 0.25;
}
[data-pc-direction=rtl] .text-opacity-50 {
  --bs-text-opacity: 0.5;
}
[data-pc-direction=rtl] .text-opacity-75 {
  --bs-text-opacity: 0.75;
}
[data-pc-direction=rtl] .text-opacity-100 {
  --bs-text-opacity: 1;
}
[data-pc-direction=rtl] .bg-theme {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-theme-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=rtl] .bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=rtl] .bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=rtl] .bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=rtl] .bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=rtl] .bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=rtl] .bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=rtl] .bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=rtl] .bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=rtl] .bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=rtl] .bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=rtl] .bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}
[data-pc-direction=rtl] .bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
[data-pc-direction=rtl] .bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
[data-pc-direction=rtl] .bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
[data-pc-direction=rtl] .bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
[data-pc-direction=rtl] .bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
[data-pc-direction=rtl] .bg-opacity-100 {
  --bs-bg-opacity: 1;
}
[data-pc-direction=rtl] .bg-gradient {
  background-image: var(--bs-gradient) !important;
}
[data-pc-direction=rtl] .user-select-all {
  user-select: all !important;
}
[data-pc-direction=rtl] .user-select-auto {
  user-select: auto !important;
}
[data-pc-direction=rtl] .user-select-none {
  user-select: none !important;
}
[data-pc-direction=rtl] .pe-none {
  pointer-events: none !important;
}
[data-pc-direction=rtl] .pe-auto {
  pointer-events: auto !important;
}
[data-pc-direction=rtl] .rounded {
  border-radius: 8px !important;
}
[data-pc-direction=rtl] .rounded-0 {
  border-radius: 0 !important;
}
[data-pc-direction=rtl] .rounded-1 {
  border-radius: 6px !important;
}
[data-pc-direction=rtl] .rounded-2 {
  border-radius: 8px !important;
}
[data-pc-direction=rtl] .rounded-3 {
  border-radius: 10px !important;
}
[data-pc-direction=rtl] .rounded-circle {
  border-radius: 50% !important;
}
[data-pc-direction=rtl] .rounded-pill {
  border-radius: 50rem !important;
}
[data-pc-direction=rtl] .rounded-top {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
[data-pc-direction=rtl] .rounded-end {
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
}
[data-pc-direction=rtl] .rounded-bottom {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
[data-pc-direction=rtl] .rounded-start {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
[data-pc-direction=rtl] .visible {
  visibility: visible !important;
}
[data-pc-direction=rtl] .invisible {
  visibility: hidden !important;
}
@media (min-width: 576px) {
  [data-pc-direction=rtl] .float-sm-start {
    float: right !important;
  }
  [data-pc-direction=rtl] .float-sm-end {
    float: left !important;
  }
  [data-pc-direction=rtl] .float-sm-none {
    float: none !important;
  }
  [data-pc-direction=rtl] .d-sm-inline {
    display: inline !important;
  }
  [data-pc-direction=rtl] .d-sm-inline-block {
    display: inline-block !important;
  }
  [data-pc-direction=rtl] .d-sm-block {
    display: block !important;
  }
  [data-pc-direction=rtl] .d-sm-grid {
    display: grid !important;
  }
  [data-pc-direction=rtl] .d-sm-table {
    display: table !important;
  }
  [data-pc-direction=rtl] .d-sm-table-row {
    display: table-row !important;
  }
  [data-pc-direction=rtl] .d-sm-table-cell {
    display: table-cell !important;
  }
  [data-pc-direction=rtl] .d-sm-flex {
    display: flex !important;
  }
  [data-pc-direction=rtl] .d-sm-inline-flex {
    display: inline-flex !important;
  }
  [data-pc-direction=rtl] .d-sm-none {
    display: none !important;
  }
  [data-pc-direction=rtl] .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  [data-pc-direction=rtl] .flex-sm-row {
    flex-direction: row !important;
  }
  [data-pc-direction=rtl] .flex-sm-column {
    flex-direction: column !important;
  }
  [data-pc-direction=rtl] .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  [data-pc-direction=rtl] .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  [data-pc-direction=rtl] .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  [data-pc-direction=rtl] .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  [data-pc-direction=rtl] .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  [data-pc-direction=rtl] .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  [data-pc-direction=rtl] .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  [data-pc-direction=rtl] .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  [data-pc-direction=rtl] .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  [data-pc-direction=rtl] .gap-sm-0 {
    gap: 0 !important;
  }
  [data-pc-direction=rtl] .gap-sm-1 {
    gap: 0.25rem !important;
  }
  [data-pc-direction=rtl] .gap-sm-2 {
    gap: 0.5rem !important;
  }
  [data-pc-direction=rtl] .gap-sm-3 {
    gap: 1rem !important;
  }
  [data-pc-direction=rtl] .gap-sm-4 {
    gap: 1.5rem !important;
  }
  [data-pc-direction=rtl] .gap-sm-5 {
    gap: 3rem !important;
  }
  [data-pc-direction=rtl] .justify-content-sm-start {
    justify-content: flex-end !important;
  }
  [data-pc-direction=rtl] .justify-content-sm-end {
    justify-content: flex-start !important;
  }
  [data-pc-direction=rtl] .justify-content-sm-center {
    justify-content: center !important;
  }
  [data-pc-direction=rtl] .justify-content-sm-between {
    justify-content: space-between !important;
  }
  [data-pc-direction=rtl] .justify-content-sm-around {
    justify-content: space-around !important;
  }
  [data-pc-direction=rtl] .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  [data-pc-direction=rtl] .align-items-sm-start {
    align-items: flex-start !important;
  }
  [data-pc-direction=rtl] .align-items-sm-end {
    align-items: flex-end !important;
  }
  [data-pc-direction=rtl] .align-items-sm-center {
    align-items: center !important;
  }
  [data-pc-direction=rtl] .align-items-sm-baseline {
    align-items: baseline !important;
  }
  [data-pc-direction=rtl] .align-items-sm-stretch {
    align-items: stretch !important;
  }
  [data-pc-direction=rtl] .align-content-sm-start {
    align-content: flex-end !important;
  }
  [data-pc-direction=rtl] .align-content-sm-end {
    align-content: flex-start !important;
  }
  [data-pc-direction=rtl] .align-content-sm-center {
    align-content: center !important;
  }
  [data-pc-direction=rtl] .align-content-sm-between {
    align-content: space-between !important;
  }
  [data-pc-direction=rtl] .align-content-sm-around {
    align-content: space-around !important;
  }
  [data-pc-direction=rtl] .align-content-sm-stretch {
    align-content: stretch !important;
  }
  [data-pc-direction=rtl] .align-self-sm-auto {
    align-self: auto !important;
  }
  [data-pc-direction=rtl] .align-self-sm-start {
    align-self: flex-end !important;
  }
  [data-pc-direction=rtl] .align-self-sm-end {
    align-self: flex-start !important;
  }
  [data-pc-direction=rtl] .align-self-sm-center {
    align-self: center !important;
  }
  [data-pc-direction=rtl] .align-self-sm-baseline {
    align-self: baseline !important;
  }
  [data-pc-direction=rtl] .align-self-sm-stretch {
    align-self: stretch !important;
  }
  [data-pc-direction=rtl] .order-sm-first {
    order: -1 !important;
  }
  [data-pc-direction=rtl] .order-sm-0 {
    order: 0 !important;
  }
  [data-pc-direction=rtl] .order-sm-1 {
    order: 1 !important;
  }
  [data-pc-direction=rtl] .order-sm-2 {
    order: 2 !important;
  }
  [data-pc-direction=rtl] .order-sm-3 {
    order: 3 !important;
  }
  [data-pc-direction=rtl] .order-sm-4 {
    order: 4 !important;
  }
  [data-pc-direction=rtl] .order-sm-5 {
    order: 5 !important;
  }
  [data-pc-direction=rtl] .order-sm-last {
    order: 6 !important;
  }
  [data-pc-direction=rtl] .m-sm-0 {
    margin: 0 !important;
  }
  [data-pc-direction=rtl] .m-sm-1 {
    margin: 0.25rem !important;
  }
  [data-pc-direction=rtl] .m-sm-2 {
    margin: 0.5rem !important;
  }
  [data-pc-direction=rtl] .m-sm-3 {
    margin: 1rem !important;
  }
  [data-pc-direction=rtl] .m-sm-4 {
    margin: 1.5rem !important;
  }
  [data-pc-direction=rtl] .m-sm-5 {
    margin: 3rem !important;
  }
  [data-pc-direction=rtl] .m-sm-auto {
    margin: auto !important;
  }
  [data-pc-direction=rtl] .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [data-pc-direction=rtl] .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  [data-pc-direction=rtl] .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  [data-pc-direction=rtl] .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  [data-pc-direction=rtl] .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  [data-pc-direction=rtl] .mt-sm-0 {
    margin-top: 0 !important;
  }
  [data-pc-direction=rtl] .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mt-sm-3 {
    margin-top: 1rem !important;
  }
  [data-pc-direction=rtl] .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mt-sm-5 {
    margin-top: 3rem !important;
  }
  [data-pc-direction=rtl] .mt-sm-auto {
    margin-top: auto !important;
  }
  [data-pc-direction=rtl] .me-sm-0 {
    margin-left: 0 !important;
  }
  [data-pc-direction=rtl] .me-sm-1 {
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .me-sm-2 {
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .me-sm-3 {
    margin-left: 1rem !important;
  }
  [data-pc-direction=rtl] .me-sm-4 {
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .me-sm-5 {
    margin-left: 3rem !important;
  }
  [data-pc-direction=rtl] .me-sm-auto {
    margin-left: auto !important;
  }
  [data-pc-direction=rtl] .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .mb-sm-auto {
    margin-bottom: auto !important;
  }
  [data-pc-direction=rtl] .ms-sm-0 {
    margin-right: 0 !important;
  }
  [data-pc-direction=rtl] .ms-sm-1 {
    margin-right: 0.25rem !important;
  }
  [data-pc-direction=rtl] .ms-sm-2 {
    margin-right: 0.5rem !important;
  }
  [data-pc-direction=rtl] .ms-sm-3 {
    margin-right: 1rem !important;
  }
  [data-pc-direction=rtl] .ms-sm-4 {
    margin-right: 1.5rem !important;
  }
  [data-pc-direction=rtl] .ms-sm-5 {
    margin-right: 3rem !important;
  }
  [data-pc-direction=rtl] .ms-sm-auto {
    margin-right: auto !important;
  }
  [data-pc-direction=rtl] .m-sm-n1 {
    margin: -0.25rem !important;
  }
  [data-pc-direction=rtl] .m-sm-n2 {
    margin: -0.5rem !important;
  }
  [data-pc-direction=rtl] .m-sm-n3 {
    margin: -1rem !important;
  }
  [data-pc-direction=rtl] .m-sm-n4 {
    margin: -1.5rem !important;
  }
  [data-pc-direction=rtl] .m-sm-n5 {
    margin: -3rem !important;
  }
  [data-pc-direction=rtl] .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  [data-pc-direction=rtl] .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  [data-pc-direction=rtl] .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=rtl] .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=rtl] .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=rtl] .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=rtl] .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=rtl] .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  [data-pc-direction=rtl] .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  [data-pc-direction=rtl] .me-sm-n1 {
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=rtl] .me-sm-n2 {
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=rtl] .me-sm-n3 {
    margin-left: -1rem !important;
  }
  [data-pc-direction=rtl] .me-sm-n4 {
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=rtl] .me-sm-n5 {
    margin-left: -3rem !important;
  }
  [data-pc-direction=rtl] .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=rtl] .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=rtl] .ms-sm-n1 {
    margin-right: -0.25rem !important;
  }
  [data-pc-direction=rtl] .ms-sm-n2 {
    margin-right: -0.5rem !important;
  }
  [data-pc-direction=rtl] .ms-sm-n3 {
    margin-right: -1rem !important;
  }
  [data-pc-direction=rtl] .ms-sm-n4 {
    margin-right: -1.5rem !important;
  }
  [data-pc-direction=rtl] .ms-sm-n5 {
    margin-right: -3rem !important;
  }
  [data-pc-direction=rtl] .p-sm-0 {
    padding: 0 !important;
  }
  [data-pc-direction=rtl] .p-sm-1 {
    padding: 0.25rem !important;
  }
  [data-pc-direction=rtl] .p-sm-2 {
    padding: 0.5rem !important;
  }
  [data-pc-direction=rtl] .p-sm-3 {
    padding: 1rem !important;
  }
  [data-pc-direction=rtl] .p-sm-4 {
    padding: 1.5rem !important;
  }
  [data-pc-direction=rtl] .p-sm-5 {
    padding: 3rem !important;
  }
  [data-pc-direction=rtl] .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [data-pc-direction=rtl] .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  [data-pc-direction=rtl] .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  [data-pc-direction=rtl] .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .pt-sm-0 {
    padding-top: 0 !important;
  }
  [data-pc-direction=rtl] .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pt-sm-3 {
    padding-top: 1rem !important;
  }
  [data-pc-direction=rtl] .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pt-sm-5 {
    padding-top: 3rem !important;
  }
  [data-pc-direction=rtl] .pe-sm-0 {
    padding-left: 0 !important;
  }
  [data-pc-direction=rtl] .pe-sm-1 {
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pe-sm-2 {
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pe-sm-3 {
    padding-left: 1rem !important;
  }
  [data-pc-direction=rtl] .pe-sm-4 {
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pe-sm-5 {
    padding-left: 3rem !important;
  }
  [data-pc-direction=rtl] .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .ps-sm-0 {
    padding-right: 0 !important;
  }
  [data-pc-direction=rtl] .ps-sm-1 {
    padding-right: 0.25rem !important;
  }
  [data-pc-direction=rtl] .ps-sm-2 {
    padding-right: 0.5rem !important;
  }
  [data-pc-direction=rtl] .ps-sm-3 {
    padding-right: 1rem !important;
  }
  [data-pc-direction=rtl] .ps-sm-4 {
    padding-right: 1.5rem !important;
  }
  [data-pc-direction=rtl] .ps-sm-5 {
    padding-right: 3rem !important;
  }
  [data-pc-direction=rtl] .text-sm-start {
    text-align: right !important;
  }
  [data-pc-direction=rtl] .text-sm-end {
    text-align: left !important;
  }
  [data-pc-direction=rtl] .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  [data-pc-direction=rtl] .float-md-start {
    float: right !important;
  }
  [data-pc-direction=rtl] .float-md-end {
    float: left !important;
  }
  [data-pc-direction=rtl] .float-md-none {
    float: none !important;
  }
  [data-pc-direction=rtl] .d-md-inline {
    display: inline !important;
  }
  [data-pc-direction=rtl] .d-md-inline-block {
    display: inline-block !important;
  }
  [data-pc-direction=rtl] .d-md-block {
    display: block !important;
  }
  [data-pc-direction=rtl] .d-md-grid {
    display: grid !important;
  }
  [data-pc-direction=rtl] .d-md-table {
    display: table !important;
  }
  [data-pc-direction=rtl] .d-md-table-row {
    display: table-row !important;
  }
  [data-pc-direction=rtl] .d-md-table-cell {
    display: table-cell !important;
  }
  [data-pc-direction=rtl] .d-md-flex {
    display: flex !important;
  }
  [data-pc-direction=rtl] .d-md-inline-flex {
    display: inline-flex !important;
  }
  [data-pc-direction=rtl] .d-md-none {
    display: none !important;
  }
  [data-pc-direction=rtl] .flex-md-fill {
    flex: 1 1 auto !important;
  }
  [data-pc-direction=rtl] .flex-md-row {
    flex-direction: row !important;
  }
  [data-pc-direction=rtl] .flex-md-column {
    flex-direction: column !important;
  }
  [data-pc-direction=rtl] .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  [data-pc-direction=rtl] .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  [data-pc-direction=rtl] .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  [data-pc-direction=rtl] .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  [data-pc-direction=rtl] .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  [data-pc-direction=rtl] .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  [data-pc-direction=rtl] .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  [data-pc-direction=rtl] .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  [data-pc-direction=rtl] .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  [data-pc-direction=rtl] .gap-md-0 {
    gap: 0 !important;
  }
  [data-pc-direction=rtl] .gap-md-1 {
    gap: 0.25rem !important;
  }
  [data-pc-direction=rtl] .gap-md-2 {
    gap: 0.5rem !important;
  }
  [data-pc-direction=rtl] .gap-md-3 {
    gap: 1rem !important;
  }
  [data-pc-direction=rtl] .gap-md-4 {
    gap: 1.5rem !important;
  }
  [data-pc-direction=rtl] .gap-md-5 {
    gap: 3rem !important;
  }
  [data-pc-direction=rtl] .justify-content-md-start {
    justify-content: flex-end !important;
  }
  [data-pc-direction=rtl] .justify-content-md-end {
    justify-content: flex-start !important;
  }
  [data-pc-direction=rtl] .justify-content-md-center {
    justify-content: center !important;
  }
  [data-pc-direction=rtl] .justify-content-md-between {
    justify-content: space-between !important;
  }
  [data-pc-direction=rtl] .justify-content-md-around {
    justify-content: space-around !important;
  }
  [data-pc-direction=rtl] .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  [data-pc-direction=rtl] .align-items-md-start {
    align-items: flex-start !important;
  }
  [data-pc-direction=rtl] .align-items-md-end {
    align-items: flex-end !important;
  }
  [data-pc-direction=rtl] .align-items-md-center {
    align-items: center !important;
  }
  [data-pc-direction=rtl] .align-items-md-baseline {
    align-items: baseline !important;
  }
  [data-pc-direction=rtl] .align-items-md-stretch {
    align-items: stretch !important;
  }
  [data-pc-direction=rtl] .align-content-md-start {
    align-content: flex-end !important;
  }
  [data-pc-direction=rtl] .align-content-md-end {
    align-content: flex-start !important;
  }
  [data-pc-direction=rtl] .align-content-md-center {
    align-content: center !important;
  }
  [data-pc-direction=rtl] .align-content-md-between {
    align-content: space-between !important;
  }
  [data-pc-direction=rtl] .align-content-md-around {
    align-content: space-around !important;
  }
  [data-pc-direction=rtl] .align-content-md-stretch {
    align-content: stretch !important;
  }
  [data-pc-direction=rtl] .align-self-md-auto {
    align-self: auto !important;
  }
  [data-pc-direction=rtl] .align-self-md-start {
    align-self: flex-end !important;
  }
  [data-pc-direction=rtl] .align-self-md-end {
    align-self: flex-start !important;
  }
  [data-pc-direction=rtl] .align-self-md-center {
    align-self: center !important;
  }
  [data-pc-direction=rtl] .align-self-md-baseline {
    align-self: baseline !important;
  }
  [data-pc-direction=rtl] .align-self-md-stretch {
    align-self: stretch !important;
  }
  [data-pc-direction=rtl] .order-md-first {
    order: -1 !important;
  }
  [data-pc-direction=rtl] .order-md-0 {
    order: 0 !important;
  }
  [data-pc-direction=rtl] .order-md-1 {
    order: 1 !important;
  }
  [data-pc-direction=rtl] .order-md-2 {
    order: 2 !important;
  }
  [data-pc-direction=rtl] .order-md-3 {
    order: 3 !important;
  }
  [data-pc-direction=rtl] .order-md-4 {
    order: 4 !important;
  }
  [data-pc-direction=rtl] .order-md-5 {
    order: 5 !important;
  }
  [data-pc-direction=rtl] .order-md-last {
    order: 6 !important;
  }
  [data-pc-direction=rtl] .m-md-0 {
    margin: 0 !important;
  }
  [data-pc-direction=rtl] .m-md-1 {
    margin: 0.25rem !important;
  }
  [data-pc-direction=rtl] .m-md-2 {
    margin: 0.5rem !important;
  }
  [data-pc-direction=rtl] .m-md-3 {
    margin: 1rem !important;
  }
  [data-pc-direction=rtl] .m-md-4 {
    margin: 1.5rem !important;
  }
  [data-pc-direction=rtl] .m-md-5 {
    margin: 3rem !important;
  }
  [data-pc-direction=rtl] .m-md-auto {
    margin: auto !important;
  }
  [data-pc-direction=rtl] .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [data-pc-direction=rtl] .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  [data-pc-direction=rtl] .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  [data-pc-direction=rtl] .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  [data-pc-direction=rtl] .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  [data-pc-direction=rtl] .mt-md-0 {
    margin-top: 0 !important;
  }
  [data-pc-direction=rtl] .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mt-md-3 {
    margin-top: 1rem !important;
  }
  [data-pc-direction=rtl] .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mt-md-5 {
    margin-top: 3rem !important;
  }
  [data-pc-direction=rtl] .mt-md-auto {
    margin-top: auto !important;
  }
  [data-pc-direction=rtl] .me-md-0 {
    margin-left: 0 !important;
  }
  [data-pc-direction=rtl] .me-md-1 {
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .me-md-2 {
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .me-md-3 {
    margin-left: 1rem !important;
  }
  [data-pc-direction=rtl] .me-md-4 {
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .me-md-5 {
    margin-left: 3rem !important;
  }
  [data-pc-direction=rtl] .me-md-auto {
    margin-left: auto !important;
  }
  [data-pc-direction=rtl] .mb-md-0 {
    margin-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .mb-md-auto {
    margin-bottom: auto !important;
  }
  [data-pc-direction=rtl] .ms-md-0 {
    margin-right: 0 !important;
  }
  [data-pc-direction=rtl] .ms-md-1 {
    margin-right: 0.25rem !important;
  }
  [data-pc-direction=rtl] .ms-md-2 {
    margin-right: 0.5rem !important;
  }
  [data-pc-direction=rtl] .ms-md-3 {
    margin-right: 1rem !important;
  }
  [data-pc-direction=rtl] .ms-md-4 {
    margin-right: 1.5rem !important;
  }
  [data-pc-direction=rtl] .ms-md-5 {
    margin-right: 3rem !important;
  }
  [data-pc-direction=rtl] .ms-md-auto {
    margin-right: auto !important;
  }
  [data-pc-direction=rtl] .m-md-n1 {
    margin: -0.25rem !important;
  }
  [data-pc-direction=rtl] .m-md-n2 {
    margin: -0.5rem !important;
  }
  [data-pc-direction=rtl] .m-md-n3 {
    margin: -1rem !important;
  }
  [data-pc-direction=rtl] .m-md-n4 {
    margin: -1.5rem !important;
  }
  [data-pc-direction=rtl] .m-md-n5 {
    margin: -3rem !important;
  }
  [data-pc-direction=rtl] .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  [data-pc-direction=rtl] .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  [data-pc-direction=rtl] .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=rtl] .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=rtl] .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=rtl] .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=rtl] .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=rtl] .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mt-md-n3 {
    margin-top: -1rem !important;
  }
  [data-pc-direction=rtl] .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mt-md-n5 {
    margin-top: -3rem !important;
  }
  [data-pc-direction=rtl] .me-md-n1 {
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=rtl] .me-md-n2 {
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=rtl] .me-md-n3 {
    margin-left: -1rem !important;
  }
  [data-pc-direction=rtl] .me-md-n4 {
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=rtl] .me-md-n5 {
    margin-left: -3rem !important;
  }
  [data-pc-direction=rtl] .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=rtl] .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=rtl] .ms-md-n1 {
    margin-right: -0.25rem !important;
  }
  [data-pc-direction=rtl] .ms-md-n2 {
    margin-right: -0.5rem !important;
  }
  [data-pc-direction=rtl] .ms-md-n3 {
    margin-right: -1rem !important;
  }
  [data-pc-direction=rtl] .ms-md-n4 {
    margin-right: -1.5rem !important;
  }
  [data-pc-direction=rtl] .ms-md-n5 {
    margin-right: -3rem !important;
  }
  [data-pc-direction=rtl] .p-md-0 {
    padding: 0 !important;
  }
  [data-pc-direction=rtl] .p-md-1 {
    padding: 0.25rem !important;
  }
  [data-pc-direction=rtl] .p-md-2 {
    padding: 0.5rem !important;
  }
  [data-pc-direction=rtl] .p-md-3 {
    padding: 1rem !important;
  }
  [data-pc-direction=rtl] .p-md-4 {
    padding: 1.5rem !important;
  }
  [data-pc-direction=rtl] .p-md-5 {
    padding: 3rem !important;
  }
  [data-pc-direction=rtl] .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [data-pc-direction=rtl] .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  [data-pc-direction=rtl] .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  [data-pc-direction=rtl] .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .pt-md-0 {
    padding-top: 0 !important;
  }
  [data-pc-direction=rtl] .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pt-md-3 {
    padding-top: 1rem !important;
  }
  [data-pc-direction=rtl] .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pt-md-5 {
    padding-top: 3rem !important;
  }
  [data-pc-direction=rtl] .pe-md-0 {
    padding-left: 0 !important;
  }
  [data-pc-direction=rtl] .pe-md-1 {
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pe-md-2 {
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pe-md-3 {
    padding-left: 1rem !important;
  }
  [data-pc-direction=rtl] .pe-md-4 {
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pe-md-5 {
    padding-left: 3rem !important;
  }
  [data-pc-direction=rtl] .pb-md-0 {
    padding-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .ps-md-0 {
    padding-right: 0 !important;
  }
  [data-pc-direction=rtl] .ps-md-1 {
    padding-right: 0.25rem !important;
  }
  [data-pc-direction=rtl] .ps-md-2 {
    padding-right: 0.5rem !important;
  }
  [data-pc-direction=rtl] .ps-md-3 {
    padding-right: 1rem !important;
  }
  [data-pc-direction=rtl] .ps-md-4 {
    padding-right: 1.5rem !important;
  }
  [data-pc-direction=rtl] .ps-md-5 {
    padding-right: 3rem !important;
  }
  [data-pc-direction=rtl] .text-md-start {
    text-align: right !important;
  }
  [data-pc-direction=rtl] .text-md-end {
    text-align: left !important;
  }
  [data-pc-direction=rtl] .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  [data-pc-direction=rtl] .float-lg-start {
    float: right !important;
  }
  [data-pc-direction=rtl] .float-lg-end {
    float: left !important;
  }
  [data-pc-direction=rtl] .float-lg-none {
    float: none !important;
  }
  [data-pc-direction=rtl] .d-lg-inline {
    display: inline !important;
  }
  [data-pc-direction=rtl] .d-lg-inline-block {
    display: inline-block !important;
  }
  [data-pc-direction=rtl] .d-lg-block {
    display: block !important;
  }
  [data-pc-direction=rtl] .d-lg-grid {
    display: grid !important;
  }
  [data-pc-direction=rtl] .d-lg-table {
    display: table !important;
  }
  [data-pc-direction=rtl] .d-lg-table-row {
    display: table-row !important;
  }
  [data-pc-direction=rtl] .d-lg-table-cell {
    display: table-cell !important;
  }
  [data-pc-direction=rtl] .d-lg-flex {
    display: flex !important;
  }
  [data-pc-direction=rtl] .d-lg-inline-flex {
    display: inline-flex !important;
  }
  [data-pc-direction=rtl] .d-lg-none {
    display: none !important;
  }
  [data-pc-direction=rtl] .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  [data-pc-direction=rtl] .flex-lg-row {
    flex-direction: row !important;
  }
  [data-pc-direction=rtl] .flex-lg-column {
    flex-direction: column !important;
  }
  [data-pc-direction=rtl] .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  [data-pc-direction=rtl] .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  [data-pc-direction=rtl] .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  [data-pc-direction=rtl] .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  [data-pc-direction=rtl] .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  [data-pc-direction=rtl] .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  [data-pc-direction=rtl] .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  [data-pc-direction=rtl] .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  [data-pc-direction=rtl] .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  [data-pc-direction=rtl] .gap-lg-0 {
    gap: 0 !important;
  }
  [data-pc-direction=rtl] .gap-lg-1 {
    gap: 0.25rem !important;
  }
  [data-pc-direction=rtl] .gap-lg-2 {
    gap: 0.5rem !important;
  }
  [data-pc-direction=rtl] .gap-lg-3 {
    gap: 1rem !important;
  }
  [data-pc-direction=rtl] .gap-lg-4 {
    gap: 1.5rem !important;
  }
  [data-pc-direction=rtl] .gap-lg-5 {
    gap: 3rem !important;
  }
  [data-pc-direction=rtl] .justify-content-lg-start {
    justify-content: flex-end !important;
  }
  [data-pc-direction=rtl] .justify-content-lg-end {
    justify-content: flex-start !important;
  }
  [data-pc-direction=rtl] .justify-content-lg-center {
    justify-content: center !important;
  }
  [data-pc-direction=rtl] .justify-content-lg-between {
    justify-content: space-between !important;
  }
  [data-pc-direction=rtl] .justify-content-lg-around {
    justify-content: space-around !important;
  }
  [data-pc-direction=rtl] .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  [data-pc-direction=rtl] .align-items-lg-start {
    align-items: flex-start !important;
  }
  [data-pc-direction=rtl] .align-items-lg-end {
    align-items: flex-end !important;
  }
  [data-pc-direction=rtl] .align-items-lg-center {
    align-items: center !important;
  }
  [data-pc-direction=rtl] .align-items-lg-baseline {
    align-items: baseline !important;
  }
  [data-pc-direction=rtl] .align-items-lg-stretch {
    align-items: stretch !important;
  }
  [data-pc-direction=rtl] .align-content-lg-start {
    align-content: flex-end !important;
  }
  [data-pc-direction=rtl] .align-content-lg-end {
    align-content: flex-start !important;
  }
  [data-pc-direction=rtl] .align-content-lg-center {
    align-content: center !important;
  }
  [data-pc-direction=rtl] .align-content-lg-between {
    align-content: space-between !important;
  }
  [data-pc-direction=rtl] .align-content-lg-around {
    align-content: space-around !important;
  }
  [data-pc-direction=rtl] .align-content-lg-stretch {
    align-content: stretch !important;
  }
  [data-pc-direction=rtl] .align-self-lg-auto {
    align-self: auto !important;
  }
  [data-pc-direction=rtl] .align-self-lg-start {
    align-self: flex-end !important;
  }
  [data-pc-direction=rtl] .align-self-lg-end {
    align-self: flex-start !important;
  }
  [data-pc-direction=rtl] .align-self-lg-center {
    align-self: center !important;
  }
  [data-pc-direction=rtl] .align-self-lg-baseline {
    align-self: baseline !important;
  }
  [data-pc-direction=rtl] .align-self-lg-stretch {
    align-self: stretch !important;
  }
  [data-pc-direction=rtl] .order-lg-first {
    order: -1 !important;
  }
  [data-pc-direction=rtl] .order-lg-0 {
    order: 0 !important;
  }
  [data-pc-direction=rtl] .order-lg-1 {
    order: 1 !important;
  }
  [data-pc-direction=rtl] .order-lg-2 {
    order: 2 !important;
  }
  [data-pc-direction=rtl] .order-lg-3 {
    order: 3 !important;
  }
  [data-pc-direction=rtl] .order-lg-4 {
    order: 4 !important;
  }
  [data-pc-direction=rtl] .order-lg-5 {
    order: 5 !important;
  }
  [data-pc-direction=rtl] .order-lg-last {
    order: 6 !important;
  }
  [data-pc-direction=rtl] .m-lg-0 {
    margin: 0 !important;
  }
  [data-pc-direction=rtl] .m-lg-1 {
    margin: 0.25rem !important;
  }
  [data-pc-direction=rtl] .m-lg-2 {
    margin: 0.5rem !important;
  }
  [data-pc-direction=rtl] .m-lg-3 {
    margin: 1rem !important;
  }
  [data-pc-direction=rtl] .m-lg-4 {
    margin: 1.5rem !important;
  }
  [data-pc-direction=rtl] .m-lg-5 {
    margin: 3rem !important;
  }
  [data-pc-direction=rtl] .m-lg-auto {
    margin: auto !important;
  }
  [data-pc-direction=rtl] .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [data-pc-direction=rtl] .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  [data-pc-direction=rtl] .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  [data-pc-direction=rtl] .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  [data-pc-direction=rtl] .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  [data-pc-direction=rtl] .mt-lg-0 {
    margin-top: 0 !important;
  }
  [data-pc-direction=rtl] .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mt-lg-3 {
    margin-top: 1rem !important;
  }
  [data-pc-direction=rtl] .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mt-lg-5 {
    margin-top: 3rem !important;
  }
  [data-pc-direction=rtl] .mt-lg-auto {
    margin-top: auto !important;
  }
  [data-pc-direction=rtl] .me-lg-0 {
    margin-left: 0 !important;
  }
  [data-pc-direction=rtl] .me-lg-1 {
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .me-lg-2 {
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .me-lg-3 {
    margin-left: 1rem !important;
  }
  [data-pc-direction=rtl] .me-lg-4 {
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .me-lg-5 {
    margin-left: 3rem !important;
  }
  [data-pc-direction=rtl] .me-lg-auto {
    margin-left: auto !important;
  }
  [data-pc-direction=rtl] .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .mb-lg-auto {
    margin-bottom: auto !important;
  }
  [data-pc-direction=rtl] .ms-lg-0 {
    margin-right: 0 !important;
  }
  [data-pc-direction=rtl] .ms-lg-1 {
    margin-right: 0.25rem !important;
  }
  [data-pc-direction=rtl] .ms-lg-2 {
    margin-right: 0.5rem !important;
  }
  [data-pc-direction=rtl] .ms-lg-3 {
    margin-right: 1rem !important;
  }
  [data-pc-direction=rtl] .ms-lg-4 {
    margin-right: 1.5rem !important;
  }
  [data-pc-direction=rtl] .ms-lg-5 {
    margin-right: 3rem !important;
  }
  [data-pc-direction=rtl] .ms-lg-auto {
    margin-right: auto !important;
  }
  [data-pc-direction=rtl] .m-lg-n1 {
    margin: -0.25rem !important;
  }
  [data-pc-direction=rtl] .m-lg-n2 {
    margin: -0.5rem !important;
  }
  [data-pc-direction=rtl] .m-lg-n3 {
    margin: -1rem !important;
  }
  [data-pc-direction=rtl] .m-lg-n4 {
    margin: -1.5rem !important;
  }
  [data-pc-direction=rtl] .m-lg-n5 {
    margin: -3rem !important;
  }
  [data-pc-direction=rtl] .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  [data-pc-direction=rtl] .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  [data-pc-direction=rtl] .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=rtl] .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=rtl] .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=rtl] .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=rtl] .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=rtl] .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  [data-pc-direction=rtl] .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  [data-pc-direction=rtl] .me-lg-n1 {
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=rtl] .me-lg-n2 {
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=rtl] .me-lg-n3 {
    margin-left: -1rem !important;
  }
  [data-pc-direction=rtl] .me-lg-n4 {
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=rtl] .me-lg-n5 {
    margin-left: -3rem !important;
  }
  [data-pc-direction=rtl] .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=rtl] .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=rtl] .ms-lg-n1 {
    margin-right: -0.25rem !important;
  }
  [data-pc-direction=rtl] .ms-lg-n2 {
    margin-right: -0.5rem !important;
  }
  [data-pc-direction=rtl] .ms-lg-n3 {
    margin-right: -1rem !important;
  }
  [data-pc-direction=rtl] .ms-lg-n4 {
    margin-right: -1.5rem !important;
  }
  [data-pc-direction=rtl] .ms-lg-n5 {
    margin-right: -3rem !important;
  }
  [data-pc-direction=rtl] .p-lg-0 {
    padding: 0 !important;
  }
  [data-pc-direction=rtl] .p-lg-1 {
    padding: 0.25rem !important;
  }
  [data-pc-direction=rtl] .p-lg-2 {
    padding: 0.5rem !important;
  }
  [data-pc-direction=rtl] .p-lg-3 {
    padding: 1rem !important;
  }
  [data-pc-direction=rtl] .p-lg-4 {
    padding: 1.5rem !important;
  }
  [data-pc-direction=rtl] .p-lg-5 {
    padding: 3rem !important;
  }
  [data-pc-direction=rtl] .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [data-pc-direction=rtl] .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  [data-pc-direction=rtl] .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  [data-pc-direction=rtl] .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .pt-lg-0 {
    padding-top: 0 !important;
  }
  [data-pc-direction=rtl] .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pt-lg-3 {
    padding-top: 1rem !important;
  }
  [data-pc-direction=rtl] .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pt-lg-5 {
    padding-top: 3rem !important;
  }
  [data-pc-direction=rtl] .pe-lg-0 {
    padding-left: 0 !important;
  }
  [data-pc-direction=rtl] .pe-lg-1 {
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pe-lg-2 {
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pe-lg-3 {
    padding-left: 1rem !important;
  }
  [data-pc-direction=rtl] .pe-lg-4 {
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pe-lg-5 {
    padding-left: 3rem !important;
  }
  [data-pc-direction=rtl] .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .ps-lg-0 {
    padding-right: 0 !important;
  }
  [data-pc-direction=rtl] .ps-lg-1 {
    padding-right: 0.25rem !important;
  }
  [data-pc-direction=rtl] .ps-lg-2 {
    padding-right: 0.5rem !important;
  }
  [data-pc-direction=rtl] .ps-lg-3 {
    padding-right: 1rem !important;
  }
  [data-pc-direction=rtl] .ps-lg-4 {
    padding-right: 1.5rem !important;
  }
  [data-pc-direction=rtl] .ps-lg-5 {
    padding-right: 3rem !important;
  }
  [data-pc-direction=rtl] .text-lg-start {
    text-align: right !important;
  }
  [data-pc-direction=rtl] .text-lg-end {
    text-align: left !important;
  }
  [data-pc-direction=rtl] .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  [data-pc-direction=rtl] .float-xl-start {
    float: right !important;
  }
  [data-pc-direction=rtl] .float-xl-end {
    float: left !important;
  }
  [data-pc-direction=rtl] .float-xl-none {
    float: none !important;
  }
  [data-pc-direction=rtl] .d-xl-inline {
    display: inline !important;
  }
  [data-pc-direction=rtl] .d-xl-inline-block {
    display: inline-block !important;
  }
  [data-pc-direction=rtl] .d-xl-block {
    display: block !important;
  }
  [data-pc-direction=rtl] .d-xl-grid {
    display: grid !important;
  }
  [data-pc-direction=rtl] .d-xl-table {
    display: table !important;
  }
  [data-pc-direction=rtl] .d-xl-table-row {
    display: table-row !important;
  }
  [data-pc-direction=rtl] .d-xl-table-cell {
    display: table-cell !important;
  }
  [data-pc-direction=rtl] .d-xl-flex {
    display: flex !important;
  }
  [data-pc-direction=rtl] .d-xl-inline-flex {
    display: inline-flex !important;
  }
  [data-pc-direction=rtl] .d-xl-none {
    display: none !important;
  }
  [data-pc-direction=rtl] .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  [data-pc-direction=rtl] .flex-xl-row {
    flex-direction: row !important;
  }
  [data-pc-direction=rtl] .flex-xl-column {
    flex-direction: column !important;
  }
  [data-pc-direction=rtl] .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  [data-pc-direction=rtl] .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  [data-pc-direction=rtl] .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  [data-pc-direction=rtl] .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  [data-pc-direction=rtl] .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  [data-pc-direction=rtl] .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  [data-pc-direction=rtl] .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  [data-pc-direction=rtl] .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  [data-pc-direction=rtl] .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  [data-pc-direction=rtl] .gap-xl-0 {
    gap: 0 !important;
  }
  [data-pc-direction=rtl] .gap-xl-1 {
    gap: 0.25rem !important;
  }
  [data-pc-direction=rtl] .gap-xl-2 {
    gap: 0.5rem !important;
  }
  [data-pc-direction=rtl] .gap-xl-3 {
    gap: 1rem !important;
  }
  [data-pc-direction=rtl] .gap-xl-4 {
    gap: 1.5rem !important;
  }
  [data-pc-direction=rtl] .gap-xl-5 {
    gap: 3rem !important;
  }
  [data-pc-direction=rtl] .justify-content-xl-start {
    justify-content: flex-end !important;
  }
  [data-pc-direction=rtl] .justify-content-xl-end {
    justify-content: flex-start !important;
  }
  [data-pc-direction=rtl] .justify-content-xl-center {
    justify-content: center !important;
  }
  [data-pc-direction=rtl] .justify-content-xl-between {
    justify-content: space-between !important;
  }
  [data-pc-direction=rtl] .justify-content-xl-around {
    justify-content: space-around !important;
  }
  [data-pc-direction=rtl] .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  [data-pc-direction=rtl] .align-items-xl-start {
    align-items: flex-start !important;
  }
  [data-pc-direction=rtl] .align-items-xl-end {
    align-items: flex-end !important;
  }
  [data-pc-direction=rtl] .align-items-xl-center {
    align-items: center !important;
  }
  [data-pc-direction=rtl] .align-items-xl-baseline {
    align-items: baseline !important;
  }
  [data-pc-direction=rtl] .align-items-xl-stretch {
    align-items: stretch !important;
  }
  [data-pc-direction=rtl] .align-content-xl-start {
    align-content: flex-end !important;
  }
  [data-pc-direction=rtl] .align-content-xl-end {
    align-content: flex-start !important;
  }
  [data-pc-direction=rtl] .align-content-xl-center {
    align-content: center !important;
  }
  [data-pc-direction=rtl] .align-content-xl-between {
    align-content: space-between !important;
  }
  [data-pc-direction=rtl] .align-content-xl-around {
    align-content: space-around !important;
  }
  [data-pc-direction=rtl] .align-content-xl-stretch {
    align-content: stretch !important;
  }
  [data-pc-direction=rtl] .align-self-xl-auto {
    align-self: auto !important;
  }
  [data-pc-direction=rtl] .align-self-xl-start {
    align-self: flex-end !important;
  }
  [data-pc-direction=rtl] .align-self-xl-end {
    align-self: flex-start !important;
  }
  [data-pc-direction=rtl] .align-self-xl-center {
    align-self: center !important;
  }
  [data-pc-direction=rtl] .align-self-xl-baseline {
    align-self: baseline !important;
  }
  [data-pc-direction=rtl] .align-self-xl-stretch {
    align-self: stretch !important;
  }
  [data-pc-direction=rtl] .order-xl-first {
    order: -1 !important;
  }
  [data-pc-direction=rtl] .order-xl-0 {
    order: 0 !important;
  }
  [data-pc-direction=rtl] .order-xl-1 {
    order: 1 !important;
  }
  [data-pc-direction=rtl] .order-xl-2 {
    order: 2 !important;
  }
  [data-pc-direction=rtl] .order-xl-3 {
    order: 3 !important;
  }
  [data-pc-direction=rtl] .order-xl-4 {
    order: 4 !important;
  }
  [data-pc-direction=rtl] .order-xl-5 {
    order: 5 !important;
  }
  [data-pc-direction=rtl] .order-xl-last {
    order: 6 !important;
  }
  [data-pc-direction=rtl] .m-xl-0 {
    margin: 0 !important;
  }
  [data-pc-direction=rtl] .m-xl-1 {
    margin: 0.25rem !important;
  }
  [data-pc-direction=rtl] .m-xl-2 {
    margin: 0.5rem !important;
  }
  [data-pc-direction=rtl] .m-xl-3 {
    margin: 1rem !important;
  }
  [data-pc-direction=rtl] .m-xl-4 {
    margin: 1.5rem !important;
  }
  [data-pc-direction=rtl] .m-xl-5 {
    margin: 3rem !important;
  }
  [data-pc-direction=rtl] .m-xl-auto {
    margin: auto !important;
  }
  [data-pc-direction=rtl] .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [data-pc-direction=rtl] .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  [data-pc-direction=rtl] .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  [data-pc-direction=rtl] .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  [data-pc-direction=rtl] .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  [data-pc-direction=rtl] .mt-xl-0 {
    margin-top: 0 !important;
  }
  [data-pc-direction=rtl] .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mt-xl-3 {
    margin-top: 1rem !important;
  }
  [data-pc-direction=rtl] .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mt-xl-5 {
    margin-top: 3rem !important;
  }
  [data-pc-direction=rtl] .mt-xl-auto {
    margin-top: auto !important;
  }
  [data-pc-direction=rtl] .me-xl-0 {
    margin-left: 0 !important;
  }
  [data-pc-direction=rtl] .me-xl-1 {
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .me-xl-2 {
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .me-xl-3 {
    margin-left: 1rem !important;
  }
  [data-pc-direction=rtl] .me-xl-4 {
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .me-xl-5 {
    margin-left: 3rem !important;
  }
  [data-pc-direction=rtl] .me-xl-auto {
    margin-left: auto !important;
  }
  [data-pc-direction=rtl] .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .mb-xl-auto {
    margin-bottom: auto !important;
  }
  [data-pc-direction=rtl] .ms-xl-0 {
    margin-right: 0 !important;
  }
  [data-pc-direction=rtl] .ms-xl-1 {
    margin-right: 0.25rem !important;
  }
  [data-pc-direction=rtl] .ms-xl-2 {
    margin-right: 0.5rem !important;
  }
  [data-pc-direction=rtl] .ms-xl-3 {
    margin-right: 1rem !important;
  }
  [data-pc-direction=rtl] .ms-xl-4 {
    margin-right: 1.5rem !important;
  }
  [data-pc-direction=rtl] .ms-xl-5 {
    margin-right: 3rem !important;
  }
  [data-pc-direction=rtl] .ms-xl-auto {
    margin-right: auto !important;
  }
  [data-pc-direction=rtl] .m-xl-n1 {
    margin: -0.25rem !important;
  }
  [data-pc-direction=rtl] .m-xl-n2 {
    margin: -0.5rem !important;
  }
  [data-pc-direction=rtl] .m-xl-n3 {
    margin: -1rem !important;
  }
  [data-pc-direction=rtl] .m-xl-n4 {
    margin: -1.5rem !important;
  }
  [data-pc-direction=rtl] .m-xl-n5 {
    margin: -3rem !important;
  }
  [data-pc-direction=rtl] .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  [data-pc-direction=rtl] .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  [data-pc-direction=rtl] .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=rtl] .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=rtl] .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=rtl] .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=rtl] .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=rtl] .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  [data-pc-direction=rtl] .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  [data-pc-direction=rtl] .me-xl-n1 {
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=rtl] .me-xl-n2 {
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=rtl] .me-xl-n3 {
    margin-left: -1rem !important;
  }
  [data-pc-direction=rtl] .me-xl-n4 {
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=rtl] .me-xl-n5 {
    margin-left: -3rem !important;
  }
  [data-pc-direction=rtl] .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=rtl] .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=rtl] .ms-xl-n1 {
    margin-right: -0.25rem !important;
  }
  [data-pc-direction=rtl] .ms-xl-n2 {
    margin-right: -0.5rem !important;
  }
  [data-pc-direction=rtl] .ms-xl-n3 {
    margin-right: -1rem !important;
  }
  [data-pc-direction=rtl] .ms-xl-n4 {
    margin-right: -1.5rem !important;
  }
  [data-pc-direction=rtl] .ms-xl-n5 {
    margin-right: -3rem !important;
  }
  [data-pc-direction=rtl] .p-xl-0 {
    padding: 0 !important;
  }
  [data-pc-direction=rtl] .p-xl-1 {
    padding: 0.25rem !important;
  }
  [data-pc-direction=rtl] .p-xl-2 {
    padding: 0.5rem !important;
  }
  [data-pc-direction=rtl] .p-xl-3 {
    padding: 1rem !important;
  }
  [data-pc-direction=rtl] .p-xl-4 {
    padding: 1.5rem !important;
  }
  [data-pc-direction=rtl] .p-xl-5 {
    padding: 3rem !important;
  }
  [data-pc-direction=rtl] .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [data-pc-direction=rtl] .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  [data-pc-direction=rtl] .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  [data-pc-direction=rtl] .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .pt-xl-0 {
    padding-top: 0 !important;
  }
  [data-pc-direction=rtl] .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pt-xl-3 {
    padding-top: 1rem !important;
  }
  [data-pc-direction=rtl] .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pt-xl-5 {
    padding-top: 3rem !important;
  }
  [data-pc-direction=rtl] .pe-xl-0 {
    padding-left: 0 !important;
  }
  [data-pc-direction=rtl] .pe-xl-1 {
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pe-xl-2 {
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pe-xl-3 {
    padding-left: 1rem !important;
  }
  [data-pc-direction=rtl] .pe-xl-4 {
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pe-xl-5 {
    padding-left: 3rem !important;
  }
  [data-pc-direction=rtl] .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .ps-xl-0 {
    padding-right: 0 !important;
  }
  [data-pc-direction=rtl] .ps-xl-1 {
    padding-right: 0.25rem !important;
  }
  [data-pc-direction=rtl] .ps-xl-2 {
    padding-right: 0.5rem !important;
  }
  [data-pc-direction=rtl] .ps-xl-3 {
    padding-right: 1rem !important;
  }
  [data-pc-direction=rtl] .ps-xl-4 {
    padding-right: 1.5rem !important;
  }
  [data-pc-direction=rtl] .ps-xl-5 {
    padding-right: 3rem !important;
  }
  [data-pc-direction=rtl] .text-xl-start {
    text-align: right !important;
  }
  [data-pc-direction=rtl] .text-xl-end {
    text-align: left !important;
  }
  [data-pc-direction=rtl] .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  [data-pc-direction=rtl] .float-xxl-start {
    float: right !important;
  }
  [data-pc-direction=rtl] .float-xxl-end {
    float: left !important;
  }
  [data-pc-direction=rtl] .float-xxl-none {
    float: none !important;
  }
  [data-pc-direction=rtl] .d-xxl-inline {
    display: inline !important;
  }
  [data-pc-direction=rtl] .d-xxl-inline-block {
    display: inline-block !important;
  }
  [data-pc-direction=rtl] .d-xxl-block {
    display: block !important;
  }
  [data-pc-direction=rtl] .d-xxl-grid {
    display: grid !important;
  }
  [data-pc-direction=rtl] .d-xxl-table {
    display: table !important;
  }
  [data-pc-direction=rtl] .d-xxl-table-row {
    display: table-row !important;
  }
  [data-pc-direction=rtl] .d-xxl-table-cell {
    display: table-cell !important;
  }
  [data-pc-direction=rtl] .d-xxl-flex {
    display: flex !important;
  }
  [data-pc-direction=rtl] .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  [data-pc-direction=rtl] .d-xxl-none {
    display: none !important;
  }
  [data-pc-direction=rtl] .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  [data-pc-direction=rtl] .flex-xxl-row {
    flex-direction: row !important;
  }
  [data-pc-direction=rtl] .flex-xxl-column {
    flex-direction: column !important;
  }
  [data-pc-direction=rtl] .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  [data-pc-direction=rtl] .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  [data-pc-direction=rtl] .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  [data-pc-direction=rtl] .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  [data-pc-direction=rtl] .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  [data-pc-direction=rtl] .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  [data-pc-direction=rtl] .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  [data-pc-direction=rtl] .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  [data-pc-direction=rtl] .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  [data-pc-direction=rtl] .gap-xxl-0 {
    gap: 0 !important;
  }
  [data-pc-direction=rtl] .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  [data-pc-direction=rtl] .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  [data-pc-direction=rtl] .gap-xxl-3 {
    gap: 1rem !important;
  }
  [data-pc-direction=rtl] .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  [data-pc-direction=rtl] .gap-xxl-5 {
    gap: 3rem !important;
  }
  [data-pc-direction=rtl] .justify-content-xxl-start {
    justify-content: flex-end !important;
  }
  [data-pc-direction=rtl] .justify-content-xxl-end {
    justify-content: flex-start !important;
  }
  [data-pc-direction=rtl] .justify-content-xxl-center {
    justify-content: center !important;
  }
  [data-pc-direction=rtl] .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  [data-pc-direction=rtl] .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  [data-pc-direction=rtl] .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  [data-pc-direction=rtl] .align-items-xxl-start {
    align-items: flex-start !important;
  }
  [data-pc-direction=rtl] .align-items-xxl-end {
    align-items: flex-end !important;
  }
  [data-pc-direction=rtl] .align-items-xxl-center {
    align-items: center !important;
  }
  [data-pc-direction=rtl] .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  [data-pc-direction=rtl] .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  [data-pc-direction=rtl] .align-content-xxl-start {
    align-content: flex-end !important;
  }
  [data-pc-direction=rtl] .align-content-xxl-end {
    align-content: flex-start !important;
  }
  [data-pc-direction=rtl] .align-content-xxl-center {
    align-content: center !important;
  }
  [data-pc-direction=rtl] .align-content-xxl-between {
    align-content: space-between !important;
  }
  [data-pc-direction=rtl] .align-content-xxl-around {
    align-content: space-around !important;
  }
  [data-pc-direction=rtl] .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  [data-pc-direction=rtl] .align-self-xxl-auto {
    align-self: auto !important;
  }
  [data-pc-direction=rtl] .align-self-xxl-start {
    align-self: flex-end !important;
  }
  [data-pc-direction=rtl] .align-self-xxl-end {
    align-self: flex-start !important;
  }
  [data-pc-direction=rtl] .align-self-xxl-center {
    align-self: center !important;
  }
  [data-pc-direction=rtl] .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  [data-pc-direction=rtl] .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  [data-pc-direction=rtl] .order-xxl-first {
    order: -1 !important;
  }
  [data-pc-direction=rtl] .order-xxl-0 {
    order: 0 !important;
  }
  [data-pc-direction=rtl] .order-xxl-1 {
    order: 1 !important;
  }
  [data-pc-direction=rtl] .order-xxl-2 {
    order: 2 !important;
  }
  [data-pc-direction=rtl] .order-xxl-3 {
    order: 3 !important;
  }
  [data-pc-direction=rtl] .order-xxl-4 {
    order: 4 !important;
  }
  [data-pc-direction=rtl] .order-xxl-5 {
    order: 5 !important;
  }
  [data-pc-direction=rtl] .order-xxl-last {
    order: 6 !important;
  }
  [data-pc-direction=rtl] .m-xxl-0 {
    margin: 0 !important;
  }
  [data-pc-direction=rtl] .m-xxl-1 {
    margin: 0.25rem !important;
  }
  [data-pc-direction=rtl] .m-xxl-2 {
    margin: 0.5rem !important;
  }
  [data-pc-direction=rtl] .m-xxl-3 {
    margin: 1rem !important;
  }
  [data-pc-direction=rtl] .m-xxl-4 {
    margin: 1.5rem !important;
  }
  [data-pc-direction=rtl] .m-xxl-5 {
    margin: 3rem !important;
  }
  [data-pc-direction=rtl] .m-xxl-auto {
    margin: auto !important;
  }
  [data-pc-direction=rtl] .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [data-pc-direction=rtl] .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  [data-pc-direction=rtl] .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  [data-pc-direction=rtl] .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  [data-pc-direction=rtl] .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  [data-pc-direction=rtl] .mt-xxl-0 {
    margin-top: 0 !important;
  }
  [data-pc-direction=rtl] .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  [data-pc-direction=rtl] .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  [data-pc-direction=rtl] .mt-xxl-auto {
    margin-top: auto !important;
  }
  [data-pc-direction=rtl] .me-xxl-0 {
    margin-left: 0 !important;
  }
  [data-pc-direction=rtl] .me-xxl-1 {
    margin-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .me-xxl-2 {
    margin-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .me-xxl-3 {
    margin-left: 1rem !important;
  }
  [data-pc-direction=rtl] .me-xxl-4 {
    margin-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .me-xxl-5 {
    margin-left: 3rem !important;
  }
  [data-pc-direction=rtl] .me-xxl-auto {
    margin-left: auto !important;
  }
  [data-pc-direction=rtl] .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  [data-pc-direction=rtl] .ms-xxl-0 {
    margin-right: 0 !important;
  }
  [data-pc-direction=rtl] .ms-xxl-1 {
    margin-right: 0.25rem !important;
  }
  [data-pc-direction=rtl] .ms-xxl-2 {
    margin-right: 0.5rem !important;
  }
  [data-pc-direction=rtl] .ms-xxl-3 {
    margin-right: 1rem !important;
  }
  [data-pc-direction=rtl] .ms-xxl-4 {
    margin-right: 1.5rem !important;
  }
  [data-pc-direction=rtl] .ms-xxl-5 {
    margin-right: 3rem !important;
  }
  [data-pc-direction=rtl] .ms-xxl-auto {
    margin-right: auto !important;
  }
  [data-pc-direction=rtl] .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  [data-pc-direction=rtl] .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  [data-pc-direction=rtl] .m-xxl-n3 {
    margin: -1rem !important;
  }
  [data-pc-direction=rtl] .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  [data-pc-direction=rtl] .m-xxl-n5 {
    margin: -3rem !important;
  }
  [data-pc-direction=rtl] .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  [data-pc-direction=rtl] .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  [data-pc-direction=rtl] .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=rtl] .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=rtl] .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=rtl] .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=rtl] .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=rtl] .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  [data-pc-direction=rtl] .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  [data-pc-direction=rtl] .me-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  [data-pc-direction=rtl] .me-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  [data-pc-direction=rtl] .me-xxl-n3 {
    margin-left: -1rem !important;
  }
  [data-pc-direction=rtl] .me-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  [data-pc-direction=rtl] .me-xxl-n5 {
    margin-left: -3rem !important;
  }
  [data-pc-direction=rtl] .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  [data-pc-direction=rtl] .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  [data-pc-direction=rtl] .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  [data-pc-direction=rtl] .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  [data-pc-direction=rtl] .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  [data-pc-direction=rtl] .ms-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  [data-pc-direction=rtl] .ms-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  [data-pc-direction=rtl] .ms-xxl-n3 {
    margin-right: -1rem !important;
  }
  [data-pc-direction=rtl] .ms-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  [data-pc-direction=rtl] .ms-xxl-n5 {
    margin-right: -3rem !important;
  }
  [data-pc-direction=rtl] .p-xxl-0 {
    padding: 0 !important;
  }
  [data-pc-direction=rtl] .p-xxl-1 {
    padding: 0.25rem !important;
  }
  [data-pc-direction=rtl] .p-xxl-2 {
    padding: 0.5rem !important;
  }
  [data-pc-direction=rtl] .p-xxl-3 {
    padding: 1rem !important;
  }
  [data-pc-direction=rtl] .p-xxl-4 {
    padding: 1.5rem !important;
  }
  [data-pc-direction=rtl] .p-xxl-5 {
    padding: 3rem !important;
  }
  [data-pc-direction=rtl] .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [data-pc-direction=rtl] .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  [data-pc-direction=rtl] .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  [data-pc-direction=rtl] .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .pt-xxl-0 {
    padding-top: 0 !important;
  }
  [data-pc-direction=rtl] .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  [data-pc-direction=rtl] .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  [data-pc-direction=rtl] .pe-xxl-0 {
    padding-left: 0 !important;
  }
  [data-pc-direction=rtl] .pe-xxl-1 {
    padding-left: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pe-xxl-2 {
    padding-left: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pe-xxl-3 {
    padding-left: 1rem !important;
  }
  [data-pc-direction=rtl] .pe-xxl-4 {
    padding-left: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pe-xxl-5 {
    padding-left: 3rem !important;
  }
  [data-pc-direction=rtl] .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  [data-pc-direction=rtl] .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  [data-pc-direction=rtl] .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  [data-pc-direction=rtl] .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  [data-pc-direction=rtl] .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  [data-pc-direction=rtl] .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  [data-pc-direction=rtl] .ps-xxl-0 {
    padding-right: 0 !important;
  }
  [data-pc-direction=rtl] .ps-xxl-1 {
    padding-right: 0.25rem !important;
  }
  [data-pc-direction=rtl] .ps-xxl-2 {
    padding-right: 0.5rem !important;
  }
  [data-pc-direction=rtl] .ps-xxl-3 {
    padding-right: 1rem !important;
  }
  [data-pc-direction=rtl] .ps-xxl-4 {
    padding-right: 1.5rem !important;
  }
  [data-pc-direction=rtl] .ps-xxl-5 {
    padding-right: 3rem !important;
  }
  [data-pc-direction=rtl] .text-xxl-start {
    text-align: right !important;
  }
  [data-pc-direction=rtl] .text-xxl-end {
    text-align: left !important;
  }
  [data-pc-direction=rtl] .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  [data-pc-direction=rtl] .fs-1 {
    font-size: 2.375rem !important;
  }
  [data-pc-direction=rtl] .fs-2 {
    font-size: 1.875rem !important;
  }
  [data-pc-direction=rtl] .fs-3 {
    font-size: 1.5rem !important;
  }
}
@media print {
  [data-pc-direction=rtl] .d-print-inline {
    display: inline !important;
  }
  [data-pc-direction=rtl] .d-print-inline-block {
    display: inline-block !important;
  }
  [data-pc-direction=rtl] .d-print-block {
    display: block !important;
  }
  [data-pc-direction=rtl] .d-print-grid {
    display: grid !important;
  }
  [data-pc-direction=rtl] .d-print-table {
    display: table !important;
  }
  [data-pc-direction=rtl] .d-print-table-row {
    display: table-row !important;
  }
  [data-pc-direction=rtl] .d-print-table-cell {
    display: table-cell !important;
  }
  [data-pc-direction=rtl] .d-print-flex {
    display: flex !important;
  }
  [data-pc-direction=rtl] .d-print-inline-flex {
    display: inline-flex !important;
  }
  [data-pc-direction=rtl] .d-print-none {
    display: none !important;
  }
}

/**  =====================
    1. Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
.p-0 {
  padding: 0px;
}
.p-t-0 {
  padding-top: 0px;
}
.p-b-0 {
  padding-bottom: 0px;
}
.p-l-0 {
  padding-left: 0px;
}
.p-r-0 {
  padding-right: 0px;
}

.m-0 {
  margin: 0px;
}
.m-t-0 {
  margin-top: 0px;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-l-0 {
  margin-left: 0px;
}
.m-r-0 {
  margin-right: 0px;
}

.p-5 {
  padding: 5px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-l-5 {
  padding-left: 5px;
}
.p-r-5 {
  padding-right: 5px;
}

.m-5 {
  margin: 5px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-r-5 {
  margin-right: 5px;
}

.p-10 {
  padding: 10px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-r-10 {
  padding-right: 10px;
}

.m-10 {
  margin: 10px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-r-10 {
  margin-right: 10px;
}

.p-15 {
  padding: 15px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-r-15 {
  padding-right: 15px;
}

.m-15 {
  margin: 15px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-r-15 {
  margin-right: 15px;
}

.p-20 {
  padding: 20px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-r-20 {
  padding-right: 20px;
}

.m-20 {
  margin: 20px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-r-20 {
  margin-right: 20px;
}

.p-25 {
  padding: 25px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-r-25 {
  padding-right: 25px;
}

.m-25 {
  margin: 25px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-r-25 {
  margin-right: 25px;
}

.p-30 {
  padding: 30px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-r-30 {
  padding-right: 30px;
}

.m-30 {
  margin: 30px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-r-30 {
  margin-right: 30px;
}

.p-35 {
  padding: 35px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-l-35 {
  padding-left: 35px;
}
.p-r-35 {
  padding-right: 35px;
}

.m-35 {
  margin: 35px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-l-35 {
  margin-left: 35px;
}
.m-r-35 {
  margin-right: 35px;
}

.p-40 {
  padding: 40px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-l-40 {
  padding-left: 40px;
}
.p-r-40 {
  padding-right: 40px;
}

.m-40 {
  margin: 40px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-r-40 {
  margin-right: 40px;
}

.p-45 {
  padding: 45px;
}
.p-t-45 {
  padding-top: 45px;
}
.p-b-45 {
  padding-bottom: 45px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-r-45 {
  padding-right: 45px;
}

.m-45 {
  margin: 45px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-r-45 {
  margin-right: 45px;
}

.p-50 {
  padding: 50px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-r-50 {
  padding-right: 50px;
}

.m-50 {
  margin: 50px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-l-50 {
  margin-left: 50px;
}
.m-r-50 {
  margin-right: 50px;
}

/*====== Padding , Margin css ends ======*/
/*====== Font-size css starts ======*/
.f-6 {
  font-size: 6px;
}

.f-8 {
  font-size: 8px;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-38 {
  font-size: 38px;
}

.f-40 {
  font-size: 40px;
}

.f-42 {
  font-size: 42px;
}

.f-44 {
  font-size: 44px;
}

.f-46 {
  font-size: 46px;
}

.f-48 {
  font-size: 48px;
}

.f-50 {
  font-size: 50px;
}

.f-52 {
  font-size: 52px;
}

.f-54 {
  font-size: 54px;
}

.f-56 {
  font-size: 56px;
}

.f-58 {
  font-size: 58px;
}

.f-60 {
  font-size: 60px;
}

.f-62 {
  font-size: 62px;
}

.f-64 {
  font-size: 64px;
}

.f-66 {
  font-size: 66px;
}

.f-68 {
  font-size: 68px;
}

.f-70 {
  font-size: 70px;
}

.f-72 {
  font-size: 72px;
}

.f-74 {
  font-size: 74px;
}

.f-76 {
  font-size: 76px;
}

.f-78 {
  font-size: 78px;
}

.f-80 {
  font-size: 80px;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100;
}

.f-w-200 {
  font-weight: 200;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-800 {
  font-weight: 800;
}

.f-w-900 {
  font-weight: 900;
}

/*====== Font-weight css ends ======*/
/*====== width, Height css starts ======*/
.wid-10 {
  width: 10px;
}

.hei-10 {
  height: 10px;
}

.wid-15 {
  width: 15px;
}

.hei-15 {
  height: 15px;
}

.wid-20 {
  width: 20px;
}

.hei-20 {
  height: 20px;
}

.wid-25 {
  width: 25px;
}

.hei-25 {
  height: 25px;
}

.wid-30 {
  width: 30px;
}

.hei-30 {
  height: 30px;
}

.wid-35 {
  width: 35px;
}

.hei-35 {
  height: 35px;
}

.wid-40 {
  width: 40px;
}

.hei-40 {
  height: 40px;
}

.wid-45 {
  width: 45px;
}

.hei-45 {
  height: 45px;
}

.wid-50 {
  width: 50px;
}

.hei-50 {
  height: 50px;
}

.wid-55 {
  width: 55px;
}

.hei-55 {
  height: 55px;
}

.wid-60 {
  width: 60px;
}

.hei-60 {
  height: 60px;
}

.wid-65 {
  width: 65px;
}

.hei-65 {
  height: 65px;
}

.wid-70 {
  width: 70px;
}

.hei-70 {
  height: 70px;
}

.wid-75 {
  width: 75px;
}

.hei-75 {
  height: 75px;
}

.wid-80 {
  width: 80px;
}

.hei-80 {
  height: 80px;
}

.wid-85 {
  width: 85px;
}

.hei-85 {
  height: 85px;
}

.wid-90 {
  width: 90px;
}

.hei-90 {
  height: 90px;
}

.wid-95 {
  width: 95px;
}

.hei-95 {
  height: 95px;
}

.wid-100 {
  width: 100px;
}

.hei-100 {
  height: 100px;
}

.wid-105 {
  width: 105px;
}

.hei-105 {
  height: 105px;
}

.wid-110 {
  width: 110px;
}

.hei-110 {
  height: 110px;
}

.wid-115 {
  width: 115px;
}

.hei-115 {
  height: 115px;
}

.wid-120 {
  width: 120px;
}

.hei-120 {
  height: 120px;
}

.wid-125 {
  width: 125px;
}

.hei-125 {
  height: 125px;
}

.wid-130 {
  width: 130px;
}

.hei-130 {
  height: 130px;
}

.wid-135 {
  width: 135px;
}

.hei-135 {
  height: 135px;
}

.wid-140 {
  width: 140px;
}

.hei-140 {
  height: 140px;
}

.wid-145 {
  width: 145px;
}

.hei-145 {
  height: 145px;
}

.wid-150 {
  width: 150px;
}

.hei-150 {
  height: 150px;
}

/*====== width, Height css ends ======*/
/*====== border-width css starts ======*/
.b-wid-1 {
  border-width: 1px;
}

.b-wid-2 {
  border-width: 2px;
}

.b-wid-3 {
  border-width: 3px;
}

.b-wid-4 {
  border-width: 4px;
}

.b-wid-5 {
  border-width: 5px;
}

.b-wid-6 {
  border-width: 6px;
}

.b-wid-7 {
  border-width: 7px;
}

.b-wid-8 {
  border-width: 8px;
}

/*====== border-width css ends ======*/
/*====== background starts ======*/
.text-header {
  color: var(--bs-heading-color);
}

.bg-body {
  background: var(--bs-body-bg);
}

.bg-light-theme {
  background: #ffebf0;
  color: #ff3366;
}

.icon-svg-theme {
  fill: #ffebf0;
  stroke: #ff3366;
}

.material-icons-two-tone.text-theme {
  background-color: #ff3366;
}

.text-hover-theme:hover {
  color: #ff3366 !important;
}

.bg-light-primary {
  background: #e8e9f2;
  color: #1A237E;
}

.icon-svg-primary {
  fill: #e8e9f2;
  stroke: #1A237E;
}

.material-icons-two-tone.text-primary {
  background-color: #1A237E;
}

.text-hover-primary:hover {
  color: #1A237E !important;
}

.bg-light-secondary {
  background: #eff0f2;
  color: #5b6b79;
}

.icon-svg-secondary {
  fill: #eff0f2;
  stroke: #5b6b79;
}

.material-icons-two-tone.text-secondary {
  background-color: #5b6b79;
}

.text-hover-secondary:hover {
  color: #5b6b79 !important;
}

.bg-light-success {
  background: #eaf6f2;
  color: #2ca87f;
}

.icon-svg-success {
  fill: #eaf6f2;
  stroke: #2ca87f;
}

.material-icons-two-tone.text-success {
  background-color: #2ca87f;
}

.text-hover-success:hover {
  color: #2ca87f !important;
}

.bg-light-info {
  background: #ecfafb;
  color: #3ec9d6;
}

.icon-svg-info {
  fill: #ecfafb;
  stroke: #3ec9d6;
}

.material-icons-two-tone.text-info {
  background-color: #3ec9d6;
}

.text-hover-info:hover {
  color: #3ec9d6 !important;
}

.bg-light-warning {
  background: #fcf3e6;
  color: #e58a00;
}

.icon-svg-warning {
  fill: #fcf3e6;
  stroke: #e58a00;
}

.material-icons-two-tone.text-warning {
  background-color: #e58a00;
}

.text-hover-warning:hover {
  color: #e58a00 !important;
}

.bg-light-danger {
  background: #fce9e9;
  color: #dc2626;
}

.icon-svg-danger {
  fill: #fce9e9;
  stroke: #dc2626;
}

.material-icons-two-tone.text-danger {
  background-color: #dc2626;
}

.text-hover-danger:hover {
  color: #dc2626 !important;
}

.bg-light-light {
  background: #fefeff;
  color: #f8f9fa;
}

.icon-svg-light {
  fill: #fefeff;
  stroke: #f8f9fa;
}

.material-icons-two-tone.text-light {
  background-color: #f8f9fa;
}

.text-hover-light:hover {
  color: #f8f9fa !important;
}

.bg-light-dark {
  background: #e9e9ea;
  color: #212529;
}

.icon-svg-dark {
  fill: #e9e9ea;
  stroke: #212529;
}

.material-icons-two-tone.text-dark {
  background-color: #212529;
}

.text-hover-dark:hover {
  color: #212529 !important;
}

/*====== background ends ======*/
/*====== border color css starts ======*/
.b-theme {
  border: 1px solid #ff3366;
}

.border-bottom-theme td {
  border-bottom: 1px solid #ff3366;
}

.border-bottom-theme th {
  border-bottom: 1px solid #ff3366 !important;
}

.fill-theme {
  fill: #ff3366;
}

.b-primary {
  border: 1px solid #1A237E;
}

.border-bottom-primary td {
  border-bottom: 1px solid #1A237E;
}

.border-bottom-primary th {
  border-bottom: 1px solid #1A237E !important;
}

.fill-primary {
  fill: #1A237E;
}

.b-secondary {
  border: 1px solid #5b6b79;
}

.border-bottom-secondary td {
  border-bottom: 1px solid #5b6b79;
}

.border-bottom-secondary th {
  border-bottom: 1px solid #5b6b79 !important;
}

.fill-secondary {
  fill: #5b6b79;
}

.b-success {
  border: 1px solid #2ca87f;
}

.border-bottom-success td {
  border-bottom: 1px solid #2ca87f;
}

.border-bottom-success th {
  border-bottom: 1px solid #2ca87f !important;
}

.fill-success {
  fill: #2ca87f;
}

.b-info {
  border: 1px solid #3ec9d6;
}

.border-bottom-info td {
  border-bottom: 1px solid #3ec9d6;
}

.border-bottom-info th {
  border-bottom: 1px solid #3ec9d6 !important;
}

.fill-info {
  fill: #3ec9d6;
}

.b-warning {
  border: 1px solid #e58a00;
}

.border-bottom-warning td {
  border-bottom: 1px solid #e58a00;
}

.border-bottom-warning th {
  border-bottom: 1px solid #e58a00 !important;
}

.fill-warning {
  fill: #e58a00;
}

.b-danger {
  border: 1px solid #dc2626;
}

.border-bottom-danger td {
  border-bottom: 1px solid #dc2626;
}

.border-bottom-danger th {
  border-bottom: 1px solid #dc2626 !important;
}

.fill-danger {
  fill: #dc2626;
}

.b-light {
  border: 1px solid #f8f9fa;
}

.border-bottom-light td {
  border-bottom: 1px solid #f8f9fa;
}

.border-bottom-light th {
  border-bottom: 1px solid #f8f9fa !important;
}

.fill-light {
  fill: #f8f9fa;
}

.b-dark {
  border: 1px solid #212529;
}

.border-bottom-dark td {
  border-bottom: 1px solid #212529;
}

.border-bottom-dark th {
  border-bottom: 1px solid #212529 !important;
}

.fill-dark {
  fill: #212529;
}

/*====== border color css ends ======*/
/*====== text-color, background color css starts ======*/
.bg-light-facebook {
  background: #ecf0f7;
  color: #4267b2;
}

.bg-facebook {
  background: #4267b2;
}

.text-facebook {
  color: #4267b2;
}

.bg-light-twitter {
  background: #ecf9ff;
  color: #42c0fb;
}

.bg-twitter {
  background: #42c0fb;
}

.text-twitter {
  color: #42c0fb;
}

.bg-light-dribbble {
  background: #fdedf3;
  color: #ec4a89;
}

.bg-dribbble {
  background: #ec4a89;
}

.text-dribbble {
  color: #ec4a89;
}

.bg-light-pinterest {
  background: #f9e9ea;
  color: #bf2131;
}

.bg-pinterest {
  background: #bf2131;
}

.text-pinterest {
  color: #bf2131;
}

.bg-light-youtube {
  background: #fceae8;
  color: #e0291d;
}

.bg-youtube {
  background: #e0291d;
}

.text-youtube {
  color: #e0291d;
}

.bg-light-googleplus {
  background: #f9ecea;
  color: #c73e2e;
}

.bg-googleplus {
  background: #c73e2e;
}

.text-googleplus {
  color: #c73e2e;
}

.bg-light-instagram {
  background: #f7f2ef;
  color: #aa7c62;
}

.bg-instagram {
  background: #aa7c62;
}

.text-instagram {
  color: #aa7c62;
}

.bg-light-viber {
  background: #f2eef5;
  color: #7b519d;
}

.bg-viber {
  background: #7b519d;
}

.text-viber {
  color: #7b519d;
}

.bg-light-behance {
  background: #e6eeff;
  color: #0057ff;
}

.bg-behance {
  background: #0057ff;
}

.text-behance {
  color: #0057ff;
}

.bg-light-dropbox {
  background: #ebf2ff;
  color: #3380ff;
}

.bg-dropbox {
  background: #3380ff;
}

.text-dropbox {
  color: #3380ff;
}

.bg-light-linkedin {
  background: #e6f1f8;
  color: #0077b5;
}

.bg-linkedin {
  background: #0077b5;
}

.text-linkedin {
  color: #0077b5;
}

.bg-light-amazon {
  background: #e6e6e6;
  color: #000000;
}

.bg-amazon {
  background: #000000;
}

.text-amazon {
  color: #000000;
}

/*====== text-color css ends ======*/
.font-hairline {
  font-weight: 100 !important;
}

.font-thin {
  font-weight: 200 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.font-black {
  font-weight: 900 !important;
}

.text-xs {
  font-size: 0.625rem !important;
}

.text-sm {
  font-size: 0.75rem !important;
}

.text-md {
  font-size: 0.8125rem !important;
}

.text-base {
  font-size: 0.875rem !important;
}

.text-lg {
  font-size: 1rem !important;
}

.text-xl {
  font-size: 1.125rem !important;
}

.text-2xl {
  font-size: 1.25rem !important;
}

.text-3xl {
  font-size: 1.5rem !important;
}

.text-4xl {
  font-size: 2rem !important;
}

.text-5xl {
  font-size: 2.25rem !important;
}

.text-6xl {
  font-size: 2.5rem !important;
}

.text-7xl {
  font-size: 3rem !important;
}

.text-8xl {
  font-size: 4rem !important;
}

.text-9xl {
  font-size: 6rem !important;
}

.text-10xl {
  font-size: 8rem !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.normal-case {
  text-transform: none !important;
}

/*====== more bootstrap colors start ======*/
.bg-blue-100 {
  background: var(--bs-blue-100);
}

.text-blue-100 {
  color: var(--bs-blue-100);
}

.bg-blue-200 {
  background: var(--bs-blue-200);
}

.text-blue-200 {
  color: var(--bs-blue-200);
}

.bg-blue-300 {
  background: var(--bs-blue-300);
}

.text-blue-300 {
  color: var(--bs-blue-300);
}

.bg-blue-400 {
  background: var(--bs-blue-400);
}

.text-blue-400 {
  color: var(--bs-blue-400);
}

.bg-blue-500 {
  background: var(--bs-blue-500);
}

.text-blue-500 {
  color: var(--bs-blue-500);
}

.bg-blue-600 {
  background: var(--bs-blue-600);
}

.text-blue-600 {
  color: var(--bs-blue-600);
}

.bg-blue-700 {
  background: var(--bs-blue-700);
}

.text-blue-700 {
  color: var(--bs-blue-700);
}

.bg-blue-800 {
  background: var(--bs-blue-800);
}

.text-blue-800 {
  color: var(--bs-blue-800);
}

.bg-blue-900 {
  background: var(--bs-blue-900);
}

.text-blue-900 {
  color: var(--bs-blue-900);
}

.bg-indigo-100 {
  background: var(--bs-indigo-100);
}

.text-indigo-100 {
  color: var(--bs-indigo-100);
}

.bg-indigo-200 {
  background: var(--bs-indigo-200);
}

.text-indigo-200 {
  color: var(--bs-indigo-200);
}

.bg-indigo-300 {
  background: var(--bs-indigo-300);
}

.text-indigo-300 {
  color: var(--bs-indigo-300);
}

.bg-indigo-400 {
  background: var(--bs-indigo-400);
}

.text-indigo-400 {
  color: var(--bs-indigo-400);
}

.bg-indigo-500 {
  background: var(--bs-indigo-500);
}

.text-indigo-500 {
  color: var(--bs-indigo-500);
}

.bg-indigo-600 {
  background: var(--bs-indigo-600);
}

.text-indigo-600 {
  color: var(--bs-indigo-600);
}

.bg-indigo-700 {
  background: var(--bs-indigo-700);
}

.text-indigo-700 {
  color: var(--bs-indigo-700);
}

.bg-indigo-800 {
  background: var(--bs-indigo-800);
}

.text-indigo-800 {
  color: var(--bs-indigo-800);
}

.bg-indigo-900 {
  background: var(--bs-indigo-900);
}

.text-indigo-900 {
  color: var(--bs-indigo-900);
}

.bg-purple-100 {
  background: var(--bs-purple-100);
}

.text-purple-100 {
  color: var(--bs-purple-100);
}

.bg-purple-200 {
  background: var(--bs-purple-200);
}

.text-purple-200 {
  color: var(--bs-purple-200);
}

.bg-purple-300 {
  background: var(--bs-purple-300);
}

.text-purple-300 {
  color: var(--bs-purple-300);
}

.bg-purple-400 {
  background: var(--bs-purple-400);
}

.text-purple-400 {
  color: var(--bs-purple-400);
}

.bg-purple-500 {
  background: var(--bs-purple-500);
}

.text-purple-500 {
  color: var(--bs-purple-500);
}

.bg-purple-600 {
  background: var(--bs-purple-600);
}

.text-purple-600 {
  color: var(--bs-purple-600);
}

.bg-purple-700 {
  background: var(--bs-purple-700);
}

.text-purple-700 {
  color: var(--bs-purple-700);
}

.bg-purple-800 {
  background: var(--bs-purple-800);
}

.text-purple-800 {
  color: var(--bs-purple-800);
}

.bg-purple-900 {
  background: var(--bs-purple-900);
}

.text-purple-900 {
  color: var(--bs-purple-900);
}

.bg-pink-100 {
  background: var(--bs-pink-100);
}

.text-pink-100 {
  color: var(--bs-pink-100);
}

.bg-pink-200 {
  background: var(--bs-pink-200);
}

.text-pink-200 {
  color: var(--bs-pink-200);
}

.bg-pink-300 {
  background: var(--bs-pink-300);
}

.text-pink-300 {
  color: var(--bs-pink-300);
}

.bg-pink-400 {
  background: var(--bs-pink-400);
}

.text-pink-400 {
  color: var(--bs-pink-400);
}

.bg-pink-500 {
  background: var(--bs-pink-500);
}

.text-pink-500 {
  color: var(--bs-pink-500);
}

.bg-pink-600 {
  background: var(--bs-pink-600);
}

.text-pink-600 {
  color: var(--bs-pink-600);
}

.bg-pink-700 {
  background: var(--bs-pink-700);
}

.text-pink-700 {
  color: var(--bs-pink-700);
}

.bg-pink-800 {
  background: var(--bs-pink-800);
}

.text-pink-800 {
  color: var(--bs-pink-800);
}

.bg-pink-900 {
  background: var(--bs-pink-900);
}

.text-pink-900 {
  color: var(--bs-pink-900);
}

.bg-red-100 {
  background: var(--bs-red-100);
}

.text-red-100 {
  color: var(--bs-red-100);
}

.bg-red-200 {
  background: var(--bs-red-200);
}

.text-red-200 {
  color: var(--bs-red-200);
}

.bg-red-300 {
  background: var(--bs-red-300);
}

.text-red-300 {
  color: var(--bs-red-300);
}

.bg-red-400 {
  background: var(--bs-red-400);
}

.text-red-400 {
  color: var(--bs-red-400);
}

.bg-red-500 {
  background: var(--bs-red-500);
}

.text-red-500 {
  color: var(--bs-red-500);
}

.bg-red-600 {
  background: var(--bs-red-600);
}

.text-red-600 {
  color: var(--bs-red-600);
}

.bg-red-700 {
  background: var(--bs-red-700);
}

.text-red-700 {
  color: var(--bs-red-700);
}

.bg-red-800 {
  background: var(--bs-red-800);
}

.text-red-800 {
  color: var(--bs-red-800);
}

.bg-red-900 {
  background: var(--bs-red-900);
}

.text-red-900 {
  color: var(--bs-red-900);
}

.bg-orange-100 {
  background: var(--bs-orange-100);
}

.text-orange-100 {
  color: var(--bs-orange-100);
}

.bg-orange-200 {
  background: var(--bs-orange-200);
}

.text-orange-200 {
  color: var(--bs-orange-200);
}

.bg-orange-300 {
  background: var(--bs-orange-300);
}

.text-orange-300 {
  color: var(--bs-orange-300);
}

.bg-orange-400 {
  background: var(--bs-orange-400);
}

.text-orange-400 {
  color: var(--bs-orange-400);
}

.bg-orange-500 {
  background: var(--bs-orange-500);
}

.text-orange-500 {
  color: var(--bs-orange-500);
}

.bg-orange-600 {
  background: var(--bs-orange-600);
}

.text-orange-600 {
  color: var(--bs-orange-600);
}

.bg-orange-700 {
  background: var(--bs-orange-700);
}

.text-orange-700 {
  color: var(--bs-orange-700);
}

.bg-orange-800 {
  background: var(--bs-orange-800);
}

.text-orange-800 {
  color: var(--bs-orange-800);
}

.bg-orange-900 {
  background: var(--bs-orange-900);
}

.text-orange-900 {
  color: var(--bs-orange-900);
}

.bg-yellow-100 {
  background: var(--bs-yellow-100);
}

.text-yellow-100 {
  color: var(--bs-yellow-100);
}

.bg-yellow-200 {
  background: var(--bs-yellow-200);
}

.text-yellow-200 {
  color: var(--bs-yellow-200);
}

.bg-yellow-300 {
  background: var(--bs-yellow-300);
}

.text-yellow-300 {
  color: var(--bs-yellow-300);
}

.bg-yellow-400 {
  background: var(--bs-yellow-400);
}

.text-yellow-400 {
  color: var(--bs-yellow-400);
}

.bg-yellow-500 {
  background: var(--bs-yellow-500);
}

.text-yellow-500 {
  color: var(--bs-yellow-500);
}

.bg-yellow-600 {
  background: var(--bs-yellow-600);
}

.text-yellow-600 {
  color: var(--bs-yellow-600);
}

.bg-yellow-700 {
  background: var(--bs-yellow-700);
}

.text-yellow-700 {
  color: var(--bs-yellow-700);
}

.bg-yellow-800 {
  background: var(--bs-yellow-800);
}

.text-yellow-800 {
  color: var(--bs-yellow-800);
}

.bg-yellow-900 {
  background: var(--bs-yellow-900);
}

.text-yellow-900 {
  color: var(--bs-yellow-900);
}

.bg-green-100 {
  background: var(--bs-green-100);
}

.text-green-100 {
  color: var(--bs-green-100);
}

.bg-green-200 {
  background: var(--bs-green-200);
}

.text-green-200 {
  color: var(--bs-green-200);
}

.bg-green-300 {
  background: var(--bs-green-300);
}

.text-green-300 {
  color: var(--bs-green-300);
}

.bg-green-400 {
  background: var(--bs-green-400);
}

.text-green-400 {
  color: var(--bs-green-400);
}

.bg-green-500 {
  background: var(--bs-green-500);
}

.text-green-500 {
  color: var(--bs-green-500);
}

.bg-green-600 {
  background: var(--bs-green-600);
}

.text-green-600 {
  color: var(--bs-green-600);
}

.bg-green-700 {
  background: var(--bs-green-700);
}

.text-green-700 {
  color: var(--bs-green-700);
}

.bg-green-800 {
  background: var(--bs-green-800);
}

.text-green-800 {
  color: var(--bs-green-800);
}

.bg-green-900 {
  background: var(--bs-green-900);
}

.text-green-900 {
  color: var(--bs-green-900);
}

.bg-teal-100 {
  background: var(--bs-teal-100);
}

.text-teal-100 {
  color: var(--bs-teal-100);
}

.bg-teal-200 {
  background: var(--bs-teal-200);
}

.text-teal-200 {
  color: var(--bs-teal-200);
}

.bg-teal-300 {
  background: var(--bs-teal-300);
}

.text-teal-300 {
  color: var(--bs-teal-300);
}

.bg-teal-400 {
  background: var(--bs-teal-400);
}

.text-teal-400 {
  color: var(--bs-teal-400);
}

.bg-teal-500 {
  background: var(--bs-teal-500);
}

.text-teal-500 {
  color: var(--bs-teal-500);
}

.bg-teal-600 {
  background: var(--bs-teal-600);
}

.text-teal-600 {
  color: var(--bs-teal-600);
}

.bg-teal-700 {
  background: var(--bs-teal-700);
}

.text-teal-700 {
  color: var(--bs-teal-700);
}

.bg-teal-800 {
  background: var(--bs-teal-800);
}

.text-teal-800 {
  color: var(--bs-teal-800);
}

.bg-teal-900 {
  background: var(--bs-teal-900);
}

.text-teal-900 {
  color: var(--bs-teal-900);
}

.bg-cyan-100 {
  background: var(--bs-cyan-100);
}

.text-cyan-100 {
  color: var(--bs-cyan-100);
}

.bg-cyan-200 {
  background: var(--bs-cyan-200);
}

.text-cyan-200 {
  color: var(--bs-cyan-200);
}

.bg-cyan-300 {
  background: var(--bs-cyan-300);
}

.text-cyan-300 {
  color: var(--bs-cyan-300);
}

.bg-cyan-400 {
  background: var(--bs-cyan-400);
}

.text-cyan-400 {
  color: var(--bs-cyan-400);
}

.bg-cyan-500 {
  background: var(--bs-cyan-500);
}

.text-cyan-500 {
  color: var(--bs-cyan-500);
}

.bg-cyan-600 {
  background: var(--bs-cyan-600);
}

.text-cyan-600 {
  color: var(--bs-cyan-600);
}

.bg-cyan-700 {
  background: var(--bs-cyan-700);
}

.text-cyan-700 {
  color: var(--bs-cyan-700);
}

.bg-cyan-800 {
  background: var(--bs-cyan-800);
}

.text-cyan-800 {
  color: var(--bs-cyan-800);
}

.bg-cyan-900 {
  background: var(--bs-cyan-900);
}

.text-cyan-900 {
  color: var(--bs-cyan-900);
}

.bg-gray-100 {
  background: var(--bs-gray-100);
}

.text-gray-100 {
  color: var(--bs-gray-100);
}

.bg-gray-200 {
  background: var(--bs-gray-200);
}

.text-gray-200 {
  color: var(--bs-gray-200);
}

.bg-gray-300 {
  background: var(--bs-gray-300);
}

.text-gray-300 {
  color: var(--bs-gray-300);
}

.bg-gray-400 {
  background: var(--bs-gray-400);
}

.text-gray-400 {
  color: var(--bs-gray-400);
}

.bg-gray-500 {
  background: var(--bs-gray-500);
}

.text-gray-500 {
  color: var(--bs-gray-500);
}

.bg-gray-600 {
  background: var(--bs-gray-600);
}

.text-gray-600 {
  color: var(--bs-gray-600);
}

.bg-gray-700 {
  background: var(--bs-gray-700);
}

.text-gray-700 {
  color: var(--bs-gray-700);
}

.bg-gray-800 {
  background: var(--bs-gray-800);
}

.text-gray-800 {
  color: var(--bs-gray-800);
}

.bg-gray-900 {
  background: var(--bs-gray-900);
}

.text-gray-900 {
  color: var(--bs-gray-900);
}

:root {
  --bs-blue-100: #757bb1;
  --bs-blue-200: #5e65a4;
  --bs-blue-300: #474f97;
  --bs-blue-400: #30398a;
  --bs-blue-500: #1A237E;
  --bs-blue-600: #171f71;
  --bs-blue-700: #141c64;
  --bs-blue-800: #121858;
  --bs-blue-900: #0f154b;
  --bs-indigo-100: #d1b7fb;
  --bs-indigo-200: #b388f9;
  --bs-indigo-300: #9458f6;
  --bs-indigo-400: #7d34f4;
  --bs-indigo-500: #6610f2;
  --bs-indigo-600: #5e0ef0;
  --bs-indigo-700: #530cee;
  --bs-indigo-800: #4909ec;
  --bs-indigo-900: #3805e8;
  --bs-purple-100: #d1c4e9;
  --bs-purple-200: #b39ddb;
  --bs-purple-300: #9575cd;
  --bs-purple-400: #7e58c2;
  --bs-purple-500: #673ab7;
  --bs-purple-600: #5f34b0;
  --bs-purple-700: #542ca7;
  --bs-purple-800: #4a259f;
  --bs-purple-900: #391890;
  --bs-pink-100: #f8c5dd;
  --bs-pink-200: #f49fc6;
  --bs-pink-300: #ef78af;
  --bs-pink-400: #eb5b9d;
  --bs-pink-500: #e83e8c;
  --bs-pink-600: #e53884;
  --bs-pink-700: #e23079;
  --bs-pink-800: #de286f;
  --bs-pink-900: #d81b5c;
  --bs-red-100: #f5bebe;
  --bs-red-200: #ee9393;
  --bs-red-300: #e76767;
  --bs-red-400: #e14747;
  --bs-red-500: #dc2626;
  --bs-red-600: #d82222;
  --bs-red-700: #d31c1c;
  --bs-red-800: #ce1717;
  --bs-red-900: #c50d0d;
  --bs-orange-100: #fed8b9;
  --bs-orange-200: #febf8a;
  --bs-orange-300: #fea55b;
  --bs-orange-400: #fd9137;
  --bs-orange-500: #fd7e14;
  --bs-orange-600: #fd7612;
  --bs-orange-700: #fc6b0e;
  --bs-orange-800: #fc610b;
  --bs-orange-900: #fc4e06;
  --bs-yellow-100: #f7dcb3;
  --bs-yellow-200: #f2c580;
  --bs-yellow-300: #edad4d;
  --bs-yellow-400: #e99c26;
  --bs-yellow-500: #e58a00;
  --bs-yellow-600: #e28200;
  --bs-yellow-700: #de7700;
  --bs-yellow-800: #da6d00;
  --bs-yellow-900: #d35a00;
  --bs-green-100: #c0e5d9;
  --bs-green-200: #96d4bf;
  --bs-green-300: #6bc2a5;
  --bs-green-400: #4cb592;
  --bs-green-500: #2ca87f;
  --bs-green-600: #27a077;
  --bs-green-700: #21976c;
  --bs-green-800: #1b8d62;
  --bs-green-900: #107d4f;
  --bs-teal-100: #b3d9d9;
  --bs-teal-200: #80c0c0;
  --bs-teal-300: #4da6a6;
  --bs-teal-400: #269393;
  --bs-teal-500: #008080;
  --bs-teal-600: #007878;
  --bs-teal-700: #006d6d;
  --bs-teal-800: #006363;
  --bs-teal-900: #005050;
  --bs-cyan-100: #c5eff3;
  --bs-cyan-200: #9fe4eb;
  --bs-cyan-300: #78d9e2;
  --bs-cyan-400: #5bd1dc;
  --bs-cyan-500: #3ec9d6;
  --bs-cyan-600: #38c3d1;
  --bs-cyan-700: #30bccc;
  --bs-cyan-800: #28b5c6;
  --bs-cyan-900: #1ba9bc;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #f3f5f7;
  --bs-gray-300: #dbe0e5;
  --bs-gray-400: #bec8d0;
  --bs-gray-500: #8996a4;
  --bs-gray-600: #5b6b79;
  --bs-gray-700: #3e4853;
  --bs-gray-800: #1d2630;
  --bs-gray-900: #131920;
}

/*====== more bootstrap colors end ======*/
/**  =====================
    1. Generic-class css end
========================== **/
/**  =====================
    2. Custom css start
==========================  **/
*:focus {
  outline: none;
}

.accordion {
  --bs-accordion-color: #131920;
}

a:hover {
  outline: none;
  text-decoration: none;
}
a:not([href]) {
  color: inherit;
}

p {
  font-size: 14px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  color: var(--pc-heading-color);
}

b,
strong {
  font-weight: 600;
}

.breadcrumb-default-icon .breadcrumb-item + .breadcrumb-item::before {
  position: relative;
  top: 2px;
}

.btn-page .btn, .btn-page .introjs-tooltip .introjs-button, .introjs-tooltip .btn-page .introjs-button {
  margin-right: 5px;
  margin-bottom: 5px;
}
.btn-page .btn-group .btn, .btn-page .btn-group .introjs-tooltip .introjs-button, .introjs-tooltip .btn-page .btn-group .introjs-button {
  margin-right: 0;
  margin-bottom: 0;
}
.btn-page .btn-group .btn:last-child, .btn-page .btn-group .introjs-tooltip .introjs-button:last-child, .introjs-tooltip .btn-page .btn-group .introjs-button:last-child {
  border-left: none;
}
.btn-page .btn-group label:first-of-type {
  border-right: none;
}

.material-icons-two-tone {
  background-color: var(--bs-body-color);
  -webkit-text-fill-color: transparent;
  vertical-align: text-bottom;
  -webkit-background-clip: text;
}
.material-icons-two-tone.text-white {
  background-color: #fff;
}

.img-radius {
  border-radius: 50%;
}

.pc-icon:not([class*=wid-]) {
  width: 22px;
}
.pc-icon:not([class*=hei-]) {
  height: 22px;
}

.peity-full-width .peity {
  width: 100%;
  height: 125px;
}

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34;
}

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
  color: #c678dd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
  color: #e06c75;
}

.hljs-literal {
  color: #56b6c2;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
  color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e6c07b;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #d19a66;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: #61aeee;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}

/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/
.bd-example-row {
  background: #f8f9fa;
  padding: 15px;
  margin-bottom: 15px;
}
.bd-example-row .row > [class^=col-],
.bd-example-row .row > .col {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}
.bd-example-row .row + .row {
  margin-top: 1rem;
}
.bd-example-row .flex-items-bottom,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-top {
  min-height: 6rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

/* Docs examples */
.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem;
  border: solid #ddd;
  border-width: 0.2rem 0 0;
}
@media only screen and (max-height: 575px) {
  .bd-example {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2rem;
  }
}

/* Example modals */
.modal {
  z-index: 1081;
}
.modal .popover,
.modal .tooltip {
  z-index: 1073;
}

.modal-backdrop {
  z-index: 1071;
}

.bd-example-modal {
  background-color: var(--bs-body-bg);
}
.bd-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}
.bd-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 0 20px;
}
.bd-example-modal .offcanvas {
  position: static;
  display: block;
  height: 200px;
  visibility: visible;
  transform: translate(0);
}

.modal .bd-example-row {
  padding: 15px 25px;
}

/* Example tabbable tabs */
.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}

/* Code snippets */
.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f3f2fb;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
@media only screen and (max-height: 575px) {
  .highlight {
    padding: 1.5rem;
  }
}

.bd-content .highlight {
  margin-right: 15px;
  margin-left: 15px;
}
@media only screen and (max-height: 575px) {
  .bd-content .highlight {
    margin-right: 0;
    margin-left: 0;
  }
}

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
}
.highlight pre code {
  font-size: inherit;
  color: #333;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: transparent;
}

/* ================================    Blockquote Start  ===================== */
svg.feather:not([class*=wid-]) {
  width: 20px;
}
svg.feather:not([class*=hei-]) {
  height: 20px;
}

@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}
pre[class*=language-]:before,
pre[class*=language-]:after {
  display: none;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  display: flex;
  margin: 0;
}

pre[class*=language-] > code {
  width: 100%;
}

.blockquote {
  padding: 0.5rem 1rem;
}

/* ================================    Blockquote End  ===================== */
.fixed-button {
  position: fixed;
  bottom: -50px;
  right: 30px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  opacity: 0;
  z-index: 9;
  transition: all 0.5s ease;
}

.fixed-button.active {
  bottom: 50px;
  opacity: 1;
}

.color-card .card-body {
  margin: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  background: rgba(107, 117, 125, 0.08);
  border-radius: 8px;
}

.color-block {
  border-radius: 8px;
  margin: 4px 0;
}
.color-block.bg-blue-100 {
  color: #ffffff;
}
.color-block.text-blue-100 {
  background-color: #ffffff;
}
.color-block.bg-blue-200 {
  color: #ffffff;
}
.color-block.text-blue-200 {
  background-color: #ffffff;
}
.color-block.bg-blue-300 {
  color: #ffffff;
}
.color-block.text-blue-300 {
  background-color: #ffffff;
}
.color-block.bg-blue-400 {
  color: #ffffff;
}
.color-block.text-blue-400 {
  background-color: #ffffff;
}
.color-block.bg-blue-500 {
  color: #ffffff;
}
.color-block.text-blue-500 {
  background-color: #ffffff;
}
.color-block.bg-blue-600 {
  color: #ffffff;
}
.color-block.text-blue-600 {
  background-color: #ffffff;
}
.color-block.bg-blue-700 {
  color: #ffffff;
}
.color-block.text-blue-700 {
  background-color: #ffffff;
}
.color-block.bg-blue-800 {
  color: #ffffff;
}
.color-block.text-blue-800 {
  background-color: #ffffff;
}
.color-block.bg-blue-900 {
  color: #ffffff;
}
.color-block.text-blue-900 {
  background-color: #ffffff;
}
.color-block.bg-indigo-100 {
  color: #ffffff;
}
.color-block.text-indigo-100 {
  background-color: #ffffff;
}
.color-block.bg-indigo-200 {
  color: #ffffff;
}
.color-block.text-indigo-200 {
  background-color: #ffffff;
}
.color-block.bg-indigo-300 {
  color: #ffffff;
}
.color-block.text-indigo-300 {
  background-color: #ffffff;
}
.color-block.bg-indigo-400 {
  color: #ffffff;
}
.color-block.text-indigo-400 {
  background-color: #ffffff;
}
.color-block.bg-indigo-500 {
  color: #ffffff;
}
.color-block.text-indigo-500 {
  background-color: #ffffff;
}
.color-block.bg-indigo-600 {
  color: #ffffff;
}
.color-block.text-indigo-600 {
  background-color: #ffffff;
}
.color-block.bg-indigo-700 {
  color: #ffffff;
}
.color-block.text-indigo-700 {
  background-color: #ffffff;
}
.color-block.bg-indigo-800 {
  color: #ffffff;
}
.color-block.text-indigo-800 {
  background-color: #ffffff;
}
.color-block.bg-indigo-900 {
  color: #ffffff;
}
.color-block.text-indigo-900 {
  background-color: #ffffff;
}
.color-block.bg-purple-100 {
  color: #ffffff;
}
.color-block.text-purple-100 {
  background-color: #ffffff;
}
.color-block.bg-purple-200 {
  color: #ffffff;
}
.color-block.text-purple-200 {
  background-color: #ffffff;
}
.color-block.bg-purple-300 {
  color: #ffffff;
}
.color-block.text-purple-300 {
  background-color: #ffffff;
}
.color-block.bg-purple-400 {
  color: #ffffff;
}
.color-block.text-purple-400 {
  background-color: #ffffff;
}
.color-block.bg-purple-500 {
  color: #ffffff;
}
.color-block.text-purple-500 {
  background-color: #ffffff;
}
.color-block.bg-purple-600 {
  color: #ffffff;
}
.color-block.text-purple-600 {
  background-color: #ffffff;
}
.color-block.bg-purple-700 {
  color: #ffffff;
}
.color-block.text-purple-700 {
  background-color: #ffffff;
}
.color-block.bg-purple-800 {
  color: #ffffff;
}
.color-block.text-purple-800 {
  background-color: #ffffff;
}
.color-block.bg-purple-900 {
  color: #ffffff;
}
.color-block.text-purple-900 {
  background-color: #ffffff;
}
.color-block.bg-pink-100 {
  color: #212529;
}
.color-block.text-pink-100 {
  background-color: #212529;
}
.color-block.bg-pink-200 {
  color: #ffffff;
}
.color-block.text-pink-200 {
  background-color: #ffffff;
}
.color-block.bg-pink-300 {
  color: #ffffff;
}
.color-block.text-pink-300 {
  background-color: #ffffff;
}
.color-block.bg-pink-400 {
  color: #ffffff;
}
.color-block.text-pink-400 {
  background-color: #ffffff;
}
.color-block.bg-pink-500 {
  color: #ffffff;
}
.color-block.text-pink-500 {
  background-color: #ffffff;
}
.color-block.bg-pink-600 {
  color: #ffffff;
}
.color-block.text-pink-600 {
  background-color: #ffffff;
}
.color-block.bg-pink-700 {
  color: #ffffff;
}
.color-block.text-pink-700 {
  background-color: #ffffff;
}
.color-block.bg-pink-800 {
  color: #ffffff;
}
.color-block.text-pink-800 {
  background-color: #ffffff;
}
.color-block.bg-pink-900 {
  color: #ffffff;
}
.color-block.text-pink-900 {
  background-color: #ffffff;
}
.color-block.bg-red-100 {
  color: #ffffff;
}
.color-block.text-red-100 {
  background-color: #ffffff;
}
.color-block.bg-red-200 {
  color: #ffffff;
}
.color-block.text-red-200 {
  background-color: #ffffff;
}
.color-block.bg-red-300 {
  color: #ffffff;
}
.color-block.text-red-300 {
  background-color: #ffffff;
}
.color-block.bg-red-400 {
  color: #ffffff;
}
.color-block.text-red-400 {
  background-color: #ffffff;
}
.color-block.bg-red-500 {
  color: #ffffff;
}
.color-block.text-red-500 {
  background-color: #ffffff;
}
.color-block.bg-red-600 {
  color: #ffffff;
}
.color-block.text-red-600 {
  background-color: #ffffff;
}
.color-block.bg-red-700 {
  color: #ffffff;
}
.color-block.text-red-700 {
  background-color: #ffffff;
}
.color-block.bg-red-800 {
  color: #ffffff;
}
.color-block.text-red-800 {
  background-color: #ffffff;
}
.color-block.bg-red-900 {
  color: #ffffff;
}
.color-block.text-red-900 {
  background-color: #ffffff;
}
.color-block.bg-orange-100 {
  color: #212529;
}
.color-block.text-orange-100 {
  background-color: #212529;
}
.color-block.bg-orange-200 {
  color: #ffffff;
}
.color-block.text-orange-200 {
  background-color: #ffffff;
}
.color-block.bg-orange-300 {
  color: #ffffff;
}
.color-block.text-orange-300 {
  background-color: #ffffff;
}
.color-block.bg-orange-400 {
  color: #ffffff;
}
.color-block.text-orange-400 {
  background-color: #ffffff;
}
.color-block.bg-orange-500 {
  color: #ffffff;
}
.color-block.text-orange-500 {
  background-color: #ffffff;
}
.color-block.bg-orange-600 {
  color: #ffffff;
}
.color-block.text-orange-600 {
  background-color: #ffffff;
}
.color-block.bg-orange-700 {
  color: #ffffff;
}
.color-block.text-orange-700 {
  background-color: #ffffff;
}
.color-block.bg-orange-800 {
  color: #ffffff;
}
.color-block.text-orange-800 {
  background-color: #ffffff;
}
.color-block.bg-orange-900 {
  color: #ffffff;
}
.color-block.text-orange-900 {
  background-color: #ffffff;
}
.color-block.bg-yellow-100 {
  color: #212529;
}
.color-block.text-yellow-100 {
  background-color: #212529;
}
.color-block.bg-yellow-200 {
  color: #ffffff;
}
.color-block.text-yellow-200 {
  background-color: #ffffff;
}
.color-block.bg-yellow-300 {
  color: #ffffff;
}
.color-block.text-yellow-300 {
  background-color: #ffffff;
}
.color-block.bg-yellow-400 {
  color: #ffffff;
}
.color-block.text-yellow-400 {
  background-color: #ffffff;
}
.color-block.bg-yellow-500 {
  color: #ffffff;
}
.color-block.text-yellow-500 {
  background-color: #ffffff;
}
.color-block.bg-yellow-600 {
  color: #ffffff;
}
.color-block.text-yellow-600 {
  background-color: #ffffff;
}
.color-block.bg-yellow-700 {
  color: #ffffff;
}
.color-block.text-yellow-700 {
  background-color: #ffffff;
}
.color-block.bg-yellow-800 {
  color: #ffffff;
}
.color-block.text-yellow-800 {
  background-color: #ffffff;
}
.color-block.bg-yellow-900 {
  color: #ffffff;
}
.color-block.text-yellow-900 {
  background-color: #ffffff;
}
.color-block.bg-green-100 {
  color: #212529;
}
.color-block.text-green-100 {
  background-color: #212529;
}
.color-block.bg-green-200 {
  color: #ffffff;
}
.color-block.text-green-200 {
  background-color: #ffffff;
}
.color-block.bg-green-300 {
  color: #ffffff;
}
.color-block.text-green-300 {
  background-color: #ffffff;
}
.color-block.bg-green-400 {
  color: #ffffff;
}
.color-block.text-green-400 {
  background-color: #ffffff;
}
.color-block.bg-green-500 {
  color: #ffffff;
}
.color-block.text-green-500 {
  background-color: #ffffff;
}
.color-block.bg-green-600 {
  color: #ffffff;
}
.color-block.text-green-600 {
  background-color: #ffffff;
}
.color-block.bg-green-700 {
  color: #ffffff;
}
.color-block.text-green-700 {
  background-color: #ffffff;
}
.color-block.bg-green-800 {
  color: #ffffff;
}
.color-block.text-green-800 {
  background-color: #ffffff;
}
.color-block.bg-green-900 {
  color: #ffffff;
}
.color-block.text-green-900 {
  background-color: #ffffff;
}
.color-block.bg-teal-100 {
  color: #212529;
}
.color-block.text-teal-100 {
  background-color: #212529;
}
.color-block.bg-teal-200 {
  color: #ffffff;
}
.color-block.text-teal-200 {
  background-color: #ffffff;
}
.color-block.bg-teal-300 {
  color: #ffffff;
}
.color-block.text-teal-300 {
  background-color: #ffffff;
}
.color-block.bg-teal-400 {
  color: #ffffff;
}
.color-block.text-teal-400 {
  background-color: #ffffff;
}
.color-block.bg-teal-500 {
  color: #ffffff;
}
.color-block.text-teal-500 {
  background-color: #ffffff;
}
.color-block.bg-teal-600 {
  color: #ffffff;
}
.color-block.text-teal-600 {
  background-color: #ffffff;
}
.color-block.bg-teal-700 {
  color: #ffffff;
}
.color-block.text-teal-700 {
  background-color: #ffffff;
}
.color-block.bg-teal-800 {
  color: #ffffff;
}
.color-block.text-teal-800 {
  background-color: #ffffff;
}
.color-block.bg-teal-900 {
  color: #ffffff;
}
.color-block.text-teal-900 {
  background-color: #ffffff;
}
.color-block.bg-cyan-100 {
  color: #212529;
}
.color-block.text-cyan-100 {
  background-color: #212529;
}
.color-block.bg-cyan-200 {
  color: #212529;
}
.color-block.text-cyan-200 {
  background-color: #212529;
}
.color-block.bg-cyan-300 {
  color: #ffffff;
}
.color-block.text-cyan-300 {
  background-color: #ffffff;
}
.color-block.bg-cyan-400 {
  color: #ffffff;
}
.color-block.text-cyan-400 {
  background-color: #ffffff;
}
.color-block.bg-cyan-500 {
  color: #ffffff;
}
.color-block.text-cyan-500 {
  background-color: #ffffff;
}
.color-block.bg-cyan-600 {
  color: #ffffff;
}
.color-block.text-cyan-600 {
  background-color: #ffffff;
}
.color-block.bg-cyan-700 {
  color: #ffffff;
}
.color-block.text-cyan-700 {
  background-color: #ffffff;
}
.color-block.bg-cyan-800 {
  color: #ffffff;
}
.color-block.text-cyan-800 {
  background-color: #ffffff;
}
.color-block.bg-cyan-900 {
  color: #ffffff;
}
.color-block.text-cyan-900 {
  background-color: #ffffff;
}
.color-block.bg-gray-100 {
  color: #212529;
}
.color-block.text-gray-100 {
  background-color: #212529;
}
.color-block.bg-gray-200 {
  color: #212529;
}
.color-block.text-gray-200 {
  background-color: #212529;
}
.color-block.bg-gray-300 {
  color: #212529;
}
.color-block.text-gray-300 {
  background-color: #212529;
}
.color-block.bg-gray-400 {
  color: #ffffff;
}
.color-block.text-gray-400 {
  background-color: #ffffff;
}
.color-block.bg-gray-500 {
  color: #ffffff;
}
.color-block.text-gray-500 {
  background-color: #ffffff;
}
.color-block.bg-gray-600 {
  color: #ffffff;
}
.color-block.text-gray-600 {
  background-color: #ffffff;
}
.color-block.bg-gray-700 {
  color: #ffffff;
}
.color-block.text-gray-700 {
  background-color: #ffffff;
}
.color-block.bg-gray-800 {
  color: #ffffff;
}
.color-block.text-gray-800 {
  background-color: #ffffff;
}
.color-block.bg-gray-900 {
  color: #ffffff;
}
.color-block.text-gray-900 {
  background-color: #ffffff;
}

.row > div .color-block:first-child {
  margin-top: 0;
}
.row > div .color-block:last-child {
  margin-bottom: 0;
}

.card .toast-container {
  position: relative;
}

.blockquote-footer {
  margin-top: 0;
}

.toast-container {
  z-index: 1022;
}

.carousel-indicators li::marker {
  font-size: 0;
}

.pagination .page-item .page-link {
  border-radius: 0;
}
.pagination .page-item:first-child .page-link {
  border-radius: var(--bs-pagination-border-radius) 0 0 var(--bs-pagination-border-radius);
}
.pagination .page-item:last-child .page-link {
  border-radius: 0 var(--bs-pagination-border-radius) var(--bs-pagination-border-radius) 0;
}

.customer-body {
  height: calc(100% - 60px);
}

.offcanvas-top,
.offcanvas-bottom {
  min-height: 240px;
}

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000000;
  pointer-events: none;
  animation: loading 2000ms ease-in-out;
  animation-iteration-count: infinite;
  opacity: 1;
  transition: width 1350ms ease-in-out, opacity 350ms linear, left 50ms ease-in-out;
}

.bar {
  background-color: var(--bs-primary);
  height: 5px;
  width: 100%;
}

@keyframes loading {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 100%;
    left: 100%;
  }
}
/**  =====================
    2. Custom css end
==========================  **/
.pc-sidebar {
  background: var(--pc-sidebar-background);
  width: 280px;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1026;
  overflow: hidden;
  box-shadow: var(--pc-sidebar-shadow);
  border-right: var(--pc-sidebar-border);
}
.pc-sidebar .navbar-wrapper {
  width: 280px;
  background: inherit;
}
.pc-sidebar ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.pc-sidebar .pc-caption {
  color: var(--pc-sidebar-caption-color);
  display: block;
  padding: 24px 23px 8px !important;
  text-transform: uppercase;
  position: relative;
  line-height: 1.66;
  font-size: 11px;
  font-weight: 600;
}
.pc-sidebar .pc-caption:first-child {
  padding-top: 10px !important;
}
.pc-sidebar .pc-caption:first-child::after {
  display: none;
}
.pc-sidebar .pc-caption svg,
.pc-sidebar .pc-caption i {
  display: none;
}
.pc-sidebar .pc-caption label {
  margin-bottom: 0;
}
.pc-sidebar .pc-caption span:not(.badge) {
  display: block;
  color: #67758a;
  font-size: 14px;
  font-weight: 500;
}
.pc-sidebar .pc-micon {
  margin-right: 15px;
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.pc-sidebar .pc-micon i {
  font-size: 18px;
  vertical-align: super;
}
.pc-sidebar .pc-micon i.material-icons-two-tone {
  font-size: 22px;
  display: inline-block;
}
.pc-sidebar .pc-micon > svg {
  width: 22px;
  height: 22px;
  display: inline-block;
}
.pc-sidebar .pc-link {
  display: block;
  padding: 14px 20px;
  color: var(--pc-sidebar-color);
  position: relative;
  font-size: 14px;
  font-weight: 400;
}
.pc-sidebar .pc-link:focus, .pc-sidebar .pc-link.active, .pc-sidebar .pc-link:hover {
  text-decoration: none;
}
.pc-sidebar .pc-navbar > .pc-item {
  margin: 0 10px;
}
.pc-sidebar .pc-navbar > .pc-item > .pc-link::after {
  content: "";
  border-radius: var(--bs-border-radius);
  position: absolute;
  top: 2px;
  right: 2px;
  left: 2px;
  bottom: 2px;
  opacity: 0.1;
}
.pc-sidebar .pc-navbar > .pc-item.active > .pc-link {
  font-weight: 500;
  color: var(--pc-sidebar-active-color);
}
.pc-sidebar .pc-navbar > .pc-item.active > .pc-link:after {
  background: var(--pc-sidebar-active-color);
}
.pc-sidebar .pc-navbar > .pc-item:hover:not(.active) > .pc-link:after {
  background: var(--pc-sidebar-color);
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-item > .pc-link:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 20px;
  width: 5px;
  height: 5px;
  background: var(--pc-sidebar-color);
  opacity: 0.5;
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-item.pc-trigger > .pc-link, .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-item.active > .pc-link {
  font-weight: 500;
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-item.pc-trigger > .pc-link:after, .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-item.active > .pc-link:after {
  background: var(--pc-sidebar-active-color);
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-item.pc-trigger > .pc-link:after, .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-item.active > .pc-link:after, .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-item:focus > .pc-link:after, .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-item:hover > .pc-link:after {
  opacity: 1;
  transform: scale(1.2);
  background: var(--pc-sidebar-active-color);
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu:after {
  left: 30px;
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-link {
  padding: 12px 30px 12px 60px;
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-link:after {
  left: 28px;
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu:after {
  left: 46px;
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link {
  padding: 12px 30px 12px 80px;
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link:after {
  left: 62px;
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu:after {
  left: 63px;
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link {
  padding: 12px 30px 12px 95px;
}
.pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link:after {
  left: 79px;
}
.pc-sidebar .pc-item.disabled a {
  cursor: not-allowed;
  user-select: none;
}
.pc-sidebar .pc-arrow {
  float: right;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  position: relative;
}
.pc-sidebar .pc-arrow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.pc-sidebar .pc-arrow > svg {
  width: 14px;
  height: 14px;
}
.pc-sidebar .pc-badge {
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  line-height: 1;
  float: right;
  background: var(--bs-primary);
}
.pc-sidebar .m-header {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 16px 24px;
}
.pc-sidebar .m-header .logo-sm {
  display: none;
}
.pc-sidebar .navbar-content {
  position: relative;
  height: calc(100vh - 74px);
  padding: 10px 0;
}
.pc-sidebar .card {
  box-shadow: var(--bs-card-box-shadow);
  margin: 15px;
}
.pc-sidebar .card.pc-user-card {
  margin: 0 15px 15px;
  box-shadow: none;
  background: var(--pc-sidebar-user-background);
}
.pc-sidebar .card.pc-user-card .card-body {
  padding: 20px;
}
.pc-sidebar .card.pc-user-card .pc-user-links a {
  display: flex;
  align-items: center;
  color: var(--pc-sidebar-color);
  padding: 8px 0px;
}
.pc-sidebar .card.pc-user-card .pc-user-links a i {
  font-size: 18px;
  margin-right: 10px;
}
.pc-sidebar .card.pc-user-card .pc-user-links a:focus, .pc-sidebar .card.pc-user-card .pc-user-links a:hover {
  color: var(--pc-sidebar-active-color);
}
.pc-sidebar .pc-hasmenu:not(.pc-trigger) > .pc-submenu {
  display: none;
}
.pc-sidebar .pc-hasmenu.pc-trigger > .pc-submenu {
  display: block;
}
.pc-sidebar .pc-hasmenu.pc-trigger > .pc-link > .pc-arrow {
  transform: rotate(90deg);
}

.pc-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.15);
}

[data-pc-sidebar-caption=false] .pc-sidebar .pc-caption {
  display: none;
}

@media (min-width: 1025px) {
  .navbar-overlay .pc-sidebar.pc-over-menu-active .navbar-wrapper {
    position: relative;
    z-index: 5;
  }
  .navbar-overlay .pc-sidebar:not(.pc-over-menu-active) {
    left: -280px;
    box-shadow: none;
  }
  .pc-sidebar {
    transition: width 0.2s ease;
  }
  .pc-sidebar ~ .pc-header {
    transition: left 0.2s ease;
  }
  .pc-sidebar ~ .pc-footer,
  .pc-sidebar ~ .pc-container {
    transition: margin-left 0.2s ease;
  }
  .pc-sidebar.pc-sidebar-hide {
    width: 0;
    --pc-sidebar-border: none;
  }
  .pc-sidebar.pc-sidebar-hide ~ .pc-header {
    left: 0;
  }
  .pc-sidebar.pc-sidebar-hide ~ .pc-footer,
  .pc-sidebar.pc-sidebar-hide ~ .pc-container {
    margin-left: 0px;
  }
  .pc-header .pc-h-item.pc-sidebar-popup {
    display: none;
  }
}
@media (max-width: 1024px) {
  .pc-header .pc-h-item.pc-sidebar-collapse {
    display: none;
  }
  .pc-sidebar {
    left: -280px;
    box-shadow: none;
    background: var(--bs-body-bg);
    top: 0;
    transition: all 0.2s ease-in-out;
  }
  .pc-sidebar.mob-sidebar-active {
    left: 0;
  }
  .pc-sidebar.mob-sidebar-active .navbar-wrapper {
    position: relative;
    z-index: 5;
    background: inherit;
  }
}
[data-pc-layout=color-header] {
  --pc-sidebar-background: #fff;
  --pc-sidebar-border: none;
  --pc-header-color: #fff;
  --pc-layout-2-color: #1A237E;
  background: var(--pc-layout-2-color);
  position: relative;
}
[data-pc-layout=color-header] a.preset.preset-1 {
  background: #1A237E;
}
[data-pc-layout=color-header] a.preset.preset-gradient-1 {
  background: linear-gradient(to right, #1A237E 0%, #232fa8 100%);
}
[data-pc-layout=color-header].preset-1 {
  --pc-layout-2-color: #1A237E;
}
[data-pc-layout=color-header].preset-gradient-1 {
  --pc-layout-2-color: linear-gradient(to right, #1A237E 0%, #232fa8 100%);
}
[data-pc-layout=color-header] a.preset.preset-2 {
  background: #6610f2;
}
[data-pc-layout=color-header] a.preset.preset-gradient-2 {
  background: linear-gradient(to right, #6610f2 0%, #8540f5 100%);
}
[data-pc-layout=color-header].preset-2 {
  --pc-layout-2-color: #6610f2;
}
[data-pc-layout=color-header].preset-gradient-2 {
  --pc-layout-2-color: linear-gradient(to right, #6610f2 0%, #8540f5 100%);
}
[data-pc-layout=color-header] a.preset.preset-3 {
  background: #673ab7;
}
[data-pc-layout=color-header] a.preset.preset-gradient-3 {
  background: linear-gradient(to right, #673ab7 0%, #8259cb 100%);
}
[data-pc-layout=color-header].preset-3 {
  --pc-layout-2-color: #673ab7;
}
[data-pc-layout=color-header].preset-gradient-3 {
  --pc-layout-2-color: linear-gradient(to right, #673ab7 0%, #8259cb 100%);
}
[data-pc-layout=color-header] a.preset.preset-4 {
  background: #e83e8c;
}
[data-pc-layout=color-header] a.preset.preset-gradient-4 {
  background: linear-gradient(to right, #e83e8c 0%, #ed6ca7 100%);
}
[data-pc-layout=color-header].preset-4 {
  --pc-layout-2-color: #e83e8c;
}
[data-pc-layout=color-header].preset-gradient-4 {
  --pc-layout-2-color: linear-gradient(to right, #e83e8c 0%, #ed6ca7 100%);
}
[data-pc-layout=color-header] a.preset.preset-5 {
  background: #dc2626;
}
[data-pc-layout=color-header] a.preset.preset-gradient-5 {
  background: linear-gradient(to right, #dc2626 0%, #e35252 100%);
}
[data-pc-layout=color-header].preset-5 {
  --pc-layout-2-color: #dc2626;
}
[data-pc-layout=color-header].preset-gradient-5 {
  --pc-layout-2-color: linear-gradient(to right, #dc2626 0%, #e35252 100%);
}
[data-pc-layout=color-header] a.preset.preset-6 {
  background: #fd7e14;
}
[data-pc-layout=color-header] a.preset.preset-gradient-6 {
  background: linear-gradient(to right, #fd7e14 0%, #fd9a47 100%);
}
[data-pc-layout=color-header].preset-6 {
  --pc-layout-2-color: #fd7e14;
}
[data-pc-layout=color-header].preset-gradient-6 {
  --pc-layout-2-color: linear-gradient(to right, #fd7e14 0%, #fd9a47 100%);
}
[data-pc-layout=color-header] a.preset.preset-7 {
  background: #e58a00;
}
[data-pc-layout=color-header] a.preset.preset-gradient-7 {
  background: linear-gradient(to right, #e58a00 0%, #ffa419 100%);
}
[data-pc-layout=color-header].preset-7 {
  --pc-layout-2-color: #e58a00;
}
[data-pc-layout=color-header].preset-gradient-7 {
  --pc-layout-2-color: linear-gradient(to right, #e58a00 0%, #ffa419 100%);
}
[data-pc-layout=color-header] a.preset.preset-8 {
  background: #2ca87f;
}
[data-pc-layout=color-header] a.preset.preset-gradient-8 {
  background: linear-gradient(to right, #2ca87f 0%, #3bcc9c 100%);
}
[data-pc-layout=color-header].preset-8 {
  --pc-layout-2-color: #2ca87f;
}
[data-pc-layout=color-header].preset-gradient-8 {
  --pc-layout-2-color: linear-gradient(to right, #2ca87f 0%, #3bcc9c 100%);
}
[data-pc-layout=color-header] a.preset.preset-9 {
  background: #008080;
}
[data-pc-layout=color-header] a.preset.preset-gradient-9 {
  background: linear-gradient(to right, #008080 0%, #00b3b3 100%);
}
[data-pc-layout=color-header].preset-9 {
  --pc-layout-2-color: #008080;
}
[data-pc-layout=color-header].preset-gradient-9 {
  --pc-layout-2-color: linear-gradient(to right, #008080 0%, #00b3b3 100%);
}
[data-pc-layout=color-header] a.preset.preset-10 {
  background: #3ec9d6;
}
[data-pc-layout=color-header] a.preset.preset-gradient-10 {
  background: linear-gradient(to right, #3ec9d6 0%, #68d5df 100%);
}
[data-pc-layout=color-header].preset-10 {
  --pc-layout-2-color: #3ec9d6;
}
[data-pc-layout=color-header].preset-gradient-10 {
  --pc-layout-2-color: linear-gradient(to right, #3ec9d6 0%, #68d5df 100%);
}
[data-pc-layout=color-header] a.preset.preset-11 {
  background: #212529;
}
[data-pc-layout=color-header] a.preset.preset-gradient-11 {
  background: linear-gradient(to right, #212529 0%, #383f45 100%);
}
[data-pc-layout=color-header].preset-11 {
  --pc-layout-2-color: #212529;
}
[data-pc-layout=color-header].preset-gradient-11 {
  --pc-layout-2-color: linear-gradient(to right, #212529 0%, #383f45 100%);
}
[data-pc-layout=color-header] .pc-header {
  position: absolute;
  background: transparent;
}
[data-pc-layout=color-header] .pc-header .pc-head-link::before {
  background: rgba(255, 255, 255, 0.15);
}
[data-pc-layout=color-header] .pc-sidebar {
  position: absolute;
  background: transparent;
  height: 100%;
}
[data-pc-layout=color-header] .pc-sidebar .navbar-wrapper {
  height: 100%;
}
[data-pc-layout=color-header] .pc-sidebar .navbar-content {
  box-shadow: inset 0 0 1px 1px var(--bs-border-color);
  background: var(--pc-sidebar-background);
  border-radius: 0 12px 0 0;
  padding: 18px 0 10px;
  height: calc(100% - 74px);
  position: relative;
}
[data-pc-layout=color-header] .pc-footer {
  background: var(--bs-body-bg);
}
[data-pc-layout=color-header] .pc-container {
  padding-top: 140px;
}
[data-pc-layout=color-header] .pc-container .pc-content {
  background: var(--bs-body-bg);
  min-height: calc(100vh - 273px);
}
[data-pc-layout=color-header] .pc-container .page-header {
  margin-top: -140px;
  padding: 0;
}
[data-pc-layout=color-header] .pc-container .page-header h2, [data-pc-layout=color-header] .pc-container .page-header .h2 {
  color: #fff;
}
[data-pc-layout=color-header] .pc-container .page-header .breadcrumb {
  margin-bottom: 5px;
  --bs-breadcrumb-divider-color: #fff;
}
[data-pc-layout=color-header] .pc-container .page-header .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14' stroke='%23ffffff' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E"));
}
[data-pc-layout=color-header] .pc-container .page-header .breadcrumb .breadcrumb-item,
[data-pc-layout=color-header] .pc-container .page-header .breadcrumb a {
  color: #fff;
}

.pc-header {
  background: var(--pc-header-background);
  color: var(--pc-header-color);
  min-height: 74px;
  box-shadow: var(--pc-header-shadow);
  backdrop-filter: blur(7px);
  position: fixed;
  left: 280px;
  right: 0;
  z-index: 1025;
  display: flex;
}
.pc-header ul {
  margin-bottom: 0;
  display: inline-flex;
}
.pc-header .m-header {
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 280px;
  padding: 16px 10px 16px 24px;
}
.pc-header .header-wrapper {
  display: flex;
  padding: 0 25px;
  flex-grow: 1;
}
@media (max-width: 575.98px) {
  .pc-header .header-wrapper {
    padding: 0 15px;
  }
}
.pc-header .form-search i {
  top: 5px;
}
.pc-header .form-search i svg {
  width: 16px;
  height: 16px;
}
.pc-header .form-search .form-control, .pc-header .form-search .datatable-input, .pc-header .form-search .custom-select,
.pc-header .form-search .dataTable-selector,
.pc-header .form-search .dataTable-input {
  width: 198px;
  padding: 0.65rem 0.75rem 0.65rem 40px;
}
.pc-header .header-search {
  position: relative;
}
.pc-header .header-search .form-control, .pc-header .header-search .datatable-input, .pc-header .header-search .custom-select,
.pc-header .header-search .dataTable-selector,
.pc-header .header-search .dataTable-input {
  border-radius: 8px;
  padding: 0.344rem 1.8rem;
  width: 200px;
  max-width: 100%;
}
@media (max-width: 1024px) {
  .pc-header .header-search .form-control, .pc-header .header-search .datatable-input, .pc-header .header-search .custom-select,
  .pc-header .header-search .dataTable-selector,
  .pc-header .header-search .dataTable-input {
    width: 100%;
  }
}
.pc-header .header-search .icon-search {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 12px;
  height: 12px;
}
.pc-header .header-search .btn-search {
  position: absolute;
  top: 7px;
  right: 9px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 8px;
}
.pc-header .pc-h-item {
  min-height: 74px;
  display: flex;
  align-items: center;
  position: relative;
}
.pc-header .pc-head-link {
  margin: 0 4px;
  position: relative;
  font-weight: 500;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 8px;
  color: var(--pc-header-color);
  overflow: hidden;
}
.pc-header .pc-head-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: var(--pc-active-background);
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
}
.pc-header .pc-head-link > svg,
.pc-header .pc-head-link > i {
  position: relative;
  z-index: 5;
  color: var(--pc-header-color);
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
}
.pc-header .pc-head-link > i {
  font-size: 24px;
}
.pc-header .pc-head-link > svg {
  width: 24px;
  height: 24px;
}
.pc-header .pc-head-link.active, .pc-header .pc-head-link:active, .pc-header .pc-head-link:focus, .pc-header .pc-head-link:hover {
  text-decoration: none;
  color: var(--pc-header-color);
}
.pc-header .pc-head-link.active > svg,
.pc-header .pc-head-link.active > i, .pc-header .pc-head-link:active > svg,
.pc-header .pc-head-link:active > i, .pc-header .pc-head-link:focus > svg,
.pc-header .pc-head-link:focus > i, .pc-header .pc-head-link:hover > svg,
.pc-header .pc-head-link:hover > i {
  color: var(--pc-header-color);
  transform: scale(1.08);
}
.pc-header .pc-head-link.active::before, .pc-header .pc-head-link:active::before, .pc-header .pc-head-link:focus::before, .pc-header .pc-head-link:hover::before {
  border-radius: 0;
  transform: scale(1);
}
.pc-header .pc-head-link.active .hamburger .hamburger-inner, .pc-header .pc-head-link:active .hamburger .hamburger-inner, .pc-header .pc-head-link:focus .hamburger .hamburger-inner, .pc-header .pc-head-link:hover .hamburger .hamburger-inner {
  background-color: #5b6b79;
}
.pc-header .pc-head-link.active .hamburger .hamburger-inner::after, .pc-header .pc-head-link.active .hamburger .hamburger-inner::before, .pc-header .pc-head-link:active .hamburger .hamburger-inner::after, .pc-header .pc-head-link:active .hamburger .hamburger-inner::before, .pc-header .pc-head-link:focus .hamburger .hamburger-inner::after, .pc-header .pc-head-link:focus .hamburger .hamburger-inner::before, .pc-header .pc-head-link:hover .hamburger .hamburger-inner::after, .pc-header .pc-head-link:hover .hamburger .hamburger-inner::before {
  background-color: #5b6b79;
}
.pc-header .pc-head-link.active i.material-icons-two-tone, .pc-header .pc-head-link:active i.material-icons-two-tone, .pc-header .pc-head-link:focus i.material-icons-two-tone, .pc-header .pc-head-link:hover i.material-icons-two-tone {
  background-color: #5b6b79;
}
.pc-header .pc-head-link .pc-h-badge {
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 50%;
  font-size: 9px;
  z-index: 9;
}
.pc-header .pc-head-link .pc-h-badge.dots {
  width: 9px;
  height: 9px;
  top: 7px;
  right: 16px;
  padding: 0;
}
.pc-header .pc-head-link .user-desc,
.pc-header .pc-head-link .user-name {
  display: block;
  line-height: 1;
}
.pc-header .pc-head-link .user-name {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
}
.pc-header .pc-head-link .user-desc {
  font-size: 12px;
  font-weight: 400;
  color: var(--pc-header-color);
}
.pc-header .pc-h-dropdown {
  transform: none !important;
  top: 100% !important;
}
.pc-header .pc-h-dropdown .ti-circle {
  font-size: 7px;
  vertical-align: middle;
}
.pc-header .pc-h-dropdown.dropdown-menu-end {
  right: 0 !important;
  left: auto !important;
}
.pc-header .pc-level-menu {
  position: relative;
}
.pc-header .pc-level-menu:hover > .dropdown-menu {
  display: block;
  left: 100%;
  top: -18px !important;
}
.pc-header .drp-search {
  min-width: 20rem;
}
.pc-header #vertical-nav-toggle {
  margin-left: -15px;
}
.pc-header .pc-cart-menu .drp-cart {
  min-width: 20rem;
  padding: 0;
}
.pc-header .pc-cart-menu .drp-cart .cart-head {
  border-radius: 2px 2px 0 0;
  padding: 20px;
  background: rgba(232, 233, 242, 0.2);
  border-bottom: 1px solid #e7eaee;
}
.pc-header .pc-cart-menu .drp-cart .cart-item {
  display: flex;
  padding: 10px 20px;
}
.pc-header .pc-cart-menu .drp-cart .cart-item + .cart-item {
  border-top: 1px dashed #e7eaee;
}
.pc-header .pc-cart-menu .drp-cart .cart-item img {
  width: 60px;
}
.pc-header .pc-cart-menu .drp-cart .cart-item .cart-desc {
  flex: 1;
}
.pc-header .pc-cart-menu .drp-cart .cart-item .p-star {
  fill: currentColor;
}
.pc-header .pc-cart-menu .drp-cart .cart-item.table-responsive {
  border-top: 1px solid #e7eaee;
  background: rgba(232, 233, 242, 0.2);
}
.pc-header .pc-cart-menu .drp-cart .cart-item svg {
  width: 14px;
  height: 14px;
}
.pc-header .user-avtar {
  width: 40px;
  border-radius: 50%;
}
.pc-header .header-user-profile .pc-head-link {
  width: auto;
  padding: 7px;
  background: transparent;
}
.pc-header .header-user-profile .pc-head-link:focus .user-avtar, .pc-header .header-user-profile .pc-head-link:hover .user-avtar {
  transform: scale(1.03);
}
.pc-header .header-user-profile .pc-head-link:focus::before, .pc-header .header-user-profile .pc-head-link:hover::before {
  display: none;
}
.pc-header .header-user-profile .pc-head-link > span > i {
  font-size: 22px;
  margin-right: 8px;
}
.pc-header .header-user-profile .pc-head-link .user-avtar {
  width: 40px;
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
}
@media (max-width: 575.98px) {
  .pc-header .header-user-profile .pc-head-link {
    width: 40px;
  }
  .pc-header .header-user-profile .pc-head-link .user-avtar {
    margin-right: 0;
  }
  .pc-header .header-user-profile .pc-head-link > span,
  .pc-header .header-user-profile .pc-head-link > span > i {
    display: none;
  }
}
.pc-header .dropdown-user-profile {
  min-width: 352px;
  max-width: 100%;
}
.pc-header .dropdown-user-profile .dropdown-header,
.pc-header .dropdown-user-profile .dropdown-body {
  padding: 16px 20px;
}
.pc-header .dropdown-user-profile .dropdown-item {
  padding: 10px 15px;
  display: flex;
  border-radius: var(--bs-border-radius);
  align-items: center;
  justify-content: space-between;
  border: var(--bs-border-width) var(--bs-border-style) transparent;
}
.pc-header .dropdown-user-profile .dropdown-item:hover, .pc-header .dropdown-user-profile .dropdown-item:focus {
  color: var(--bs-dropdown-link-color);
  background: var(--pc-active-background);
  border-color: var(--bs-border-color);
}
.pc-header .dropdown-user-profile .dropdown-item .user-group .avtar,
.pc-header .dropdown-user-profile .dropdown-item .user-group img {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
@media (max-width: 575.98px) {
  .pc-header .dropdown-user-profile {
    min-width: calc(100% - 30px);
  }
}
.pc-header .dropdown-notification {
  min-width: 450px;
  max-width: 100%;
}
.pc-header .dropdown-notification .dropdown-header,
.pc-header .dropdown-notification .dropdown-body {
  padding: 16px 20px;
}
.pc-header .dropdown-notification .list-group-item-action:active, .pc-header .dropdown-notification .list-group-item-action:hover, .pc-header .dropdown-notification .list-group-item-action:focus {
  background: #e8e9f2;
}
.pc-header .dropdown-notification .list-group-item-action .user-avtar,
.pc-header .dropdown-notification .list-group-item-action h5,
.pc-header .dropdown-notification .list-group-item-action .h5 {
  cursor: pointer;
}
.pc-header .dropdown-notification .badge {
  font-size: 0.8125rem;
  padding: 0.43em 1em;
}
.pc-header .dropdown-notification .user-avtar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
}
.pc-header .dropdown-notification .notification-file {
  display: flex;
  align-items: center;
}
.pc-header .dropdown-notification .notification-file i {
  font-size: 20px;
  margin-right: 16px;
}
.pc-header .dropdown-notification .card {
  cursor: pointer;
}
.pc-header .dropdown-notification .card:hover {
  background: var(--pc-active-background);
}
@media (max-width: 575.98px) {
  .pc-header .dropdown-notification {
    min-width: calc(100% - 30px);
  }
}

.pc-announcement-offcanvas {
  --bs-offcanvas-width: 474px;
}
.pc-announcement-offcanvas .offcanvas-body::-webkit-scrollbar {
  width: 6px;
  opacity: 0;
}
.pc-announcement-offcanvas .offcanvas-body::-webkit-scrollbar:hover {
  opacity: 1;
}
.pc-announcement-offcanvas .offcanvas-body::-webkit-scrollbar-track {
  background: transparent;
}
.pc-announcement-offcanvas .offcanvas-body::-webkit-scrollbar-thumb {
  background: #e9ecef;
}
.pc-announcement-offcanvas .offcanvas-body::-webkit-scrollbar-thumb:hover {
  background: #aeb9c5;
}

.drp-upgrade-card {
  background-size: cover;
}
.drp-upgrade-card .user-group .avtar,
.drp-upgrade-card .user-group img {
  font-size: 14px;
}

.user-group {
  display: inline-flex;
  align-items: center;
}
.user-group .avtar,
.user-group img {
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 2;
  transition: all 0.1s ease-in-out;
  border: 2px solid #ffffff;
}
.user-group .avtar + .avtar,
.user-group .avtar + img,
.user-group img + .avtar,
.user-group img + img {
  margin-left: -14px;
}
.user-group .avtar:hover,
.user-group img:hover {
  z-index: 5;
}

.notification-modal.modal.fade .modal-dialog {
  transform: translate(100%, 0);
}

.notification-modal.modal.show .modal-dialog {
  transform: none;
}

.notification-modal {
  padding-right: 0 !important;
}
.notification-modal .modal-dialog {
  margin: 0 0 0 auto;
}
.notification-modal .modal-content, .notification-modal .introjs-tooltip {
  min-height: 100vh;
  border: none;
  border-radius: 0;
  box-shadow: -1px 0 9px -3px rgba(0, 0, 0, 0.6);
}

@media (max-width: 1024px) {
  .pc-header {
    top: 0;
    left: 0;
    transition: all 0.15s ease-in-out;
  }
  .pc-header .m-header {
    display: none;
  }
  .pc-header .pc-head-link .user-desc,
  .pc-header .pc-head-link .user-name {
    display: none;
  }
  .pc-header .pcm-logo {
    margin-left: 30px;
  }
  .pc-header .pcm-logo img {
    max-width: 160px;
  }
  .pc-header.mob-header-active {
    top: 74px;
  }
  .pc-header.mob-header-active .header-wrapper {
    background: var(pc-header-background);
    position: relative;
    z-index: 5;
  }
  .pc-header .pc-md-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background: rgba(0, 0, 0, 0.2);
  }
  .pc-header .pc-level-menu .dropdown-menu {
    left: 0;
    display: block;
    padding-left: 30px;
  }
  .pc-header .pc-mob-drp.mob-drp-active .pc-h-item {
    display: block;
    min-height: auto;
    position: relative;
  }
  .pc-header .pc-mob-drp.mob-drp-active .pc-h-item .pc-head-link {
    display: block;
    margin: 5px 10px !important;
  }
  .pc-header .pc-mob-drp.mob-drp-active .pc-h-item .dropdown-menu {
    position: relative !important;
    width: 100%;
    float: none;
    box-shadow: none;
  }
  .pc-header .pc-mob-drp.mob-drp-active ul {
    display: block;
  }
  .minimenu .pc-header {
    left: 0;
  }
}
@media (max-width: 575.98px) {
  .pc-header .pc-head-link {
    padding: 0.65rem;
    margin: 0 5px;
  }
  .pc-header .pc-h-item {
    position: static;
  }
  .pc-header .pc-h-item .pc-h-dropdown {
    left: 15px !important;
    right: 15px !important;
  }
}
.pc-footer {
  position: relative;
  z-index: 995;
  margin-left: 280px;
  margin-top: 74px;
  padding: 15px 0;
}
.pc-footer a {
  color: var(--bs-body-color);
}
.pc-footer a:hover {
  color: var(--bs-primary);
}
.pc-footer .footer-wrapper {
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 1024px) {
  .pc-footer .footer-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 575.98px) {
  .pc-footer .footer-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.pc-footer .footer-link .list-inline-item:not(:last-child) {
  margin-right: 0.9rem;
}
@media (max-width: 1024px) {
  .pc-footer {
    margin-left: 0;
  }
}
@media (max-width: 575.98px) {
  .pc-footer {
    margin-top: 50px;
  }
}

.loader-bg {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgb(255, 255, 255);
  z-index: 1034;
}
.loader-bg .loader-track {
  height: 5px;
  width: 100%;
  display: inline-block;
  position: absolute;
  overflow: hidden;
  top: 0;
  background: rgba(var(--bs-primary-rgb), 0.4);
}
.loader-bg .loader-track .loader-fill {
  width: 300px;
  height: 5px;
  background: var(--bs-primary);
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  transition: transform 0.2s linear;
  transform-origin: left center;
  animation: 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) 0s infinite normal none running loader-animate;
}

@keyframes loader-animate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
.pc-container {
  position: relative;
  top: 74px;
  margin-left: 280px;
  min-height: calc(100vh - 135px);
}
.pc-container .pc-content {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
}
@media (max-width: 1199.98px) {
  .pc-container .pc-content.container {
    max-width: 100%;
  }
}
.pc-container .page-header + .row {
  padding-top: 24px;
}
.pc-container .page-header + .pc-content {
  padding-top: 85px;
}

.page-header {
  display: flex;
  align-items: center;
  top: 74px;
  left: 280px;
  right: 0;
  z-index: 1023;
  min-height: 55px;
  padding: 13px 0px;
  background: transparent;
  border-radius: 8px;
}
.page-header .page-block {
  width: 100%;
}
.page-header .page-header-title {
  display: inline-block;
}
.page-header h5, .page-header .h5 {
  margin-bottom: 0;
  margin-right: 8px;
  padding-right: 8px;
  font-weight: 500;
}
.page-header .breadcrumb {
  padding: 0;
  display: inline-flex;
  background: transparent;
  font-size: 13px;
}
.page-header .breadcrumb a {
  color: var(--bs-body-color);
}
.page-header .breadcrumb .breadcrumb-item a:hover {
  color: #1A237E;
}
.page-header .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  position: relative;
  top: 2px;
}
.page-header .breadcrumb .breadcrumb-item:last-child {
  opacity: 0.75;
}
.page-header .breadcrumb svg {
  width: 14px;
  height: 14px;
  vertical-align: baseline;
}

.upgrade-card .card-body {
  padding-bottom: 100px;
  z-index: 1;
  position: relative;
}
.upgrade-card > .container {
  z-index: 5;
  position: relative;
  margin-top: -60px;
}
.upgrade-card .up-price-table td {
  border-color: #f8f9fa;
}
.upgrade-card .up-price-table th {
  border: none;
}
.upgrade-card .up-price-table th + th {
  border-radius: 5px 5px 0 0;
  padding: 20px 0;
}
.upgrade-card .up-price-table tbody tr:first-child td {
  border-top: none;
}
.upgrade-card .up-price-table td,
.upgrade-card .up-price-table th {
  text-align: right;
}
.upgrade-card .up-price-table td + td,
.upgrade-card .up-price-table td + th,
.upgrade-card .up-price-table th + td,
.upgrade-card .up-price-table th + th {
  text-align: center;
}
.upgrade-card .up-price-table td + td,
.upgrade-card .up-price-table th + td {
  border-left: 1px solid #f8f9fa;
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition: filter 0.15s linear;
  width: 20px;
  height: 20px;
}
.hamburger.is-active .hamburger-inner {
  background-color: #000;
}
.hamburger.is-active .hamburger-inner::after, .hamburger.is-active .hamburger-inner::before {
  background-color: #000;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 18px;
  height: 2px;
  background-color: var(--pc-header-color);
  border-radius: 4px;
  position: absolute;
  transition: transform 0.15s ease;
}
.hamburger-inner::after, .hamburger-inner::before {
  width: 18px;
  height: 2px;
  background-color: var(--pc-header-color);
  border-radius: 4px;
  position: absolute;
  transition: transform 0.15s ease;
}
.hamburger-inner::after, .hamburger-inner::before {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -6px;
}
.hamburger-inner::after {
  bottom: -6px;
}

.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(4px, 2px, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(4px, -2px, 0) rotate(-45deg) scale(0.7, 1);
}

@media (max-width: 1024px) {
  .pc-container {
    margin-left: 0px;
    margin-right: 0px;
  }
  .pc-container .page-header + .row,
  .pc-container .page-header + .pc-content,
  .pc-container .pc-content {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .page-header {
    position: relative;
    left: 0;
    top: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 575.98px) {
  .col-form-label {
    padding-top: 0;
    padding-bottom: 5px;
  }
  .pc-container .pc-content {
    padding: 15px;
  }
  .pc-container .container {
    padding-left: 0;
    padding-right: 0;
  }
  .pc-container .page-header + .row,
  .pc-container .page-header + .pc-content {
    padding-left: 0;
    padding-right: 0;
  }
  .page-header h5, .page-header .h5 {
    padding-bottom: 5px;
    margin-bottom: 0;
    border-right: none;
    font-size: 14px;
  }
  .page-header .breadcrumb {
    display: flex;
    margin-bottom: 0;
    font-size: 11px;
  }
  .page-header .text-md-right {
    margin-top: 5px;
  }
}
.navbar-content {
  position: relative;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.simplebar-content-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before, .simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}
[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #aeb9c5;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}
.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media (min-width: 768px) {
  .footer-wrapper.container,
  .pc-content.container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .footer-wrapper.container,
  .pc-content.container {
    max-width: 720px;
  }
}
@media (min-width: 1200px) {
  .footer-wrapper.container,
  .pc-content.container {
    max-width: 960px;
  }
}
@media (min-width: 1400px) {
  .footer-wrapper.container,
  .pc-content.container {
    max-width: 1140px;
  }
}

.floting-button {
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 1030;
}
.floting-button .btn, .floting-button .introjs-tooltip .introjs-button, .introjs-tooltip .floting-button .introjs-button {
  animation: pulse 2s infinite;
}
@media (max-width: 575.98px) {
  .floting-button .btn, .floting-button .introjs-tooltip .introjs-button, .introjs-tooltip .floting-button .introjs-button {
    --bs-btn-padding-y: 0.8rem;
    --bs-btn-padding-x: 0.8rem;
    --bs-btn-border-radius: 50px;
  }
  .floting-button .btn span, .floting-button .introjs-tooltip .introjs-button span, .introjs-tooltip .floting-button .introjs-button span {
    display: none;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.3);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(var(--bs-danger-rgb), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0);
  }
}
[data-pc-layout=compact] .pc-sidebar .pc-user-card {
  display: none;
}
[data-pc-layout=compact] .pc-sidebar .pc-navbar > .pc-item {
  margin: 4px 14px;
}
[data-pc-layout=compact] .pc-sidebar .pc-micon {
  height: 27px;
  width: 46px;
}
[data-pc-layout=compact] .pc-sidebar .pc-micon svg {
  width: 26px;
  height: 26px;
}
[data-pc-layout=compact] .pc-sidebar .m-header .logo.logo-sm {
  width: 50px;
}
[data-pc-layout=compact] .pc-sidebar .pc-navbar > .pc-item.pc-hasmenu > .pc-submenu {
  position: absolute;
  left: 94px;
  top: 0;
  height: 100vh;
  min-width: 186px;
  background: #f8f9fa;
}
[data-pc-layout=compact] .pc-sidebar .pc-navbar > .pc-item > .pc-submenu .pc-link {
  padding: 12px 30px 12px 45px;
}
[data-pc-layout=compact] .pc-sidebar .pc-navbar > .pc-item > .pc-submenu .pc-link::after {
  left: 30px;
}
[data-pc-layout=compact] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link {
  padding: 12px 30px 12px 52px;
}
[data-pc-layout=compact] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link::after {
  left: 40px;
}
[data-pc-layout=compact] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link {
  padding: 12px 30px 12px 70px;
}
[data-pc-layout=compact] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link::after {
  left: 52px;
}
[data-pc-layout=compact] .pc-sidebar:not(.pc-compact-submenu-active) {
  width: 100px;
}
[data-pc-layout=compact] .pc-sidebar:not(.pc-compact-submenu-active) .m-header {
  padding: 16px 12px;
  width: 100px;
}
[data-pc-layout=compact] .pc-sidebar:not(.pc-compact-submenu-active) .m-header > a {
  margin: 0 auto;
}
[data-pc-layout=compact] .pc-sidebar:not(.pc-compact-submenu-active) .m-header .logo.logo-lg {
  display: none;
}
[data-pc-layout=compact] .pc-sidebar:not(.pc-compact-submenu-active) .m-header .logo.logo-sm {
  display: inline-block;
}
@media (min-width: 1025px) {
  [data-pc-layout=compact] .pc-sidebar:not(.pc-compact-submenu-active) ~ .pc-footer,
  [data-pc-layout=compact] .pc-sidebar:not(.pc-compact-submenu-active) ~ .pc-container {
    margin-left: 100px;
  }
}
@media (max-width: 1024.98px) {
  [data-pc-layout=compact] .pc-sidebar:not(.pc-compact-submenu-active) .m-header {
    width: 100px;
  }
  [data-pc-layout=compact] .pc-sidebar:not(.pc-compact-submenu-active) .m-header .b-brand {
    width: 50px;
    overflow: hidden;
    margin: 0 auto;
  }
}
@media (max-width: 1024.98px) {
  [data-pc-layout=compact] .pc-sidebar:not(.mob-sidebar-active) {
    left: -300px;
  }
}
[data-pc-layout=compact] .pc-sidebar .navbar-content {
  width: 100px;
  height: calc(100vh - 74px);
}
[data-pc-layout=compact] .pc-sidebar .pc-compact-submenu {
  position: relative;
}
[data-pc-layout=compact] .pc-sidebar .pc-compact-submenu::after {
  content: "";
  width: var(--bs-border-width);
  background: var(--bs-border-color);
  height: calc(100% - 30px);
  position: absolute;
  left: 0;
  top: 0;
}
[data-pc-layout=compact] .pc-sidebar .pc-compact-submenu .pc-compact-title {
  padding: 20px 18px;
  margin-bottom: 14px;
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}
[data-pc-layout=compact] .pc-sidebar .pc-compact-submenu .pc-compact-title .avtar i {
  font-size: 18px;
}
[data-pc-layout=compact] .pc-sidebar .pc-compact-submenu .pc-compact-title h5, [data-pc-layout=compact] .pc-sidebar .pc-compact-submenu .pc-compact-title .h5 {
  font-weight: 600;
}
[data-pc-layout=compact] .pc-sidebar .pc-compact-submenu .pc-compact-list {
  height: calc(100vh - 74px - 80px);
}
[data-pc-layout=compact] .pc-sidebar .pc-compact-submenu .pc-compact-list .simplebar-content > .pc-submenu > .pc-item:before {
  left: 15px;
}
[data-pc-layout=compact] .pc-sidebar .pc-compact-submenu .pc-compact-list .simplebar-content > .pc-submenu > .pc-item > .pc-link {
  padding: 10px 16px;
}
[data-pc-layout=compact] .pc-sidebar .pc-compact-submenu .pc-compact-list .simplebar-content > .pc-submenu > .pc-item .pc-submenu > .pc-item:before {
  left: 30px;
}
[data-pc-layout=compact] .pc-sidebar .pc-compact-submenu .pc-compact-list .simplebar-content > .pc-submenu > .pc-item .pc-submenu > .pc-item .pc-link {
  padding: 10px 16px 10px 30px;
}
[data-pc-layout=compact] .pc-sidebar .pc-compact-submenu .pc-compact-list .simplebar-content > .pc-submenu > .pc-item .pc-submenu .pc-submenu > .pc-item:before {
  left: 45px;
}
[data-pc-layout=compact] .pc-sidebar .pc-compact-submenu .pc-compact-list .simplebar-content > .pc-submenu > .pc-item .pc-submenu .pc-submenu > .pc-item .pc-link {
  padding: 10px 16px 10px 45px;
}
[data-pc-layout=compact] .pc-sidebar.pc-compact-submenu-active .navbar-content {
  width: 300px;
}
[data-pc-layout=compact] .pc-sidebar.pc-compact-submenu-active .navbar-wrapper {
  display: flex !important;
  flex-wrap: wrap;
  width: 300px;
}
[data-pc-layout=compact] .pc-sidebar.pc-compact-submenu-active .navbar-wrapper .m-header {
  width: 100%;
  border-bottom: 1px solid var(--bs-border-color);
}
[data-pc-layout=compact] .pc-sidebar.pc-compact-submenu-active .navbar-wrapper .pc-compact-submenu {
  flex: 1;
  width: calc(100% - 100px);
}
[data-pc-layout=compact] .pc-sidebar.pc-compact-submenu-active .navbar-wrapper .pc-compact-submenu .pc-compact-list .simplebar-content > .pc-submenu {
  display: block !important;
}
[data-pc-layout=compact] .pc-sidebar.pc-compact-submenu-active .navbar-content::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background-color: var(--bs-border-color);
  top: 0;
  left: 92px;
}
@media (min-width: 1025px) {
  [data-pc-layout=compact] .pc-sidebar.pc-compact-submenu-active ~ .pc-footer,
  [data-pc-layout=compact] .pc-sidebar.pc-compact-submenu-active ~ .pc-container {
    margin-left: 300px;
  }
}
[data-pc-layout=compact] .pc-sidebar .pc-navbar > .pc-item > .pc-link {
  padding: 14px 12px;
  display: inline-block;
}
[data-pc-layout=compact] .pc-sidebar .pc-navbar > .pc-item > .pc-link .pc-micon {
  margin-right: 0;
}
[data-pc-layout=compact] .pc-sidebar .pc-navbar > .pc-item > .pc-link .pc-micon i {
  vertical-align: middle;
  font-size: 22px;
}
[data-pc-layout=compact] .pc-sidebar.pc-sidebar-hide {
  width: 0;
}
[data-pc-layout=compact] .pc-sidebar.pc-sidebar-hide ~ .pc-footer,
[data-pc-layout=compact] .pc-sidebar.pc-sidebar-hide ~ .pc-container {
  margin-left: 0px;
}
[data-pc-layout=compact] .pc-sidebar.pc-sidebar-hide .pc-navbar > .pc-item.pc-hasmenu.pc-trigger > .pc-submenu {
  display: none !important;
}
[data-pc-layout=compact] .pc-sidebar .pc-badge,
[data-pc-layout=compact] .pc-sidebar .pc-caption,
[data-pc-layout=compact] .pc-sidebar .pc-mtext,
[data-pc-layout=compact] .pc-sidebar .pc-navbar > li > a > .pc-arrow {
  display: none;
}
@media (min-width: 1025px) {
  [data-pc-layout=compact] .pc-header {
    left: 100px;
  }
}
[data-pc-layout=compact].pc-sidebar-hide .pc-header {
  left: 0px;
}
@media (min-width: 1025px) {
  [data-pc-layout=compact].pc-compact-submenu-active.pc-sidebar-hide .pc-header {
    left: 0px;
  }
  [data-pc-layout=compact].pc-compact-submenu-active .pc-header {
    left: 300px;
  }
}
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar:not(.pc-compact-submenu-active) ~ .pc-footer,
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar:not(.pc-compact-submenu-active) ~ .pc-container {
  margin-left: 20px;
  margin-right: 100px;
}
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar.pc-compact-submenu-active ~ .pc-container,
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar.pc-compact-submenu-active ~ .pc-footer {
  margin-left: 20px;
  margin-right: 300px;
}
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar ~ .pc-footer,
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar ~ .pc-container {
  transition: margin-right 0.15s ease;
}
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide ~ .pc-footer,
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide ~ .pc-container {
  margin-right: 20px;
}
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar .pc-compact-submenu::after {
  left: auto;
  right: 0;
}
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar .pc-compact-submenu .pc-compact-list .simplebar-content > .pc-submenu > .pc-item:before {
  left: auto;
  right: 15px;
}
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar .pc-compact-submenu .pc-compact-list .simplebar-content > .pc-submenu > .pc-item .pc-submenu > .pc-item:before {
  left: auto;
  right: 30px;
}
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar .pc-compact-submenu .pc-compact-list .simplebar-content > .pc-submenu > .pc-item .pc-submenu > .pc-item .pc-link {
  padding: 10px 30px 10px 16px;
}
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar .pc-compact-submenu .pc-compact-list .simplebar-content > .pc-submenu > .pc-item .pc-submenu .pc-submenu > .pc-item:before {
  left: auto;
  right: 45px;
}
[data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar .pc-compact-submenu .pc-compact-list .simplebar-content > .pc-submenu > .pc-item .pc-submenu .pc-submenu > .pc-item .pc-link {
  padding: 10px 45px 10px 16px;
}
@media (min-width: 1025px) {
  [data-pc-layout=compact][data-pc-direction=rtl] .pc-header {
    right: 100px;
    left: 0;
  }
  [data-pc-layout=compact][data-pc-direction=rtl].pc-sidebar-hide .pc-header {
    right: 0;
  }
  [data-pc-layout=compact][data-pc-direction=rtl].pc-compact-submenu-active .pc-header {
    right: 300px;
  }
  [data-pc-layout=compact][data-pc-direction=rtl].pc-compact-submenu-active.pc-sidebar-hide .pc-header {
    right: 0;
  }
}
@media (max-width: 1024px) {
  [data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar:not(.pc-compact-submenu-active) ~ .pc-footer,
  [data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar:not(.pc-compact-submenu-active) ~ .pc-container {
    margin-right: 20px;
  }
  [data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar.pc-compact-submenu-active ~ .pc-footer,
  [data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar.pc-compact-submenu-active ~ .pc-container {
    margin-right: 20px;
  }
  [data-pc-layout=compact][data-pc-direction=rtl] .pc-sidebar:not(.mob-sidebar-active) {
    left: auto;
    right: -300px;
  }
}

@media (min-width: 1025px) {
  [data-pc-layout=horizontal] .pc-header {
    z-index: 1027;
    box-shadow: none;
  }
  [data-pc-layout=horizontal] .pc-header .pc-h-item.pc-sidebar-collapse {
    display: none;
  }
  [data-pc-layout=horizontal] .pc-sidebar {
    width: 100%;
    background: var(--pc-header-background);
    backdrop-filter: blur(7px);
    height: 60px;
    overflow: visible;
  }
  [data-pc-layout=horizontal] .pc-sidebar .navbar-content,
  [data-pc-layout=horizontal] .pc-sidebar .m-header {
    background: var(--pc-header-background);
    backdrop-filter: blur(7px);
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-badge,
  [data-pc-layout=horizontal] .pc-sidebar .pc-caption:not(:first-child):after {
    display: none;
  }
  [data-pc-layout=horizontal] .pc-sidebar .navbar-wrapper {
    width: 100%;
  }
  [data-pc-layout=horizontal] .pc-sidebar .navbar-content {
    height: 57px;
    padding: 6px 20px;
    box-shadow: var(--pc-sidebar-shadow);
    border-bottom: var(--pc-sidebar-border);
  }
  [data-pc-layout=horizontal] .pc-sidebar .navbar-content .simplebar-mask,
  [data-pc-layout=horizontal] .pc-sidebar .navbar-content .simplebar-content-wrapper {
    overflow: visible !important;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-link {
    display: block;
    align-items: center;
    padding: 10px 14px;
    color: var(--pc-sidebar-color);
    font-size: 14px;
    font-weight: 500;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-link:active, [data-pc-layout=horizontal] .pc-sidebar .pc-link:focus, [data-pc-layout=horizontal] .pc-sidebar .pc-link:hover {
    text-decoration: none;
    color: var(--pc-sidebar-active-color);
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-link .pc-micon i {
    vertical-align: middle;
  }
  [data-pc-layout=horizontal] .pc-sidebar .card {
    display: none;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar {
    display: inline-block !important;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-link {
    display: flex;
    align-items: center;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar > .pc-item {
    position: relative;
    margin: 0;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar > .pc-item:hover:not(.active) > .pc-link {
    color: var(--bs-primary);
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar > .pc-item:hover:not(.active) > .pc-link:after {
    background: var(--bs-primary);
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar > .pc-item .pc-submenu {
    position: absolute;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar > .pc-item .pc-submenu:after {
    display: none;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar > .pc-item > .pc-submenu.edge {
    left: auto;
    right: 0;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar > .pc-item > .pc-link {
    margin-left: 2px;
    margin-right: 2px;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar > .pc-item > .pc-link > .pc-arrow {
    transform: rotate(90deg);
    margin-left: 10px;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-link {
    padding: 12px 30px;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link {
    padding: 12px 30px;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link {
    padding: 12px 30px;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-item {
    display: inline-block;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-item.pc-caption {
    padding: 0 !important;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-arrow {
    margin-left: auto;
    float: right;
    display: inline-block;
    transition: all 0.2s ease-in-out;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-arrow > svg {
    width: 14px;
    height: 14px;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu {
    position: absolute;
    background: var(--pc-header-submenu-background);
    padding: 15px 0;
    min-width: 225px;
    border-radius: 4px;
    animation: 0.2s ease-in-out 0s normal forwards 1 fadein;
    box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu:before {
    display: none;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-item {
    display: block;
    position: relative;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-item .pc-submenu > .pc-item:before, [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-item::before {
    left: 20px;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-item .pc-link {
    color: var(--pc-header-submenu-color);
    position: relative;
    padding: 12px 15px 12px 20px;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-item .pc-link .pc-icon svg,
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-item .pc-link .pc-icon i {
    color: var(--pc-sidebar-color);
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-item .pc-link:after {
    display: none;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-item:hover > .pc-link, [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-item > .pc-link:hover {
    color: var(--bs-primary);
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-submenu {
    left: 100%;
    top: -15px;
    z-index: 1;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-submenu.edge {
    left: auto;
    right: 100%;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-submenu.edge-alt {
    top: auto;
    bottom: -15px;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-submenu.edge-alt.edge-alt-full::-webkit-scrollbar {
    width: 6px;
    opacity: 0;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-submenu.edge-alt.edge-alt-full::-webkit-scrollbar:hover {
    opacity: 1;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-submenu.edge-alt.edge-alt-full::-webkit-scrollbar-track {
    background: transparent;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-submenu.edge-alt.edge-alt-full::-webkit-scrollbar-thumb {
    background: #e9ecef;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-submenu.edge-alt.edge-alt-full::-webkit-scrollbar-thumb:hover {
    background: #aeb9c5;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-submenu.edge-alt.edge-alt-full .pc-submenu {
    left: 200px;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-submenu .pc-submenu.edge-alt.edge-alt-full .pc-submenu.edge {
    left: 0;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-item .pc-submenu {
    display: none;
  }
  [data-pc-layout=horizontal] .pc-sidebar .pc-item:hover > .pc-submenu {
    display: block !important;
  }
  [data-pc-layout=horizontal] .pc-container,
  [data-pc-layout=horizontal] .pc-footer {
    margin-left: 0px;
  }
  [data-pc-layout=horizontal] .pc-container {
    top: calc(74px + 60px);
    min-height: calc(100vh - 74px - 120px);
  }
  [data-pc-layout=horizontal] .pc-container .pc-content > .row:first-child {
    padding-top: 20px;
  }
  [data-pc-layout=horizontal] .pc-footer {
    top: 60px;
  }
}
[data-pc-layout=horizontal][data-pc-direction=rtl] .pc-container,
[data-pc-layout=horizontal][data-pc-direction=rtl] .pc-footer {
  margin-right: 20px;
}
@media (min-width: 1025px) {
  [data-pc-layout=horizontal][data-pc-direction=rtl] .pc-sidebar .pc-arrow {
    margin-right: auto;
    margin-left: 0;
  }
  [data-pc-layout=horizontal][data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-item:before {
    right: 20px;
  }
  [data-pc-layout=horizontal][data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-item .pc-link {
    padding: 8px 20px 8px 15px;
  }
  [data-pc-layout=horizontal][data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-item:before {
    right: 20px;
  }
  [data-pc-layout=horizontal][data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-item:before {
    right: 20px;
  }
  [data-pc-layout=horizontal][data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-item .pc-link {
    padding: 8px 20px 8px 15px;
  }
}

[data-pc-layout=tab] .pc-sidebar .tab-container {
  display: flex;
  align-items: flex-start;
}
[data-pc-layout=tab] .pc-sidebar .tab-container .tab-sidemenu,
[data-pc-layout=tab] .pc-sidebar .tab-container .tab-link {
  height: calc(100vh - 74px);
}
[data-pc-layout=tab] .pc-sidebar .tab-container .tab-sidemenu {
  width: 75px;
  border-right: 1px solid var(--bs-border-color);
}
[data-pc-layout=tab] .pc-sidebar .tab-container .tab-sidemenu .nav-link {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 4px auto;
  border-radius: var(--bs-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--pc-sidebar-color);
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
}
[data-pc-layout=tab] .pc-sidebar .tab-container .tab-sidemenu .nav-link:hover {
  background: rgba(var(--pc-sidebar-color-rgb), 0.2);
}
[data-pc-layout=tab] .pc-sidebar .tab-container .tab-sidemenu .nav-link:focus, [data-pc-layout=tab] .pc-sidebar .tab-container .tab-sidemenu .nav-link.active {
  color: var(--bs-primary);
  background: rgba(var(--bs-primary-rgb), 0.2);
}
[data-pc-layout=tab] .pc-sidebar .tab-container .tab-link {
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
[data-pc-layout=tab] .pc-sidebar .pc-caption,
[data-pc-layout=tab] .pc-sidebar .pc-user-card {
  display: none;
}
[data-pc-layout=tab] .pc-sidebar .pc-mtext {
  margin-left: 10px;
}
[data-pc-layout=tab] .pc-sidebar .pc-micon {
  margin-right: 0;
}
[data-pc-layout=tab] .pc-sidebar:not(.pc-sidebar-hide) {
  width: 320px;
}
[data-pc-layout=tab] .pc-sidebar:not(.pc-sidebar-hide) .navbar-wrapper {
  width: 320px;
}
[data-pc-layout=tab] .pc-sidebar .pc-navbar > .pc-item > .pc-submenu .pc-link {
  padding: 12px 30px 12px 45px;
}
[data-pc-layout=tab] .pc-sidebar .pc-navbar > .pc-item > .pc-submenu .pc-link::after {
  left: 30px;
}
[data-pc-layout=tab] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link {
  padding: 12px 30px 12px 52px;
}
[data-pc-layout=tab] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link::after {
  left: 40px;
}
[data-pc-layout=tab] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link {
  padding: 12px 30px 12px 70px;
}
[data-pc-layout=tab] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link::after {
  left: 52px;
}
@media (min-width: 1025px) {
  [data-pc-layout=tab] .pc-sidebar:not(.pc-sidebar-hide) ~ .pc-header {
    left: 320px;
  }
  [data-pc-layout=tab] .pc-sidebar:not(.pc-sidebar-hide) ~ .pc-container,
  [data-pc-layout=tab] .pc-sidebar:not(.pc-sidebar-hide) ~ .pc-footer {
    margin-left: 320px;
  }
}
@media (max-width: 1024px) {
  [data-pc-layout=tab] .pc-sidebar:not(.mob-sidebar-active) {
    left: -320px;
  }
}
[data-pc-layout=tab][data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item > .pc-submenu .pc-link {
  padding: 12px 45px 12px 30px;
}
[data-pc-layout=tab][data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item > .pc-submenu .pc-link::after {
  right: 30px;
}
[data-pc-layout=tab][data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link {
  padding: 12px 52px 12px 30px;
}
[data-pc-layout=tab][data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link::after {
  right: 40px;
}
[data-pc-layout=tab][data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link {
  padding: 12px 70px 12px 30px;
}
[data-pc-layout=tab][data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link::after {
  right: 52px;
}
@media (min-width: 1025px) {
  [data-pc-layout=tab][data-pc-direction=rtl] .pc-sidebar:not(.pc-sidebar-hide) ~ .pc-header {
    left: 0;
    right: 320px;
  }
  [data-pc-layout=tab][data-pc-direction=rtl] .pc-sidebar:not(.pc-sidebar-hide) ~ .pc-container,
  [data-pc-layout=tab][data-pc-direction=rtl] .pc-sidebar:not(.pc-sidebar-hide) ~ .pc-footer {
    margin-left: 0;
    margin-right: 320px;
  }
}
@media (max-width: 1024px) {
  [data-pc-layout=tab][data-pc-direction=rtl] .pc-sidebar:not(.mob-sidebar-active) {
    left: 0;
    right: -320px;
  }
}
[data-pc-layout=tab][data-pc-direction=rtl] .pc-sidebar .tab-container .tab-sidemenu {
  border-left: 1px solid var(--bs-border-color);
  border-right: none;
}

.dropbox-card {
  overflow: hidden;
  position: relative;
}
.dropbox-card::after {
  content: "";
  background-image: url("../images/widget/img-dropbox-bg.svg");
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.5;
  background-position: bottom right;
  background-size: 100%;
  background-repeat: no-repeat;
}
.dropbox-card > * {
  position: relative;
  z-index: 5;
}
.dropbox-card .avtar {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}

.available-balance-card {
  overflow: hidden;
  position: relative;
}
.available-balance-card::after {
  content: "";
  background: linear-gradient(245deg, transparent 25.46%, rgba(0, 0, 0, 0.2) 68.77%, rgba(0, 0, 0, 0.3) 81.72%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.6;
}
.available-balance-card > * {
  position: relative;
  z-index: 5;
}
.available-balance-card .avtar {
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
}

.analytics-tab {
  border-bottom: 0;
}
.analytics-tab .nav-item {
  margin-bottom: 0px;
}
.analytics-tab .nav-item .nav-link {
  position: relative;
  padding: 1rem 0.8rem;
  margin: 0 0.2rem;
  font-weight: 500;
  color: var(--bs-body-color);
  display: inline-flex;
  align-items: center;
}
.analytics-tab .nav-item .nav-link:after {
  content: "";
  background: var(--bs-primary);
  position: absolute;
  transition: all 0.3s ease-in-out;
  left: 50%;
  right: 50%;
  bottom: -1px;
  height: 2px;
  border-radius: 2px 2px 0 0;
}
.analytics-tab .nav-link:hover {
  border-color: transparent;
  background: transparent;
  color: var(--bs-primary);
}
.analytics-tab .nav-item.show .nav-link,
.analytics-tab .nav-link.active {
  border-color: transparent;
  color: var(--bs-primary);
  background: transparent;
}
.analytics-tab .nav-item.show .nav-link:after,
.analytics-tab .nav-link.active:after {
  left: 0;
  right: 0;
}

.overview-product-legends {
  border: 1px solid var(--bs-border-color);
  padding: 16px;
  border-radius: var(--bs-border-radius);
}
.overview-product-legends p {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.overview-product-legends p::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: currentColor;
  margin-left: -10px;
}
.overview-product-legends p span {
  color: var(--bs-body-color);
}

.able-user-group .avtar {
  width: 30px;
  height: 30px;
}

.table-card .card-body,
.table-body.card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
.table-card .card-body .table > thead > tr > th,
.table-body.card-body .table > thead > tr > th {
  border-top: 0;
}
.table-card .card-body .table tr td:first-child,
.table-card .card-body .table tr th:first-child,
.table-body.card-body .table tr td:first-child,
.table-body.card-body .table tr th:first-child {
  padding-left: 25px;
}
@media (max-width: 575.98px) {
  .table-card .card-body .table tr td:first-child,
  .table-card .card-body .table tr th:first-child,
  .table-body.card-body .table tr td:first-child,
  .table-body.card-body .table tr th:first-child {
    padding-left: 20px;
  }
}
.table-card .card-body .table tr td:last-child,
.table-card .card-body .table tr th:last-child,
.table-body.card-body .table tr td:last-child,
.table-body.card-body .table tr th:last-child {
  padding-right: 25px;
}
@media (max-width: 575.98px) {
  .table-card .card-body .table tr td:last-child,
  .table-card .card-body .table tr th:last-child,
  .table-body.card-body .table tr td:last-child,
  .table-body.card-body .table tr th:last-child {
    padding-right: 20px;
  }
}
.table-card .card-body .table.without-header tr:first-child td,
.table-body.card-body .table.without-header tr:first-child td {
  border-top: none;
}

.social-widget-card {
  color: #fff;
}
.social-widget-card i {
  position: absolute;
  right: 14px;
  top: 24px;
  font-size: 48px;
  opacity: 0.4;
  transition: all ease-in 0.3s;
}
.social-widget-card:hover i {
  opacity: 1;
  transform: scale(1.1);
}

.task-card .task-list {
  position: relative;
}
.task-card .task-list:after {
  content: "";
  position: absolute;
  background: #e7eaee;
  height: 100%;
  width: 2px;
  top: 0;
  left: 10px;
  z-index: 1;
}
.task-card .task-list:before {
  content: "";
  position: absolute;
  background: #e7eaee;
  height: 15px;
  width: 15px;
  bottom: -14px;
  left: 3px;
  z-index: 2;
  border-radius: 50%;
}
.task-card .task-list li {
  margin-bottom: 30px;
  padding-left: 30px;
  position: relative;
}
.task-card .task-list li .task-icon {
  position: absolute;
  left: 3px;
  top: 1px;
  border-radius: 50%;
  padding: 2px;
  color: #fff;
  min-width: 15px;
  min-height: 15px;
  z-index: 2;
}

.new-cust-card img {
  width: 40px;
}
.new-cust-card h6, .new-cust-card .h6 {
  margin-bottom: 0;
}
.new-cust-card .align-middle {
  position: relative;
}
.new-cust-card .align-middle .status {
  position: absolute;
  right: 0;
  top: 19px;
  font-size: 13px;
}
.new-cust-card .align-middle .status.active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #2ca87f;
}

.table-card .row-table {
  display: flex;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
}
.table-card .row-table svg {
  margin: 0 20px;
}
.table-card .row-table > [class*=col-] {
  display: table-cell;
  float: none;
  table-layout: fixed;
  vertical-align: middle;
}
.table-card .row-table > [class*=col-] .row {
  display: flex;
  align-items: center;
}

.welcome-banner {
  overflow: hidden;
  position: relative;
}
.welcome-banner::after {
  content: "";
  background-image: url("../images/widget/img-dropbox-bg.svg");
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.5;
  background-position: bottom right;
  background-size: 100%;
  background-repeat: no-repeat;
}
.welcome-banner > * {
  position: relative;
  z-index: 5;
}
.welcome-banner .img-welcome-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  z-index: 2;
  padding-right: 64px;
}
@media (max-width: 768px) {
  .welcome-banner .img-welcome-banner {
    padding-right: 24px;
  }
}
.welcome-banner .img-welcome-banner img {
  width: 200px;
}

.avtar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  width: 48px;
  height: 48px;
}
.avtar.avtar-xxs {
  width: 16px;
  height: 16px;
  font-size: 14px;
  border-radius: 12px;
}
.avtar.avtar-xs {
  width: 32px;
  height: 32px;
  font-size: 12px;
  border-radius: 12px;
}
.avtar.avtar-s {
  width: 40px;
  height: 40px;
  font-size: 14px;
  border-radius: 12px;
}
.avtar.avtar-l {
  width: 64px;
  height: 64px;
  font-size: 20px;
  border-radius: 12px;
}
.avtar.avtar-xl {
  width: 80px;
  height: 80px;
  font-size: 24px;
  border-radius: 12px;
}
.avtar.avtar-square {
  border-radius: 4px;
  border: 2px solid;
}
.avtar.avtar-icon {
  border: 2px solid #e7eaee;
}
.avtar.avtar-icon > img {
  width: 60%;
}

.badge.dot {
  width: 6px;
  height: 6px;
  display: block;
  padding: 0;
}
.badge.bg-light-theme {
  background: #ffebf0;
  color: #ff3366;
}
.badge.bg-light-primary {
  background: #e8e9f2;
  color: #1A237E;
}
.badge.bg-light-secondary {
  background: #eff0f2;
  color: #5b6b79;
}
.badge.bg-light-success {
  background: #eaf6f2;
  color: #2ca87f;
}
.badge.bg-light-info {
  background: #ecfafb;
  color: #3ec9d6;
}
.badge.bg-light-warning {
  background: #fcf3e6;
  color: #e58a00;
}
.badge.bg-light-danger {
  background: #fce9e9;
  color: #dc2626;
}
.badge.bg-light-light {
  background: #fefeff;
  color: #f8f9fa;
}
.badge.bg-light-dark {
  background: #e9e9ea;
  color: #212529;
}

.btn, .introjs-tooltip .introjs-button {
  font-size: 14px;
}
.btn i, .introjs-tooltip .introjs-button i {
  font-size: 18px;
}
.btn i.ph-duotone, .introjs-tooltip .introjs-button i.ph-duotone {
  vertical-align: text-bottom;
}
.btn svg, .introjs-tooltip .introjs-button svg {
  width: 18px;
  height: 18px;
}
.btn[class*=btn-link-], .introjs-tooltip [class*=btn-link-].introjs-button, .btn[class*=btn-light-], .introjs-tooltip [class*=btn-light-].introjs-button {
  box-shadow: none;
}
.btn[class*=btn-outline-]:not(:hover), .introjs-tooltip [class*=btn-outline-].introjs-button:not(:hover) {
  box-shadow: none;
}
.btn.btn-shadow, .introjs-tooltip .btn-shadow.introjs-button {
  box-shadow: 0 6px 7px -1px rgba(80, 86, 175, 0.3);
}
.btn.btn-sm i, .introjs-tooltip .btn-sm.introjs-button i, .btn-group-sm > .btn i, .introjs-tooltip .btn-group-sm > .introjs-button i {
  font-size: 14px;
}
.btn.btn-icon, .introjs-tooltip .btn-icon.introjs-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  width: 40px;
  height: 40px;
}
.btn.btn-icon.avtar-xs, .introjs-tooltip .btn-icon.avtar-xs.introjs-button {
  width: 20px;
  height: 20px;
  font-size: 10px;
}
.btn.btn-icon.avtar-s, .introjs-tooltip .btn-icon.avtar-s.introjs-button {
  width: 30px;
  height: 30px;
  font-size: 12px;
}
.btn.btn-icon.avtar-l, .introjs-tooltip .btn-icon.avtar-l.introjs-button {
  width: 50px;
  height: 50px;
  font-size: 18px;
}
.btn.btn-icon.avtar-xl, .introjs-tooltip .btn-icon.avtar-xl.introjs-button {
  width: 60px;
  height: 60px;
  font-size: 20px;
  border-radius: 30px;
}

.btn-light-theme {
  background: #ffebf0;
  color: #ff3366;
  border-color: #ffebf0;
}
.btn-light-theme .material-icons-two-tone {
  background-color: #ff3366;
}
.btn-light-theme:hover {
  background: #ff3366;
  color: #fff;
  border-color: #ff3366;
}
.btn-light-theme:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-theme.focus, .btn-light-theme:focus {
  background: #ff3366;
  color: #fff;
  border-color: #ff3366;
}
.btn-light-theme.focus .material-icons-two-tone, .btn-light-theme:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-theme:not(:disabled):not(.disabled).active, .btn-light-theme:not(:disabled):not(.disabled):active, .show > .btn-light-theme.dropdown-toggle {
  background: #ff3366;
  color: #fff;
  border-color: #ff3366;
}
.btn-light-theme:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-theme:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-theme.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-theme,
.btn-check:checked + .btn-light-theme {
  background: #ff3366;
  color: #fff;
  border-color: #ff3366;
}
.btn-check:active + .btn-light-theme .material-icons-two-tone,
.btn-check:checked + .btn-light-theme .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-theme {
  background: transparent;
  color: #ff3366;
  border-color: transparent;
}
.btn-link-theme .material-icons-two-tone {
  background-color: #ff3366;
}
.btn-link-theme:hover {
  background: #ffebf0;
  color: #ff3366;
  border-color: #ffebf0;
}
.btn-link-theme.focus, .btn-link-theme:focus {
  background: #ffebf0;
  color: #ff3366;
  border-color: #ffebf0;
}
.btn-link-theme:not(:disabled):not(.disabled).active, .btn-link-theme:not(:disabled):not(.disabled):active, .show > .btn-link-theme.dropdown-toggle {
  background: #ffebf0;
  color: #ff3366;
  border-color: #ffebf0;
}

.btn-check:active + .btn-link-theme,
.btn-check:checked + .btn-link-theme {
  background: #ffebf0;
  color: #ff3366;
  border-color: #ffebf0;
}

.btn-link-hover-theme {
  background: transparent;
  color: var(--bs-secondary);
  border-color: transparent;
}
.btn-link-hover-theme .material-icons-two-tone {
  background-color: var(--bs-secondary);
}
.btn-link-hover-theme:hover {
  background: #ffebf0;
  color: #ff3366;
  border-color: #ffebf0;
}
.btn-link-hover-theme.focus, .btn-link-hover-theme:focus {
  background: #ffebf0;
  color: #ff3366;
  border-color: #ffebf0;
}
.btn-link-hover-theme:not(:disabled):not(.disabled).active, .btn-link-hover-theme:not(:disabled):not(.disabled):active, .show > .btn-link-hover-theme.dropdown-toggle {
  background: #ffebf0;
  color: #ff3366;
  border-color: #ffebf0;
}

.btn-check:active + .btn-link-hover-theme,
.btn-check:checked + .btn-link-hover-theme {
  background: #ffebf0;
  color: #ff3366;
  border-color: #ffebf0;
}

.btn-light-primary {
  background: #e8e9f2;
  color: #1A237E;
  border-color: #e8e9f2;
}
.btn-light-primary .material-icons-two-tone {
  background-color: #1A237E;
}
.btn-light-primary:hover {
  background: #1A237E;
  color: #fff;
  border-color: #1A237E;
}
.btn-light-primary:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-primary.focus, .btn-light-primary:focus {
  background: #1A237E;
  color: #fff;
  border-color: #1A237E;
}
.btn-light-primary.focus .material-icons-two-tone, .btn-light-primary:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-primary:not(:disabled):not(.disabled).active, .btn-light-primary:not(:disabled):not(.disabled):active, .show > .btn-light-primary.dropdown-toggle {
  background: #1A237E;
  color: #fff;
  border-color: #1A237E;
}
.btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-primary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-primary,
.btn-check:checked + .btn-light-primary {
  background: #1A237E;
  color: #fff;
  border-color: #1A237E;
}
.btn-check:active + .btn-light-primary .material-icons-two-tone,
.btn-check:checked + .btn-light-primary .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-primary {
  background: transparent;
  color: #1A237E;
  border-color: transparent;
}
.btn-link-primary .material-icons-two-tone {
  background-color: #1A237E;
}
.btn-link-primary:hover {
  background: #e8e9f2;
  color: #1A237E;
  border-color: #e8e9f2;
}
.btn-link-primary.focus, .btn-link-primary:focus {
  background: #e8e9f2;
  color: #1A237E;
  border-color: #e8e9f2;
}
.btn-link-primary:not(:disabled):not(.disabled).active, .btn-link-primary:not(:disabled):not(.disabled):active, .show > .btn-link-primary.dropdown-toggle {
  background: #e8e9f2;
  color: #1A237E;
  border-color: #e8e9f2;
}

.btn-check:active + .btn-link-primary,
.btn-check:checked + .btn-link-primary {
  background: #e8e9f2;
  color: #1A237E;
  border-color: #e8e9f2;
}

.btn-link-hover-primary {
  background: transparent;
  color: var(--bs-secondary);
  border-color: transparent;
}
.btn-link-hover-primary .material-icons-two-tone {
  background-color: var(--bs-secondary);
}
.btn-link-hover-primary:hover {
  background: #e8e9f2;
  color: #1A237E;
  border-color: #e8e9f2;
}
.btn-link-hover-primary.focus, .btn-link-hover-primary:focus {
  background: #e8e9f2;
  color: #1A237E;
  border-color: #e8e9f2;
}
.btn-link-hover-primary:not(:disabled):not(.disabled).active, .btn-link-hover-primary:not(:disabled):not(.disabled):active, .show > .btn-link-hover-primary.dropdown-toggle {
  background: #e8e9f2;
  color: #1A237E;
  border-color: #e8e9f2;
}

.btn-check:active + .btn-link-hover-primary,
.btn-check:checked + .btn-link-hover-primary {
  background: #e8e9f2;
  color: #1A237E;
  border-color: #e8e9f2;
}

.btn-light-secondary {
  background: #eff0f2;
  color: #5b6b79;
  border-color: #eff0f2;
}
.btn-light-secondary .material-icons-two-tone {
  background-color: #5b6b79;
}
.btn-light-secondary:hover {
  background: #5b6b79;
  color: #fff;
  border-color: #5b6b79;
}
.btn-light-secondary:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-secondary.focus, .btn-light-secondary:focus {
  background: #5b6b79;
  color: #fff;
  border-color: #5b6b79;
}
.btn-light-secondary.focus .material-icons-two-tone, .btn-light-secondary:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-secondary:not(:disabled):not(.disabled).active, .btn-light-secondary:not(:disabled):not(.disabled):active, .show > .btn-light-secondary.dropdown-toggle {
  background: #5b6b79;
  color: #fff;
  border-color: #5b6b79;
}
.btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-secondary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-secondary,
.btn-check:checked + .btn-light-secondary {
  background: #5b6b79;
  color: #fff;
  border-color: #5b6b79;
}
.btn-check:active + .btn-light-secondary .material-icons-two-tone,
.btn-check:checked + .btn-light-secondary .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-secondary {
  background: transparent;
  color: #5b6b79;
  border-color: transparent;
}
.btn-link-secondary .material-icons-two-tone {
  background-color: #5b6b79;
}
.btn-link-secondary:hover {
  background: #eff0f2;
  color: #5b6b79;
  border-color: #eff0f2;
}
.btn-link-secondary.focus, .btn-link-secondary:focus {
  background: #eff0f2;
  color: #5b6b79;
  border-color: #eff0f2;
}
.btn-link-secondary:not(:disabled):not(.disabled).active, .btn-link-secondary:not(:disabled):not(.disabled):active, .show > .btn-link-secondary.dropdown-toggle {
  background: #eff0f2;
  color: #5b6b79;
  border-color: #eff0f2;
}

.btn-check:active + .btn-link-secondary,
.btn-check:checked + .btn-link-secondary {
  background: #eff0f2;
  color: #5b6b79;
  border-color: #eff0f2;
}

.btn-link-hover-secondary {
  background: transparent;
  color: var(--bs-secondary);
  border-color: transparent;
}
.btn-link-hover-secondary .material-icons-two-tone {
  background-color: var(--bs-secondary);
}
.btn-link-hover-secondary:hover {
  background: #eff0f2;
  color: #5b6b79;
  border-color: #eff0f2;
}
.btn-link-hover-secondary.focus, .btn-link-hover-secondary:focus {
  background: #eff0f2;
  color: #5b6b79;
  border-color: #eff0f2;
}
.btn-link-hover-secondary:not(:disabled):not(.disabled).active, .btn-link-hover-secondary:not(:disabled):not(.disabled):active, .show > .btn-link-hover-secondary.dropdown-toggle {
  background: #eff0f2;
  color: #5b6b79;
  border-color: #eff0f2;
}

.btn-check:active + .btn-link-hover-secondary,
.btn-check:checked + .btn-link-hover-secondary {
  background: #eff0f2;
  color: #5b6b79;
  border-color: #eff0f2;
}

.btn-light-success {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}
.btn-light-success .material-icons-two-tone {
  background-color: #2ca87f;
}
.btn-light-success:hover {
  background: #2ca87f;
  color: #fff;
  border-color: #2ca87f;
}
.btn-light-success:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-success.focus, .btn-light-success:focus {
  background: #2ca87f;
  color: #fff;
  border-color: #2ca87f;
}
.btn-light-success.focus .material-icons-two-tone, .btn-light-success:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-success:not(:disabled):not(.disabled).active, .btn-light-success:not(:disabled):not(.disabled):active, .show > .btn-light-success.dropdown-toggle {
  background: #2ca87f;
  color: #fff;
  border-color: #2ca87f;
}
.btn-light-success:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-success:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-success.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-success,
.btn-check:checked + .btn-light-success {
  background: #2ca87f;
  color: #fff;
  border-color: #2ca87f;
}
.btn-check:active + .btn-light-success .material-icons-two-tone,
.btn-check:checked + .btn-light-success .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-success {
  background: transparent;
  color: #2ca87f;
  border-color: transparent;
}
.btn-link-success .material-icons-two-tone {
  background-color: #2ca87f;
}
.btn-link-success:hover {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}
.btn-link-success.focus, .btn-link-success:focus {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}
.btn-link-success:not(:disabled):not(.disabled).active, .btn-link-success:not(:disabled):not(.disabled):active, .show > .btn-link-success.dropdown-toggle {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}

.btn-check:active + .btn-link-success,
.btn-check:checked + .btn-link-success {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}

.btn-link-hover-success {
  background: transparent;
  color: var(--bs-secondary);
  border-color: transparent;
}
.btn-link-hover-success .material-icons-two-tone {
  background-color: var(--bs-secondary);
}
.btn-link-hover-success:hover {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}
.btn-link-hover-success.focus, .btn-link-hover-success:focus {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}
.btn-link-hover-success:not(:disabled):not(.disabled).active, .btn-link-hover-success:not(:disabled):not(.disabled):active, .show > .btn-link-hover-success.dropdown-toggle {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}

.btn-check:active + .btn-link-hover-success,
.btn-check:checked + .btn-link-hover-success {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}

.btn-light-info {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}
.btn-light-info .material-icons-two-tone {
  background-color: #3ec9d6;
}
.btn-light-info:hover {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6;
}
.btn-light-info:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-info.focus, .btn-light-info:focus {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6;
}
.btn-light-info.focus .material-icons-two-tone, .btn-light-info:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-info:not(:disabled):not(.disabled).active, .btn-light-info:not(:disabled):not(.disabled):active, .show > .btn-light-info.dropdown-toggle {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6;
}
.btn-light-info:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-info:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-info.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-info,
.btn-check:checked + .btn-light-info {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6;
}
.btn-check:active + .btn-light-info .material-icons-two-tone,
.btn-check:checked + .btn-light-info .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-info {
  background: transparent;
  color: #3ec9d6;
  border-color: transparent;
}
.btn-link-info .material-icons-two-tone {
  background-color: #3ec9d6;
}
.btn-link-info:hover {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}
.btn-link-info.focus, .btn-link-info:focus {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}
.btn-link-info:not(:disabled):not(.disabled).active, .btn-link-info:not(:disabled):not(.disabled):active, .show > .btn-link-info.dropdown-toggle {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}

.btn-check:active + .btn-link-info,
.btn-check:checked + .btn-link-info {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}

.btn-link-hover-info {
  background: transparent;
  color: var(--bs-secondary);
  border-color: transparent;
}
.btn-link-hover-info .material-icons-two-tone {
  background-color: var(--bs-secondary);
}
.btn-link-hover-info:hover {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}
.btn-link-hover-info.focus, .btn-link-hover-info:focus {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}
.btn-link-hover-info:not(:disabled):not(.disabled).active, .btn-link-hover-info:not(:disabled):not(.disabled):active, .show > .btn-link-hover-info.dropdown-toggle {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}

.btn-check:active + .btn-link-hover-info,
.btn-check:checked + .btn-link-hover-info {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}

.btn-light-warning {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}
.btn-light-warning .material-icons-two-tone {
  background-color: #e58a00;
}
.btn-light-warning:hover {
  background: #e58a00;
  color: #fff;
  border-color: #e58a00;
}
.btn-light-warning:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-warning.focus, .btn-light-warning:focus {
  background: #e58a00;
  color: #fff;
  border-color: #e58a00;
}
.btn-light-warning.focus .material-icons-two-tone, .btn-light-warning:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-warning:not(:disabled):not(.disabled).active, .btn-light-warning:not(:disabled):not(.disabled):active, .show > .btn-light-warning.dropdown-toggle {
  background: #e58a00;
  color: #fff;
  border-color: #e58a00;
}
.btn-light-warning:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-warning:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-warning.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-warning,
.btn-check:checked + .btn-light-warning {
  background: #e58a00;
  color: #fff;
  border-color: #e58a00;
}
.btn-check:active + .btn-light-warning .material-icons-two-tone,
.btn-check:checked + .btn-light-warning .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-warning {
  background: transparent;
  color: #e58a00;
  border-color: transparent;
}
.btn-link-warning .material-icons-two-tone {
  background-color: #e58a00;
}
.btn-link-warning:hover {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}
.btn-link-warning.focus, .btn-link-warning:focus {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}
.btn-link-warning:not(:disabled):not(.disabled).active, .btn-link-warning:not(:disabled):not(.disabled):active, .show > .btn-link-warning.dropdown-toggle {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}

.btn-check:active + .btn-link-warning,
.btn-check:checked + .btn-link-warning {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}

.btn-link-hover-warning {
  background: transparent;
  color: var(--bs-secondary);
  border-color: transparent;
}
.btn-link-hover-warning .material-icons-two-tone {
  background-color: var(--bs-secondary);
}
.btn-link-hover-warning:hover {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}
.btn-link-hover-warning.focus, .btn-link-hover-warning:focus {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}
.btn-link-hover-warning:not(:disabled):not(.disabled).active, .btn-link-hover-warning:not(:disabled):not(.disabled):active, .show > .btn-link-hover-warning.dropdown-toggle {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}

.btn-check:active + .btn-link-hover-warning,
.btn-check:checked + .btn-link-hover-warning {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}

.btn-light-danger {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}
.btn-light-danger .material-icons-two-tone {
  background-color: #dc2626;
}
.btn-light-danger:hover {
  background: #dc2626;
  color: #fff;
  border-color: #dc2626;
}
.btn-light-danger:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-danger.focus, .btn-light-danger:focus {
  background: #dc2626;
  color: #fff;
  border-color: #dc2626;
}
.btn-light-danger.focus .material-icons-two-tone, .btn-light-danger:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-danger:not(:disabled):not(.disabled).active, .btn-light-danger:not(:disabled):not(.disabled):active, .show > .btn-light-danger.dropdown-toggle {
  background: #dc2626;
  color: #fff;
  border-color: #dc2626;
}
.btn-light-danger:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-danger:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-danger.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-danger,
.btn-check:checked + .btn-light-danger {
  background: #dc2626;
  color: #fff;
  border-color: #dc2626;
}
.btn-check:active + .btn-light-danger .material-icons-two-tone,
.btn-check:checked + .btn-light-danger .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-danger {
  background: transparent;
  color: #dc2626;
  border-color: transparent;
}
.btn-link-danger .material-icons-two-tone {
  background-color: #dc2626;
}
.btn-link-danger:hover {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}
.btn-link-danger.focus, .btn-link-danger:focus {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}
.btn-link-danger:not(:disabled):not(.disabled).active, .btn-link-danger:not(:disabled):not(.disabled):active, .show > .btn-link-danger.dropdown-toggle {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}

.btn-check:active + .btn-link-danger,
.btn-check:checked + .btn-link-danger {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}

.btn-link-hover-danger {
  background: transparent;
  color: var(--bs-secondary);
  border-color: transparent;
}
.btn-link-hover-danger .material-icons-two-tone {
  background-color: var(--bs-secondary);
}
.btn-link-hover-danger:hover {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}
.btn-link-hover-danger.focus, .btn-link-hover-danger:focus {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}
.btn-link-hover-danger:not(:disabled):not(.disabled).active, .btn-link-hover-danger:not(:disabled):not(.disabled):active, .show > .btn-link-hover-danger.dropdown-toggle {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}

.btn-check:active + .btn-link-hover-danger,
.btn-check:checked + .btn-link-hover-danger {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}

.btn-light-light {
  background: #fefeff;
  color: #f8f9fa;
  border-color: #fefeff;
}
.btn-light-light .material-icons-two-tone {
  background-color: #f8f9fa;
}
.btn-light-light:hover {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa;
}
.btn-light-light:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-light.focus, .btn-light-light:focus {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa;
}
.btn-light-light.focus .material-icons-two-tone, .btn-light-light:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-light:not(:disabled):not(.disabled).active, .btn-light-light:not(:disabled):not(.disabled):active, .show > .btn-light-light.dropdown-toggle {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa;
}
.btn-light-light:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-light:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-light.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-light,
.btn-check:checked + .btn-light-light {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa;
}
.btn-check:active + .btn-light-light .material-icons-two-tone,
.btn-check:checked + .btn-light-light .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-light {
  background: transparent;
  color: #f8f9fa;
  border-color: transparent;
}
.btn-link-light .material-icons-two-tone {
  background-color: #f8f9fa;
}
.btn-link-light:hover {
  background: #fefeff;
  color: #f8f9fa;
  border-color: #fefeff;
}
.btn-link-light.focus, .btn-link-light:focus {
  background: #fefeff;
  color: #f8f9fa;
  border-color: #fefeff;
}
.btn-link-light:not(:disabled):not(.disabled).active, .btn-link-light:not(:disabled):not(.disabled):active, .show > .btn-link-light.dropdown-toggle {
  background: #fefeff;
  color: #f8f9fa;
  border-color: #fefeff;
}

.btn-check:active + .btn-link-light,
.btn-check:checked + .btn-link-light {
  background: #fefeff;
  color: #f8f9fa;
  border-color: #fefeff;
}

.btn-link-hover-light {
  background: transparent;
  color: var(--bs-secondary);
  border-color: transparent;
}
.btn-link-hover-light .material-icons-two-tone {
  background-color: var(--bs-secondary);
}
.btn-link-hover-light:hover {
  background: #fefeff;
  color: #f8f9fa;
  border-color: #fefeff;
}
.btn-link-hover-light.focus, .btn-link-hover-light:focus {
  background: #fefeff;
  color: #f8f9fa;
  border-color: #fefeff;
}
.btn-link-hover-light:not(:disabled):not(.disabled).active, .btn-link-hover-light:not(:disabled):not(.disabled):active, .show > .btn-link-hover-light.dropdown-toggle {
  background: #fefeff;
  color: #f8f9fa;
  border-color: #fefeff;
}

.btn-check:active + .btn-link-hover-light,
.btn-check:checked + .btn-link-hover-light {
  background: #fefeff;
  color: #f8f9fa;
  border-color: #fefeff;
}

.btn-light-dark {
  background: #e9e9ea;
  color: #212529;
  border-color: #e9e9ea;
}
.btn-light-dark .material-icons-two-tone {
  background-color: #212529;
}
.btn-light-dark:hover {
  background: #212529;
  color: #fff;
  border-color: #212529;
}
.btn-light-dark:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dark.focus, .btn-light-dark:focus {
  background: #212529;
  color: #fff;
  border-color: #212529;
}
.btn-light-dark.focus .material-icons-two-tone, .btn-light-dark:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dark:not(:disabled):not(.disabled).active, .btn-light-dark:not(:disabled):not(.disabled):active, .show > .btn-light-dark.dropdown-toggle {
  background: #212529;
  color: #fff;
  border-color: #212529;
}
.btn-light-dark:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-dark:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-dark.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-dark,
.btn-check:checked + .btn-light-dark {
  background: #212529;
  color: #fff;
  border-color: #212529;
}
.btn-check:active + .btn-light-dark .material-icons-two-tone,
.btn-check:checked + .btn-light-dark .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-dark {
  background: transparent;
  color: #212529;
  border-color: transparent;
}
.btn-link-dark .material-icons-two-tone {
  background-color: #212529;
}
.btn-link-dark:hover {
  background: #e9e9ea;
  color: #212529;
  border-color: #e9e9ea;
}
.btn-link-dark.focus, .btn-link-dark:focus {
  background: #e9e9ea;
  color: #212529;
  border-color: #e9e9ea;
}
.btn-link-dark:not(:disabled):not(.disabled).active, .btn-link-dark:not(:disabled):not(.disabled):active, .show > .btn-link-dark.dropdown-toggle {
  background: #e9e9ea;
  color: #212529;
  border-color: #e9e9ea;
}

.btn-check:active + .btn-link-dark,
.btn-check:checked + .btn-link-dark {
  background: #e9e9ea;
  color: #212529;
  border-color: #e9e9ea;
}

.btn-link-hover-dark {
  background: transparent;
  color: var(--bs-secondary);
  border-color: transparent;
}
.btn-link-hover-dark .material-icons-two-tone {
  background-color: var(--bs-secondary);
}
.btn-link-hover-dark:hover {
  background: #e9e9ea;
  color: #212529;
  border-color: #e9e9ea;
}
.btn-link-hover-dark.focus, .btn-link-hover-dark:focus {
  background: #e9e9ea;
  color: #212529;
  border-color: #e9e9ea;
}
.btn-link-hover-dark:not(:disabled):not(.disabled).active, .btn-link-hover-dark:not(:disabled):not(.disabled):active, .show > .btn-link-hover-dark.dropdown-toggle {
  background: #e9e9ea;
  color: #212529;
  border-color: #e9e9ea;
}

.btn-check:active + .btn-link-hover-dark,
.btn-check:checked + .btn-link-hover-dark {
  background: #e9e9ea;
  color: #212529;
  border-color: #e9e9ea;
}

.btn-light-facebook {
  background: #ecf0f7;
  color: #4267b2;
  border-color: #ecf0f7;
}
.btn-light-facebook .material-icons-two-tone {
  background-color: #4267b2;
}
.btn-light-facebook:hover {
  background: #4267b2;
  color: #fff;
  border-color: #4267b2;
}
.btn-light-facebook:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-facebook.focus, .btn-light-facebook:focus {
  background: #4267b2;
  color: #fff;
  border-color: #4267b2;
}
.btn-light-facebook.focus .material-icons-two-tone, .btn-light-facebook:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-facebook:not(:disabled):not(.disabled).active, .btn-light-facebook:not(:disabled):not(.disabled):active, .show > .btn-light-facebook.dropdown-toggle {
  background: #4267b2;
  color: #fff;
  border-color: #4267b2;
}
.btn-light-facebook:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-facebook:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-facebook.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-twitter {
  background: #ecf9ff;
  color: #42c0fb;
  border-color: #ecf9ff;
}
.btn-light-twitter .material-icons-two-tone {
  background-color: #42c0fb;
}
.btn-light-twitter:hover {
  background: #42c0fb;
  color: #fff;
  border-color: #42c0fb;
}
.btn-light-twitter:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-twitter.focus, .btn-light-twitter:focus {
  background: #42c0fb;
  color: #fff;
  border-color: #42c0fb;
}
.btn-light-twitter.focus .material-icons-two-tone, .btn-light-twitter:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-twitter:not(:disabled):not(.disabled).active, .btn-light-twitter:not(:disabled):not(.disabled):active, .show > .btn-light-twitter.dropdown-toggle {
  background: #42c0fb;
  color: #fff;
  border-color: #42c0fb;
}
.btn-light-twitter:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-twitter:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-twitter.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-dribbble {
  background: #fdedf3;
  color: #ec4a89;
  border-color: #fdedf3;
}
.btn-light-dribbble .material-icons-two-tone {
  background-color: #ec4a89;
}
.btn-light-dribbble:hover {
  background: #ec4a89;
  color: #fff;
  border-color: #ec4a89;
}
.btn-light-dribbble:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dribbble.focus, .btn-light-dribbble:focus {
  background: #ec4a89;
  color: #fff;
  border-color: #ec4a89;
}
.btn-light-dribbble.focus .material-icons-two-tone, .btn-light-dribbble:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dribbble:not(:disabled):not(.disabled).active, .btn-light-dribbble:not(:disabled):not(.disabled):active, .show > .btn-light-dribbble.dropdown-toggle {
  background: #ec4a89;
  color: #fff;
  border-color: #ec4a89;
}
.btn-light-dribbble:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-dribbble:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-dribbble.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-pinterest {
  background: #f9e9ea;
  color: #bf2131;
  border-color: #f9e9ea;
}
.btn-light-pinterest .material-icons-two-tone {
  background-color: #bf2131;
}
.btn-light-pinterest:hover {
  background: #bf2131;
  color: #fff;
  border-color: #bf2131;
}
.btn-light-pinterest:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-pinterest.focus, .btn-light-pinterest:focus {
  background: #bf2131;
  color: #fff;
  border-color: #bf2131;
}
.btn-light-pinterest.focus .material-icons-two-tone, .btn-light-pinterest:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-pinterest:not(:disabled):not(.disabled).active, .btn-light-pinterest:not(:disabled):not(.disabled):active, .show > .btn-light-pinterest.dropdown-toggle {
  background: #bf2131;
  color: #fff;
  border-color: #bf2131;
}
.btn-light-pinterest:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-pinterest:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-pinterest.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-youtube {
  background: #fceae8;
  color: #e0291d;
  border-color: #fceae8;
}
.btn-light-youtube .material-icons-two-tone {
  background-color: #e0291d;
}
.btn-light-youtube:hover {
  background: #e0291d;
  color: #fff;
  border-color: #e0291d;
}
.btn-light-youtube:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-youtube.focus, .btn-light-youtube:focus {
  background: #e0291d;
  color: #fff;
  border-color: #e0291d;
}
.btn-light-youtube.focus .material-icons-two-tone, .btn-light-youtube:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-youtube:not(:disabled):not(.disabled).active, .btn-light-youtube:not(:disabled):not(.disabled):active, .show > .btn-light-youtube.dropdown-toggle {
  background: #e0291d;
  color: #fff;
  border-color: #e0291d;
}
.btn-light-youtube:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-youtube:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-youtube.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-googleplus {
  background: #f9ecea;
  color: #c73e2e;
  border-color: #f9ecea;
}
.btn-light-googleplus .material-icons-two-tone {
  background-color: #c73e2e;
}
.btn-light-googleplus:hover {
  background: #c73e2e;
  color: #fff;
  border-color: #c73e2e;
}
.btn-light-googleplus:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-googleplus.focus, .btn-light-googleplus:focus {
  background: #c73e2e;
  color: #fff;
  border-color: #c73e2e;
}
.btn-light-googleplus.focus .material-icons-two-tone, .btn-light-googleplus:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-googleplus:not(:disabled):not(.disabled).active, .btn-light-googleplus:not(:disabled):not(.disabled):active, .show > .btn-light-googleplus.dropdown-toggle {
  background: #c73e2e;
  color: #fff;
  border-color: #c73e2e;
}
.btn-light-googleplus:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-googleplus:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-googleplus.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-instagram {
  background: #f7f2ef;
  color: #aa7c62;
  border-color: #f7f2ef;
}
.btn-light-instagram .material-icons-two-tone {
  background-color: #aa7c62;
}
.btn-light-instagram:hover {
  background: #aa7c62;
  color: #fff;
  border-color: #aa7c62;
}
.btn-light-instagram:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-instagram.focus, .btn-light-instagram:focus {
  background: #aa7c62;
  color: #fff;
  border-color: #aa7c62;
}
.btn-light-instagram.focus .material-icons-two-tone, .btn-light-instagram:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-instagram:not(:disabled):not(.disabled).active, .btn-light-instagram:not(:disabled):not(.disabled):active, .show > .btn-light-instagram.dropdown-toggle {
  background: #aa7c62;
  color: #fff;
  border-color: #aa7c62;
}
.btn-light-instagram:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-instagram:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-instagram.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-viber {
  background: #f2eef5;
  color: #7b519d;
  border-color: #f2eef5;
}
.btn-light-viber .material-icons-two-tone {
  background-color: #7b519d;
}
.btn-light-viber:hover {
  background: #7b519d;
  color: #fff;
  border-color: #7b519d;
}
.btn-light-viber:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-viber.focus, .btn-light-viber:focus {
  background: #7b519d;
  color: #fff;
  border-color: #7b519d;
}
.btn-light-viber.focus .material-icons-two-tone, .btn-light-viber:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-viber:not(:disabled):not(.disabled).active, .btn-light-viber:not(:disabled):not(.disabled):active, .show > .btn-light-viber.dropdown-toggle {
  background: #7b519d;
  color: #fff;
  border-color: #7b519d;
}
.btn-light-viber:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-viber:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-viber.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-behance {
  background: #e6eeff;
  color: #0057ff;
  border-color: #e6eeff;
}
.btn-light-behance .material-icons-two-tone {
  background-color: #0057ff;
}
.btn-light-behance:hover {
  background: #0057ff;
  color: #fff;
  border-color: #0057ff;
}
.btn-light-behance:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-behance.focus, .btn-light-behance:focus {
  background: #0057ff;
  color: #fff;
  border-color: #0057ff;
}
.btn-light-behance.focus .material-icons-two-tone, .btn-light-behance:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-behance:not(:disabled):not(.disabled).active, .btn-light-behance:not(:disabled):not(.disabled):active, .show > .btn-light-behance.dropdown-toggle {
  background: #0057ff;
  color: #fff;
  border-color: #0057ff;
}
.btn-light-behance:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-behance:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-behance.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-dropbox {
  background: #ebf2ff;
  color: #3380ff;
  border-color: #ebf2ff;
}
.btn-light-dropbox .material-icons-two-tone {
  background-color: #3380ff;
}
.btn-light-dropbox:hover {
  background: #3380ff;
  color: #fff;
  border-color: #3380ff;
}
.btn-light-dropbox:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dropbox.focus, .btn-light-dropbox:focus {
  background: #3380ff;
  color: #fff;
  border-color: #3380ff;
}
.btn-light-dropbox.focus .material-icons-two-tone, .btn-light-dropbox:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dropbox:not(:disabled):not(.disabled).active, .btn-light-dropbox:not(:disabled):not(.disabled):active, .show > .btn-light-dropbox.dropdown-toggle {
  background: #3380ff;
  color: #fff;
  border-color: #3380ff;
}
.btn-light-dropbox:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-dropbox:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-dropbox.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-linkedin {
  background: #e6f1f8;
  color: #0077b5;
  border-color: #e6f1f8;
}
.btn-light-linkedin .material-icons-two-tone {
  background-color: #0077b5;
}
.btn-light-linkedin:hover {
  background: #0077b5;
  color: #fff;
  border-color: #0077b5;
}
.btn-light-linkedin:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-linkedin.focus, .btn-light-linkedin:focus {
  background: #0077b5;
  color: #fff;
  border-color: #0077b5;
}
.btn-light-linkedin.focus .material-icons-two-tone, .btn-light-linkedin:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-linkedin:not(:disabled):not(.disabled).active, .btn-light-linkedin:not(:disabled):not(.disabled):active, .show > .btn-light-linkedin.dropdown-toggle {
  background: #0077b5;
  color: #fff;
  border-color: #0077b5;
}
.btn-light-linkedin:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-linkedin:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-linkedin.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-amazon {
  background: #e6e6e6;
  color: #000000;
  border-color: #e6e6e6;
}
.btn-light-amazon .material-icons-two-tone {
  background-color: #000000;
}
.btn-light-amazon:hover {
  background: #000000;
  color: #fff;
  border-color: #000000;
}
.btn-light-amazon:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-amazon.focus, .btn-light-amazon:focus {
  background: #000000;
  color: #fff;
  border-color: #000000;
}
.btn-light-amazon.focus .material-icons-two-tone, .btn-light-amazon:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-amazon:not(:disabled):not(.disabled).active, .btn-light-amazon:not(:disabled):not(.disabled):active, .show > .btn-light-amazon.dropdown-toggle {
  background: #000000;
  color: #fff;
  border-color: #000000;
}
.btn-light-amazon:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-amazon:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-amazon.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-pc-default:not(:hover) {
  color: var(--bs-secondary);
}

.dropdown-toggle.arrow-none:after {
  display: none;
}

.pc-header .dropdown-menu {
  animation: 0.3s ease-in-out 0s normal forwards 0.3s fadein;
}

@keyframes fadein {
  from {
    transform: translate3d(0, 8px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.dropdown .dropdown-item {
  display: flex;
  align-items: center;
}
.dropdown .dropdown-item.active, .dropdown .dropdown-item:active, .dropdown .dropdown-item:focus, .dropdown .dropdown-item:hover {
  background: var(--pc-active-background);
  color: var(--bs-dropdown-link-color);
}
.dropdown .dropdown-item.active i.material-icons-two-tone, .dropdown .dropdown-item:active i.material-icons-two-tone, .dropdown .dropdown-item:focus i.material-icons-two-tone, .dropdown .dropdown-item:hover i.material-icons-two-tone {
  background-color: var(--bs-dropdown-link-color);
}

.dropdown-menu {
  box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  border: none;
  padding: 8px;
}
.dropdown-menu .dropdown-item {
  border-radius: 8px;
  padding: 10px 15px;
}
.dropdown-menu .dropdown-item i {
  font-size: 18px;
  margin-right: 10px;
}
.dropdown-menu .dropdown-item i.material-icons-two-tone {
  vertical-align: bottom;
  font-size: 22px;
  background-color: var(--pc-header-color);
}
.dropdown-menu .dropdown-item svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  fill: #f2f2f2;
}
.dropdown-menu .dropdown-item .float-right svg {
  width: 14px;
  height: 14px;
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.card {
  box-shadow: var(--pc-card-box-shadow);
  margin-bottom: 24px;
  transition: box-shadow 0.2s ease-in-out;
}
.card .card-header {
  border-bottom: 1px solid var(--bs-card-border-color);
}
.card .card-header h5, .card .card-header .h5 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1rem;
}
.card .card-header h5 + p, .card .card-header .h5 + p,
.card .card-header h5 + small,
.card .card-header .h5 + small,
.card .card-header h5 + .small,
.card .card-header .h5 + .small {
  margin-top: 10px;
}
.card .card-header h5 + p:last-child, .card .card-header .h5 + p:last-child,
.card .card-header h5 + small:last-child,
.card .card-header .h5 + small:last-child,
.card .card-header h5 + .small:last-child,
.card .card-header .h5 + .small:last-child {
  margin-bottom: 0;
}
.card .card-header .card-header-right {
  right: 10px;
  top: 10px;
  float: right;
  padding: 0;
  position: absolute;
}
@media only screen and (max-width: 575px) {
  .card .card-header .card-header-right {
    display: none;
  }
}
.card .card-header .card-header-right .dropdown-menu {
  margin-top: 0;
}
.card .card-header .card-header-right .dropdown-menu li {
  cursor: pointer;
}
.card .card-header .card-header-right .dropdown-menu li a {
  font-size: 14px;
  text-transform: capitalize;
}
.card .card-header .card-header-right .btn.dropdown-toggle, .card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.introjs-button, .introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.introjs-button {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #888;
}
.card .card-header .card-header-right .btn.dropdown-toggle i, .card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.introjs-button i, .introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.introjs-button i {
  margin-right: 0;
}
.card .card-header .card-header-right .btn.dropdown-toggle:after, .card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.introjs-button:after, .introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.introjs-button:after {
  display: none;
}
.card .card-header .card-header-right .btn.dropdown-toggle:focus, .card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.introjs-button:focus, .introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.introjs-button:focus {
  box-shadow: none;
  outline: none;
}
.card .card-header .card-header-right .btn.dropdown-toggle, .card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.introjs-button, .introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.introjs-button {
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 8px;
}
.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span, .card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.mobile-menu.introjs-button span, .introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.mobile-menu.introjs-button span {
  background-color: #888;
  height: 2px;
  border-radius: 5px;
}
.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span:after, .card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.mobile-menu.introjs-button span:after, .introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.mobile-menu.introjs-button span:after, .card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span:before, .card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.mobile-menu.introjs-button span:before, .introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.mobile-menu.introjs-button span:before {
  border-radius: 5px;
  height: 2px;
  background-color: #888;
}
.card .card-header .card-header-right .nav-pills {
  padding: 0;
  box-shadow: none;
  background: transparent;
}
.card .card-footer {
  transition: box-shadow 0.2s ease-in-out;
}
.card:hover .card-footer[class*=bg-] {
  box-shadow: none;
}

@media (max-width: 575.98px) {
  .card {
    margin-bottom: 20px;
  }
  .card .card-header {
    padding: 20px;
  }
  .card .card-header h5, .card .card-header .h5 {
    font-size: 0.875rem;
  }
  .card .card-body {
    padding: 20px;
  }
}
.progress {
  overflow: visible;
}
.progress .progress-bar {
  border-radius: 8px;
}
.progress.progress-theme {
  background: #ffebf0;
}
.progress.progress-theme .progress-bar {
  background: #ff3366;
}
.progress.progress-primary {
  background: #e8e9f2;
}
.progress.progress-primary .progress-bar {
  background: #1A237E;
}
.progress.progress-secondary {
  background: #eff0f2;
}
.progress.progress-secondary .progress-bar {
  background: #5b6b79;
}
.progress.progress-success {
  background: #eaf6f2;
}
.progress.progress-success .progress-bar {
  background: #2ca87f;
}
.progress.progress-info {
  background: #ecfafb;
}
.progress.progress-info .progress-bar {
  background: #3ec9d6;
}
.progress.progress-warning {
  background: #fcf3e6;
}
.progress.progress-warning .progress-bar {
  background: #e58a00;
}
.progress.progress-danger {
  background: #fce9e9;
}
.progress.progress-danger .progress-bar {
  background: #dc2626;
}
.progress.progress-light {
  background: #fefeff;
}
.progress.progress-light .progress-bar {
  background: #f8f9fa;
}
.progress.progress-dark {
  background: #e9e9ea;
}
.progress.progress-dark .progress-bar {
  background: #212529;
}

.progress-stacked {
  overflow: hidden;
}
.progress-stacked .progress:not(:first-child) .progress-bar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.progress-stacked .progress:not(:last-child) .progress-bar {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.table.table-align-center td,
.table.table-align-center th {
  vertical-align: middle;
}
.table thead th {
  padding: 0.9rem 0.75rem;
}
.table td,
.table th {
  vertical-align: middle;
}
.table.table-borderless td,
.table.table-borderless th {
  border: none !important;
}

.table-hover tbody tr:hover {
  background-color: rgba(26, 35, 126, 0.03);
}

@media (max-width: 1199.98px) {
  .affiliate-table tr th:first-child,
  .affiliate-table tr td:first-child {
    min-width: 150px;
  }
}

.tabs-border.nav-tabs .nav-item {
  margin-bottom: 0;
}
.tabs-border.nav-tabs .nav-link {
  border: none;
  background: no-repeat center bottom, center 100%;
  background-size: 0 100%, 100% 100%;
  transition: background 0.3s ease-out;
  background-image: linear-gradient(to top, theme-color("primary") 2px, rgba(255, 255, 255, 0) 2px);
}
.tabs-border.nav-tabs .nav-link.active {
  background-size: 100% 100%, 100% 100%;
}

.tabs-light.nav-pill + .tab-content {
  border-top: 1px solid #e7eaee;
}
.tabs-light.nav-pill .nav-item {
  margin-bottom: 0;
}
.tabs-light.nav-pill .nav-item .nav-link {
  color: #1A237E;
  background: #e8e9f2;
  border-radius: 4px;
  transition: background 0.3s ease-out;
}
.tabs-light.nav-pill .nav-item + .nav-item {
  margin-left: 10px;
}
.tabs-light.nav-pill .nav-link {
  border: none;
}
.tabs-light.nav-pill .nav-link.active {
  color: #fff;
  background: #1A237E;
}

.choices__list--dropdown {
  z-index: 9999 !important;
}

select.form-control:hover,
.form-control:hover,
.datatable-input:hover,
.custom-select:hover,
.dataTable-selector:hover,
.dataTable-input:hover {
  background-color: #f8f9fa;
}
select.form-control[readonly],
.form-control[readonly],
[readonly].datatable-input,
[readonly].custom-select,
[readonly].dataTable-selector,
[readonly].dataTable-input {
  opacity: 0.6;
}

.input-group-text svg {
  width: 18px;
  height: 18px;
}

.form-control-color-picker {
  height: 43px;
  padding: 0.5rem;
}

select.form-control, select.datatable-input, select.custom-select,
select.dataTable-selector,
select.dataTable-input {
  appearance: none;
  background: #ffffff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") no-repeat right 0.75rem center/18px 25px;
}
select.form-control[data-multiselectsplitter-firstselect-selector], select[data-multiselectsplitter-firstselect-selector].datatable-input, select[data-multiselectsplitter-firstselect-selector].custom-select,
select[data-multiselectsplitter-firstselect-selector].dataTable-selector,
select[data-multiselectsplitter-firstselect-selector].dataTable-input, select.form-control[data-multiselectsplitter-secondselect-selector], select[data-multiselectsplitter-secondselect-selector].datatable-input, select[data-multiselectsplitter-secondselect-selector].custom-select,
select[data-multiselectsplitter-secondselect-selector].dataTable-selector,
select[data-multiselectsplitter-secondselect-selector].dataTable-input {
  background: none;
}

.form-floating > label {
  top: 1px;
}
.form-floating > .form-control:focus ~ label, .form-floating > .datatable-input:focus ~ label, .form-floating > .custom-select:focus ~ label,
.form-floating > .dataTable-selector:focus ~ label,
.form-floating > .dataTable-input:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .datatable-input:not(:placeholder-shown) ~ label,
.form-floating > .custom-select:not(:placeholder-shown) ~ label,
.form-floating > .dataTable-selector:not(:placeholder-shown) ~ label,
.form-floating > .dataTable-input:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label,
.form-floating > .datatable-selector ~ label {
  color: #5b6b79;
}
.form-floating > .form-control:focus ~ label, .form-floating > .datatable-input:focus ~ label, .form-floating > .custom-select:focus ~ label,
.form-floating > .dataTable-selector:focus ~ label,
.form-floating > .dataTable-input:focus ~ label {
  color: var(--bs-primary);
}
.form-floating > input {
  color: #131920;
}

.bootstrap-select -select > .dropdown-toggle.btn-light,
.bootstrap-select -select > .dropdown-toggle.btn-secondary,
.bootstrap-select .introjs-tooltip -select > .dropdown-toggle.introjs-button.introjs-prevbutton,
.introjs-tooltip .bootstrap-select -select > .dropdown-toggle.introjs-button.introjs-prevbutton,
.bootstrap-select -select > .dropdown-toggle.btn-default {
  border-color: #bec8d0 !important;
  box-shadow: none;
  background: #ffffff !important;
  color: #131920;
}
.bootstrap-select -select > .dropdown-toggle.btn-light:focus,
.bootstrap-select -select > .dropdown-toggle.btn-secondary:focus,
.bootstrap-select .introjs-tooltip -select > .dropdown-toggle.introjs-button.introjs-prevbutton:focus,
.introjs-tooltip .bootstrap-select -select > .dropdown-toggle.introjs-button.introjs-prevbutton:focus,
.bootstrap-select -select > .dropdown-toggle.btn-default:focus {
  outline: none !important;
}

.form-search {
  position: relative;
}
.form-search i {
  position: absolute;
  top: 14px;
  left: 15px;
  font-size: 20px;
}
.form-search .form-control, .form-search .datatable-input, .form-search .custom-select,
.form-search .dataTable-selector,
.form-search .dataTable-input {
  padding-left: 50px;
}

.form-check label {
  cursor: pointer;
}
.form-check label input {
  cursor: pointer;
}

.form-check-input.f-12 {
  margin-left: -2.9em;
}

.form-check .form-check-input.input-theme:checked {
  border-color: #ff3366;
  background-color: #ff3366;
}
.form-check .form-check-input.input-light-theme:checked {
  border-color: #ffebf0;
  background-color: #ffebf0;
}
.form-check .form-check-input.input-light-theme:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ff3366' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-theme:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ff3366'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-theme:focus[type=checkbox], .form-check .form-check-input.input-theme:focus[type=radio], .form-check .form-check-input.input-light-theme:focus[type=checkbox], .form-check .form-check-input.input-light-theme:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(255, 51, 102, 0.25);
  border-color: #ff3366;
}
.form-check.form-switch .form-check-input.input-light-theme:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ff3366'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-primary:checked {
  border-color: #1A237E;
  background-color: #1A237E;
}
.form-check .form-check-input.input-light-primary:checked {
  border-color: #e8e9f2;
  background-color: #e8e9f2;
}
.form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%231A237E' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%231A237E'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-primary:focus[type=checkbox], .form-check .form-check-input.input-primary:focus[type=radio], .form-check .form-check-input.input-light-primary:focus[type=checkbox], .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(26, 35, 126, 0.25);
  border-color: #1A237E;
}
.form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231A237E'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-secondary:checked {
  border-color: #5b6b79;
  background-color: #5b6b79;
}
.form-check .form-check-input.input-light-secondary:checked {
  border-color: #eff0f2;
  background-color: #eff0f2;
}
.form-check .form-check-input.input-light-secondary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%235b6b79' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-secondary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%235b6b79'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-secondary:focus[type=checkbox], .form-check .form-check-input.input-secondary:focus[type=radio], .form-check .form-check-input.input-light-secondary:focus[type=checkbox], .form-check .form-check-input.input-light-secondary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(91, 107, 121, 0.25);
  border-color: #5b6b79;
}
.form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%235b6b79'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-success:checked {
  border-color: #2ca87f;
  background-color: #2ca87f;
}
.form-check .form-check-input.input-light-success:checked {
  border-color: #eaf6f2;
  background-color: #eaf6f2;
}
.form-check .form-check-input.input-light-success:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%232ca87f' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-success:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%232ca87f'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-success:focus[type=checkbox], .form-check .form-check-input.input-success:focus[type=radio], .form-check .form-check-input.input-light-success:focus[type=checkbox], .form-check .form-check-input.input-light-success:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(44, 168, 127, 0.25);
  border-color: #2ca87f;
}
.form-check.form-switch .form-check-input.input-light-success:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232ca87f'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-info:checked {
  border-color: #3ec9d6;
  background-color: #3ec9d6;
}
.form-check .form-check-input.input-light-info:checked {
  border-color: #ecfafb;
  background-color: #ecfafb;
}
.form-check .form-check-input.input-light-info:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233ec9d6' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-info:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%233ec9d6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-info:focus[type=checkbox], .form-check .form-check-input.input-info:focus[type=radio], .form-check .form-check-input.input-light-info:focus[type=checkbox], .form-check .form-check-input.input-light-info:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(62, 201, 214, 0.25);
  border-color: #3ec9d6;
}
.form-check.form-switch .form-check-input.input-light-info:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233ec9d6'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-warning:checked {
  border-color: #e58a00;
  background-color: #e58a00;
}
.form-check .form-check-input.input-light-warning:checked {
  border-color: #fcf3e6;
  background-color: #fcf3e6;
}
.form-check .form-check-input.input-light-warning:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23e58a00' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-warning:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23e58a00'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-warning:focus[type=checkbox], .form-check .form-check-input.input-warning:focus[type=radio], .form-check .form-check-input.input-light-warning:focus[type=checkbox], .form-check .form-check-input.input-light-warning:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(229, 138, 0, 0.25);
  border-color: #e58a00;
}
.form-check.form-switch .form-check-input.input-light-warning:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23e58a00'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-danger:checked {
  border-color: #dc2626;
  background-color: #dc2626;
}
.form-check .form-check-input.input-light-danger:checked {
  border-color: #fce9e9;
  background-color: #fce9e9;
}
.form-check .form-check-input.input-light-danger:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23dc2626' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-danger:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23dc2626'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-danger:focus[type=checkbox], .form-check .form-check-input.input-danger:focus[type=radio], .form-check .form-check-input.input-light-danger:focus[type=checkbox], .form-check .form-check-input.input-light-danger:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(220, 38, 38, 0.25);
  border-color: #dc2626;
}
.form-check.form-switch .form-check-input.input-light-danger:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23dc2626'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-light:checked {
  border-color: #f8f9fa;
  background-color: #f8f9fa;
}
.form-check .form-check-input.input-light-light:checked {
  border-color: #fefeff;
  background-color: #fefeff;
}
.form-check .form-check-input.input-light-light:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23f8f9fa' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-light:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23f8f9fa'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light:focus[type=checkbox], .form-check .form-check-input.input-light:focus[type=radio], .form-check .form-check-input.input-light-light:focus[type=checkbox], .form-check .form-check-input.input-light-light:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.25);
  border-color: #f8f9fa;
}
.form-check.form-switch .form-check-input.input-light-light:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f8f9fa'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-dark:checked {
  border-color: #212529;
  background-color: #212529;
}
.form-check .form-check-input.input-light-dark:checked {
  border-color: #e9e9ea;
  background-color: #e9e9ea;
}
.form-check .form-check-input.input-light-dark:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-dark:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23212529'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-dark:focus[type=checkbox], .form-check .form-check-input.input-dark:focus[type=radio], .form-check .form-check-input.input-light-dark:focus[type=checkbox], .form-check .form-check-input.input-light-dark:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.25);
  border-color: #212529;
}
.form-check.form-switch .form-check-input.input-light-dark:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23212529'/%3e%3c/svg%3e");
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .introjs-tooltip .input-group > .input-group-append:last-child > .introjs-button:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.introjs-tooltip .input-group > .input-group-append:not(:last-child) > .introjs-button,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.introjs-tooltip .input-group > .input-group-prepend > .introjs-button,
.input-group > .input-group-prepend > .input-group-text {
  border-right: none;
}

.form-v1 .form-label-group {
  position: relative;
  margin-bottom: 1rem;
}
.form-v1 .form-label-group > input,
.form-v1 .form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}
.form-v1 .form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  color: #495057;
  pointer-events: none;
  cursor: text;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}
.form-v1 .form-label-group input::-webkit-input-placeholder {
  color: transparent;
}
.form-v1 .form-label-group input::-ms-input-placeholder {
  color: transparent;
}
.form-v1 .form-label-group input::-moz-placeholder {
  color: transparent;
}
.form-v1 .form-label-group input::placeholder {
  color: transparent;
}
.form-v1 .form-label-group input:not(:-moz-placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}
.form-v1 .form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}
.form-v1 .form-label-group input:not(:-moz-placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}
.form-v1 .form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

.datepicker {
  width: 275px;
  padding: 10px;
}
.datepicker.dropdown-menu {
  padding: 10px;
}
.datepicker.datepicker-orient-top {
  margin-top: 8px;
}
.datepicker table {
  width: 100%;
}
.datepicker td,
.datepicker th {
  font-weight: regular;
  width: 35px;
  height: 35px;
}
.datepicker thead th {
  color: #262626;
}
.datepicker thead th.datepicker-switch, .datepicker thead th.next, .datepicker thead th.prev {
  font-weight: 500;
  color: #131920;
}
.datepicker thead th.datepicker-switch i, .datepicker thead th.next i, .datepicker thead th.prev i {
  font-size: 1.2rem;
  color: #131920;
}
.datepicker thead th.datepicker-switch i:before, .datepicker thead th.next i:before, .datepicker thead th.prev i:before {
  line-height: 0;
  vertical-align: middle;
}
.datepicker thead th.datepicker-switch:hover, .datepicker thead th.next:hover, .datepicker thead th.prev:hover {
  background: #f8f9fa !important;
}
.datepicker thead th.dow {
  color: #131920;
  font-weight: 500;
}
.datepicker tbody tr > td.day {
  color: #131920;
}
.datepicker tbody tr > td.day:hover {
  background: #f8f9fa;
  color: #131920;
}
.datepicker tbody tr > td.day.old {
  color: #131920;
}
.datepicker tbody tr > td.day.new {
  color: #131920;
}
.datepicker tbody tr > td.day.active, .datepicker tbody tr > td.day.active:hover, .datepicker tbody tr > td.day.selected, .datepicker tbody tr > td.day.selected:hover {
  background: #1A237E;
  color: #fff;
}
.datepicker tbody tr > td.day.today {
  position: relative;
  background: #1A237E !important;
  color: #fff !important;
}
.datepicker tbody tr > td.day.today:before {
  content: "";
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #ffffff;
  border-top-color: #131920;
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.datepicker tbody tr > td.day.range {
  background: #f8f9fa;
}
.datepicker tbody tr > td span.hour,
.datepicker tbody tr > td span.minute,
.datepicker tbody tr > td span.month,
.datepicker tbody tr > td span.year {
  color: #131920;
}
.datepicker tbody tr > td span.hour:hover,
.datepicker tbody tr > td span.minute:hover,
.datepicker tbody tr > td span.month:hover,
.datepicker tbody tr > td span.year:hover {
  background: #f8f9fa;
}
.datepicker tbody tr > td span.hour.active, .datepicker tbody tr > td span.hour.active.focused:hover, .datepicker tbody tr > td span.hour.active:hover, .datepicker tbody tr > td span.hour.focused, .datepicker tbody tr > td span.hour.focused:hover,
.datepicker tbody tr > td span.minute.active,
.datepicker tbody tr > td span.minute.active.focused:hover,
.datepicker tbody tr > td span.minute.active:hover,
.datepicker tbody tr > td span.minute.focused,
.datepicker tbody tr > td span.minute.focused:hover,
.datepicker tbody tr > td span.month.active,
.datepicker tbody tr > td span.month.active.focused:hover,
.datepicker tbody tr > td span.month.active:hover,
.datepicker tbody tr > td span.month.focused,
.datepicker tbody tr > td span.month.focused:hover,
.datepicker tbody tr > td span.year.active,
.datepicker tbody tr > td span.year.active.focused:hover,
.datepicker tbody tr > td span.year.active:hover,
.datepicker tbody tr > td span.year.focused,
.datepicker tbody tr > td span.year.focused:hover {
  background: #1A237E;
  color: #fff;
}
.datepicker tfoot tr > th.clear, .datepicker tfoot tr > th.today {
  font-weight: 500;
}
.datepicker tfoot tr > th.clear:hover, .datepicker tfoot tr > th.today:hover {
  background: #f8f9fa;
}

.bootstrap-timepicker-widget {
  left: auto;
  width: 175px;
}
.bootstrap-timepicker-widget .bootstrap-timepicker-hour,
.bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
.bootstrap-timepicker-widget .bootstrap-timepicker-minute,
.bootstrap-timepicker-widget .bootstrap-timepicker-second {
  border: none;
  box-shadow: none;
}
.bootstrap-timepicker-widget table td > a {
  border: 0;
}
.bootstrap-timepicker-widget table td > a i {
  font-size: 1.1rem;
}

.bootstrap-timepicker-widget .bootstrap-timepicker-hour,
.bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
.bootstrap-timepicker-widget .bootstrap-timepicker-minute,
.bootstrap-timepicker-widget .bootstrap-timepicker-second {
  color: #262626;
}
.bootstrap-timepicker-widget table td > a:hover {
  background: #f8f9fa;
}

.tagify__tag {
  --tag-bg: #f8f9fa;
  --tag-hover: rgba(26, 35, 126, 0.3);
  --tag-remove-bg: rgba(220, 38, 38, 0.3);
  --tag-remove-btn-bg--hover: #dc2626;
}
.tagify__tag.tagify__tag--theme {
  --tag-bg: rgba(255, 51, 102, 0.2);
}
.tagify__tag.tagify__tag--theme div .tagify__tag-text {
  color: #ff3366;
}
.tagify__tag.tagify__tag--primary {
  --tag-bg: rgba(26, 35, 126, 0.2);
}
.tagify__tag.tagify__tag--primary div .tagify__tag-text {
  color: #1A237E;
}
.tagify__tag.tagify__tag--secondary {
  --tag-bg: rgba(91, 107, 121, 0.2);
}
.tagify__tag.tagify__tag--secondary div .tagify__tag-text {
  color: #5b6b79;
}
.tagify__tag.tagify__tag--success {
  --tag-bg: rgba(44, 168, 127, 0.2);
}
.tagify__tag.tagify__tag--success div .tagify__tag-text {
  color: #2ca87f;
}
.tagify__tag.tagify__tag--info {
  --tag-bg: rgba(62, 201, 214, 0.2);
}
.tagify__tag.tagify__tag--info div .tagify__tag-text {
  color: #3ec9d6;
}
.tagify__tag.tagify__tag--warning {
  --tag-bg: rgba(229, 138, 0, 0.2);
}
.tagify__tag.tagify__tag--warning div .tagify__tag-text {
  color: #e58a00;
}
.tagify__tag.tagify__tag--danger {
  --tag-bg: rgba(220, 38, 38, 0.2);
}
.tagify__tag.tagify__tag--danger div .tagify__tag-text {
  color: #dc2626;
}
.tagify__tag.tagify__tag--light {
  --tag-bg: rgba(248, 249, 250, 0.2);
}
.tagify__tag.tagify__tag--light div .tagify__tag-text {
  color: #f8f9fa;
}
.tagify__tag.tagify__tag--dark {
  --tag-bg: rgba(33, 37, 41, 0.2);
}
.tagify__tag.tagify__tag--dark div .tagify__tag-text {
  color: #212529;
}

.bootstrap-switch {
  border-color: #e7eaee;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
  background: #f8f9fa;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-theme,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-theme {
  color: #fff;
  background: #ff3366;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  color: #fff;
  background: #1A237E;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-secondary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-secondary {
  color: #fff;
  background: #5b6b79;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
  color: #fff;
  background: #2ca87f;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
  color: #fff;
  background: #3ec9d6;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
  color: #fff;
  background: #e58a00;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
  color: #fff;
  background: #dc2626;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-light,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-light {
  color: #fff;
  background: #f8f9fa;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-dark,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-dark {
  color: #fff;
  background: #212529;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn[class*=btn-]:not(.btn-light), .introjs-tooltip .bootstrap-select > .dropdown-toggle.bs-placeholder[class*=btn-].introjs-button:not(.btn-light) {
  color: #fff;
}

.typeahead .twitter-typeahead {
  display: block !important;
}

.scrollable-dropdown-menu .tt-menu {
  max-height: 150px;
  overflow-y: auto;
}

.tt-menu {
  padding: 10px 0;
  font-size: 0.875rem;
  background: #fff;
  min-width: 10rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.tt-menu .tt-suggestion {
  padding: 4px 20px;
  border-radius: 2px;
}
.tt-menu .tt-suggestion.active, .tt-menu .tt-suggestion:active, .tt-menu .tt-suggestion:focus, .tt-menu .tt-suggestion:hover {
  background: rgba(26, 35, 126, 0.03);
  color: #1A237E;
}

.sticky-action {
  top: 74px;
  position: sticky;
  z-index: 1020;
  background: var(--bs-card-bg);
  border-radius: var(--bs-card-border-radius);
}

.dropzone {
  min-height: auto;
}

.dropzone {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #e7eaee;
  border-radius: 8px;
}
.dropzone .dropzone-msg-title {
  color: #131920;
  margin: 0 0 5px;
  padding: 0;
  font-weight: 500;
  font-size: 1.2rem;
}
.dropzone .dropzone-msg-desc {
  color: #131920;
  font-weight: 400;
  font-size: 1rem;
}
.dropzone .dz-preview .dz-image {
  border-radius: 8px;
}
.dropzone.dropzone-theme {
  border-color: #ff3366;
}
.dropzone.dropzone-primary {
  border-color: #1A237E;
}
.dropzone.dropzone-secondary {
  border-color: #5b6b79;
}
.dropzone.dropzone-success {
  border-color: #2ca87f;
}
.dropzone.dropzone-info {
  border-color: #3ec9d6;
}
.dropzone.dropzone-warning {
  border-color: #e58a00;
}
.dropzone.dropzone-danger {
  border-color: #dc2626;
}
.dropzone.dropzone-light {
  border-color: #f8f9fa;
}
.dropzone.dropzone-dark {
  border-color: #212529;
}

.dz-started .dropzone-msg {
  display: none;
}

.dropzone-multi {
  border: 0;
  padding: 0;
}
.dropzone-multi .dz-message {
  display: none;
}
.dropzone-multi .dropzone-panel .dropzone-remove-all,
.dropzone-multi .dropzone-panel .dropzone-upload {
  display: none;
}
.dropzone-multi .dropzone-item {
  background: #f8f9fa;
  border-radius: 8px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
}
.dropzone-multi .dropzone-item .dropzone-progress {
  width: 20%;
}
.dropzone-multi .dropzone-item .dropzone-progress .progress {
  height: 0.5rem;
  transition: all 0.2s ease-in-out;
}
.dropzone-multi .dropzone-item .dropzone-file .dropzone-filename {
  font-size: 0.9rem;
  font-weight: 500;
  color: #131920;
  text-overflow: ellipsis;
  margin-right: 0.5rem;
}
.dropzone-multi .dropzone-item .dropzone-file .dropzone-filename b {
  font-size: 0.9rem;
  font-weight: 500;
  color: #131920;
}
.dropzone-multi .dropzone-item .dropzone-file .dropzone-error {
  margin-top: 0.25rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #dc2626;
  text-overflow: ellipsis;
}
.dropzone-multi .dropzone-item .dropzone-toolbar {
  margin-left: 1rem;
  display: flex;
  flex-wrap: nowrap;
}
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start i {
  font-size: 0.8rem;
  color: #262626;
}
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel:hover i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete:hover i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start:hover i {
  color: #1A237E;
}
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
  transition: all 0.2s ease-in-out;
}

.uppy-Dashboard--modal {
  z-index: 1030;
}
.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  z-index: 1030;
}
.uppy-Dashboard--modal .uppy-Dashboard-inner {
  z-index: 1031;
}

.uppy-DragDrop--is-dragdrop-supported {
  border-color: #f8f9fa;
}

.pc-uppy-thumbnail-container .pc-uppy-thumbnail img {
  width: 100px;
}

.slider-selection {
  box-shadow: none;
}

.pc-toggle-noUiSlider {
  height: 50px;
}

.pc-toggle-noUiSlider.off .noUi-handle {
  border-color: #dc2626;
  background: #dc2626;
  box-shadow: none;
}

.note-editor.card .card-header {
  padding: 0 5px 5px;
  border-bottom-color: #a9a9a9;
}

#cke5-inline-demo .ck-content {
  margin-bottom: 1rem;
  padding: 2.5rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
#cke5-inline-demo .ck-content h3, #cke5-inline-demo .ck-content .h3 {
  margin-top: 0;
}
#cke5-inline-demo header.ck-content {
  text-align: center;
}
#cke5-inline-demo header.ck-content h2:first-of-type, #cke5-inline-demo header.ck-content .h2:first-of-type {
  margin-top: 0;
}
#cke5-inline-demo header.ck-content h2 + h3, #cke5-inline-demo header.ck-content .h2 + h3, #cke5-inline-demo header.ck-content h2 + .h3, #cke5-inline-demo header.ck-content .h2 + .h3 {
  margin-top: 0;
  font-weight: 600;
}
#cke5-inline-demo .demo-row {
  width: 100%;
  display: flex;
}
#cke5-inline-demo .demo-row .demo-row__half {
  width: 50%;
}
#cke5-inline-demo .demo-row .demo-row__half:first-of-type {
  padding-right: 0.5rem;
}
#cke5-inline-demo .demo-row .demo-row__half:last-of-type {
  padding-left: 0.5rem;
}
#cke5-inline-demo .demo-row p {
  margin-bottom: 0;
}
#cke5-inline-demo .demo-row h3, #cke5-inline-demo .demo-row .h3 {
  margin: 0;
  font-weight: 600;
}
@media (max-width: 575.98px) {
  #cke5-inline-demo .ck-content {
    padding: 1rem;
  }
  #cke5-inline-demo .demo-row {
    flex-direction: column;
  }
  #cke5-inline-demo .demo-row .demo-row__half {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.switch-demo .custom-switch-v1 {
  margin-bottom: 4px;
}

.custom-switch-v1.form-switch {
  padding-left: 2.9em;
}
.custom-switch-v1.form-switch .form-check-input {
  height: 20px;
  width: 35px;
  margin-left: -2.9em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}
.custom-switch-v1.form-switch .form-check-input[class*=input-light-] {
  border: none;
}
.custom-switch-v1.form-switch .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.25);
}
.custom-switch-v1.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-theme:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ff3366'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%231A237E'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%235b6b79'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-success:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%232ca87f'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-info:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%233ec9d6'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-warning:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23e58a00'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-danger:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23dc2626'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-light:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23f8f9fa'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-dark:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23212529'/%3e%3c/svg%3e");
}
.custom-switch-v1 .custom-control-label::before {
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 35px;
  border-radius: 0.8rem;
  top: 0;
  left: -2.55rem;
}
.custom-switch-v1 .custom-control-label::after {
  top: calc(0.15625rem - 2px);
  left: calc(-2.25rem - 4px);
  height: 19px;
  width: 19px;
  border-radius: 0.7rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}
.custom-switch-v1 .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(0.95rem);
}

.row[data-multiselectsplitter-wrapper-selector] select {
  margin-bottom: 8px;
}

.choices {
  position: relative;
  margin-bottom: 5px;
  font-size: 16px;
}
.choices:focus {
  outline: none;
}
.choices:last-child {
  margin-bottom: 0;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #f3f5f7;
  cursor: not-allowed;
  user-select: none;
}
.choices.is-disabled .choices__item {
  cursor: not-allowed;
}
.choices [hidden] {
  display: none !important;
}

.choices[data-type*=select-one] {
  cursor: pointer;
}
.choices[data-type*=select-one] .choices__inner {
  padding-bottom: 7.5px;
}
.choices[data-type*=select-one] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #bec8d0;
  background-color: #ffffff;
  margin: 0;
}
.choices[data-type*=select-one] .choices__button {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.5;
}
.choices[data-type*=select-one] .choices__button:focus, .choices[data-type*=select-one] .choices__button:hover {
  opacity: 1;
}
.choices[data-type*=select-one] .choices__button:focus {
  box-shadow: 0 0 0 2px var(--bs-primary);
}
.choices[data-type*=select-one] .choices__item[data-value=""] .choices__button {
  display: none;
}
.choices[data-type*=select-one]:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #131920 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}
.choices[data-type*=select-one].is-open:after {
  border-color: transparent transparent #131920 transparent;
  margin-top: -7.5px;
}
.choices[data-type*=select-one][dir=rtl]:after {
  left: 11.5px;
  right: auto;
}
.choices[data-type*=select-one][dir=rtl] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*=select-multiple] .choices__inner,
.choices[data-type*=text] .choices__inner {
  cursor: text;
}
.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
  position: relative;
  display: inline-block;
  margin: 0 -4px 0 8px;
  padding-left: 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.35);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}
.choices[data-type*=select-multiple] .choices__button:focus, .choices[data-type*=select-multiple] .choices__button:hover,
.choices[data-type*=text] .choices__button:focus,
.choices[data-type*=text] .choices__button:hover {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #ffffff;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #bec8d0;
  border-radius: 8px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
}
.is-focused .choices__inner, .is-open .choices__inner {
  border-width: 1px;
  border-color: #1A237E;
  box-shadow: 0 0 0 0.2rem rgba(26, 35, 126, 0.25);
}
.is-open .choices__inner {
  border-radius: 8px 8px 0 0;
}
.is-flipped.is-open .choices__inner {
  border-radius: 0 0 8px 8px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}
[dir=rtl] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}
.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}
.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 8px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: var(--bs-primary);
  border: 1px solid var(--bs-primary);
  color: #ffffff;
  word-break: break-all;
  box-sizing: border-box;
}
.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}
[dir=rtl] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: rgba(var(--bs-primary-rgb), 0.5);
  border: 1px solid rgba(var(--bs-primary-rgb), 0.7);
}
.is-disabled .choices__list--multiple .choices__item {
  background-color: rgba(var(--bs-primary-rgb), 0.5);
  border: 1px solid rgba(var(--bs-primary-rgb), 0.5);
}

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #bec8d0;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
}
.choices__list--dropdown.is-active {
  visibility: visible;
}
.is-open .choices__list--dropdown {
  border-color: #92a2b0;
}
.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.choices__list--dropdown .choices__list {
  padding: 10px 15px;
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}
.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
}
[dir=rtl] .choices__list--dropdown .choices__item {
  text-align: right;
}
@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: 10px;
  }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: rgba(26, 35, 126, 0.1);
}
.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #dce1e5;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #ffffff;
  font-size: 14px;
  margin-bottom: 0px;
  border: 0;
  color: #131920;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}
.choices__input:focus {
  outline: 0;
}
[dir=rtl] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: 0.5;
}

.typeahead {
  position: relative;
}
.typeahead > ul {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.17);
  display: none;
  float: left;
  font-size: 14px;
  left: 0;
  list-style: none;
  margin: 2px 0 0;
  min-width: 170px;
  padding: 5px 0;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 1000;
}
.typeahead > ul > li > a {
  clear: both;
  color: #333;
  cursor: default;
  display: block;
  font-weight: 400;
  line-height: 1.42857143;
  padding: 3px 20px;
  white-space: nowrap;
}
.typeahead > ul > li.active > a:hover, .typeahead > ul > li.active > a,
.typeahead > ul > li > a:hover {
  background-color: var(--bs-primary);
  color: #fff !important;
  text-decoration: none;
}

.validate-me .error {
  border-color: #dc2626;
}

.error-message {
  color: #dc2626;
}

.flatpickr-input[readonly] {
  background: #ffffff;
}

@media (max-width: 575.98px) {
  .noUi-target {
    margin: 0 15px;
  }
}

.noUi-connect {
  background: var(--bs-primary);
}

.editor-statusbar {
  padding-bottom: 0;
}

.editor-toolbar.fullscreen,
.CodeMirror-fullscreen {
  z-index: 1099;
}

@media (max-width: 575.98px) {
  .flatpickr-calendar {
    width: 270px;
  }
}

.form-wizard .nav-pills .nav-link {
  color: var(--bs-body-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.form-wizard .nav-pills .nav-link i {
  font-size: 20px;
}
.form-wizard .nav-pills .nav-link.active,
.form-wizard .nav-pills .show > .nav-link {
  color: #fff;
}

/**  =====================
      Authentication css start
==========================  **/
.auth-main {
  position: relative;
}
.auth-main .auth-wrapper {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}
.auth-main .auth-wrapper .saprator {
  position: relative;
  display: flex;
  align-self: center;
  justify-content: center;
}
.auth-main .auth-wrapper .saprator:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--bs-border-color);
  z-index: 1;
}
.auth-main .auth-wrapper .saprator span {
  font-size: 0.875rem;
  padding: 8px 24px;
  background: var(--bs-card-bg);
  z-index: 5;
  text-transform: capitalize;
  color: var(--pc-heading-color);
  font-weight: 500;
}
.auth-main .auth-wrapper .auth-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.auth-main .auth-wrapper .auth-form .card {
  width: 100%;
  max-width: 480px;
  box-shadow: none;
}
.auth-main .auth-wrapper .auth-form img + span {
  padding-left: 10px;
}
.auth-main .auth-wrapper .auth-form h5 span, .auth-main .auth-wrapper .auth-form .h5 span {
  text-decoration: underline;
}
.auth-main .auth-wrapper.v1 {
  display: flex;
  align-items: center;
}
.auth-main .auth-wrapper.v1 .auth-form {
  flex-direction: column;
  background: url("../images/authentication/img-auth-bg.jpg");
  min-height: 100vh;
  padding: 24px;
  background-size: cover;
  position: relative;
  justify-content: center;
}
.auth-main .auth-wrapper.v1 .auth-form .card-body {
  padding: 40px;
}
@media (max-width: 575.98px) {
  .auth-main .auth-wrapper.v1 .auth-form .card-body {
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  }
}
.auth-main .auth-wrapper.v2 {
  display: flex;
  align-items: center;
  background: #ffffff;
}
.auth-main .auth-wrapper.v2 .img-auth-side {
  height: 100vh;
}
@media (max-width: 1199.98px) {
  .auth-main .auth-wrapper.v2 .img-auth-side {
    display: none;
  }
}
.auth-main .auth-wrapper.v2 .auth-form .card {
  border: none;
  box-shadow: none;
}
.auth-main .auth-wrapper.v3 {
  display: flex;
  align-items: center;
  background: #ffffff;
}
.auth-main .auth-wrapper.v3 .auth-sidecontent {
  width: 580px;
  align-self: stretch;
  background: var(--bs-primary);
  position: relative;
  display: flex;
  align-items: center;
}
.auth-main .auth-wrapper.v3 .auth-sidecontent > * {
  position: relative;
  z-index: 5;
}
.auth-main .auth-wrapper.v3 .auth-sidecontent::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 2;
  background: linear-gradient(338deg, rgba(0, 0, 0, 0.3), transparent);
}
.auth-main .auth-wrapper.v3 .auth-sidecontent .carousel-indicators [data-bs-target] {
  height: 6px;
  border-radius: 4px;
  border-top: none;
  border-bottom: none;
}
.auth-main .auth-wrapper.v3 .auth-sidecontent .carousel-indicators [data-bs-target]:not(.active) {
  width: 6px;
}
@media (max-width: 1199.98px) {
  .auth-main .auth-wrapper.v3 .auth-sidecontent {
    display: none;
  }
}
.auth-main .auth-wrapper.v3 .auth-form {
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 24px;
}
.auth-main .auth-wrapper.v3 .auth-form .auth-footer,
.auth-main .auth-wrapper.v3 .auth-form .auth-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.auth-main .auth-wrapper.v3 .auth-form .card {
  border: none;
  box-shadow: none;
}

.auth-option .auth-megaoption {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 180px;
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}
.auth-option .auth-megaoption svg {
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
}
.auth-option .btn-check:checked + .auth-megaoption {
  border-color: var(--bs-primary);
  background: var(--bs-primary-light);
  box-shadow: 0 0 0 1px var(--bs-primary), 0px 8px 24px rgba(27, 46, 94, 0.12);
}
.auth-option .btn-check:checked + .auth-megaoption svg,
.auth-option .btn-check:checked + .auth-megaoption span {
  color: var(--bs-primary);
}

.code-input {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.code-input::-webkit-outer-spin-button, .code-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.code-input[type=number] {
  -moz-appearance: textfield;
}

/**====== Authentication css end ======**/
/**  =====================
      Maintaince css start
==========================  **/
.maintenance-block {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.error-card {
  box-shadow: none;
  text-align: center;
  border: none;
  background: transparent;
  overflow: hidden;
}
.error-card .error-image-block {
  position: relative;
  text-align: center;
  display: inline-flex;
}

.construction-card {
  border: none;
  background: transparent;
  text-align: center;
}

.construction-card-1 {
  background: url("../images/pages/img-cunstruct-1-bg.png");
  min-height: 100vh;
  background-size: 100%;
  background-repeat: no-repeat;
}
.construction-card-1 .construction-card-bottom {
  background: url("../images/pages/img-cunstruct-1-bottom.svg");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.soon-card {
  border: none;
  background: transparent;
}
.soon-card .timer-block .avtar {
  margin: 5px 0;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  border: 1px solid #e7eaee;
  padding-bottom: 25px;
  padding-top: 25px;
}

.soon-card-1 {
  background: url("../images/authentication/img-auth-bg.jpg");
  min-height: 100vh;
  background-size: cover;
  justify-content: center;
}

/**====== Maintaince css end ======**/
/**  =====================
   22. Pages css start
==========================  **/
.nav-price {
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  padding: 10px;
}
.nav-price.nav-pills .nav-link {
  padding: 16px 20px;
  color: var(--bs-body-color);
}
.nav-price.nav-pills .nav-link.active,
.nav-price.nav-pills .show > .nav-link {
  color: var(--bs-body-color);
  background-color: var(--bs-card-bg);
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
}

.price-card .price-head {
  border-radius: var(--bs-border-radius);
  padding: 20px;
  text-align: center;
}
.price-card .price-price {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
}
.price-card .price-price span {
  margin-top: 8px;
  display: block;
  font-weight: 400;
  font-size: 0.875rem;
}
.price-card .product-list {
  text-align: center;
}
.price-card .product-list li {
  padding: 5px 0;
  border: none;
  position: relative;
}
.price-card .product-list li:not(.enable) {
  opacity: 0.5;
  text-decoration: line-through;
}
.price-card.price-popular .price-head {
  background: rgba(var(--bs-primary-rgb), 0.1);
  margin-bottom: 16px;
}

.product-check-list {
  text-align: left;
}
.product-check-list li {
  padding: 8px 0 8px 25px;
  position: relative;
  opacity: 0.5;
}
.product-check-list li::before {
  content: "\ea5e";
  font-family: tabler-icons !important;
  position: absolute;
  left: 0;
  top: 8px;
  opacity: 0.5;
}
.product-check-list li.enable {
  opacity: 1;
}
.product-check-list li.enable::before {
  opacity: 1;
  color: #2ca87f;
}

.price-check {
  overflow: hidden;
  position: relative;
}
.price-check .form-check-label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.price-check .form-check-input,
.price-check .form-check-label .row {
  position: relative;
  z-index: 8;
}
.price-check .form-check .form-check-input:checked + .form-check-label::after {
  background: rgba(var(--bs-primary-rgb), 0.1);
}

.contact-hero {
  padding: 110px 0 50px;
  background-size: cover;
  position: relative;
  justify-content: center;
}

.contact-form {
  padding: 90px 0;
}

.contact-footer {
  position: relative;
}
.contact-footer .top-footer {
  background: rgba(255, 255, 255, 0.05);
  padding: 80px 0;
}
.contact-footer .bottom-footer {
  padding: 10px 0;
}
.contact-footer .footer-link a {
  margin: 14px 0;
  display: block;
  color: var(--bs-primary);
}
.contact-footer .footer-link a:not(:hover) {
  opacity: 0.6;
  color: var(--bs-body-color);
}
.contact-footer .footer-sos-link i {
  font-size: 18px;
}

/**  =====================
  23. Icon layouts css start
==========================  **/
.i-main .i-block {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin: 5px;
  border: 1px solid #e7eaee;
  border-radius: var(--bs-border-radius);
  position: relative;
  cursor: pointer;
}
.i-main .i-block i {
  font-size: 30px;
}
.i-main .i-block label {
  margin-bottom: 0;
  display: none;
}
.i-main .i-block span.ic-badge {
  position: absolute;
  bottom: 0;
  right: 0;
}

/**  =====================
  23. Icon layouts css end
==========================  **/
/**  =====================
  24. User card css start
==========================  **/
.user-upload {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
}
.user-upload .img-avtar-upload {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.user-upload:hover .img-avtar-upload {
  opacity: 1;
}

.social-profile .img-profile-avtar {
  border-radius: 50%;
  width: 90px;
  margin-top: -20%;
  box-shadow: 0 0 0 3px var(--bs-card-bg);
}
@media (max-width: 767.98px) {
  .social-profile {
    text-align: center;
  }
  .social-profile .img-profile-avtar {
    margin-top: -70px;
  }
  .social-profile .soc-profile-data {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.user-story-block {
  display: flex;
  align-items: flex-start;
}
.user-story-block .user-story {
  min-width: 115px;
  margin-bottom: 0;
  margin-right: 8px;
  overflow: hidden;
  cursor: pointer;
}
.user-story-block .user-story:hover .card-img {
  transform: scale(1.4);
}
.user-story-block .user-story:hover .card-img-overlay {
  backdrop-filter: blur(3px);
}
.user-story-block .user-story .card-img {
  transition: all 0.15s ease-in-out;
}
.user-story-block .user-story .card-img-overlay {
  transition: all 0.15s ease-in-out;
}
.user-story-block .user-story .card-img-overlay .img-user {
  box-shadow: 0 0 0 2px var(--bs-card-bg);
}
.user-story-block .user-story.add-story .card-img-overlay {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.user-story-block .user-story.add-story .card-img-overlay .btn-plus {
  background: var(--bs-card-bg);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  border: 1px dashed var(--bs-border-color);
}
.user-story-block .horizontal-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.user-story-block .horizontal-scroll::-webkit-scrollbar {
  height: 6px;
  opacity: 0;
}
.user-story-block .horizontal-scroll::-webkit-scrollbar:hover {
  opacity: 1;
}
.user-story-block .horizontal-scroll::-webkit-scrollbar-track {
  background: transparent;
}
.user-story-block .horizontal-scroll::-webkit-scrollbar-thumb {
  background: #e9ecef;
}
.user-story-block .horizontal-scroll::-webkit-scrollbar-thumb:hover {
  background: #aeb9c5;
}

.img-post {
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 0;
}
.img-post .card-img {
  transition: all 0.15s ease-in-out;
}
.img-post .card-img-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 35px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
}
.img-post:hover .card-img {
  transform: scale(1.2);
}
.img-post:hover .card-img-overlay {
  opacity: 1;
  backdrop-filter: blur(10px);
}

.post-modal-lightbox {
  backdrop-filter: blur(4px);
}

.post-user-group .avtar {
  width: 25px;
  height: 25px;
}
.post-user-group .avtar + .avtar {
  margin-left: -9px;
}

.comment-block .comment {
  background: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  margin-bottom: 24px;
}
.comment-block .comment + .comment-content {
  margin-left: 65px;
}
.comment-block .comment.sub-comment {
  position: relative;
  margin-left: 70px;
}
.comment-block .comment.sub-comment:before, .comment-block .comment.sub-comment:after {
  content: "";
  position: absolute;
  background: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.comment-block .comment.sub-comment:before {
  width: 35px;
  height: 2px;
  right: 100%;
  top: 40px;
}
.comment-block .comment.sub-comment:after {
  width: 2px;
  height: 133px;
  right: calc(100% + 32px);
  top: -90px;
}
.comment-block .comment.sub-comment + .comment-content {
  margin-left: 140px;
}
.comment-block .comment-content {
  margin-left: 57px;
}

.input-comment {
  position: relative;
}
.input-comment .form-control, .input-comment .datatable-input, .input-comment .custom-select,
.input-comment .dataTable-selector,
.input-comment .dataTable-input {
  padding: 0.56rem 5.5rem 0.56rem 3.5rem;
}
.input-comment .list-inline {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}
.input-comment .list-inline.start-0 {
  padding-left: 0.35rem;
}
.input-comment .list-inline.end-0 {
  padding-right: 0.35rem;
}

.social-event-card .carousel-indicators {
  left: auto;
  margin-left: auto;
  margin-right: 0;
}
.social-event-card .carousel-indicators [data-bs-target] {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.social-gallery-card .card-img-overlay {
  background: rgba(0, 0, 0, 0.1);
}

/**  =====================
  24. User card css end
==========================  **/
/**  =====================
  25. Application css start
==========================  **/
/**  =====================
      Chat css start
==========================  **/
.chat-wrapper {
  display: flex;
}
.chat-wrapper .chat-user_list {
  width: 300px;
  margin-right: var(--bs-gutter-x);
}
@media (max-width: 1399.98px) {
  .chat-wrapper .chat-user_list {
    margin-left: 20px !important;
    margin-right: 20px !important;
    width: calc(100% - 40px);
  }
}
.chat-wrapper .chat-user_list .scroll-block {
  height: calc(100vh - 520px);
}
.chat-wrapper .chat-content {
  flex: 1 1 auto;
  min-width: 1%;
}
.chat-wrapper .chat-user_info {
  width: 300px;
  margin-left: var(--bs-gutter-x);
}
@media (max-width: 1399.98px) {
  .chat-wrapper .chat-user_info {
    margin-left: 0 !important;
    margin-right: 0;
    padding-inline: 20px;
    width: 100%;
  }
}
.chat-wrapper .chat-user_info .scroll-block {
  height: calc(100vh - 470px);
}
.chat-wrapper .chat-user_info .chat-avtar img {
  border: 1px solid var(--bs-success);
  padding: 3px;
}
.chat-wrapper .chat-user_info .chat-avtar .chat-badge {
  bottom: 8px;
  right: 8px;
  width: 8px;
  height: 8px;
}
@media (max-width: 575.98px) {
  .chat-wrapper .avtar.avtar-s {
    width: 35px;
    height: 35px;
  }
}
.chat-wrapper .chat-offcanvas {
  --bs-offcanvas-width: 301px;
}
.chat-wrapper .chat-offcanvas.show .collapse:not(.show) {
  display: block;
}
.chat-wrapper .chat-offcanvas.show .chat-user_list {
  margin-right: 0;
}
.chat-wrapper .chat-offcanvas.show .chat-user_list .scroll-block {
  height: calc(100vh - 425px);
}
.chat-wrapper .chat-offcanvas.show .chat-user_info {
  margin-left: 0;
  margin-right: 0;
}
.chat-wrapper .chat-offcanvas.show .chat-user_info .scroll-block {
  height: calc(100vh - 375px);
}

.chat-badge {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  color: #fff;
  overflow: hidden;
}

.chat-badge-status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.chat-avtar {
  position: relative;
}
.chat-avtar .chat-badge {
  position: absolute;
  bottom: 1px;
  right: 1px;
}
@media (max-width: 1399.98px) {
  .chat-avtar .wid-40 {
    min-width: 35px;
  }
}

.chat-message.scroll-block {
  height: calc(100vh - 390px);
}
.chat-message .msg-content {
  padding: 12px 16px;
  border-radius: var(--bs-border-radius);
}
.chat-message .message-out,
.chat-message .message-in {
  display: flex;
  margin-bottom: 24px;
}
.chat-message .message-out > .d-flex,
.chat-message .message-in > .d-flex {
  max-width: 90%;
}
@media (max-width: 767.98px) {
  .chat-message .message-out > .d-flex,
  .chat-message .message-in > .d-flex {
    max-width: 100%;
  }
}
.chat-message .message-out .chat-msg .chat-msg-option,
.chat-message .message-in .chat-msg .chat-msg-option {
  opacity: 0;
}
.chat-message .message-out .chat-msg:hover .chat-msg-option,
.chat-message .message-in .chat-msg:hover .chat-msg-option {
  opacity: 1;
}
.chat-message .message-out {
  justify-content: flex-end;
}
.chat-message .message-out p {
  color: #fff;
}
.chat-message .message-out p:last-child {
  text-align: right;
}
.chat-message .message-out .chat-msg:not(:first-child) .msg-content {
  border-top-right-radius: 0;
}
.chat-message .message-out .chat-msg:not(:last-child) .msg-content {
  border-bottom-right-radius: 0;
}
.chat-message .message-in {
  justify-content: flex-start;
}
.chat-message .message-in .chat-msg:not(:first-child) .msg-content {
  border-top-left-radius: 0;
}
.chat-message .message-in .chat-msg:not(:last-child) .msg-content {
  border-bottom-left-radius: 0;
}

/**  =====================
      mail css start
==========================  **/
.mail-wrapper {
  display: flex;
}
.mail-wrapper .mail-menulist {
  width: 300px;
  margin-right: var(--bs-gutter-x);
}
.mail-wrapper .mail-menulist .scroll-block {
  height: calc(100vh - 270px);
}
.mail-wrapper .mail-menulist .list-group-item-action {
  padding: 14px 16px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  color: var(--bs-body-color);
  border: none;
  border-radius: var(--bs-border-radius);
}
.mail-wrapper .mail-menulist .list-group-item-action span {
  display: flex;
  align-items: center;
}
.mail-wrapper .mail-menulist .list-group-item-action .avtar {
  width: 22px;
  height: 22px;
  color: var(--bs-body-color);
  background: rgba(var(--bs-black-rgb), 0.1);
}
.mail-wrapper .mail-menulist .list-group-item-action i {
  font-size: 22px;
  margin-right: 8px;
  opacity: 0.6;
}
.mail-wrapper .mail-menulist .list-group-item-action .material-icons-two-tone {
  font-size: 22px;
  margin-right: 8px;
  opacity: 0.6;
}
.mail-wrapper .mail-menulist .list-group-item-action .material-icons-two-tone:not([class*=text-]) {
  background-color: var(--bs-body-color);
}
.mail-wrapper .mail-menulist .list-group-item-action + .list-group-item.active {
  margin-top: 0;
}
.mail-wrapper .mail-content {
  flex: 1 1 auto;
  min-width: 1%;
}
.mail-wrapper .mail-content .card .card {
  background: transparent;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}
.mail-wrapper .mail-content .card .card .card-body {
  padding: 0;
}
.mail-wrapper .scroll-block {
  height: calc(100vh - 290px);
}
.mail-wrapper .mail-offcanvas {
  --bs-offcanvas-width: 301px;
}
.mail-wrapper .mail-offcanvas.show .collapse:not(.show) {
  display: block;
}
.mail-wrapper .mail-offcanvas.show .mail-menulist {
  margin-right: 0;
}
.mail-wrapper .mail-offcanvas.show .mail-menulist .scroll-block {
  height: calc(100vh - 185px);
}
.mail-wrapper .img-user {
  width: 40px;
}
.mail-wrapper .mail-table {
  border-collapse: separate;
  width: calc(100% - 10px);
  margin: 0 5px;
}
.mail-wrapper .mail-table tr {
  display: flex;
  align-items: center;
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  margin: 10px 0;
}
.mail-wrapper .mail-table tr.unread {
  font-weight: 500;
}
.mail-wrapper .mail-table tr:not(.unread) {
  background: var(--bs-body-bg);
}
.mail-wrapper .mail-table tr td {
  height: 64px;
  display: inline-flex;
  align-items: center;
}
.mail-wrapper .mail-table tr td h5, .mail-wrapper .mail-table tr td .h5 {
  font-weight: 500;
}
.mail-wrapper .mail-table tr td:nth-child(1) {
  width: 130px;
}
.mail-wrapper .mail-table tr td:nth-child(2) {
  width: 180px;
}
.mail-wrapper .mail-table tr td:nth-child(2) .text-truncate {
  width: 105px;
}
.mail-wrapper .mail-table tr td:nth-child(4) {
  width: 65px;
}
.mail-wrapper .mail-table tr td:nth-child(5) {
  width: 150px;
}
.mail-wrapper .mail-table tr td:nth-child(3) {
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  position: relative;
}
.mail-wrapper .mail-table tr td:nth-child(3) .text-truncate {
  position: absolute;
  width: calc(100% - 1.4rem);
  top: 0;
  transform: translatey(-50%);
  left: 0;
}
.mail-wrapper .mail-table tr td:nth-child(2), .mail-wrapper .mail-table tr td:nth-child(3) {
  cursor: pointer;
}
.mail-wrapper.mini-mail-list .img-user {
  width: 30px;
}
.mail-wrapper.mini-mail-list .mail-table td {
  height: 45px;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.mail-wrapper.mini-mail-list .mail-table td .avtar {
  width: 26px;
  height: 26px;
  border-radius: 8px;
}
.mail-wrapper.mini-mail-list .mail-table td .avtar i.f-18 {
  font-size: 16px;
}
.mail-wrapper tr:hover .mail-option .mail-buttons {
  opacity: 1;
  transform: translateY(-50%);
}

.empty-mail .simplebar-content {
  height: 100%;
}

.mail-option {
  position: relative;
  text-align: right;
}
.mail-option .list-inline-item:not(:last-child) {
  margin-right: 0.1rem;
}
.mail-option .mail-buttons {
  position: absolute;
  top: 50%;
  right: 5px;
  padding: 6px;
  opacity: 0;
  transform: translateY(-50%) scale(0.8);
  transition: all 0.15s ease-in-out;
  transform-origin: right;
  background: var(--bs-card-bg);
  border-radius: var(--bs-border-radius);
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
}

.pc-icon-checkbox {
  position: relative;
  padding: 0;
}
.pc-icon-checkbox .pc-icon-uncheck {
  display: inline-block;
  opacity: 0.35;
}
.pc-icon-checkbox .pc-icon-check {
  display: none;
}
.pc-icon-checkbox .form-check-input {
  position: absolute;
  z-index: 9;
  margin-left: 0;
  left: 3px;
  top: 2px;
  opacity: 0;
  cursor: pointer;
  font-size: 20px;
}
.pc-icon-checkbox .form-check-input:checked ~ .pc-icon-uncheck {
  display: none;
}
.pc-icon-checkbox .form-check-input:checked ~ .pc-icon-check {
  display: inline-block;
}

.compose_mail_modal.show.modal-pos-down .modal-dialog {
  margin: 20px;
  align-items: flex-end;
  height: calc(100% - 40px);
  --bs-modal-width: calc(100% - 40px);
}
.compose_mail_modal.show.modal-pos-down .modal-dialog .modal-body {
  overflow: auto;
  height: calc(100vh - 205px);
}
.compose_mail_modal.show.modal-pos-down .modal-dialog .ql-container {
  height: calc(100vh - 480px) !important;
}

.mail-details .indent-text {
  text-align: justify;
}
.mail-details hr {
  opacity: 0.04;
}

[data-pc-preset*=preset-] .mail-wrapper .mail-menulist .list-group-item.active {
  background: rgba(var(--pc-sidebar-color-rgb), 0.1);
}
[data-pc-preset*=preset-] .mail-wrapper .mail-menulist .list-group-item.active .avtar {
  color: var(--bs-secondary);
  background: rgba(var(--bs-secondary-rgb), 0.1);
}
[data-pc-preset*=preset-] .mail-wrapper .mail-menulist .list-group-item.active .material-icons-two-tone {
  background-color: var(--bs-secondary);
  opacity: 1;
}

@media (max-width: 767.98px) {
  .mail-wrapper .mail-table tr {
    flex-wrap: wrap;
  }
  .mail-wrapper .mail-table tr td {
    height: auto;
  }
  .mail-wrapper .mail-table tr td:nth-child(5) {
    margin-right: auto;
    flex: 1 1 auto;
    min-width: 0;
  }
  .mail-wrapper .mail-table tr td:nth-child(3), .mail-wrapper .mail-table tr td:nth-child(5) {
    margin-left: 55px;
  }
  .mail-wrapper .mail-table tr td:nth-child(4) {
    order: 6;
  }
  .mail-wrapper .mail-table tr td:nth-child(3) {
    width: 100%;
  }
}
/**  =====================
  file-manager css start
==========================  **/
.file-manger-wrapper .card {
  transition: all 0.2s ease-in-out;
}
.file-manger-wrapper .card:hover {
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
}

.file-card {
  position: relative;
}
.file-card .form-check .form-check-label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--bs-border-radius);
}
.file-card .form-check .form-check-input:checked + .form-check-label::after {
  background: rgba(var(--bs-primary-rgb), 0.03);
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12), inset 0 0 0 2px var(--bs-primary);
}
.file-card .user-popup {
  position: relative;
  z-index: 5;
}
.file-card .user-group .avtar {
  width: 30px;
  height: 30px;
}
.file-card.table {
  border-collapse: separate;
  width: calc(100% - 20px);
  margin: 0 10px;
  border-spacing: 0 10px;
}
.file-card.table tr {
  background: var(--bs-card-bg);
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
}
.file-card.table tr td:nth-child(2) {
  cursor: pointer;
}
.file-card.table ul {
  margin-bottom: 0;
}

.nav-files {
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  padding: 10px;
  background: var(--bs-card-bg);
}
.nav-files.nav-pills .nav-link {
  padding: 0;
  color: var(--bs-body-color);
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.nav-files.nav-pills .nav-link.active,
.nav-files.nav-pills .show > .nav-link {
  color: var(--bs-body-color);
  background-color: var(--bs-card-bg);
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
}

/**  =====================
      mail css start
==========================  **/
.fc td,
.fc th {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}
.fc .fc-toolbar h2, .fc .fc-toolbar .h2 {
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .fc .fc-toolbar .fc-left,
  .fc .fc-toolbar .fc-right,
  .fc .fc-toolbar .fc-center {
    float: none;
    display: block;
    text-align: center;
    clear: both;
    margin: 10px 0;
  }
  .fc .fc-toolbar > * > * {
    float: none;
  }
  .fc .fc-toolbar .fc-today-button {
    display: none;
  }
}
.fc .fc-toolbar .fc-button {
  text-transform: capitalize;
}
.fc .fc-daygrid-day-top {
  flex-direction: column;
  padding: 14px;
}
.fc .fc-col-header-cell {
  background-color: transparent;
  border: none;
}
.fc .fc-col-header-cell-cushion {
  display: block;
  padding: 16px 4px;
}
.fc .fc-scrollgrid-section .fc-scroller-harness {
  padding: 0;
}
.fc .fc-daygrid-day-number {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  margin: 2px;
}
.fc .fc-daygrid-day-number:hover {
  background-color: #5b6b79;
  color: #ffffff;
}
.fc .fc-daygrid-day.fc-day-today {
  background: url("../images/application/img-cal-bg.jpg");
  background-size: cover;
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  background-color: var(--bs-primary);
  color: #ffffff;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
}
.fc .fc-timegrid-col.fc-day-today {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
}
.fc .fc-col-header,
.fc .fc-daygrid-body,
.fc .fc-scrollgrid-sync-table {
  width: 100% !important;
}
.fc .fc-scrollgrid-section > * {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}
.fc .fc-scrollgrid-section-liquid > td {
  border-top: 0;
}
.fc a[data-navlink]:hover {
  text-decoration: none;
}

.fc-theme-bootstrap a:not([href]) {
  color: var(--bs-body-color);
}

.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc-event {
  background: #eff0f2;
  color: #5b6b79;
  border-radius: 50px;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px;
  text-align: center;
  border: 1px solid;
}

.fc-h-event .fc-event-main {
  color: #5b6b79;
}

.fc-event-title,
.fc-sticky {
  font-weight: 600 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fc-daygrid-event-dot {
  border-color: #ffffff !important;
  display: none;
}

.fc-event-time {
  display: none;
}

.fc-event .fc-content {
  color: #ffffff;
}

#external-events .external-event {
  text-align: left;
  padding: 8px 16px;
  margin: 6px 0;
}

.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark .fc-content {
  color: #f8f9fa;
}

.fc-prev-button.fc-button,
.fc-next-button.fc-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0;
}

.fc-toolbar-chunk .d-inline-flex .fc-button {
  color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.15);
  border: none;
  box-shadow: none;
  border-radius: 50px !important;
}
.fc-toolbar-chunk .d-inline-flex .fc-button:not(:first-child) {
  margin-left: 5px;
}
.fc-toolbar-chunk .d-inline-flex .fc-button:focus, .fc-toolbar-chunk .d-inline-flex .fc-button:hover, .fc-toolbar-chunk .d-inline-flex .fc-button.active {
  color: #ffffff;
  background-color: var(--bs-primary);
}
.fc-toolbar-chunk .fc-button-primary {
  background-color: rgba(var(--bs-primary-rgb), 0.1) !important;
  color: var(--bs-primary);
  border: var(--bs-primary);
  padding: 9px 16px;
}
.fc-toolbar-chunk .fc-button-primary:focus, .fc-toolbar-chunk .fc-button-primary:hover {
  background-color: var(--bs-primary) !important;
  color: #fff;
}
.fc-toolbar-chunk .fc-button-primary:focus {
  box-shadow: none;
}
.fc-toolbar-chunk .fc-button-primary:not(:disabled).fc-button-active, .fc-toolbar-chunk .fc-button-primary:not(:disabled):active {
  background-color: var(--bs-primary) !important;
  color: #fff;
}
.fc-toolbar-chunk .fc-button-primary:not(:disabled).fc-button-active:focus, .fc-toolbar-chunk .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}
.fc-toolbar-chunk .fc-today-button {
  border-radius: 50px !important;
  background-color: var(--bs-primary) !important;
  border: var(--bs-primary);
  color: #fff;
}

@media (max-width: 575.98px) {
  .fc-toolbar {
    flex-direction: column;
    gap: 16px;
  }
}

.fc-daygrid-event-harness .fc-daygrid-event {
  backdrop-filter: blur(4px);
}

.fc-timegrid-event-harness .fc-timegrid-event {
  backdrop-filter: blur(4px);
}

.fc-timegrid-slots table tr {
  border-color: #e7eaee !important;
}

.fc-list-table {
  border-color: #e7eaee;
}

.fc-event.event-theme {
  background: #ffebf0 !important;
  color: #ff3366 !important;
}
.fc-event.event-theme.fc-h-event .fc-event-main {
  color: #ff3366;
}
.fc-event.event-theme.fc-h-event:focus, .fc-event.event-theme.fc-h-event:hover {
  background: #ff3366;
  color: #fff;
}
.fc-event.event-primary {
  background: #e8e9f2 !important;
  color: #1A237E !important;
}
.fc-event.event-primary.fc-h-event .fc-event-main {
  color: #1A237E;
}
.fc-event.event-primary.fc-h-event:focus, .fc-event.event-primary.fc-h-event:hover {
  background: #1A237E;
  color: #fff;
}
.fc-event.event-secondary {
  background: #eff0f2 !important;
  color: #5b6b79 !important;
}
.fc-event.event-secondary.fc-h-event .fc-event-main {
  color: #5b6b79;
}
.fc-event.event-secondary.fc-h-event:focus, .fc-event.event-secondary.fc-h-event:hover {
  background: #5b6b79;
  color: #fff;
}
.fc-event.event-success {
  background: #eaf6f2 !important;
  color: #2ca87f !important;
}
.fc-event.event-success.fc-h-event .fc-event-main {
  color: #2ca87f;
}
.fc-event.event-success.fc-h-event:focus, .fc-event.event-success.fc-h-event:hover {
  background: #2ca87f;
  color: #fff;
}
.fc-event.event-info {
  background: #ecfafb !important;
  color: #3ec9d6 !important;
}
.fc-event.event-info.fc-h-event .fc-event-main {
  color: #3ec9d6;
}
.fc-event.event-info.fc-h-event:focus, .fc-event.event-info.fc-h-event:hover {
  background: #3ec9d6;
  color: #fff;
}
.fc-event.event-warning {
  background: #fcf3e6 !important;
  color: #e58a00 !important;
}
.fc-event.event-warning.fc-h-event .fc-event-main {
  color: #e58a00;
}
.fc-event.event-warning.fc-h-event:focus, .fc-event.event-warning.fc-h-event:hover {
  background: #e58a00;
  color: #fff;
}
.fc-event.event-danger {
  background: #fce9e9 !important;
  color: #dc2626 !important;
}
.fc-event.event-danger.fc-h-event .fc-event-main {
  color: #dc2626;
}
.fc-event.event-danger.fc-h-event:focus, .fc-event.event-danger.fc-h-event:hover {
  background: #dc2626;
  color: #fff;
}
.fc-event.event-light {
  background: #fefeff !important;
  color: #f8f9fa !important;
}
.fc-event.event-light.fc-h-event .fc-event-main {
  color: #f8f9fa;
}
.fc-event.event-light.fc-h-event:focus, .fc-event.event-light.fc-h-event:hover {
  background: #f8f9fa;
  color: #fff;
}
.fc-event.event-dark {
  background: #e9e9ea !important;
  color: #212529 !important;
}
.fc-event.event-dark.fc-h-event .fc-event-main {
  color: #212529;
}
.fc-event.event-dark.fc-h-event:focus, .fc-event.event-dark.fc-h-event:hover {
  background: #212529;
  color: #fff;
}

.fc .fc-popover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  border-radius: 8px;
  border: none;
  overflow: hidden;
  z-index: 1040;
}
.fc .fc-popover .fc-popover-header,
.fc .fc-popover .fc-popover-body {
  padding: 12px 14px;
}
.fc .fc-popover .fc-popover-header {
  background: transparent;
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}
.fc .fc-popover .fc-popover-header .fc-popover-close {
  color: #dc2626;
  width: 24px;
  height: 24px;
  font-size: 14px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.fc .fc-popover .fc-popover-header .fc-popover-close:focus, .fc .fc-popover .fc-popover-header .fc-popover-close:hover {
  background: #fce9e9;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-soft-dark .fc-event-main,
.fc-daygrid-event-harness .fc-daygrid-event.bg-soft-dark .fc-event-title {
  color: var(--bs-dark) !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 5px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 5px;
}

.fc-v-event .fc-event-main {
  color: inherit;
}

.avtar.pc-cal-toggle {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: sticky;
  top: calc(100% - 80px);
  left: calc(100% - 120px);
  z-index: 1025;
}

.cal-event-offcanvas {
  --bs-offcanvas-zindex: 1081;
}

/**  =====================
      e-commerce css start
==========================  **/
.ecomm-prod-slider .carousel-indicators [data-bs-target] {
  opacity: 0.3;
}
.ecomm-prod-slider .carousel-indicators .active {
  opacity: 1;
}
.ecomm-prod-slider .product-carousel-indicators {
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: flex-start;
}
.ecomm-prod-slider .product-carousel-indicators::-webkit-scrollbar {
  height: 6px;
  opacity: 0;
}
.ecomm-prod-slider .product-carousel-indicators::-webkit-scrollbar:hover {
  opacity: 1;
}
.ecomm-prod-slider .product-carousel-indicators::-webkit-scrollbar-track {
  background: transparent;
}
.ecomm-prod-slider .product-carousel-indicators::-webkit-scrollbar-thumb {
  background: #e9ecef;
}
.ecomm-prod-slider .product-carousel-indicators::-webkit-scrollbar-thumb:hover {
  background: #aeb9c5;
}

.offer-check-block {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.offer-check-block::-webkit-scrollbar {
  height: 6px;
  opacity: 0;
}
.offer-check-block::-webkit-scrollbar:hover {
  opacity: 1;
}
.offer-check-block::-webkit-scrollbar-track {
  background: transparent;
}
.offer-check-block::-webkit-scrollbar-thumb {
  background: #e9ecef;
}
.offer-check-block::-webkit-scrollbar-thumb:hover {
  background: #aeb9c5;
}

.offer-check {
  overflow: visible;
  position: relative;
  width: 180px;
  flex: 0 0 auto;
  margin: 10px;
}
.offer-check .offer-details {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin: 14px 0;
}
.offer-check .form-check-label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--bs-border-radius);
}
.offer-check .form-check-input {
  position: relative;
  z-index: 8;
  float: right;
}
.offer-check .form-check {
  padding-left: 0;
  margin-bottom: 0;
}
.offer-check .form-check .form-check-input:checked + .form-check-label::after {
  background: rgba(var(--bs-primary-rgb), 0.1);
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12), inset 0 0 0 2px rgba(var(--bs-primary-rgb), 0.5);
}

.divider {
  position: relative;
  text-align: center;
  margin: 8px 0;
}
.divider::after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 1px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: var(--bs-border-color);
}
.divider span {
  position: relative;
  z-index: 5;
  padding: 8px 16px;
  background: var(--bs-card-bg);
  text-transform: uppercase;
}

.coupon-card {
  overflow: hidden;
  position: relative;
  border: none;
}
.coupon-card::after, .coupon-card::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
}
.coupon-card::after {
  width: 180px;
  height: 180px;
  left: -23px;
  top: -64px;
  opacity: 0.2;
}
.coupon-card::before {
  width: 60px;
  height: 60px;
  left: -10px;
  bottom: -20px;
  opacity: 0.15;
}
.coupon-card .card-body {
  position: relative;
  z-index: 5;
}
.coupon-card .card-body::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(213.66deg, transparent 25.46%, rgba(0, 0, 0, 0.2) 68.77%, rgba(0, 0, 0, 0.3) 81.72%);
  z-index: 1;
}
.coupon-card .card-body > div {
  position: relative;
  z-index: 5;
}
.coupon-card .btn-coupon {
  color: var(--bs-white);
  border-color: var(--bs-white);
  background: rgba(var(--bs-white-rgb), 0.2);
}
.coupon-card .btn-coupon:hover, .coupon-card .btn-coupon:focus, .coupon-card .btn-coupon:active {
  color: var(--bs-white);
  border-color: var(--bs-white);
  background: rgba(var(--bs-white-rgb), 0.4);
}
.coupon-card.small-coupon-card::after, .coupon-card.small-coupon-card::before {
  display: none;
}

.coupon-label {
  margin: 16px 0 8px;
  padding: 24px 50px;
  position: relative;
  border-radius: var(--bs-border-radius);
  border: 1px dashed;
}
.coupon-label:before, .coupon-label:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  background: var(--bs-modal-bg);
  border-radius: 50%;
  border-top: 1px dashed;
}
.coupon-label:before {
  border-right: 1px dashed;
  left: 0;
  transform: translate(-50%, -50%) rotate(45deg);
}
.coupon-label:after {
  right: 0;
  border-left: 1px dashed;
  transform: translate(50%, -50%) rotate(-45deg);
}
.coupon-label.coupon-label-primary {
  border-color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.05);
}
.coupon-label.coupon-label-primary:before, .coupon-label.coupon-label-primary:after {
  border-color: var(--bs-primary);
}
.coupon-label.coupon-label-warning {
  border-color: var(--bs-warning);
  background-color: rgba(var(--bs-warning-rgb), 0.05);
}
.coupon-label.coupon-label-warning:before, .coupon-label.coupon-label-warning:after {
  border-color: var(--bs-warning);
}

.address-check {
  overflow: visible;
  position: relative;
  width: calc(100% - 20px);
  margin: 10px;
}
.address-check .address-details {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin: 14px 0;
}
.address-check .form-check-label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--bs-border-radius);
}
.address-check .form-check-input {
  position: relative;
  z-index: 8;
  opacity: 0;
}
.address-check .address-btns .avtar {
  position: relative;
  z-index: 6;
}
.address-check .form-check {
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-border-radius);
}
.address-check .form-check .form-check-input:checked + .form-check-label .address-btns {
  opacity: 0;
}
.address-check .form-check .form-check-input:checked + .form-check-label::after {
  background: rgba(var(--bs-primary-rgb), 0.1);
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12), inset 0 0 0 2px rgba(var(--bs-primary-rgb), 0.5);
}

.ecom-wrapper {
  display: flex;
}
.ecom-wrapper .ecom-filter {
  width: 300px;
  margin-right: var(--bs-gutter-x);
}
.ecom-wrapper .ecom-filter .scroll-block {
  height: calc(100vh - 120px);
}
.ecom-wrapper .ecom-offcanvas {
  --bs-offcanvas-width: 301px;
}
.ecom-wrapper .ecom-offcanvas .sticky-xxl-top {
  top: 100px;
}
.ecom-wrapper .ecom-offcanvas:not(.show) .btn-link-danger.btn-pc-default {
  display: none;
}
.ecom-wrapper .ecom-offcanvas.show .collapse-horizontal.collapse:not(.show) {
  display: block;
}
.ecom-wrapper .ecom-offcanvas.show .ecom-filter {
  margin-right: 0;
}
.ecom-wrapper .ecom-offcanvas.show .ecom-filter .scroll-block {
  height: calc(100vh - 120px);
}

.product-card {
  transition: all 0.3s ease-in-out;
}
.product-card .card-img-top {
  position: relative;
  padding: 8px 8px 0;
  width: 100%;
  overflow: hidden;
}
.product-card .card-img-top .img-prod {
  border-radius: var(--bs-border-radius);
}
.product-card .prod-color {
  display: inline-flex;
  align-items: center;
}
.product-card .prod-color span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px var(--bs-card-bg);
}
.product-card .prod-color span:not(:first-child) {
  margin-left: -3px;
}
.product-card .prod-content {
  -webkit-line-clamp: 2;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-card .btn-prod-cart {
  transition: all 0.3s ease-in-out;
}
.product-card .btn-prod-cart .btn, .product-card .btn-prod-cart .introjs-tooltip .introjs-button, .introjs-tooltip .product-card .btn-prod-cart .introjs-button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.product-card .btn-prod-cart .btn svg, .product-card .btn-prod-cart .introjs-tooltip .introjs-button svg, .introjs-tooltip .product-card .btn-prod-cart .introjs-button svg {
  width: 24px;
  height: 24px;
}
.product-card:hover {
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
}
.product-card:not(:hover) .btn-prod-cart {
  transform: scale(0) rotate(-90deg);
}

.product-sticky {
  top: 85px;
}
.product-sticky .prod-likes {
  z-index: 5;
}

.color-checkbox {
  position: relative;
  padding: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}
.color-checkbox .form-check-input {
  margin: 0;
  position: absolute;
  top: 0;
  z-index: 5;
  font-size: 22px;
  opacity: 0;
}
.color-checkbox .form-check-input:checked ~ i::after {
  content: "\ea5e";
  font-family: tabler-icons !important;
  line-height: 1;
  font-size: 14px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 1px var(--bs-card-bg), 0 0 0 5px var(--bs-border-color);
}
.color-checkbox i {
  font-size: 28px;
}

.checkout-tabs {
  border-bottom: 0;
}
.checkout-tabs .nav-item {
  margin-bottom: 0px;
}
.checkout-tabs .nav-item .nav-link {
  position: relative;
  padding: 0.8rem;
  margin: 0 0.2rem;
  font-weight: 500;
  color: var(--bs-body-color);
  display: flex;
  align-items: center;
  border-radius: var(--bs-border-radius) !important;
}
.checkout-tabs .nav-item .nav-link p {
  margin-top: 0;
  font-weight: 400;
  opacity: 0.7;
}
.checkout-tabs .nav-item .nav-link .avtar i {
  font-size: 18px;
}
@media (max-width: 575.98px) {
  .checkout-tabs .nav-item {
    width: 100%;
  }
}
.checkout-tabs .nav-link:hover {
  border-color: transparent;
  color: var(--bs-primary);
}
.checkout-tabs .nav-link:hover p,
.checkout-tabs .nav-link:hover h5,
.checkout-tabs .nav-link:hover .h5 {
  color: var(--bs-primary);
}
.checkout-tabs .nav-link:hover .avtar {
  background-color: rgba(var(--bs-primary-rgb), 0.15);
  color: var(--bs-primary);
}
.checkout-tabs .nav-item.show .nav-link,
.checkout-tabs .nav-link.active {
  border-color: transparent;
  background-color: transparent;
  color: #1A237E;
}
.checkout-tabs .nav-item.show .nav-link p,
.checkout-tabs .nav-item.show .nav-link h5,
.checkout-tabs .nav-item.show .nav-link .h5,
.checkout-tabs .nav-link.active p,
.checkout-tabs .nav-link.active h5,
.checkout-tabs .nav-link.active .h5 {
  color: #1A237E;
}
.checkout-tabs .nav-item.show .nav-link .avtar,
.checkout-tabs .nav-link.active .avtar {
  background-color: var(--bs-primary);
  color: #fff;
}

.prod-likes {
  position: relative;
  padding: 0;
}
.prod-likes .prod-likes-icon {
  stroke: rgba(19, 25, 32, 0.5);
  fill: rgba(19, 25, 32, 0.2);
  z-index: 3;
}
.prod-likes .form-check-input {
  width: 20px;
  height: 20px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 5;
  cursor: pointer;
}
.prod-likes .form-check-input:checked + .prod-likes-icon {
  stroke: var(--bs-danger);
  fill: rgba(var(--bs-danger-rgb), 0.8);
  animation: _26Tc6u 0.2s ease 0.3s forwards;
}

.pc-like {
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 10px;
}
.pc-like .pc-dots:nth-child(4):before {
  transform: translate3d(-4px, -5px, 0);
}
.pc-like .pc-dots:nth-child(4):after {
  transform: translate3d(4px, -5px, 0) scale(0.7);
}
.pc-like .pc-dots:nth-child(3):before {
  transform: translate3d(-5px, -4px, 0);
}
.pc-like .pc-dots:nth-child(3):after {
  transform: translate3d(-5px, 4px, 0) scale(0.7);
}
.pc-like .pc-dots:nth-child(2):before {
  transform: translate3d(-4px, 5px, 0);
}
.pc-like .pc-dots:nth-child(2):after {
  transform: translate3d(4px, 5px, 0) scale(0.7);
}
.pc-like .pc-dots:first-child:before {
  transform: translate3d(5px, -4px, 0);
}
.pc-like .pc-dots:first-child:after {
  transform: translate3d(5px, 4px, 0) scale(0.7);
}
.pc-like .pc-dots:after {
  background: #dc2626;
}
.pc-like .pc-dots:before {
  background: #e58a00;
}
.pc-like .like-wrapper {
  position: relative;
  display: inline-flex;
}
.pc-like .pc-group {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
}
.pc-like .pc-dots:after, .pc-like .pc-dots:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  margin: -2px;
  border-radius: 50%;
}
.pc-like.pc-like-animate .pc-dots:first-child:after {
  animation: _3z13ic 0.6s ease 0.3s;
}
.pc-like.pc-like-animate .pc-dots:nth-child(4):before {
  animation: u9Mhrv 0.6s ease 0.3s;
}
.pc-like.pc-like-animate .pc-dots:nth-child(4):after {
  animation: _2ERa1V 0.6s ease 0.3s;
}
.pc-like.pc-like-animate .pc-dots:nth-child(3):before {
  animation: _3TtatR 0.6s ease 0.3s;
}
.pc-like.pc-like-animate .pc-dots:nth-child(3):after {
  animation: _3UC1np 0.6s ease 0.3s;
}
.pc-like.pc-like-animate .pc-dots:nth-child(2):before {
  animation: _33IoP_ 0.6s ease 0.3s;
}
.pc-like.pc-like-animate .pc-dots:nth-child(2):after {
  animation: _2D5htx 0.6s ease 0.3s;
}
.pc-like.pc-like-animate .pc-group {
  animation: r9OBmc 0.6s ease 0.3s;
}
.pc-like.pc-like-animate .pc-dots:first-child:before {
  animation: _18Xo9B 0.6s ease 0.3s;
}

@keyframes _26Tc6u {
  0% {
    transform: translateZ(0) scale(0);
  }
  40% {
    transform: translateZ(0) scale(1.2);
  }
  to {
    transform: translateZ(0) scale(1);
  }
}
@keyframes _3d25CI {
  0% {
    transform: translateZ(0) scale(0);
    opacity: 1;
  }
  to {
    transform: translateZ(0) scale(1);
    opacity: 0;
  }
}
@keyframes r9OBmc {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes _18Xo9B {
  to {
    transform: translate3d(22px, -4px, 0);
  }
}
@keyframes _3z13ic {
  to {
    transform: translate3d(14px, 4px, 0) scale(0.7);
  }
}
@keyframes _33IoP_ {
  to {
    transform: translate3d(-4px, 22px, 0);
  }
}
@keyframes _2D5htx {
  to {
    transform: translate3d(4px, 18px, 0) scale(0.7);
  }
}
@keyframes _3TtatR {
  to {
    transform: translate3d(-22px, -4px, 0);
  }
}
@keyframes _3UC1np {
  to {
    transform: translate3d(-18px, 4px, 0) scale(0.7);
  }
}
@keyframes u9Mhrv {
  to {
    transform: translate3d(-4px, -22px, 0);
  }
}
@keyframes _2ERa1V {
  to {
    transform: translate3d(4px, -18px, 0) scale(0.7);
  }
}
/**====== e-commerce css end ======**/
.invoice-tab,
.profile-tabs {
  border-bottom: 0;
}
.invoice-tab .nav-item,
.profile-tabs .nav-item {
  margin-bottom: 0px;
}
.invoice-tab .nav-item .nav-link,
.profile-tabs .nav-item .nav-link {
  position: relative;
  padding: 1rem 0;
  margin: 0 1.5rem 0 0;
  font-weight: 500;
  color: var(--bs-body-color);
  display: inline-flex;
  align-items: center;
}
@media (max-width: 575.98px) {
  .invoice-tab .nav-item .nav-link,
  .profile-tabs .nav-item .nav-link {
    padding: 0.8rem 0;
  }
}
.invoice-tab .nav-item .nav-link i,
.profile-tabs .nav-item .nav-link i {
  font-size: 18px;
}
.invoice-tab .nav-item .nav-link .material-icons-two-tone,
.profile-tabs .nav-item .nav-link .material-icons-two-tone {
  font-size: 20px;
}
.invoice-tab .nav-item .nav-link:after,
.profile-tabs .nav-item .nav-link:after {
  content: "";
  background: var(--bs-primary);
  position: absolute;
  transition: all 0.3s ease-in-out;
  left: 50%;
  right: 50%;
  bottom: -1px;
  height: 2px;
  border-radius: 2px 2px 0 0;
}
.invoice-tab .nav-link:hover,
.profile-tabs .nav-link:hover {
  border-color: transparent;
  background: transparent;
  color: var(--bs-primary);
}
.invoice-tab .nav-link:hover .material-icons-two-tone,
.profile-tabs .nav-link:hover .material-icons-two-tone {
  background-color: var(--bs-primary);
}
.invoice-tab .nav-item.show .nav-link,
.invoice-tab .nav-link.active,
.profile-tabs .nav-item.show .nav-link,
.profile-tabs .nav-link.active {
  border-color: transparent;
  color: var(--bs-primary);
  background: transparent;
}
.invoice-tab .nav-item.show .nav-link .material-icons-two-tone,
.invoice-tab .nav-link.active .material-icons-two-tone,
.profile-tabs .nav-item.show .nav-link .material-icons-two-tone,
.profile-tabs .nav-link.active .material-icons-two-tone {
  background-color: var(--bs-primary);
}
.invoice-tab .nav-item.show .nav-link:after,
.invoice-tab .nav-link.active:after,
.profile-tabs .nav-item.show .nav-link:after,
.profile-tabs .nav-link.active:after {
  left: 0;
  right: 0;
}

.table tr td .row {
  margin-right: 50px;
}
.table tr td .row .col {
  display: flex;
  flex-direction: column;
  width: 1%;
}

.table-invoice-comp {
  border-spacing: 0 10px;
  border-collapse: separate;
  width: calc(100% - 10px);
  margin: 0 5px;
}
.table-invoice-comp tbody tr {
  box-shadow: var(--pc-card-shadow);
  background: var(--bs-card-bg);
  border-radius: 4px;
  position: relative;
}

.invoice-total {
  width: 100%;
  max-width: 400px;
}

.invoice-tab .nav-item .nav-link {
  padding: 0.9rem 0.8rem;
  margin: 0;
  font-size: 16px;
}
.invoice-tab .avtar {
  font-size: 13px;
  width: 22px;
  height: 22px;
}

/**  =====================
  25. Application css end
==========================  **/
/**  =====================
  admin css start
==========================  **/
.widget-calender .datepicker-header {
  border-bottom: 1px solid var(--bs-border-color);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.widget-calender .datepicker-view,
.widget-calender .datepicker {
  width: 100%;
}
.widget-calender .datepicker {
  padding: 0;
}
.widget-calender .datepicker span.datepicker-cell {
  border-radius: 25px;
}

.course-price {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.course-price .form-check label {
  border: 1px solid var(--bs-border-color);
  padding: 12px 20px;
  border-radius: var(--bs-border-radius);
}
.course-price .form-check label .badge {
  color: var(--bs-body-color);
  background: rgba(var(--bs-secondary-rgb), 0.1);
  border-radius: var(--bs-border-radius);
}
.course-price .form-check-input {
  display: none;
}
.course-price .form-check-input:checked ~ label {
  background: var(--bs-primary);
  border-color: var(--bs-primary);
}
.course-price .form-check-input:checked ~ label span {
  color: rgb(255, 255, 255);
}
.course-price .form-check-input:checked ~ label .badge {
  background: rgba(255, 255, 255, 0.1);
}

.course-wizard {
  position: relative;
  display: inline-flex;
  width: auto;
  margin-bottom: 30px;
}
.course-wizard:after {
  content: "";
  position: absolute;
  left: 50px;
  right: 50px;
  top: 26px;
  height: 1px;
  background: var(--bs-border-color);
  z-index: 1;
}
.course-wizard .nav-item .nav-link {
  display: block;
  color: var(--bs-body-color);
  text-align: center;
  min-width: 120px;
  position: relative;
  z-index: 5;
}
.course-wizard .nav-item .nav-link .course-wizard-num {
  width: 34px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 14px;
  background-color: var(--bs-card-bg);
  border: 1px solid var(--bs-border-color);
}
.course-wizard.nav-pills .nav-link.active,
.course-wizard.nav-pills .show > .nav-link {
  background: transparent;
  color: var(--bs-body-color);
}
.course-wizard.nav-pills .nav-link.active .course-wizard-num,
.course-wizard.nav-pills .show > .nav-link .course-wizard-num {
  background: var(--bs-primary);
  border-color: var(--bs-primary);
  color: #fff;
}

.set-color-block {
  padding: 20px;
  border-radius: var(--bs-border-radius);
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.set-color-block.active, .set-color-block:hover {
  background-color: rgba(var(--bs-body-bg-rgb), 0.4);
  border-color: var(--bs-border-color);
}

.nav-price {
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  padding: 10px;
}
.nav-price.nav-pills .nav-link {
  padding: 16px 20px;
  color: var(--bs-body-color);
}
.nav-price.nav-pills .nav-link.active,
.nav-price.nav-pills .show > .nav-link {
  color: var(--bs-body-color);
  background-color: var(--bs-card-bg);
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
}

.price-card.price-popular {
  border: 2px solid var(--bs-primary);
}
.price-card.price-popular .price-head {
  background: rgba(var(--bs-primary-rgb), 0.1);
  margin-bottom: 16px;
}
.price-card.price-popular .price-head.v2 {
  border-radius: var(--bs-border-radius);
  margin: 0 0 16px;
}
.price-card .price-head {
  border-radius: var(--bs-border-radius) var(--bs-border-radius) 60px 60px;
  padding: 20px;
  text-align: center;
  margin: 15px 15px 30px;
  padding-bottom: 30px;
  position: relative;
}
.price-card .price-head.v3 {
  margin: 0 0 25px;
  padding: 0;
}
.price-card .price-price {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
}
.price-card .price-price span {
  margin-top: 8px;
  font-weight: 400;
  font-size: 0.875rem;
  opacity: 0.8;
}
.price-card .card-body.v3 {
  display: flex;
  flex-direction: column;
}
.price-card .product-list.v3 {
  flex: 1 1 auto;
}
.price-card .product-list.v3 .list-group-item {
  padding: 4px 0 4px 25px;
  padding-left: 0;
  background: transparent;
  border: none;
}
.price-card .product-list.v3 .list-group-item:before {
  display: none;
}

.hd-body .excerpt {
  padding: 15px;
  background: #f8f9fa;
  border: 1px solid var(--bs-border-color);
}
.hd-body .ticket-customer,
.hd-body .ticket-type-icon {
  display: block;
  text-transform: capitalize;
}
.hd-body:hover .hover-blk {
  transform: scale(1);
}

.sm-view .card-body .excerpt,
.sm-view .card-body ul.list-inline {
  display: none;
}

.md-view .excerpt {
  display: none;
}

.md-view .col-auto ul li:nth-child(1),
.md-view .col-auto ul li:nth-child(3),
.sm-view .col-auto ul li:nth-child(1),
.sm-view .col-auto ul li:nth-child(3) {
  display: none;
}

.hover-blk {
  background: #fff;
  width: 270px;
  left: calc(100% + 10px);
  transform: scale(0);
  z-index: 5;
  transition: all 0.1s ease-out;
}
.hover-blk .img-txt p {
  display: inline-block;
}

.topic-name h1, .topic-name .h1 {
  float: left;
  font-weight: normal;
}
.topic-name .btn-star {
  float: right;
}

.dicon-blk {
  top: 0;
  right: 0;
}
.dicon-blk li {
  display: inline-block;
}

.hd-detail .col-right .edit-del {
  opacity: 0;
}
.hd-detail .col-right .edit-del i {
  opacity: 0.2;
}
.hd-detail .col-right .edit-del i:hover {
  opacity: 1;
}
.hd-detail:hover .edit-del {
  opacity: 1;
}

.hdd-user i {
  bottom: 0;
}

.ticket-block .hd-body {
  border-left: 3px solid #ccc;
}
.ticket-block .col.border-right {
  border-color: #ccc !important;
}
.ticket-block .ticket-type-icon {
  font-weight: 500;
  color: #111;
}
.ticket-block .excerpt h6, .ticket-block .excerpt .h6 {
  color: #686c71;
  font-weight: 500;
}
.ticket-block .excerpt h6 a, .ticket-block .excerpt .h6 a {
  color: #686c71;
}
.ticket-block .excerpt h6 a:hover, .ticket-block .excerpt .h6 a:hover {
  text-decoration: underline !important;
  color: #111;
}

.ticket-block.open-tic .hd-body {
  border-color: var(--bs-danger);
}

.ticket-block.close-tic .hd-body {
  border-color: var(--bs-success);
}

.right-col .card-footer label {
  font-weight: 500;
}

.hdd-right-inner .select2-container--default {
  margin-bottom: 10px;
}
.hdd-right-inner .select2-container--default .select2-selection {
  border-color: var(--bs-border-color);
}

.hdd-right-side {
  width: 495px;
}
.hdd-right-side .hdd-right-inner {
  position: fixed;
  width: 465px;
}

.span-content a {
  padding: 0 7px;
}

@media (max-width: 992px) {
  .hd-detail .col-right .edit-del {
    opacity: 1;
  }
}
@media (max-width: 767px) {
  .hdd-right-side {
    width: 100%;
  }
  .hdd-right-side .hdd-right-inner {
    position: static;
    width: 100%;
  }
}
@media (max-width: 575px) {
  .q-view .content {
    width: 450px;
  }
}
@media (max-width: 380px) {
  .q-view .content {
    width: 300px;
  }
}
#qviewModal.fade .q-view-modal.modal-dialog {
  transform: translateX(550px);
}
#qviewModal.show {
  padding-right: 0 !important;
}
#qviewModal.show .q-view-modal.modal-dialog {
  transform: none;
}

.q-view-modal {
  margin: 0;
}
.q-view-modal .modal-content, .q-view-modal .introjs-tooltip {
  height: 100vh;
  border-radius: 0;
  border: none;
}
.q-view-modal .modal-content .modal-body, .q-view-modal .introjs-tooltip .modal-body {
  flex: 1 1 100%;
}
.q-view-modal .modal-content .modal-body .trumbowyg-box, .q-view-modal .introjs-tooltip .modal-body .trumbowyg-box,
.q-view-modal .modal-content .modal-body .trumbowyg-editor,
.q-view-modal .introjs-tooltip .modal-body .trumbowyg-editor {
  min-height: 110px;
}
@media (min-width: 576px) {
  .q-view-modal.modal-dialog {
    max-width: 550px;
    margin: 0 0 0 auto;
  }
}

.ticket-card.open-ticket {
  border-left: 3px solid #dc2626;
}
.ticket-card.close-ticket {
  border-left: 3px solid #2ca87f;
}

.help-main.sm-view .help-md-hidden,
.help-main.sm-view .help-sm-hidden {
  display: none;
}
.help-main.md-view .help-md-hidden {
  display: none;
}

.popup-trigger {
  cursor: pointer;
}

.invoice-tab .nav-item .nav-link {
  padding: 0.9rem 0.8rem;
  margin: 0;
  font-size: 16px;
}
.invoice-tab .avtar {
  font-size: 13px;
  width: 22px;
  height: 22px;
}

.feed-card h6, .feed-card .h6 {
  margin-top: 7px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}
.feed-card h6 > span, .feed-card .h6 > span {
  font-size: 11px;
}
.feed-card h6:hover, .feed-card .h6:hover {
  color: #1A237E;
}
.feed-card .feed-icon {
  border-radius: 50%;
  display: block;
  width: 34px;
  height: 34px;
  text-align: center;
  padding: 10px 8px;
}
.feed-card .card-body {
  position: relative;
}
.feed-card .card-body .border-feed {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.feed-card .card-body .border-feed i {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.feed-card .card-body .border-feed i.material-icons-two-tone {
  background-color: #fff;
}
.feed-card:hover .border-feed.bg-theme {
  background-color: rgba(255, 51, 102, 0.3) !important;
}
.feed-card:hover .border-feed.bg-theme i {
  color: #ff3366;
}
.feed-card:hover .border-feed.bg-theme i.material-icons-two-tone {
  background-color: #ff3366;
}
.feed-card:hover .border-feed.bg-primary {
  background-color: rgba(26, 35, 126, 0.3) !important;
}
.feed-card:hover .border-feed.bg-primary i {
  color: #1A237E;
}
.feed-card:hover .border-feed.bg-primary i.material-icons-two-tone {
  background-color: #1A237E;
}
.feed-card:hover .border-feed.bg-secondary {
  background-color: rgba(91, 107, 121, 0.3) !important;
}
.feed-card:hover .border-feed.bg-secondary i {
  color: #5b6b79;
}
.feed-card:hover .border-feed.bg-secondary i.material-icons-two-tone {
  background-color: #5b6b79;
}
.feed-card:hover .border-feed.bg-success {
  background-color: rgba(44, 168, 127, 0.3) !important;
}
.feed-card:hover .border-feed.bg-success i {
  color: #2ca87f;
}
.feed-card:hover .border-feed.bg-success i.material-icons-two-tone {
  background-color: #2ca87f;
}
.feed-card:hover .border-feed.bg-info {
  background-color: rgba(62, 201, 214, 0.3) !important;
}
.feed-card:hover .border-feed.bg-info i {
  color: #3ec9d6;
}
.feed-card:hover .border-feed.bg-info i.material-icons-two-tone {
  background-color: #3ec9d6;
}
.feed-card:hover .border-feed.bg-warning {
  background-color: rgba(229, 138, 0, 0.3) !important;
}
.feed-card:hover .border-feed.bg-warning i {
  color: #e58a00;
}
.feed-card:hover .border-feed.bg-warning i.material-icons-two-tone {
  background-color: #e58a00;
}
.feed-card:hover .border-feed.bg-danger {
  background-color: rgba(220, 38, 38, 0.3) !important;
}
.feed-card:hover .border-feed.bg-danger i {
  color: #dc2626;
}
.feed-card:hover .border-feed.bg-danger i.material-icons-two-tone {
  background-color: #dc2626;
}
.feed-card:hover .border-feed.bg-light {
  background-color: rgba(248, 249, 250, 0.3) !important;
}
.feed-card:hover .border-feed.bg-light i {
  color: #f8f9fa;
}
.feed-card:hover .border-feed.bg-light i.material-icons-two-tone {
  background-color: #f8f9fa;
}
.feed-card:hover .border-feed.bg-dark {
  background-color: rgba(33, 37, 41, 0.3) !important;
}
.feed-card:hover .border-feed.bg-dark i {
  color: #212529;
}
.feed-card:hover .border-feed.bg-dark i.material-icons-two-tone {
  background-color: #212529;
}

/**  =====================
  admin css end
==========================  **/
/**  =====================
    26. Sweetalert css start
========================== **/
body .swal2-container {
  z-index: 1080 !important;
}
body .swal2-container.swal2-noanimation, body .swal2-container.swal2-backdrop-show {
  background: rgba(0, 0, 0, 0.6) !important;
}

.swal-icon--error {
  border-color: #dc2626;
}

.swal-icon--error__line {
  background-color: #dc2626;
}

.swal-icon--warning {
  border-color: #e58a00;
}

.swal-icon--warning__body {
  background-color: #e58a00;
}

.swal2-popup {
  background: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
}
.swal2-popup .swal2-footer {
  border-top-color: var(--bs-border-color) !important;
}

.swal-icon--warning__dot {
  background-color: #e58a00;
}

@-webkit-keyframes pulseWarning {
  0% {
    border-color: #ff9900;
  }
  to {
    border-color: #e58a00;
  }
}
@keyframes pulseWarning {
  0% {
    border-color: #ff9900;
  }
  to {
    border-color: #e58a00;
  }
}
.swal-icon--success {
  border-color: #2ca87f;
}

.swal-icon--success__ring {
  border: 4px solid rgba(44, 168, 127, 0.2);
}

.swal-icon--success__line {
  background-color: #2ca87f;
}

.swal-icon--info {
  border-color: #3ec9d6;
}
.swal-icon--info:after, .swal-icon--info:before {
  background-color: #3ec9d6;
}

.swal-title {
  color: rgba(0, 0, 0, 0.65);
}

.swal-text {
  color: rgba(0, 0, 0, 0.64);
}

.swal-button {
  background-color: var(--bs-primary);
  margin: 0 10px;
}
.swal-button:not([disabled]):hover {
  background-color: #161d69;
}
.swal-button:active {
  background-color: #161d69;
}
.swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(26, 35, 126, 0.29);
}

.swal-button--cancel {
  color: #555;
  background-color: #efefef;
  margin: 0 10px;
}
.swal-button--cancel:not([disabled]):hover {
  background-color: #e8e8e8;
}
.swal-button--cancel:active {
  background-color: #d7d7d7;
}
.swal-button--cancel:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(116, 136, 150, 0.29);
}

.swal-button--danger {
  background-color: #dc2626;
  margin: 0 10px;
}
.swal-button--danger:not([disabled]):hover {
  background-color: #c82020;
}
.swal-button--danger:active {
  background-color: #c82020;
}
.swal-button--danger:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(220, 38, 38, 0.29);
}

.swal-footer {
  text-align: center;
}

.swal-content__input:focus {
  border-color: rgba(26, 35, 126, 0.29);
}

.swal-content__textarea:focus {
  border-color: rgba(26, 35, 126, 0.29);
}

.swal2-popup.swal2-toast .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(50, 100, 150, 0.4);
}
.swal2-popup.swal2-toast .swal2-success {
  border-color: #2ca87f !important;
}

.swal2-loader {
  border-color: var(--bs-primary) transparent var(--bs-primary) transparent !important;
}

.swal2-styled.swal2-confirm {
  background-color: var(--bs-primary) !important;
}
.swal2-styled.swal2-deny {
  background-color: #dc2626 !important;
}
.swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(26, 35, 126, 0.4) !important;
}

.swal2-close:hover {
  color: #dc2626 !important;
}

.swal2-content {
  color: #545454;
}

.swal2-file,
.swal2-input,
.swal2-textarea {
  border: 1px solid #d9d9d9;
}

.swal2-file.swal2-inputerror,
.swal2-input.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  border-color: #dc2626 !important;
  box-shadow: 0 0 2px #dc2626 !important;
}

.swal2-file:focus,
.swal2-input:focus,
.swal2-textarea:focus {
  border: 1px solid #b4dbed;
  box-shadow: 0 0 3px #c4e6f5;
}

.swal2-validation-message {
  background: #f0f0f0;
  color: #666;
}
.swal2-validation-message::before {
  background-color: #dc2626;
}

.swal2-icon.swal2-error {
  border-color: #e76868 !important;
  color: #dc2626 !important;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  background-color: #dc2626 !important;
}
.swal2-icon.swal2-warning {
  border-color: #ffae33 !important;
  color: #e58a00 !important;
}
.swal2-icon.swal2-info {
  border-color: #7ddbe3 !important;
  color: #3ec9d6 !important;
}
.swal2-icon.swal2-question {
  border-color: #916dd1 !important;
  color: #673ab7 !important;
}
.swal2-icon.swal2-success {
  border-color: #4fd1a6 !important;
  color: #2ca87f !important;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border: 0.25em solid rgba(44, 168, 127, 0.3) !important;
}
.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: #2ca87f !important;
}

.swal2-progress-steps .swal2-progress-step {
  background: var(--bs-primary);
  color: #fff;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: var(--bs-primary);
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: #3ec9d6;
  color: #fff;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #3ec9d6;
}
.swal2-progress-steps .swal2-progress-step-line {
  background: var(--bs-primary);
}

.swal2-actions .btn, .swal2-actions .introjs-tooltip .introjs-button, .introjs-tooltip .swal2-actions .introjs-button {
  margin: 0 10px;
}

div:where(.swal2-container) input:where(.swal2-input),
div:where(.swal2-container) textarea:where(.swal2-textarea),
div:where(.swal2-container) div:where(.swal2-radio),
div:where(.swal2-container) label:where(.swal2-checkbox) {
  margin: 0.4em 0em 0 !important;
}

div:where(.swal2-container) input:where(.swal2-file) {
  margin: 1em auto 3px !important;
}

div:where(.swal2-container) select:where(.swal2-select) {
  margin: 1em 2em 3px !important;
}

div:where(.swal2-container) .swal2-radio,
div:where(.swal2-container) .swal2-checkbox,
div:where(.swal2-container) .swal2-range {
  background: var(--bs-body-bg) !important;
}

.swal2-styled.swal2-confirm i {
  margin: 0 5px;
}

div:where(.swal2-container) .swal2-input,
div:where(.swal2-container) .swal2-textarea {
  margin: 1.7em 1.7em 0 !important;
}

.swal2-rtl .swal2-close {
  margin-right: initial;
  margin-left: 0;
}

div:where(.swal2-icon) {
  margin: 1.5em auto 0.6em !important;
}

div:where(.swal2-container) button:where(.swal2-close):focus {
  box-shadow: none !important;
}

/**  =====================
    26. Sweetalert css end
========================== **/
/**  =====================
    27. Lightbox css start
========================== **/
.modal-lightbox .modal-body {
  padding: 0;
}
.modal-lightbox .modal-body img {
  border-radius: 2px;
}
@media (max-width: 575.98px) {
  .modal-lightbox .modal-dialog {
    margin: var(--bs-modal-margin) 24px;
  }
}
.modal-lightbox .modal-content, .modal-lightbox .introjs-tooltip {
  border-radius: 2px;
  border: none;
}
.modal-lightbox .btn-close {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%) scale(0.8);
  background-color: var(--bs-modal-bg);
  padding: 8px;
  z-index: 5;
  opacity: 1;
  border-radius: 50%;
}

/**  =====================
    27. Lightbox css end
========================== **/
/**  =====================
    28. Notification css start
========================== **/
.alert-dismissable .close,
.alert-dismissible .close {
  color: inherit;
  text-shadow: none;
}

.notifier-container {
  z-index: 1051;
  font-family: "Inter var", sans-serif;
  width: 400px;
  max-width: 98%;
  top: 15px;
}

.notifier {
  padding: calc(25px - 5px) calc(25px - 5px);
  border-radius: 8px;
}
.notifier .notifier-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2px;
}
.notifier .notifier-body {
  font-size: 0.875rem;
}
.notifier .notifier-img .img {
  width: 40px;
  height: 40px;
}
.notifier .notifier-close:focus, .notifier .notifier-close:hover {
  color: #dc2626;
  background: transparent;
}

.notifier.theme {
  border-left-color: #ff3366;
}
.notifier.primary {
  border-left-color: #1A237E;
}
.notifier.secondary {
  border-left-color: #5b6b79;
}
.notifier.success {
  border-left-color: #2ca87f;
}
.notifier.info {
  border-left-color: #3ec9d6;
}
.notifier.warning {
  border-left-color: #e58a00;
}
.notifier.danger {
  border-left-color: #dc2626;
}
.notifier.light {
  border-left-color: #f8f9fa;
}
.notifier.dark {
  border-left-color: #212529;
}

/**  =====================
    28. Notification css end
========================== **/
/**  =====================
    29. Modal css start
========================== **/
.modal.fade.modal-animate {
  /* Effect 1: Fade in and scale up */
  /* Effect 2: Slide from the right */
  /* Effect 3: Slide from the bottom */
  /* Effect 4: Newspaper */
  /* Effect 5: fall */
  /* Effect 6: side fall */
  /* Effect 7:  slide and stick to top */
  /* Effect 8: 3D flip horizontal */
  /* Effect 9: 3D flip vertical */
  /* Effect 10: 3D sign */
  /* Effect 11: Super scaled */
  /* Effect 12:  Just me */
  /* Effect 13: 3D slit */
  /* Effect 14:  3D Rotate from bottom */
  /* Effect 15:  3D Rotate in from left */
  /* Effect 16:  Blur */
  /* Effect 17:  Slide in from bottom with perspective on container */
  /* Effect 18:  Slide from right with perspective on container */
  /* Effect 19:  Slip in from the top with perspective on container */
}
.modal.fade.modal-animate .modal-dialog {
  display: flex;
  align-items: center;
  height: calc(100% - 3.5rem);
}
.modal.fade.modal-animate.anim-fade-in-scale .modal-dialog {
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.3s;
}
.modal.fade.modal-animate.anim-fade-in-scale.show .modal-dialog {
  transform: none;
  opacity: 1;
}
.modal.fade.modal-animate.anim-slide-in-right .modal-dialog {
  transform: translateX(20%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}
.modal.fade.modal-animate.anim-slide-in-right.show .modal-dialog {
  transform: translateX(0);
  opacity: 1;
}
.modal.fade.modal-animate.anim-slide-in-bottom .modal-dialog {
  transform: translateY(20%);
  opacity: 0;
  transition: all 0.3s;
}
.modal.fade.modal-animate.anim-slide-in-bottom.show .modal-dialog {
  transform: translateY(0);
  opacity: 1;
}
.modal.fade.modal-animate.anim-newspaper .modal-dialog {
  transform: scale(0) rotate(720deg);
  opacity: 0;
  transition: all 0.5s;
}
.modal.fade.modal-animate.anim-newspaper.show .modal-dialog {
  transform: none;
  opacity: 1;
}
.modal.fade.modal-animate.anim-fall {
  perspective: 1300px;
}
.modal.fade.modal-animate.anim-fall .modal-dialog {
  transform-style: preserve-3d;
  transform: translateZ(600px) rotateX(20deg);
  opacity: 0;
  transition: all 0.5s;
}
.modal.fade.modal-animate.anim-fall.show .modal-dialog {
  transition: all 0.3s ease-in;
  transform: translateZ(0px) rotateX(0deg);
  opacity: 1;
}
.modal.fade.modal-animate.anim-side-fall {
  perspective: 1300px;
}
.modal.fade.modal-animate.anim-side-fall .modal-dialog {
  transform-style: preserve-3d;
  transform: translate(30%) translateZ(600px) rotate(10deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}
.modal.fade.modal-animate.anim-side-fall.show .modal-dialog {
  transform: translate(0%) translateZ(0) rotate(0deg);
  opacity: 1;
}
.modal.fade.modal-animate.anim-sticky-up .modal-dialog {
  align-items: flex-start;
  margin-top: 0;
  transform: translateY(-200%);
  transition: all 0.3s;
  opacity: 0;
}
.modal.fade.modal-animate.anim-sticky-up.show .modal-dialog {
  transform: translateY(0%);
  border-radius: 0 0 3px 3px;
  opacity: 1;
}
.modal.fade.modal-animate.anim-3d-flip-horizontal {
  perspective: 1300px;
}
.modal.fade.modal-animate.anim-3d-flip-horizontal .modal-dialog {
  transform-style: preserve-3d;
  transform: rotateY(-70deg);
  transition: all 0.3s;
  opacity: 0;
}
.modal.fade.modal-animate.anim-3d-flip-horizontal.show .modal-dialog {
  transform: rotateY(0deg);
  opacity: 1;
}
.modal.fade.modal-animate.anim-3d-flip-vertical {
  perspective: 1300px;
}
.modal.fade.modal-animate.anim-3d-flip-vertical .modal-dialog {
  transform-style: preserve-3d;
  transform: rotateX(-70deg);
  transition: all 0.3s;
  opacity: 0;
}
.modal.fade.modal-animate.anim-3d-flip-vertical.show .modal-dialog {
  transform: rotateX(0deg);
  opacity: 1;
}
.modal.fade.modal-animate.anim-3d-sign {
  perspective: 1300px;
}
.modal.fade.modal-animate.anim-3d-sign .modal-dialog {
  transform-style: preserve-3d;
  transform: rotateX(-60deg);
  transform-origin: 50% 0;
  opacity: 0;
  transition: all 0.3s;
}
.modal.fade.modal-animate.anim-3d-sign.show .modal-dialog {
  transform: rotateX(0deg);
  opacity: 1;
}
.modal.fade.modal-animate.anim-super-scaled .modal-dialog {
  transform: scale(2);
  opacity: 0;
  transition: all 0.3s;
}
.modal.fade.modal-animate.anim-super-scaled.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}
.modal.fade.modal-animate.anim-just-me {
  background: #fff;
}
.modal.fade.modal-animate.anim-just-me .modal-dialog {
  transform: scale(0.8);
  opacity: 0;
  transition: all 0.3s;
  background: transparent;
}
.modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content, .modal.fade.modal-animate.anim-just-me .modal-dialog .introjs-tooltip {
  border: none;
}
.modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content .modal-header, .modal.fade.modal-animate.anim-just-me .modal-dialog .introjs-tooltip .modal-header {
  background: transparent !important;
}
.modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content .modal-header .btn-close.btn-close-white, .modal.fade.modal-animate.anim-just-me .modal-dialog .introjs-tooltip .modal-header .btn-close.btn-close-white {
  filter: none;
}
.modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content .modal-header .text-white.modal-title, .modal.fade.modal-animate.anim-just-me .modal-dialog .introjs-tooltip .modal-header .text-white.modal-title {
  color: var(--pc-header-color) !important;
}
.modal.fade.modal-animate.anim-just-me.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}
.modal.fade.modal-animate.anim-3d-slit {
  perspective: 1300px;
}
.modal.fade.modal-animate.anim-3d-slit .modal-dialog {
  transform-style: preserve-3d;
  transform: translateZ(-3000px) rotateY(90deg);
  opacity: 0;
}
.modal.fade.modal-animate.anim-3d-slit.show .modal-dialog {
  animation: slit 0.7s forwards ease-out;
}
.modal.fade.modal-animate.anim-3d-rotate-bottom {
  perspective: 1300px;
}
.modal.fade.modal-animate.anim-3d-rotate-bottom .modal-dialog {
  transform-style: preserve-3d;
  transform: translateY(100%) rotateX(90deg);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s ease-out;
}
.modal.fade.modal-animate.anim-3d-rotate-bottom.show .modal-dialog {
  transform: translateY(0%) rotateX(0deg);
  opacity: 1;
}
.modal.fade.modal-animate.anim-3d-rotate-InLeft {
  perspective: 1300px;
}
.modal.fade.modal-animate.anim-3d-rotate-InLeft .modal-dialog {
  transform-style: preserve-3d;
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s;
}
.modal.fade.modal-animate.anim-3d-rotate-InLeft.show .modal-dialog {
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
  opacity: 1;
}
.modal.fade.modal-animate.anim-blur {
  backdrop-filter: blur(8px);
}
.modal.fade.modal-animate.anim-blur .modal-dialog {
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.3s;
}
.modal.fade.modal-animate.anim-blur.show .modal-dialog {
  transform: none;
  opacity: 1;
}
.modal.fade.modal-animate.anim-let-me-in .modal-dialog {
  opacity: 0;
  transform: translateY(200%);
  transition: all 0.3s 0.2s;
}
.modal.fade.modal-animate.anim-let-me-in.show .modal-dialog {
  transform: translateY(0);
  opacity: 1;
}
.modal.fade.modal-animate.anim-make-way .modal-dialog {
  opacity: 0;
  transform: translateX(200%);
  transition: all 0.3s 0.2s;
}
.modal.fade.modal-animate.anim-make-way.show .modal-dialog {
  transform: translateY(0);
  opacity: 1;
}
.modal.fade.modal-animate.anim-slip-from-top .modal-dialog {
  opacity: 0;
  transform: translateY(-200%);
  transition: all 0.5s 0.1s;
}
.modal.fade.modal-animate.anim-slip-from-top.show .modal-dialog {
  transform: translateY(0);
  opacity: 1;
}

body {
  /* Effect 17:  Slide in from bottom with perspective on container */
  /* Effect 18:  Slide from right with perspective on container */
  /* Effect 19:  Slip in from the top with perspective on container */
}
body.anim-let-me-in {
  perspective: 600px;
  overflow: hidden;
  height: 100vh;
}
body.anim-let-me-in .header-user-list,
body.anim-let-me-in .page-header,
body.anim-let-me-in .header-chat,
body.anim-let-me-in .menu-styler {
  display: none;
}
body.anim-let-me-in .pc-sidebar,
body.anim-let-me-in .pc-header,
body.anim-let-me-in .pc-container,
body.anim-let-me-in .modal-backdrop {
  transition: transform 0.3s;
}
body.anim-let-me-in.modal-open .pc-sidebar,
body.anim-let-me-in.modal-open .pc-header,
body.anim-let-me-in.modal-open .pc-container,
body.anim-let-me-in.modal-open .modal-backdrop {
  transform: rotateX(-3deg);
  transform-origin: 50% 0%;
  transform-style: preserve-3d;
}
body.anim-make-way {
  perspective: 600px;
  overflow: hidden;
  height: 100vh;
}
body.anim-make-way .header-user-list,
body.anim-make-way .page-header,
body.anim-make-way .header-chat,
body.anim-make-way .menu-styler {
  display: none;
}
body.anim-make-way .pc-sidebar,
body.anim-make-way .pc-header,
body.anim-make-way .pc-container,
body.anim-make-way .modal-backdrop {
  transition: all 0.5s;
}
body.anim-make-way.modal-open .pc-sidebar,
body.anim-make-way.modal-open .pc-header,
body.anim-make-way.modal-open .pc-container,
body.anim-make-way.modal-open .modal-backdrop {
  transform-style: preserve-3d;
  transform-origin: 0% 50%;
  animation: rotateRightSideFirst 0.5s forwards ease-in;
}
body.anim-slip-from-top {
  perspective: 600px;
  overflow: hidden;
  height: 100vh;
}
body.anim-slip-from-top .header-user-list,
body.anim-slip-from-top .header-chat,
body.anim-slip-from-top .page-header,
body.anim-slip-from-top .menu-styler {
  display: none;
}
body.anim-slip-from-top .pc-sidebar,
body.anim-slip-from-top .pc-header,
body.anim-slip-from-top .pc-container,
body.anim-slip-from-top .modal-backdrop {
  transition: all 0.5s;
  transform-style: preserve-3d;
  transform-origin: 50% 100%;
  animation: OpenTop 1.5s forwards ease-in;
}
body.anim-slip-from-top.modal-open .pc-header {
  opacity: 0;
}

@keyframes slit {
  50% {
    transform: translateZ(-250px) rotateY(89deg);
    opacity: 1;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateZ(0) rotateY(0deg);
    opacity: 1;
  }
}
@keyframes rotateRightSideFirst {
  50% {
    transform: translateZ(-50px) rotateY(5deg);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateZ(-200px);
  }
}
@keyframes OpenTop {
  50% {
    transform: rotateX(10deg);
    animation-timing-function: ease-out;
  }
}
.introjs-tooltip {
  background: #ffffff;
  position: absolute;
}
.introjs-tooltip .introjs-tooltipbuttons {
  border-top: none;
}
.introjs-tooltip .introjs-button {
  text-shadow: none;
}
/**  =====================
    29. Modal css end
========================== **/
/**  =====================
    30. Rangeslider css start
========================== **/
.slider.slider-horizontal .slider-handle.triangle,
.slider.slider-horizontal .slider-tick.triangle {
  border-bottom-color: var(--bs-primary);
}
.slider.slider-vertical .slider-handle.triangle,
.slider.slider-vertical .slider-tick.triangle {
  border-left-color: var(--bs-primary);
  border-right-color: var(--bs-primary);
}
.slider.slider-disabled .slider-handle {
  background-image: linear-gradient(to bottom, #dfdfdf 0, #bebebe 100%);
  background-repeat: repeat-x;
}
.slider.slider-disabled .slider-track {
  background-image: linear-gradient(to bottom, #f8f9fa 0, #f8f9fa 100%);
  background-repeat: repeat-x;
}

.slider-track {
  background-image: linear-gradient(to bottom, #f8f9fa 0, #f8f9fa 100%);
  background-repeat: repeat-x;
}

.slider-selection {
  background-image: linear-gradient(to bottom, #404ed8 0, #404ed8 100%);
  background-repeat: repeat-x;
}
.slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #2b3ad3 0, #2b3ad3 100%);
  background-repeat: repeat-x;
}

.slider-handle {
  background-color: var(--bs-primary);
  background-image: linear-gradient(to bottom, var(--bs-primary) 0, var(--bs-primary) 100%);
  background-repeat: repeat-x;
}

.slider-tick {
  background-image: linear-gradient(to bottom, #f9f9f9 0, #f5f5f5 100%);
  background-repeat: repeat-x;
}
.slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #2b3ad3 0, #2b3ad3 100%);
  background-repeat: repeat-x;
}

.slider .tooltip {
  position: absolute;
}

#ex7-enabled {
  position: relative;
  top: 4px;
  height: 18px;
  width: 18px;
}

#RGB {
  height: 10px;
  background: rgb(128, 128, 128);
}

#RC .slider-selection {
  background: #ff8282;
}
#RC .slider-handle {
  background: red;
}

#GC .slider-selection {
  background: #478f3b;
}
#GC .slider-handle {
  background: green;
}

#BC .slider-selection {
  background: #8283ff;
}
#BC .slider-handle {
  border-bottom-color: blue;
}

#B,
#G,
#R {
  width: 300px;
}

.slider-handle.custom {
  background: transparent none;
}
.slider-handle.custom::before {
  line-height: 15px;
  font-size: 28px;
  content: "★";
  color: #726204;
}

#slider12a .slider-track-high,
#slider12c .slider-track-high {
  background: #2ca87f;
}

#slider12b .slider-track-low {
  background: #dc2626;
}

#slider12c .slider-track-low {
  background: #dc2626;
}
#slider12c .slider-selection {
  background: #e58a00;
}

#slider22 .slider-rangeHighlight {
  background: #f70616;
}
#slider22 .slider-rangeHighlight.category1 {
  background: #ff9900;
}
#slider22 .slider-rangeHighlight.category2 {
  background: #99cc00;
}

.vtree a.vtree-leaf-label {
  color: #131920;
}
.vtree a.vtree-leaf-label:hover, .vtree a.vtree-leaf-label:focus, .vtree a.vtree-leaf-label:active {
  color: var(--bs-primary);
}

/**  =====================
    30. Rangeslider css end
========================== **/
/**  =====================
    31. Daterange css start
========================== **/
.daterangepicker {
  font-family: "Inter var", sans-serif;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 25px rgba(26, 35, 126, 0.15);
  background-color: #ffffff;
}
.daterangepicker .calendar-table {
  border: 1px solid #ffffff;
  background-color: #ffffff;
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
  color: #5b6b79;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border-color: #8996a4;
}
.daterangepicker td.in-range {
  background-color: #515ddb;
  color: #3e4853;
}
.daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date {
  background-color: #ffffff;
  color: #131920;
  opacity: 0.5;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #1A237E;
  color: #fff;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #1A237E;
  color: #fff;
}
.daterangepicker td.available:hover > span,
.daterangepicker th.available:hover > span {
  border-color: #fff;
}
.daterangepicker:after {
  border-bottom: 6px solid #ffffff;
}
.daterangepicker:before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.15);
}
.daterangepicker .ranges ul {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (min-width: 564px) {
  .daterangepicker .ranges ul {
    width: 160px;
  }
}
.daterangepicker .ranges ul li {
  margin: 5px 10px;
  transition: all 0.2s ease-in-out;
}
.daterangepicker .ranges ul li.active {
  background-color: #1A237E;
}
.daterangepicker .ranges ul li:hover {
  box-shadow: 0 15px 8px -9px rgba(0, 0, 0, 0.25);
  background-color: #1A237E;
  color: #fff;
}
.daterangepicker .drp-buttons {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
}

.datepicker-view .week {
  color: var(--bs-primary);
}

.datepicker-cell.selected {
  background-color: var(--bs-primary);
  color: #fff;
}
.datepicker-cell.selected:hover {
  background-color: var(--bs-primary);
  color: #fff;
}
.datepicker-cell.disabled {
  color: var(--bd-body-color);
  background-color: var(--bd-body-bg);
  opacity: 0.4;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  background-color: var(--bs-primary-light);
  color: var(--bs-primary);
  border-radius: 0;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: var(--bs-primary-light);
  color: var(--bs-primary);
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: var(--bs-primary);
  color: #fff;
}

.datepicker-input.in-edit {
  border-color: var(--bs-primary);
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange {
  background: var(--bs-primary);
  border-color: var(--bs-primary);
}
.flatpickr-day.selected.inRange, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: var(--bs-primary);
  border-color: var(--bs-primary);
}

/**  =====================
    31. Daterange css end
========================== **/
/**  =====================
    32. Tagsinput css start
========================== **/
.bootstrap-tagsinput {
  padding: 5px 10px;
  line-height: 28px;
  background: #f8f9fa;
  border: 1px solid #e7eaee;
  border-radius: 12px;
  width: 100%;
}
.bootstrap-tagsinput .tag {
  background: #1A237E;
  padding: 5px 12px;
  color: #fff;
  border-radius: 12px;
}

/**  =====================
    32. Tagsinput css end
========================== **/
/**  =====================
    33. Slider css start
========================== **/
/* nav */
.tns-controls {
  text-align: center;
  margin-bottom: 10px;
}
.tns-controls [aria-controls] {
  font-size: 15px;
  margin: 0 5px;
  padding: 0 1em;
  height: 2.5em;
  color: #fff;
  background: var(--bs-primary);
  border-radius: 3px;
  border: 0;
}

[data-action] {
  display: block;
  margin: 10px auto;
  font-size: 17px;
  min-width: 3em;
  text-align: center;
  background: transparent;
  border: 0;
}

.tns-controls [disabled] {
  color: #999999;
  background: #b3b3b3;
  cursor: not-allowed !important;
}

.tns-nav {
  text-align: center;
  margin: 10px 0;
}
.tns-nav > [aria-controls] {
  width: 9px;
  height: 9px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: #ddd;
  border: 0;
}
.tns-nav > .tns-nav-active {
  background: #999;
}

.thumbnails {
  margin: 20px 0;
  text-align: center;
}
.thumbnails li {
  display: inline-block;
  cursor: pointer;
  border: 5px solid #fff;
}
.thumbnails .tns-nav-active {
  background: none;
  border-color: var(--bs-primary);
}
.thumbnails img {
  height: auto;
  width: 46px;
}

.customize-tools {
  position: relative;
}

.controls {
  text-align: center;
}
.controls li {
  display: block;
  position: absolute;
  top: 50%;
  height: 60px;
  line-height: 60px;
  margin-top: -30px;
  padding: 0 15px;
  cursor: pointer;
  font-size: 30px;
  transition: background 0.3s;
}
.controls .prev {
  left: 0;
}
.controls .next {
  right: 0;
}
.controls li:hover {
  background: #f2f2f2;
}

@media (max-width: 575.98px) {
  .customize-tools .controls {
    display: none;
  }
}

/**  =====================
    33. Slider css end
========================== **/
/**  =====================
  34. Switches css start
==========================  **/
.switch {
  margin-bottom: 8px;
  margin-right: 8px;
}

.switch-off.btn, .introjs-tooltip .switch-off.introjs-button,
.switch-on.btn,
.introjs-tooltip .switch-on.introjs-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.switch-off.btn i, .introjs-tooltip .switch-off.introjs-button i,
.switch-on.btn i,
.introjs-tooltip .switch-on.introjs-button i {
  margin: 0 4px;
  font-size: 12px;
}

.slow .switch-group {
  transition: left 0.7s;
  -webkit-transition: left 0.7s;
}

.fast .switch-group {
  transition: left 0.1s;
  -webkit-transition: left 0.1s;
}

.quick .switch-group {
  transition: none;
  -webkit-transition: none;
}

.table.switch-table th,
.table.switch-table td {
  vertical-align: middle;
  padding: 0.45rem 0.25rem;
}
.table.switch-table th .btn, .table.switch-table th .introjs-tooltip .introjs-button, .introjs-tooltip .table.switch-table th .introjs-button,
.table.switch-table td .btn,
.table.switch-table td .introjs-tooltip .introjs-button,
.introjs-tooltip .table.switch-table td .introjs-button {
  margin-bottom: 0;
}

/**  =====================
  34. Switches css end
==========================  **/
/**  =====================
  35. Data Tables css start
==========================  **/
.datatable-table > tbody > tr > td,
.datatable-table > tbody > tr > th,
.datatable-table > tfoot > tr > td,
.datatable-table > tfoot > tr > th,
.datatable-table > thead > tr > td,
.datatable-table > thead > tr > th {
  vertical-align: middle;
  padding: 0.7rem 0.75rem;
}

.datatable-table td,
.datatable-table th,
.table td,
.table th {
  border-top: 1px solid #e7eaee;
  border-bottom: none;
  white-space: nowrap;
  padding: 0.7rem 0.75rem;
  vertical-align: middle;
}
.datatable-table thead th,
.table thead th {
  border-bottom: 1px solid #e7eaee;
  font-size: 13px;
  color: #262626;
  background: rgba(248, 249, 250, 0.5);
  text-transform: uppercase;
}
.datatable-table tbody + tbody,
.table tbody + tbody {
  border-top: 2px solid #e7eaee;
}
.datatable-table.table-dark thead th,
.table.table-dark thead th {
  background-color: #11171a;
}
.datatable-table.dataTable[class*=table-] thead th,
.table.dataTable[class*=table-] thead th {
  background: #f8f9fa;
}
.datatable-table > :not(:last-child) > :last-child > *,
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #e7eaee;
}
.datatable-table > :not(:first-child),
.table > :not(:first-child) {
  border-top: none;
}

/* Border versions */
.table-bordered {
  border: 1px solid #e7eaee;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e7eaee;
}

/* Zebra-striping */
.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(26, 35, 126, 0.03);
}

/* Hover effect */
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

/* Table backgrounds */
.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #0a0c0d;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #0a0c0d;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #1c2023;
}
.table-dark.table-hover tbody tr:hover {
  background-color: #16181b;
}
.table-dark thead th {
  color: #fff;
}

/* fixed header position */
table.dataTable.fixedHeader-floating {
  top: calc(74px + 55px) !important;
}
@media screen and (max-width: 992px) {
  table.dataTable.fixedHeader-floating {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .fixedHeader-locked {
    display: none !important;
  }
}
/**======= basic table css start =======**/
.table.table-xl td,
.table.table-xl th {
  padding: 1.25rem 0.8rem;
}

.table.table-lg td,
.table.table-lg th {
  padding: 0.9rem 0.8rem;
}

.table.table-de td,
.table.table-de th {
  padding: 0.75rem 0.8rem;
}

.table.table-sm td,
.table.table-sm th {
  padding: 0.6rem 0.8rem;
}

.table.table-xs td,
.table.table-xs th {
  padding: 0.4rem 0.8rem;
}

/**======= basic table css end ======**/
table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  top: 13px;
}

table.dataTable tbody > tr > .selected td.select-checkbox:after,
table.dataTable tbody > tr > .selected th.select-checkbox:after,
table.dataTable tbody > tr.selected td.select-checkbox:after,
table.dataTable tbody > tr.selected th.select-checkbox:after {
  margin-top: -21px;
  margin-left: -7px;
}

.datatable-top {
  padding: 0 4px 14px;
}
.datatable-top > div {
  margin: 0 0 5px;
}

.datatable-bottom {
  padding: 14px 4px 0;
}

.card.table-card .datatable-top {
  padding-top: 0;
}
.card.table-card .datatable-top,
.card.table-card .datatable-bottom {
  padding-left: var(--bs-card-spacer-x);
  padding-right: var(--bs-card-spacer-x);
}

.datatable-top > nav:first-child,
.datatable-top > div:first-child {
  float: left;
}

.datatable-bottom > nav:first-child,
.datatable-bottom > div:first-child {
  float: left;
}

.datatable-top > nav:last-child,
.datatable-top > div:last-child {
  float: right;
}

@media (max-width: 574.98px) {
  .datatable-top > div:last-child {
    float: left;
    width: 100%;
  }
}

.datatable-bottom > nav:last-child,
.datatable-bottom > div:last-child {
  float: right;
}

.dataTable-dropdown label {
  display: flex;
  align-items: center;
  width: 230px;
  white-space: nowrap;
}
.dataTable-dropdown label select {
  margin-right: 8px;
}

.dataTable-info {
  margin: 7px 0;
}

/* PAGER */
.dataTable-pagination ul {
  margin: 0;
  padding-left: 0;
}
.dataTable-pagination li {
  list-style: none;
  float: left;
}
.dataTable-pagination a {
  border: 1px solid transparent;
  float: left;
  margin-left: 2px;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  border-radius: 8px;
  color: #131920;
}
.dataTable-pagination a:hover {
  background-color: var(--bs-primary-light);
  color: var(--bs-primary);
}
.dataTable-pagination .active a {
  background-color: var(--bs-primary);
  color: #fff;
  cursor: default;
}
.dataTable-pagination .active a:focus, .dataTable-pagination .active a:hover {
  background-color: var(--bs-primary);
  color: #fff;
  cursor: default;
}
.dataTable-pagination .ellipsis a {
  cursor: not-allowed;
}
.dataTable-pagination .disabled a {
  cursor: not-allowed;
  cursor: not-allowed;
  opacity: 0.4;
}
.dataTable-pagination .disabled a:focus, .dataTable-pagination .disabled a:hover {
  cursor: not-allowed;
}
.dataTable-pagination .disabled a:focus, .dataTable-pagination .disabled a:hover {
  cursor: not-allowed;
  opacity: 0.4;
}
.dataTable-pagination .pager a {
  font-weight: bold;
}

/* TABLE */
.dataTable-table > tbody > tr > td,
.dataTable-table > tbody > tr > th {
  vertical-align: middle;
}
.dataTable-table > tfoot > tr > td,
.dataTable-table > tfoot > tr > th {
  vertical-align: middle;
}
.dataTable-table > thead > tr > td {
  vertical-align: middle;
}
.dataTable-table > thead > tr > th {
  vertical-align: middle;
}
.dataTable-table th {
  vertical-align: middle;
}
.dataTable-table th a {
  text-decoration: none;
  color: inherit;
  padding-right: 16px;
}

.dataTable-sorter {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
}
.dataTable-sorter::before, .dataTable-sorter::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  right: 4px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  opacity: 0.2;
}
.dataTable-sorter::before {
  border-top: 4px solid #000;
  bottom: 0px;
}
.dataTable-sorter::after {
  border-bottom: 4px solid #000;
  border-top: 4px solid transparent;
  top: 0px;
}

.asc .dataTable-sorter::after,
.desc .dataTable-sorter::before {
  opacity: 0.6;
}

.dataTables-empty {
  text-align: center;
}

.datatable-top::after,
.datatable-bottom::after {
  clear: both;
  content: " ";
  display: table;
}

.dt-responsive .dt-row {
  overflow: auto;
}

.dataTables_scrollHeadInner,
.dataTables_scrollFootInner {
  width: calc(100% - 6px) !important;
}

.dataTables_scrollBody::-webkit-scrollbar {
  width: 6px;
  opacity: 0;
}
.dataTables_scrollBody::-webkit-scrollbar:hover {
  opacity: 1;
}
.dataTables_scrollBody::-webkit-scrollbar-track {
  background: transparent;
}
.dataTables_scrollBody::-webkit-scrollbar-thumb {
  background: #e9ecef;
}
.dataTables_scrollBody::-webkit-scrollbar-thumb:hover {
  background: #aeb9c5;
}

table.dataTable tbody th.focus,
table.dataTable tbody td.focus {
  outline: 2px solid var(--bs-primary);
}

div.dt-autofill-handle {
  background: var(--bs-primary);
}

table.dataTable:not(.dtcr-cloned) {
  width: 100% !important;
}

table.dataTable {
  border-collapse: collapse !important;
}
table.dataTable .form-control, table.dataTable .datatable-input, table.dataTable .custom-select,
table.dataTable .dataTable-selector,
table.dataTable .dataTable-input {
  min-width: 100px;
  padding-right: 30px;
}
table.dataTable > tbody > tr.child ul.dtr-details {
  display: block;
}

.dt-buttons ~ .dt-search {
  margin-bottom: 15px;
}

div.dt-container div.dt-info ~ div.dt-paging {
  margin-top: 10px;
}

#basic-scroller_wrapper .dataTables_paginate,
#state-scroller_wrapper .dataTables_paginate,
#api-scroller_wrapper .dataTables_paginate {
  padding-top: 5px;
}

@media (max-width: 462px) {
  .datatable-pagination {
    margin-top: 6px;
  }
}

@media (max-width: 462px) {
  .datatable-bottom > nav:last-child,
  .datatable-bottom > div:last-child {
    float: left;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: transparent;
}

.dataTables_length select.form-select, .dataTables_length select.datatable-selector {
  padding-right: 30px;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_filter input {
    width: 155px;
  }
}

.dataTables_length,
.dataTables_filter {
  margin: 0 0 8px;
}

table.dataTable > tbody > tr,
table.DTFC_Cloned tr {
  background-color: var(--bs-card-bg);
}

.DTFC_LeftBodyWrapper .table.dataTable.DTFC_Cloned thead .sorting:after, .DTFC_LeftBodyWrapper .table.dataTable.DTFC_Cloned thead .sorting:before,
.DTFC_RightBodyLiner .table.dataTable.DTFC_Cloned thead .sorting:after,
.DTFC_RightBodyLiner .table.dataTable.DTFC_Cloned thead .sorting:before {
  display: none;
}

.fixed-header-table {
  overflow-x: hidden;
}

div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: #5561dc;
  color: #1A237E;
  border: 2px solid #1A237E;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}
div.dt-button-info h2, div.dt-button-info .h2 {
  padding: 0.5em;
  margin: 0;
  font-weight: normal;
  color: #fff;
  border-bottom: 1px solid #1A237E;
  background-color: #1A237E;
}
div.dt-button-info > div {
  padding: 1em;
}

.dtfh-floatingparenthead {
  top: 70px !important;
}

.dt-buttons {
  margin-bottom: 5px;
}

table.dataTable tbody td.focus,
table.dataTable tbody th.focus {
  box-shadow: inset 0 0 1px 2px #1A237E;
}

div.dtk-focus-alt table.dataTable tbody td.focus,
div.dtk-focus-alt table.dataTable tbody th.focus {
  box-shadow: inset 0 0 1px 2px #e58a00;
  background: rgba(229, 138, 0, 0.15);
  color: #e58a00;
}

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  top: 13px;
}

table.dataTable tbody > tr > .selected td.select-checkbox:after,
table.dataTable tbody > tr > .selected th.select-checkbox:after,
table.dataTable tbody > tr.selected td.select-checkbox:after,
table.dataTable tbody > tr.selected th.select-checkbox:after {
  margin-top: -21px;
  margin-left: -7px;
}

.datatable-wrapper.no-footer .datatable-container {
  border-bottom: none;
}

.datatable-wrapper .datatable-container {
  overflow-x: auto;
}

.datatable-top > div {
  margin: 5px 0;
}
@media (max-width: 574.98px) {
  .datatable-top > div:last-child {
    float: left;
  }
}

.datatable-dropdown label,
.dataTable-dropdown label {
  display: flex;
  align-items: center;
  width: 230px;
  white-space: nowrap;
}
.datatable-dropdown label select,
.dataTable-dropdown label select {
  margin-right: 8px;
  margin-left: 0px;
}

.datatable-sorter {
  padding-right: 18px;
}
.datatable-sorter:after {
  border-top: 0px;
  top: 4px;
}
.datatable-sorter::before {
  border-bottom: 0px;
  bottom: 4px;
}

.datatable-pagination ul {
  margin: 0;
  padding-left: 0;
}
.datatable-pagination li {
  list-style: none;
  float: left;
}
.datatable-pagination a {
  border: 1px solid transparent;
  float: left;
  margin-left: 2px;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  border-radius: var(--bs-border-radius);
  color: var(--bs-card-color);
}
.datatable-pagination a:hover {
  background-color: var(--bs-primary-light);
  color: var(--bs-primary);
}
.datatable-pagination .datatable-active a,
.datatable-pagination .active a {
  background-color: var(--bs-primary);
  color: #fff;
  cursor: default;
}
.datatable-pagination .datatable-active a:focus, .datatable-pagination .datatable-active a:hover,
.datatable-pagination .active a:focus,
.datatable-pagination .active a:hover {
  background-color: var(--bs-primary);
  color: #fff;
  cursor: default;
}
.datatable-pagination .ellipsis a {
  cursor: not-allowed;
}
.datatable-pagination .disabled a {
  cursor: not-allowed;
  cursor: not-allowed;
  opacity: 0.4;
}
.datatable-pagination .disabled a:focus, .datatable-pagination .disabled a:hover {
  cursor: not-allowed;
}
.datatable-pagination .disabled a:focus, .datatable-pagination .disabled a:hover {
  cursor: not-allowed;
  opacity: 0.4;
}
.datatable-pagination .pager a {
  font-weight: bold;
}

.dt-buttons.btn-group.flex-wrap .btn, .dt-buttons.btn-group.flex-wrap .introjs-tooltip .introjs-button, .introjs-tooltip .dt-buttons.btn-group.flex-wrap .introjs-button {
  margin-bottom: 5px;
}

.dataTable[aria-describedby=no-style_info] th {
  padding: 14px 0;
}

div.dataTables_wrapper div.dt-row {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

/**  =====================
  35. Data Tables css end
==========================  **/
/**  =====================
  36. Customizer css start
==========================  **/
.pct-c-btn {
  display: block;
  padding: 0px;
  border: 4px solid #ffffff;
  border-right: none;
  position: fixed;
  overflow: hidden;
  right: -4px;
  top: 100px;
  z-index: 1030;
  transition: all 0.15s ease-in-out;
  border-radius: 50% 4px 4px 50%;
  box-shadow: -6px 0px 14px 1px rgba(27, 46, 94, 0.04);
  background: #ffffff;
}
.pct-c-btn a {
  display: block;
  padding: 12px 16px;
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
}
.pct-c-btn a i {
  font-size: 24px;
  display: block;
  animation: anim-rotate 2.5s infinite linear;
  line-height: 24px;
  color: var(--bs-primary);
}
.pct-c-btn a:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
}
@media (max-width: 1024px) {
  .pct-c-btn {
    display: none;
  }
}

@keyframes anim-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.pct-offcanvas {
  --bs-offcanvas-width: 320px;
  --bs-offcanvas-zindex: 1079;
  transition: transform 0.45s cubic-bezier(0.37, 0.24, 0.53, 0.99);
  box-shadow: 8px 0 14px rgba(27, 46, 94, 0.4);
}
.pct-offcanvas ~ .offcanvas-backdrop {
  opacity: 0.2;
}
.pct-offcanvas .offcanvas-body {
  padding: 25px;
}
.pct-offcanvas .avtar.avtar-xs {
  width: 36px;
  height: 36px;
}
.pct-offcanvas .list-group-item {
  padding: 20px 0;
}
.pct-offcanvas .list-group-item > div > .pct-content,
.pct-offcanvas .list-group-item a.btn,
.pct-offcanvas .list-group-item .introjs-tooltip a.introjs-button,
.introjs-tooltip .pct-offcanvas .list-group-item a.introjs-button {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
}

.preset-btn {
  height: 60px;
  border: 2px solid var(--bs-gray-300);
  --bs-btn-active-border-color: var(--bs-gray-300);
}
.preset-btn:hover {
  border-color: var(--bs-primary);
}
.preset-btn:active, .preset-btn.active {
  border-color: var(--bs-primary);
}
.preset-btn.btn-img {
  height: auto;
  border-radius: 8px;
}
.preset-btn.btn-img img {
  width: 100%;
}

.theme-color {
  display: flex;
  position: relative;
  padding: 0;
}
.theme-color > a {
  position: relative;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.15s ease-in-out;
  margin-right: 4px;
  width: 20px;
  height: 50px;
  flex: none;
}
.theme-color > a::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.3);
  z-index: 1;
  transform: scale(0);
  transition: all 0.15s ease-in-out;
}
.theme-color > a i {
  font-size: 26px;
  color: #fff;
  transform: scale(0);
  transition: all 0.15s ease-in-out;
}
.theme-color > a i::before {
  position: relative;
  z-index: 5;
}
.theme-color > a:hover::after {
  transform: scale(1);
}
.theme-color > a.active {
  flex: 1;
}
.theme-color > a.active::after,
.theme-color > a.active i {
  transform: scale(1);
}
.theme-color.preset-color > a[data-value=preset-1] {
  background: #1A237E;
}
.theme-color.preset-color > a[data-value=preset-2] {
  background: #6610f2;
}
.theme-color.preset-color > a[data-value=preset-3] {
  background: #673ab7;
}
.theme-color.preset-color > a[data-value=preset-4] {
  background: #e83e8c;
}
.theme-color.preset-color > a[data-value=preset-5] {
  background: #dc2626;
}
.theme-color.preset-color > a[data-value=preset-6] {
  background: #fd7e14;
}
.theme-color.preset-color > a[data-value=preset-7] {
  background: #e58a00;
}
.theme-color.preset-color > a[data-value=preset-8] {
  background: #2ca87f;
}
.theme-color.preset-color > a[data-value=preset-9] {
  background: #008080;
}
.theme-color.preset-color > a[data-value=preset-10] {
  background: #3ec9d6;
}
.theme-color.preset-color > a[data-value=preset-11] {
  background: #212529;
}

.theme-layout .btn i, .theme-layout .introjs-tooltip .introjs-button i, .introjs-tooltip .theme-layout .introjs-button i {
  font-size: 26px;
}

.theme-main-layout a {
  padding: 5px;
  border: 2px solid var(--bs-gray-300);
  border-radius: 8px;
}
.theme-main-layout a.active {
  border: 2px solid var(--bs-primary);
}

.customizer-body {
  height: calc(100% - 85px);
}

.preset-btn {
  padding: 5px;
  width: 100%;
  line-height: 1;
}
.preset-btn.active {
  border-color: var(--bs-primary);
}

@media (max-width: 1199.98px) {
  .pc-box-width {
    display: none;
  }
}

[data-pc-layout=compact] [data-pc-layout=horizontal] .pc-rtl {
  display: none;
}

.auth-main ~ .pct-offcanvas .pc-boxcontainer {
  display: none;
}

/**  =====================
  36. Customizer css end
==========================  **/